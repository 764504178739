import React,{useState} from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Modal, Stack, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CreateShop from "./CreateShop";
import DeleteShop from "./DeleteShop";

export default function ShopAction({
  Data,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);


  return (

    <div>

      <IconButton onClick={handleClick}>
        <MoreVertIcon sx={{ color: "#3d3d3d" }} />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenEdit();
          }}
        >
          <Stack direction="row" spacing={1}>
            <AppRegistrationOutlinedIcon className="icon-menu-update" />
            <Typography className="text-menu"> Update </Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenDelete();
          }}
        >
          <Stack direction="row" spacing={1}>
            <DeleteOutlineOutlinedIcon className="icon-menu-delete" />
            <Typography className="text-menu"> Delete </Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <Modal open={openEdit}>
        <CreateShop
          handleClose={handleCloseEdit}
          setRefetch={setRefetch}
          editData={Data}
          setAlert={setAlert}
          setMessage={setMessage}
          setchekMessage={setchekMessage}
        />
      </Modal>
      
      <Modal open={openDelete}>
        <DeleteShop
          handleClose={handleCloseDelete}
          setRefetch={setRefetch}
          shopID={Data?._id}
          setAlert={setAlert}
          setMessage={setMessage}
          setchekMessage={setchekMessage}
        />
      </Modal>

    </div>
    
  );
}
