import React, { useState, useEffect } from "react";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Autocomplete,
  Paper,
} from "@mui/material";
import "./listpurchase.scss";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useQuery } from "@apollo/client";
import { GET_ALL_PRODUCT_WITH_PAGINATION } from "../../Schema/product";

export default function ListCheckProduct(props) {
  const [product, setProduct] = useState([]);
  const { data } = useQuery(GET_ALL_PRODUCT_WITH_PAGINATION, {
    variables: {
      page: 1,
      limit: 8,
      keyword: "",
      category: "",
    },
  });

  useEffect(() => {
    if (data) {
      let rows = [];
      data?.getProductsPagination?.data.forEach((element) => {
        const allrow = {
          // label: element?.name+" "+element?.remark,
          label: element?.name,
          id: element?._id,
          unit_Price: element?.cost,
        };
        rows.push(allrow);
      });
      setProduct(rows);
    }
  }, [data]);
  //end

  // Handle Message Error TextField
  const [errorMessage, setErrorMessage] = useState([
    "Can't input 0",
    "Invalid Value",
  ]);

  const [touchedQty, setTouchedQty] = useState(false);
  const handleTouchQty = () => setTouchedQty(true);

  const items = props.items;
  const listItems = items.map((item) => {
    return (
      <TableBody key={item.key} component={Paper} className="body-list-product">
        <TableRow className="body-row" width="100%">
          <TableCell className="body-title" component="th" scope="row">
            <Stack direction="column" spacing={1} className="btn-filter">
              <Autocomplete
                disabled
                disablePortal
                id="combo-box-demo"
                size="small"
                className="input-root"
                options={product}
                value={{ id: item?.product_Id, label: item?.product_name }}
                sx={{ width: 300 }}
                // onChange={(e, value) => {
                //   props.setUpdateProduct(value?.id, value?.label, item.key);
                //   props.setUpdateUnit_Price(value?.unit_Price, item?.key);
                // }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </TableCell>

          <TableCell className="body-title">
            <TextField
              fullWidth
              type="number"
              className="text-field"
              id={item.key}
              size="small"
              value={item.qty}
              onChange={(e) => props.setUpdateQty(e.target.value, item.key)}
              onFocus={handleTouchQty}
              error={
                (touchedQty && item?.qty < 0) ||
                item?.qty === 0 ||
                props.checkFieldRequired === 0 ||
                (touchedQty && isNaN(item?.qty))
              }
              helperText={
                (touchedQty && item?.qty < 0 && errorMessage[1]) ||
                (item?.qty === 0 && errorMessage[0]) ||
                (props.checkFieldRequired === 0 && errorMessage[0]) ||
                (touchedQty && isNaN(item?.qty) && errorMessage[1])
              }
            />
          </TableCell>

          <TableCell className="body-title">
            <TextField
              fullWidth
              type="number"
              className="text-field"
              id={item.key}
              size="small"
              value={item.unit_Price}
              onChange={(e) =>
                props.setUpdateUnit_Price(e.target.value, item.key)
              }
            />
          </TableCell>

          <TableCell className="body-title">
            <LocalizationProvider
              className="date-controll"
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                value={item?.expire_At}
                onChange={(e) => props.setUpdateExpire_At(e, item.key)}
                renderInput={(params) => (
                  <TextField
                    className="selection"
                    size="small"
                    {...params}
                    type="date"
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </TableCell>

          <TableCell className="body-title" align="right">
            <IconButton
              onClick={() => {
                props.deleteItem(item.key);
              }}
            >
              <DeleteRoundedIcon sx={{ color: "red" }} />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  });
  // return <FlipMove duration={300} easing="ease-in-out">
  //     {listItems}
  // </FlipMove>;

  return listItems;
}
