import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Button,
  Stack,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Pagination,
  Skeleton,
  FormControl,
  Select,
  Menu,
  MenuItem,
} from "@mui/material";
import "./../../Pages/shop.scss";
import CreatePurchase from "./CreatePurchase";
import PurchaseRoomAction from "./PurchaseRoomAction";
import AlertMessage from "../AlertMessage/AlertMessage";
import SearchIcon from "@mui/icons-material/Search";
import Profile from "../Menu/Profile";
import { Link, useLocation } from "react-router-dom";
import ViewPurchase from "./ViewPurchase";
// icon priority
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import moment from "moment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { GET_PURCHASE_PAGINATION , GET_PURCHASE_BYID } from "../../Schema/purchase";
import { useQuery } from "@apollo/client";

export default function PurchaseRoom() {

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(true);

  // get id from  url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [roomId, setRoomId] = React.useState(params.get("id"));
  const [roomName, setRoomName] = React.useState(params.get("name"));
  const [purchaseID,setPurchaseID] = React.useState(params.get("poID"));


  React.useEffect(() => {
    setRoomId(params.get("id"));
    setRoomName(params.get("name"));
    setPurchaseID(params.get("poID"));
  }, [location.search]);


  const [openViewPurchase, setOpenViewPurchase] = React.useState(false);
  const handleOpenViewPurchase = () => setOpenViewPurchase(true);
  const handleCloseViewPurchase = () => {
    window.history.replaceState(null, "", `/storage-room/purchase?id=${roomId}&name=${roomName}`)
    setOpenViewPurchase(false);
  }


  //Alert message
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [checkMessage, setCheckMessage] = useState("");
  const [DataCell, setDataCell] = useState([]);


  
  //================= get purchase by id =============
  const { refetch: refetchPurchaseByID } = useQuery(GET_PURCHASE_BYID,{
      variables: {
          purchaseId: purchaseID ? purchaseID : ""
      },
      onCompleted: ({getPurchaseById}) => {
          console.log(getPurchaseById)
          if(getPurchaseById) {
            setDataCell(getPurchaseById)
            handleOpenViewPurchase()
          }
      },
  })


  useEffect( () => {
    if(purchaseID !== "" && purchaseID !== undefined && purchaseID !== null) {
      refetchPurchaseByID();
    }
  },[location.search])


  // End get Id Storage Room

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState("");
  const [showPage, setShowPage] = useState(1);

  //================== ================
  const { data, refetch } = useQuery(GET_PURCHASE_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      storageRoomId: roomId,
      approveStatus: "",
      priority: "",
    },
    onCompleted: ({getPurchasingPaginatioin}) => {
      console.log(getPurchasingPaginatioin)
      setLoading(false);

    },
    onError: (error) => {
      console.log(error.message)
      setLoading(true);
    },
  });
  useEffect(() => {
    setShowPage(page);
  }, [page, keyword]);

  //click setlocalStorage
  const handleClick = (e) => {
    localStorage.setItem("purRoomId", JSON.stringify(e?._id));
    return null;
  };

  //get value from localstorage
  const purRoomId = JSON.parse(localStorage.getItem("purRoomId"));

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Box className="customer-page">
      <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center" spacing={1}>
          <Link to="/storage-room" style={{ textDecoration: "none" }}>
            <Typography className="page-title"> Storage Room </Typography>
          </Link>
        </Stack>

        <Stack direction="column" justifyContent="center" spacing={1}>
          <ChevronRightIcon className="icon-slash" />
        </Stack>

        <Stack direction="column" justifyContent="center" spacing={1}>
          <Typography className="page-title">{roomName}</Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }}></Box>
        <Profile />
      </Stack>

      <Stack direction="row" spacing={1}>
        <Stack direction="column" spacing={2} className="btn-search">
          <Box className="btn-text-field">
            <TextField
              className="text-field"
              fullWidth
              onChange={(e) => setKeyword(e.target.value)}
              id="input-with-sx"
              placeholder="Search PO Code"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="column" spacing={2} className="stack-btn">
          <Button
            onClick={handleOpen}
            startIcon={<AddCircleOutlineOutlinedIcon className="icon-add" />}
            className="btn-add"
          >
            <Typography className="btn-text">Create</Typography>
          </Button>

          <Modal open={open}>
            <CreatePurchase
              handleClose={handleClose}
              setRefetch={refetch}
              roomId={roomId}
              checkButton={"create"}
              setAlert={setAlert}
              setMessage={setMessage}
              setCheckMessage={setCheckMessage}
            />
          </Modal>
        </Stack>
      </Stack>

      <TableContainer className="container">
        <Table className="table" aria-label="simple table">
          <TableHead>
            <TableRow className="header-row">
              <TableCell className="header-title">PO Code</TableCell>
              <TableCell className="header-title">Purchase By </TableCell>
              <TableCell className="header-title">Supplier </TableCell>
              <TableCell className="header-title" align="center">
                Need Date
              </TableCell>
              <TableCell className="header-title" align="center">
                Priority
              </TableCell>
              <TableCell className="header-title" align="center">
                Status
              </TableCell>
              <TableCell className="header-title" align="center">
                Remark
              </TableCell>
              <TableCell className="header-title"></TableCell>
            </TableRow>
          </TableHead>

          {loading ? (
            <TableHead>
              <TableRow className="header-row">
                <TableCell className="header-title" colSpan={7}>
                  <Skeleton
                    sx={{ height: 50, width: "100%" }}
                    variant="rectangular"
                  />
                </TableCell>
              </TableRow>

              <TableRow className="header-row">
                <TableCell className="header-title" colSpan={7}>
                  <Skeleton
                    sx={{ height: 50, width: "100%" }}
                    variant="rectangular"
                  />
                </TableCell>
              </TableRow>

              <TableRow className="header-row">
                <TableCell className="header-title" colSpan={7}>
                  <Skeleton
                    sx={{ height: 50, width: "100%" }}
                    variant="rectangular"
                  />
                </TableCell>
              </TableRow>

              <TableRow className="header-row">
                <TableCell className="header-title" colSpan={7}>
                  <Skeleton
                    sx={{ height: 50, width: "100%" }}
                    variant="rectangular"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          ) : (
            <>
              {data?.getPurchasingPaginatioin?.data.map((row, index) =>
                row?.storage_Room_Id?._id === roomId ? (
                  <TableBody
                    key={index}
                    className={purRoomId === row?._id ? "body" : "body-odd"}
                  >
                    <TableRow
                      className="body-row"
                      onClick={() => handleClick(row)}
                    >
                      <TableCell
                        onClick={() => {handleOpenViewPurchase(row);setDataCell(row)}}className="body-title"
                        width="10%"
                      >
                        {row?.numbering}
                      </TableCell>
                      <TableCell
                        onClick={() => {handleOpenViewPurchase(row);setDataCell(row)}} className="body-title"
                      >{row?.purchase_By?.firsName+" "+row?.purchase_By?.lastName}
                      </TableCell>
                      <TableCell
                        onClick={() => {handleOpenViewPurchase(row);setDataCell(row)}} className="body-title"
                      >{row?.supplier_id?.name}
                      </TableCell>
                      <TableCell
                        onClick={() => {handleOpenViewPurchase(row);setDataCell(row)}} className="body-title"
                        align="center"
                      >
                        {moment(row?.need_Date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        onClick={() => {handleOpenViewPurchase(row);setDataCell(row)}}
                        className="body-title"
                        align="center"
                      >
                        {row?.priority === "Meduim" ? (
                          <Stack
                            direction="row"
                            sx={{ justifyContent: "center" }}
                            spacing={1}
                          >
                            <FiberManualRecordIcon
                              sx={{ color: "#2AEA00", width: "15px" }}
                            />
                            <Typography className="sub-title">{row?.priority}</Typography>
                          </Stack>
                        ) : row?.priority === "Urgent" ? (
                          <Stack
                            direction="row"
                            sx={{ justifyContent: "center" }}
                            spacing={1}
                          >
                            <PriorityHighIcon
                              sx={{ color: "red", width: "15px" }}
                            />
                            <Typography className="sub-title">
                              {row?.priority}
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack
                            direction="row"
                            sx={{ justifyContent: "center" }}
                            spacing={1}
                          >
                            <NotificationsActiveIcon sx={{ color: "red", width: "15px" }}/>
                            <Typography className="sub-title">
                              {row?.priority}
                            </Typography>
                          </Stack>
                        )}
                      </TableCell>

                      <TableCell
                        onClick={() => {handleOpenViewPurchase(row);setDataCell(row)}}
                        align="center"
                        className="body-title"
                      >
                        {row?.status === true ? (
                          <Stack
                            direction="row"
                            sx={{ justifyContent: "center" }}
                            spacing={1}
                          >
                            <Typography
                              className="status-voided"
                              align="center"
                            >
                              voided
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack
                            direction="row"
                            sx={{ justifyContent: "center" }}
                            spacing={1}
                          >
                            <Typography
                              className={`status-${row.approve_status}`}
                              align="center"
                            >
                              {row?.approve_status}
                            </Typography>
                          </Stack>
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => {handleOpenViewPurchase(row);setDataCell(row)}}
                        align="center"
                        className="body-title"
                      >
                        {row?.remark}
                      </TableCell>
                      <TableCell className="body-title" align="right" onClick={()=>setDataCell(row)}>
                        <PurchaseRoomAction
                          setRefetch={refetch}
                          roomId={roomId}
                          row={row}
                          setAlert={setAlert}
                          setMessage={setMessage}
                          setCheckMessage={setCheckMessage}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : null
              )}
            </>
          )}
        </Table>

        <Stack className="pagination" direction="row" spacing={1}>
          <Pagination
            page={showPage}
            hideNextButton={false}
            hidePrevButton={false}
            variant="outlined"
            count={data?.getPurchasingPaginatioin?.paginator?.totalPages}
            onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
          />

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Select
                id="demo-simple-select"
                size="small"
                value={limit}
                defaultValue={limit}
                onChange={(e) => setLimit(e.target.value)}
              >
                <MenuItem value={8}> 8 /Page</MenuItem>
                <MenuItem value={10}>10 /Page</MenuItem>
                <MenuItem value={20}>20 /Page</MenuItem>
                <MenuItem value={30}>30 /Page</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </TableContainer>

      <Modal open={openViewPurchase}>
        <ViewPurchase
          handleClose={handleCloseViewPurchase}
          DataCell={DataCell}
          setRefetch={refetch}
          setAlert={setAlert}
          setMessage={setMessage}
          setCheckMessage={setCheckMessage}
        />
      </Modal>

      <AlertMessage
        alert={alert}
        setAlert={setAlert}
        message={message}
        setMessage={setMessage}
        checkMessage={checkMessage}
      />
    </Box>
  );
}
