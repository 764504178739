import React, {useState} from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import "./deletestorageroom.scss";
import { useMutation } from "@apollo/client";
import { DELETE_STORAGE_ROOM } from "../../Schema/storageroom";

export default function DeleteStorageRoom({
  roomId,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
  handleClose,
}) {
  const [valueVoid, setValueVoid] = useState("");
  const [deleteStorageRoom] = useMutation(DELETE_STORAGE_ROOM, {
    onCompleted: ({ deleteStorageRoom }) => {
      if (deleteStorageRoom?.status) {
        setchekMessage("success");
        setMessage(deleteStorageRoom?.message);
        setAlert(true);
        setRefetch();
        handleClose();
      } else {
        setchekMessage("error");
        setMessage(deleteStorageRoom?.message);
        setAlert(true);
      }
    },
    onError: (error) => {
      setchekMessage("error");
      setMessage(error.message);
      setAlert(true);
    },
  });

  const handleDelete = () => {
    deleteStorageRoom({
      variables: {
        storageRoomId: roomId,
      },
    });
  };
  return (
    <Box className="delete-supplies">
      <Stack direction="row" spacing={5}>
        <Typography className="title" variant="h6">
          DELETE STORAGEROOM
        </Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <IconButton onClick={handleClose}>
          <ClearIcon sx={{ color: "red" }} />
        </IconButton>
      </Stack>

      <Stack direction="row" spacing={5} width="100%">
        <Typography variant="subtitle1" className="simple-text">
          Do you want to delete STORAGEROOM?
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mt: 2 }}
      >
        <Typography variant="subtitle1" className="simple-text">
          Please input 
        </Typography>
        <Typography className="body-text" >
          STORAGEROOM
        </Typography>
        <Typography variant="subtitle1" className="simple-text">
          to delete
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mb: 4 }}
      >
        <TextField
          className="text-input"
          size="small"
          fullWidth
          onChange={(e) => setValueVoid(e.target.value)}
        />
      </Stack>

      <Stack direction="row" spacing={5}>
        {valueVoid === "STORAGEROOM" ? (
          <Button
            onClick={handleDelete}
            sx={{ ":hover": { backgroundColor: "red", border: "none" } }}
            className="btn-void"
            variant="outlined"
            fullWidth
          >
            DELETE NOW
          </Button>
        ) : (
          <Button variant="outlined" className="btn-delete" fullWidth>
            DELETE
          </Button>
        )}
      </Stack>
    </Box>
  );
}
