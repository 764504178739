import React, {useState} from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Stack, Typography, IconButton, Modal} from '@mui/material';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


//component
import UpdateProduct from './UpdateProduct';
import DeleteProduct from './DeleteProduct';

export default function ProductAction({Data, refetch, setAlert, setMessage, setchekMessage}) {

    const [anchorEl, setAnchorEl] =useState(null);
    const openEl = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseEl = () => {
      setAnchorEl(null);
    };
  
    //Modal view
    const [open, setOpen] =useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    //Modal delete
    const [openDel, setOpenDel] =useState(false);
    const handleOpenDel = () => setOpenDel(true);
    const handleCloseDel = () => setOpenDel(false);
    
  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon sx={{ color: "#3D3D3D" }} />
      </IconButton>

      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={openEl}
        onClose={handleCloseEl}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={()=>{handleOpen(); handleCloseEl()}} >
         <Stack direction="row">
            <AppRegistrationOutlinedIcon className="icon-menu-update" />
            <Typography className="text-menu"> Update </Typography>
          </Stack>
        </MenuItem>

        <MenuItem onClick={()=>{handleOpenDel(); handleCloseEl()}}>
          <Stack direction="row">
            <DeleteOutlineOutlinedIcon className="icon-menu-delete" />
            <Typography className="text-menu"> Delete </Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <Modal open={open}>
        <UpdateProduct
            handleClose={handleClose}
            setAlert={setAlert}
            setMessage={setMessage}
            setchekMessage={setchekMessage}
            Data={Data}
            refetch={refetch}
            btnTitle={"Update"}
        />
      </Modal>

      <Modal open={openDel}>
        <DeleteProduct
            setAlert= {setAlert}
            setMessage ={setMessage}
            setchekMessage={setchekMessage}
            handleCloseDel={handleCloseDel}
            Data={Data}
            refetch={refetch}
        />
      </Modal>
    </div>
  );
}
