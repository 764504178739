import * as React from "react";
import "./reporttable.scss";
import { useQuery, useMutation } from "@apollo/client";
import { GET_STOCK_IN_REPORT, ADJUST_STOCK_IN } from "../../Schema/report";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import moment from "moment";
import NumberFormat from "react-number-format";
//logo
import Logo from "../../Assets/GoGlobalFull_Name.png";
import WingdingImage from "../../Assets/tacteing.jpg";


function StockInReportPrint({
  FromData,
  ToData,
  category,
  productId,
  setAlert,
  setMessage,
  setchekMessage,
}) {
  const [viewReport, setViewReport] = React.useState([]);
  const [editInput, setEditInput] = React.useState(false);
  const [quantityArray, setQuantityArray] = React.useState([]);
  const [unitPriceArray, setUnitPriceArray] = React.useState([]);

  const { data, refetch } = useQuery(GET_STOCK_IN_REPORT, {
    variables: {
      catagory: category !== undefined && category !== "" ? category : "",
      productId: productId !== undefined && productId !== "" ? productId : "",
      from: FromData !== undefined && FromData !== "" ? FromData : "",
      to: ToData !== undefined && ToData !== "" ? ToData : "",
    },
    onCompleted: ({ getStockInReport }) => {
      setViewReport(getStockInReport);

      console.log("getStockInReport::::",getStockInReport)
      
      // Qty Array
      let quantity = [];
      getStockInReport?.data?.forEach((element, index) => {
        const allQuantity = element?.qty;
        quantity.push(allQuantity);
      });
      setQuantityArray(quantity);

      // Unit Pice Array
      let unitPrice = [];
      getStockInReport?.data?.forEach((element, index) => {
        const allUnitprice = element?.unit_Price;
        unitPrice.push(allUnitprice);
      });
      setUnitPriceArray(unitPrice);
    },
    onError: (error) => {
      console.log(error?.message);
    },
    pollInterval: 10000,
  });

  const [adjustStockin] = useMutation(ADJUST_STOCK_IN, {
    onCompleted: ({ adjustStockin }) => {
      setchekMessage("success");
      setMessage(adjustStockin?.message);
      refetch();
    },
    onError: ({ error }) => {
      console.log("error", error?.message);
    },
  });

  React.useEffect(() => {
    refetch();
  }, [FromData , ToData , category , productId]);

  const handlechangeinput = (index, replaceValue) => {
    const newArray = quantityArray?.map((val, i) =>
      i !== index ? val : parseFloat(replaceValue)
    );
    // console.log(newArray, "newArray");
    setQuantityArray(newArray);
  };

  const handleAdjustQty = (index, rowData) => {
    adjustStockin({
      variables: {
        id: rowData?.purchas_id,
        productId: rowData?.product_id,
        qty: quantityArray[index],
        unitPrice: rowData?.unit_Price,
      },
    });
  };

  const handleChangePrice = (index, replaceValue) => {
    let newArray = unitPriceArray?.map((val, i) =>
      i !== index ? val : parseFloat(replaceValue)
    );
    setUnitPriceArray(newArray);
  };

  const handleUnitPrice = (index, rowData) => {
    adjustStockin({
      variables: {
        id: rowData?.purchas_id,
        productId: rowData?.product_id,
        qty: rowData?.qty,
        unitPrice: unitPriceArray[index],
      },
    });
  };

  return (
    <div className="tableRP">
      <Box className="header-table">
        <Grid container spacing={2} className="grid-containner">
          <Grid item xs={4} className="grid-item">
            <Stack direction="column" justifyContent="right" className="logo">
              <Box sx={{ mt: "58px" }}>
                <img src={Logo} width="130px" height="130px" />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={4} className="grid-item2">
            <Stack className="kingdom" direction="column" spacing={1}>
              <Typography className="title2">ព្រះរាជាណាចក្រកម្ពុជា</Typography>
              <Typography className="title2">
                ជាតិ សាសនា ព្រះមហាក្សត្រ
              </Typography>
              <div className="tackteng-font">
                <img
                  alt="Image"
                  src={WingdingImage}
                  className="image-tackteng"
                />
              </div>
            </Stack>
            <Stack direction="row" justifyContent="center" mt={3.5}>
              <Typography className="title3">
                បញ្ជីឈ្មោះទំនិញសម្រាប់ហ្គោគ្លូប៊លម៉ាត
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <TableContainer className="TableContainerPrint">
        <Table sx={{ minWidth: 650 }}>
          <TableHead className="tbhead">
            <TableRow className="item-row">
              <TableCell className="title" align="center" width="3%">
                N°{" "}
              </TableCell>
              <TableCell className="title" align="center" width="8%">
                Date
              </TableCell>
              <TableCell className="title" align="center" width="27%">
                Item
              </TableCell>
              <TableCell className="title" align="center" width="8%">
                Unit
              </TableCell>
              <TableCell className="title" align="center" width="8%">
                Quantity
              </TableCell>
              <TableCell className="title" align="center" width="8%">
                Unit Price
              </TableCell>
              <TableCell className="title" align="left" width="8%">
                Amount
              </TableCell>

              <TableCell className="title" width="30%" align="left">
                Vendor
              </TableCell>
            </TableRow>

            <TableRow className="item-row-amout">
              <TableCell className="title" colSpan={4}></TableCell>
              <TableCell className="title" align="left">
                <Stack
                  direction="row"
                  sx={{
                    fontFamily: "Century Gothic",
                    justifyContent: "center",
                  }}
                  spacing={2}
                >
                  {(viewReport?.total_qty)?.toFixed(2)}
                </Stack>
              </TableCell>

              <TableCell className="title" align="left">
                <Stack
                  direction="row"
                  sx={{ fontFamily: "Century Gothic", justifyContent: "left" }}
                  spacing={2}
                >
                  $ &nbsp; &nbsp;
                  <NumberFormat
                    value={viewReport?.total_unit_price?.toFixed(2)}
                    thousandSeparator={true}
                    displayType={"text"}
                    renderText={(value, props) => <div {...props}>{value}</div>}
                  />
                </Stack>
              </TableCell>
              <TableCell className="title" align="left">
                <Stack
                  direction="row"
                  sx={{ fontFamily: "Century Gothic", justifyContent: "left" }}
                  spacing={2}
                >
                  $ &nbsp; &nbsp;
                  <NumberFormat
                    value={viewReport?.total_amount?.toFixed(2)}
                    thousandSeparator={true}
                    displayType={"text"}
                    renderText={(value, props) => <div {...props}>{value}</div>}
                  />
                </Stack>
              </TableCell>
              <TableCell className="title" colSpan={1}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody className="bodytable">
            {viewReport?.data?.map((row, index) => (
              <TableRow key={index} className="tbrow">
                <TableCell className="title" align="center">
                  <Typography
                    sx={{ fontFamily: "Century Gothic", fontSize: "14px" }}
                  >
                    {index + 1}
                  </Typography>
                </TableCell>
                <TableCell className="title">
                  <Typography
                    sx={{ fontFamily: "Century Gothic", fontSize: "14px" }}
                  >
                    {moment(row?.date)?.utc(false)?.format("DD-MMM-YYYY")}
                  </Typography>
                </TableCell>
                <TableCell className="title">{row?.item+" "+row?.remark}</TableCell>
                <TableCell className="title" align="center">
                  {" "}
                  {row?.unit}
                </TableCell>

                <TableCell className="title" align="center">
                  {/* <input
                    style={{
                      textAlign: "center",
                      border: "none",
                      width: "30px",
                      fontFamily: "Century Gothic",
                      backgroundColor: "#fafafa",
                    }}
                    value={
                      isNaN(quantityArray[index]) ? 0 : quantityArray[index]
                    }
                    onChange={(e) => handlechangeinput(index, e.target.value)}
                    onBlur={() => handleAdjustQty(index, row)}
                  /> */}

                  { isNaN(quantityArray[index]) ? "0.00" : (quantityArray[index])?.toFixed(2) }
                </TableCell>
                <TableCell
                  className="title"
                  align="center"
                  // onClick={() => setEditInput(true)}
                >
                  <Stack
                    direction="row"
                    sx={{
                      fontFamily: "Century Gothic",
                      justifyContent: "left",
                    }}
                  >
                    {editInput ? (
                      <input
                        type="number"
                        style={{
                          textAlign: "left",
                          border: "none",
                          backgroundColor: "#fafafa",
                        }}
                        value={
                          isNaN(unitPriceArray[index]?.toFixed(2))
                            ? 0
                            : unitPriceArray[index]
                        }
                        onChange={(e) =>
                          handleChangePrice(index, e.target.value)
                        }
                        onBlur={() => {
                          setEditInput(false);
                          handleUnitPrice(index, row);
                        }}
                      />
                    ) : (
                      <>
                        $ &nbsp; &nbsp;
                        <NumberFormat
                          value={unitPriceArray[index]?.toFixed(2)}
                          thousandSeparator={true}
                          displayType={"text"}
                          renderText={(value, props) => (
                            <div {...props}>{value}</div>
                          )}
                        />
                      </>
                    )}
                  </Stack>
                </TableCell>

                <TableCell className="title" align="left">
                  <Stack
                    direction="row"
                    sx={{
                      fontFamily: "Century Gothic",
                      justifyContent: "left",
                    }}
                  >
                    $ &nbsp; &nbsp;
                    <NumberFormat
                      value={row?.amount?.toFixed(2)}
                      thousandSeparator={true}
                      displayType={"text"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </Stack>
                </TableCell>
                <TableCell className="title"> {row?.vendor} </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default StockInReportPrint;
