import { gql } from "@apollo/client";

export const GET_STOCK_IN_OUT = gql`
  query GetTotalStockInAndOut {
    getTotalStockInAndOut {
      totalStockIn
      totalStockOut
    }
  }
`;


export const GET_SUMMARY_INVENTORY = gql`
  query ProductSummaryQtyBarChart {
    productSummaryQtyBarChart {
      month
      inStock
      outStock
      onHand
    }
  }
`;


export const GET_PURCHASE_CHART = gql`
  query PurchaseProductBarChart {
    purchaseProductBarChart {
      category_name
      total_amount
      purchase_time
    }
  }
`;

export const GET_QTYINHAND = gql`
  query Query {
    getQtyInHand
  }
`;


export const GET_TOTAL_ADJUST = gql`
  query Query {
    totalAdjust
  }
`

export const GET_QTY_RECIEVED = gql`
  query Query {
    getQtyWillReceived
  }
`;


export const GET_TOTAL_USER = gql`
  query GetTotalUser {
    getTotalUser {
      shop
      supplier
    }
  }
`;

export const GET_NO_PURCHASE = gql`
  query Query {
    getNoPurchase
  }
`;

export const GET_TOTAL_COST = gql`
  query Query {
    getTotalCostOnHand
  }
`;

export const GET_TOTAL_CANCELORDER = gql`
  query Query {
    getTotalCancelOrder
  }
`;

export const GET_LOWSTOCKITEMS = gql`
  query Query {
    getLowStockItems
  }
`;

export const GET_NOITEMS = gql`
  query Query {
    getNoItems
  }
`;
