import React, { useState } from "react";
import "../../Component/StorageRoom/modalaction.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VoidReleaseCard from "./VoidStockOutCard";
import CreateReleaseCard from "./CreateStockOutCard";
import ModalDelivery from "./ModalDelivery";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import {
  IconButton,
  Modal,
  Stack,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";

export default function StockOutAction({
  row,
  setRefetch,
  setAlert,
  setMessage,
  setCheckMessage,
}) {
  // get id from  url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [releaseID, setReleaseID] = React.useState(params.get("id"));

  React.useEffect(() => {
    setReleaseID(params.get("id"));
  }, [location.search]);
  // End get Id Storage Room

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openVoid, setOpenVoid] = useState(false);
  const handleOpenVoid = () => setOpenVoid(true);
  const handleCloseVoid = () => setOpenVoid(false);

  const [openDelivery, setOpenDelivery] = useState(false);
  const handleOpenDelivery = () => setOpenDelivery(true);
  const handleCloseDelivery = () => {
    setOpenDelivery(false);
    window.history.replaceState(null, "", "/stockout");
  };

  React.useEffect(() => {
    if (releaseID !== null && row?._id === releaseID) {
      handleOpenDelivery();
    }
  }, [releaseID]);

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon sx={{ color: "#3D3D3D" }} />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        {row?.delivery !== true ? (
          <>
            <MenuItem
              onClick={() => {
                handleClose();
                handleOpenEdit();
              }}
            >
              <Stack direction="row" spacing={1}>
                <AppRegistrationOutlinedIcon className="icon-menu-update" />
                <Typography className="text-menu"> Update </Typography>
              </Stack>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleClose();
                handleOpenDelivery();
              }}
            >
              <Stack direction="row" spacing={1}>
                <DeliveryDiningIcon sx={{ color: "orange" }} />
                <Typography sx={{ color: "gray", fontFamil: "Helvetica" }}>
                  Delivery
                </Typography>
              </Stack>
            </MenuItem>
          </>
        ) : null}
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenVoid();
          }}
        >
          <Stack direction="row" spacing={1}>
            <DeleteIcon sx={{ color: "red" }} />
            <Typography sx={{ color: "gray", fontFamil: "Helvetica" }}>
              Void
            </Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <Modal open={openEdit}>
        <CreateReleaseCard
          handleClose={handleCloseEdit}
          setRefetch={setRefetch}
          checkButton={"update"}
          btnTitle="Update"
          editData={row}
          setAlert={setAlert}
          setMessage={setMessage}
          setCheckMessage={setCheckMessage}
        />
      </Modal>

      <Modal open={openDelivery}>
        <ModalDelivery
          handleClose={handleCloseDelivery}
          setRefetch={setRefetch}
          checkButton={"completed"}
          editData={row}
          setAlert={setAlert}
          setMessage={setMessage}
          setCheckMessage={setCheckMessage}
        />
      </Modal>

      <Modal open={openVoid}>
        <VoidReleaseCard
          releaseCardId={row?._id}
          handleClose={handleCloseVoid}
          setRefetch={setRefetch}
          setAlert={setAlert}
          setMessage={setMessage}
          setCheckMessage={setCheckMessage}
        />
      </Modal>
    </div>
  );
}
