import { gql } from "@apollo/client";

export const GET_STOCK_IN_REPORT = gql`
  query GetStockInReport(
    $catagory: String!
    $productId: String!
    $from: String!
    $to: String!
  ) {
    getStockInReport(
      catagory: $catagory
      product_Id: $productId
      from: $from
      to: $to
    ) {
      data {
        product_id
        purchas_id
        releaseCard_id
        date
        item
        remark
        unit
        qty
        unit_Price
        amount
        vendor
        shop
      }
      total_unit_price
      total_amount
      total_qty
    }
  }
`;

export const GET_STOCK_ON_HAND_REPORT = gql`
  query GetStockOnhandReport(
    $catagory: String!
    $productId: String!
    $from: String!
    $to: String!
  ) {
    getStockOnhandReport(
      catagory: $catagory
      product_Id: $productId
      from: $from
      to: $to
    ) {
      message
      status
      total_All_Amount
      total_All_Qty
      total_all_unit_Price
      data {
        productName
        remark
        unit
        stock_Detail {
          qty_Stock
          unit_Price
        }
        total_Qty
        total_Amount
      }
    }
  }
`;

export const GET_STOCK_OUT_REPORT = gql`
  query GetStockOutReport(
    $catagory: String!
    $productId: String!
    $from: String!
    $to: String!
  ) {
    getStockOutReport(
      catagory: $catagory
      product_Id: $productId
      from: $from
      to: $to
    ) {
      data {
        product_id
        purchas_id
        releaseCard_id
        date
        item
        remark
        unit
        qty
        unit_Price
        amount
        vendor
        shop
      }
      total_unit_price
      total_amount
      total_qty
    }
  }
`;

export const GET_SUMMARY_STOCK = gql`
  query GetSummaryStock($catagory: String!, $from: String!, $to: String!) {
    getSummaryStock(catagory: $catagory, from: $from, to: $to) {
      category
      open_balance
      purchasing
      issues
      ending
    }
  }
`;

export const ADJUST_STOCK_IN = gql`
  mutation AdjustStockin(
    $id: String!
    $productId: String!
    $qty: Float!
    $unitPrice: Float!
  ) {
    adjustStockin(
      _id: $id
      product_Id: $productId
      qty: $qty
      unit_Price: $unitPrice
    ) {
      message
      status
    }
  }
`;

export const ADJUST_STOCK_OUT = gql`
  mutation AdjustStockout(
    $id: String!
    $productId: String!
    $qty: Float!
    $unitPrice: Float!
  ) {
    adjustStockout(
      _id: $id
      product_Id: $productId
      qty: $qty
      unit_Price: $unitPrice
    ) {
      message
      status
    }
  }
`;

export const GET_EXPIRE_PRODUCTS = gql`
  query GetExpireProducts(
    $page: Int!
    $limit: Int!
    $productId: String!
    $status: String!
  ) {
    getExpireProducts(
      page: $page
      limit: $limit
      product_id: $productId
      status: $status
    ) {
      message
      data {
        unit_Price
        instock_At
        expire_At
        created_At
        product_Id {
          _id
          cost
          unit
          name
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
