import { gql } from "@apollo/client";


export const CREATE_ADJUST_QUANTITY = gql`
    mutation AdjustProductInStock($productInstockId: ID, $qty: Float, $unitPrice: Float) {
        adjustProductInStock(product_instock_id: $productInstockId, qty: $qty, unit_price: $unitPrice) {
        message
        status
        }
    }
`;


export const CREATE_PRODUCT_WAREHOUSE = gql`
    mutation RecordOldProductInStock($input: OldProductInStockInput) {
        recordOldProductInStock(input: $input) {
            message
            status
        }
    }
`;


export const GET_PRODUCT_IN_STOCK = gql`
    query GetProductInStockForAdjust($storageRoomId: ID, $receiveDate: String, $supplierId: ID, $productId: ID , $purchaseId: ID) {
        getProductInStockForAdjust(storage_room_id: $storageRoomId, receive_date: $receiveDate, supplier_id: $supplierId, product_id: $productId , purchase_id: $purchaseId) {
            _id
            product_Id {
                name
            }
            qty
            unit_Price
            storage_Room_Id {
                _id
                name
            }
            purchase_Id {
                _id
            }
            expire_At
            instock_At
            stock_Out
            status
            stock_Status
            created_At
        }
    }
`;


export const GET_PRODUCT_IN_OLD_MONTH = gql`
    query GetOldProductInStockPaginatioin($page: Int, $limit: Int, $keyword: String, $storageRoomId: String) {
        getOldProductInStockPaginatioin(page: $page, limit: $limit, keyword: $keyword, storage_Room_Id: $storageRoomId) {
        data {
            _id
            supplier_id {
                _id
                name
            }
            numbering
            need_Date
            remark
            storage_Room_Id {
                _id
                name
            }
            purchase_By {
                _id
                firsName
                lastName
            }
            receive_By {
                _id
                firsName
                lastName
            }
            approve_By {
                _id
                firsName
                lastName
            }
            items {
                _id
                product_Id {
                    _id
                    name
                    unit
                }
                qty
                unit_Price
                purchase_Id {
                    _id
                }
                key
                storage_Room_Id {
                    _id
                    name
                }
                created_At
                stock_Status
                valua
            }
            approve_status
            priority
            status
            receive_Date
            docsType
            created_At
        }
        paginator {
            slNo
            prev
            next
            perPage
            totalPosts
            totalPages
            currentPage
            hasPrevPage
            hasNextPage
            totalDocs
        }
        message
        }
    }
`


export const GET_HISTORY_ADJUST_STOCK = gql`
    query GetAdjustProductInStockHistoryPagination($page: Int, $limit: Int, $keyword: String, $pagination: Boolean, $fromDate: String, $toDate: String, $productId: String) {
        getAdjustProductInStockHistoryPagination(page: $page, limit: $limit, keyword: $keyword, pagination: $pagination, fromDate: $fromDate, toDate: $toDate, product_id: $productId) {
            data {
                _id
                product_id {
                    name
                    image_src
                    category
                    cost
                    type
                    unit
                }
                old_qty
                old_unit_price
                new_qty
                new_unit_price
                storage_room_id {
                    _id
                    name
                    place
                }
                created_by {
                    firsName
                    lastName
                    role
                    created_At
                }
                product_stock_in_id {
                    instock_At
                    expire_At
                }
                created_at
            }
            message
            paginator {
                slNo
                prev
                next
                perPage
                totalPosts
                totalPages
                currentPage
                hasPrevPage
                hasNextPage
                totalDocs
            }
        }
    }
`