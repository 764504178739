import * as React from "react";
import {
  Box,
  Stack,
  IconButton,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import "./viewuser.scss";
import ClearIcon from "@mui/icons-material/Clear";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";

export default function ViewUser({ handleCloseView, RowData }) {

  
  return (
    <Box className="view-user">
      <Stack direction="row" spacing={2}> 

        <Typography className="text-title" variant="h6"> Details about User</Typography> 


        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={handleCloseView} sx={{ color: "red" }}>
          <ClearIcon />
        </IconButton>

      </Stack>

      <Divider variant="fullWidth"  />
      <Stack direction="row" spacing={2} sx={{ mt: 2 , width:"100%"}}>

        {RowData?.image_src === "" ?
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            
          >
          <Avatar
            alt={RowData?.firsName +" "+ RowData?.lastName}
            src={`${RowData?.image_Src}`}
            className="avatar"
            sx={{ width: 100, height: 100, fontSize:"40px" ,justifyItems: "center" }}
          />
        
        </Box>
        :
          <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              >
            <Avatar alt="Remy Sharp"  src={RowData?.image_src} sx={{ width: 100, height: 100 }}/>
          </Box>
          }
        <TableContainer>
          <Table size="small">
            <TableRow >

              <TableCell className="sub-title">
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography">Name:</Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>:
                </Stack>
              </TableCell>

              <TableCell className="text-detail" width="60%">{RowData?.firsName} {RowData?.lastName} </TableCell>

            </TableRow>

            <TableRow >

              <TableCell className="sub-title">
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography"> Position</Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>:
                </Stack>
              </TableCell>

              <TableCell className="text-detail" width="60%"> {RowData?.role} </TableCell>
            </TableRow>

            <TableRow >
              <TableCell className="sub-title">
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography"> Email </Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>:
                </Stack>
              </TableCell>

              <TableCell className="text-email" width="60%">
                <i> {RowData?.email}</i>
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
}
