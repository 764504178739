import * as React from "react";
import {
  Typography,
  Stack,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  IconButton,
  Button,
} from "@mui/material";
import ReactToPrint from "react-to-print";
//icon close
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
//components
import "./viewstockoutcard.scss";
import LogoGL from "../../Assets/GoGlobalFull_Name.png";
import TextTruncate from "react-text-truncate"; // recommend

export default function ViewStockOutCard({
  handleClose,
  releaseData,
  itemData,
}) {
  const componentRef = React.useRef(null);
  //trancate
  const TextTruncate = require("react-text-truncate");

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "right",
          width: "100%",
          padding: "20px",
        }}
      >
        <Button
          sx={{
            width: "200px",
            backgroundColor: "#0969A0",
            color: "#fff",
            ":hover": {
              color: "#0969A0",
            },
          }}
        >
          Print
        </Button>
      </div>
    );
  }, []);


  console.log(itemData)
  return (
    <div className="releasecard-page">
      <Stack
        direction="row"
        // sx={{ padding: "20px 20px 0px 20px", width: "100%" }}
      >
        <Stack
          sx={{
            fontSize: "17px",
            fontFamily: "Bayon",
            color: "#0B82C4",
          }}
        >
          មើលប័ណ្ណបញ្ចេញទំនិញ
        </Stack>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Stack className="clearbutton">
          <IconButton onClick={() => handleClose()}>
            <CloseIcon sx={{ color: "red" }} />
          </IconButton>
        </Stack>
      </Stack>

      <div ref={componentRef}>
        <div
          style={{ width: "1200px", marginTop: "20px", padding: "10px 40px" }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{ mb: 2 }}
            className="releasecard-next"
          >
            <Stack
              direction="column"
              // spacing={1}
              justifyContent="center"
              sx={{ width: "340px" }}
            >
              <Stack direction="row" justifyContent="center" width="100%">
                <img
                  src={LogoGL}
                  style={{ width: "150px", height: "150px", marginTop: "20px" }}
                />
              </Stack>
              {/* <Typography
                sx={{ color: "#1b5d3c", fontSize: "18px", textAlign: "center" }}
              >
                ហ្គោគ្លូប៊លម៉ាត
              </Typography>
              <Typography
                sx={{ color: "#1b5d3c", fontSize: "18px", textAlign: "center" }}
              >
                Go Global Mart
              </Typography> */}
            </Stack>

            <Box sx={{ flexGrow: 1 }}></Box>

            <Stack direction="column" spacing={1}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Khmer OS Muol Light",
                  paddingBottom: "10px",
                  color: "#004269",
                  textAlign: "center",
                  marginLeft: "-80px",
                }}
              >
                ប័ណ្ណបញ្ចេញទំនិញ
              </Typography>
              <Stack direction="column" spacing={1} justifyContent="right">
                <TableContainer>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontFamily: "Kantumruy",
                        lineHeight: "0.1px",
                        fontWeight: "bold",
                        border: "none",
                        boxShadow: "none",
                      }}
                    >
                      កាលបរិច្ឆេទកម្មង់
                    </TableCell>
                    <TableCell
                      style={{ height: 5 }}
                      sx={{
                        border: "1px solid #C2C2C2",
                        lineHeight: "0.1px",
                        fontFamily: "Kantumruy",
                        fontWeight: "bold",
                      }}
                    >
                      {moment(itemData?.order_Date).format("DD-MM-YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontFamily: "Kantumruy",
                        lineHeight: "0.1px",
                        fontWeight: "bold",
                        border: "none",
                        boxShadow: "none",
                      }}
                    >
                      លេខរៀង
                    </TableCell>
                    <TableCell
                      sx={{
                        borderLeft: "1px solid #C2C2C2",
                        borderRight: "1px solid #C2C2C2",
                        lineHeight: "0.1px",
                        fontFamily: "Kantumruy",
                        fontWeight: "bold",
                      }}
                    >
                      {itemData?.numbering}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontFamily: "Kantumruy",
                        lineHeight: "0.1px",
                        fontWeight: "bold",
                        border: "none",
                        boxShadow: "none",
                      }}
                    >
                      កាលបរិច្ឆេទដឹកទំនិញ
                    </TableCell>
                    <TableCell
                      sx={{
                        borderLeft: "1px solid #C2C2C2",
                        borderRight: "1px solid #C2C2C2",
                        lineHeight: "0.1px",
                        fontFamily: "Kantumruy",
                        fontWeight: "bold",
                      }}
                    >
                      {moment(itemData?.delivery_At).format("DD-MM-YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontFamily: "Kantumruy",
                        lineHeight: "0.1px",
                        fontWeight: "bold",
                        border: "none",
                        boxShadow: "none",
                      }}
                    >
                      ម៉ោងដឹកទំនិញ
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid #C2C2C2",
                        lineHeight: "0.1px",
                        fontFamily: "Kantumruy",
                        fontWeight: "bold",
                      }}
                    >
                      {/* {itemData?.time} */}
                      {moment(itemData?.time, "HH:mm A")?.format("HH:mm A")}
                    </TableCell>
                  </TableRow>
                </TableContainer>
              </Stack>
            </Stack>
          </Stack>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Stack className="address">
            <TableContainer>
              <Table>
                <TableRow>
                  <TableCell
                    sx={{
                      border: "1px solid #E3F2FD",
                      backgroundColor: "#E3F2FD",
                      width: "30%",
                      color: "#004269",
                      textAlign: "center",
                      lineHeight: "1px",
                      fontFamily: "Kantumruy",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    អតិថិជន
                  </TableCell>
                  <TableCell
                    sx={{ width: "40%", border: "none", boxShadow: "none" }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #E3F2FD",
                      backgroundColor: "#E3F2FD",
                      width: "30%",
                      color: "#004269",
                      textAlign: "center",
                      lineHeight: "1px",
                      fontFamily: "Kantumruy",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    ហ្គោគ្លូប៊លម៉ាត
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      boxShadow: "none",
                      width: "30%",
                      color: "black",
                      textAlign: "center",
                      fontFamily: "Kantumruy",
                      fontWeight: "bold",
                      paddingTop: "2px",
                      paddingBottom: "5px",
                    }}
                  >
                    {/* {itemData?.customer_Id?.name} */}
                    Go Global Mart
                  </TableCell>
                  <TableCell
                    sx={{ width: "40%", border: "none", boxShadow: "none" }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      boxShadow: "none",
                      width: "30%",
                      color: "black",
                      textAlign: "center",
                      fontFamily: "Kantumruy",
                      fontWeight: "bold",
                      paddingTop: "2px",
                      paddingBottom: "5px",
                    }}
                  >
                    ហ្គោគ្លូប៊លម៉ាត
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      boxShadow: "none",
                      width: "30%",
                      color: "black",
                      textAlign: "center",
                      fontFamily: "Kantumruy",
                      fontWeight: "bold",
                      paddingTop: "2px",
                      paddingBottom: "5px",
                    }}
                  >
                    {/* {itemData?.customer_Id?.address} */}
                    ភូមិថ្មី សង្កាត់ ស្វាយដង្គំ ក្រុងសៀមរាប
                  </TableCell>
                  <TableCell
                    sx={{ width: "40%", border: "none", boxShadow: "none" }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      boxShadow: "none",
                      width: "30%",
                      color: "black",
                      textAlign: "center",
                      fontFamily: "Kantumruy",
                      fontWeight: "bold",
                      paddingTop: "2px",
                      paddingBottom: "5px",
                    }}
                  >
                    ភូមិថ្មី សង្កាត់ ស្វាយដង្គំ ក្រុងសៀមរាប
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Stack>

          <Stack>
            <TableContainer>
              <Table>
                <TableHead className="tablehead">
                  <TableRow sx={{ backgroundColor: "#E3F2FD" }}>
                    <TableCell
                      width="5%"
                      align="center"   
                      sx={{
                        color: "#3D3D3D",
                        lineHeight: "1px",
                        fontFamily: "Kantumruy",
                        fontWeight: "bold",
                        border: "1px solid #C2C2C2",
                      }}
                    >
                      ល.រ
                    </TableCell>
                    
                    <TableCell
                      width="40%"
                      sx={{
                        color: "#3D3D3D",
                        lineHeight: "1px",
                        fontFamily: "Kantumruy",
                        fontWeight: "bold",
                        border: "1px solid #C2C2C2",
                      }}
                    >
                      ការបរិយាយ
                    </TableCell>

                    <TableCell
                       align="center"   
                      width="10%"
                      sx={{
                        color: "#3D3D3D",
                        lineHeight: "1px",
                        fontFamily: "Kantumruy",
                        fontWeight: "bold",
                        border: "1px solid #C2C2C2",
                      }}
                    >
                      ខ្នាត
                    </TableCell>

                    <TableCell
                       align="center"   
                      width="10%"
                      sx={{
                        color: "#3D3D3D",
                        lineHeight: "1px",
                        fontFamily: "Kantumruy",
                        fontWeight: "bold",
                        border: "1px solid #C2C2C2",
                      }}
                    >
                      ចំនួន
                    </TableCell>
                    <TableCell
                      width="25%"
                      sx={{
                        color: "#3D3D3D",
                        lineHeight: "1px",
                        fontFamily: "Kantumruy",
                        fontWeight: "bold",
                        border: "1px solid #C2C2C2",
                      }}
                    >
                      ផ្សេងៗ
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemData?.items?.map((data, index) => (
                    <TableRow
                      key={index}
                      sx={{ bgcolor: index % 2 === 0 ? "#E9E8E9" : "#fff" }}
                    >
                      <TableCell    
                        align="center"                       
                        sx={{
                          fontFamily: "kantumruy",
                          fontWeight: "bold",
                          lineHeight: "1px",
                          borderLeft: "1px solid #C2C2C2",
                          borderRight: "1px solid #C2C2C2",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      
                      <TableCell                       
                        sx={{
                          fontFamily: "kantumruy",
                          fontWeight: "bold",
                          lineHeight: "1px",
                          borderLeft: "1px solid #C2C2C2",
                          borderRight: "1px solid #C2C2C2",
                        }}
                      >
                        {/* <TextTruncate
                          line={1}
                          element="span"
                          truncateText="…"
                          text={`${data?.product_Id?.name}`}
                          // textTruncateChild={<a href="#">Read on</a>}
                        /> */}
                        {data?.product_Id?.name}
                      </TableCell>

                      <TableCell        
                        align="center"                  
                        sx={{
                          fontFamily: "kantumruy",
                          fontWeight: "bold",
                          lineHeight: "1px",
                          borderLeft: "1px solid #C2C2C2",
                          borderRight: "1px solid #C2C2C2",
                        }}
                      >
                        {data?.product_Id?.unit}
                      </TableCell>

                      <TableCell  
                        align="center"                         
                        sx={{
                          fontFamily: "kantumruy",
                          fontWeight: "bold",
                          lineHeight: "1px",
                          borderLeft: "1px solid #C2C2C2",
                          borderRight: "1px solid #C2C2C2",
                        }}
                      >
                        {data?.qty}
                      </TableCell>
                      <TableCell       
                        sx={{
                          fontFamily: "kantumruy",
                          fontWeight: "bold",
                          lineHeight: "1px",
                          borderLeft: "1px solid #C2C2C2",
                          borderRight: "1px solid #C2C2C2",
                        }}
                      >
                        {/* {data?.product_Id?.remark} */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Stack direction="row" justifyContent="center" spacing={10}>
                  
              <Stack sx={{ paddingRight: "50px", paddingTop: "20px" }}>
                  <Typography
                    sx={{
                      fontFamily: "kantumruy",
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "center"
                    }}
                  >
                    អតិថិជន
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "kantumruy",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    {/* {itemData?.shop_Id?.name} */}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "kantumruy",
                      fontWeight: "bold",
                      fontSize: "14px",
                      letterSpacing: "2px",
                      marginTop: "40px",
                    }}
                  >
                    ....................................
                  </Typography>
              </Stack>

              <Stack sx={{ paddingRight: "50px", paddingTop: "20px" }}>
                  <Typography
                    sx={{
                      fontFamily: "kantumruy",
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "center"
                    }}
                  >
                    អ្នកដឹកជញ្ជូន
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "kantumruy",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    {/* {itemData?.delivery_By?.firsName +
                      " " +
                      itemData?.delivery_By?.lastName} */}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "kantumruy",
                      fontWeight: "bold",
                      fontSize: "14px",
                      letterSpacing: "2px",
                      marginTop: "40px",
                    }}
                  >
                    ....................................
                  </Typography>
              </Stack>

              <Stack sx={{ paddingRight: "50px", paddingTop: "20px" }}>
                  <Typography
                    sx={{
                      fontFamily: "kantumruy",
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "center"
                    }}
                  >
                    អ្នកកាន់ឃ្លាំង
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "kantumruy",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
            
                    {/* {itemData?.storage_Room_Id?.name} */}
                    
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "kantumruy",
                      fontWeight: "bold",
                      fontSize: "14px",
                      letterSpacing: "2px",
                      marginTop: "40px",
                    }}
                  >
                    ....................................
                  </Typography>
              </Stack>

            </Stack>
          </Stack>
        </div>
      </div>
      <div>
        <ReactToPrint
          content={() => componentRef.current}
          documentTitle="AwesomeFileName"
          trigger={reactToPrintTrigger}
        />
      </div>
    </div>
  );
}
