import * as React from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import "./deleteUser.scss";
import { DELETE_USER } from "../../Schema/user";
import { useMutation } from "@apollo/client";


function DeleteUser({
  handleCloseDel,
  RowData,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
}) {
  const [valueVoid, setValueVoid] = React.useState("");

  const [deleteUser] = useMutation(DELETE_USER, {
    variables: {
      userId: RowData?._id,
    },
    onCompleted: ({ deleteUser }) => {
      if (deleteUser?.status) {
        setchekMessage("success");
        setMessage(deleteUser?.message);
        setAlert(true);
        setRefetch();
        handleCloseDel();
      } else {
        setchekMessage("error");
        setMessage(deleteUser?.message);
        setAlert(true);
      }
    },
    onError: (error) => {
      setchekMessage("error");
      setMessage(error.message);
      setAlert(true);
    },
  });

  const handleDel = () => {
    setRefetch();
    deleteUser({
      userId: RowData?._id,
    });
  };
  return (
    <Box className="delete-user" >
      <Stack direction="row" spacing={5}>
        <Typography className="title" variant="h6">
          DELETE USER
        </Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <IconButton onClick={() => handleCloseDel()}>
          <ClearIcon sx={{ color: "red" }} />
        </IconButton>
      </Stack>

      <Stack direction="row" spacing={5} width="100%">
        <Typography variant="subtitle1" className="simple-text">
          Do you want to delete USER?
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mt: 4 }}
      >
        <Typography variant="subtitle1" className="simple-text">
          Please input
        </Typography>
        <Typography className="body-text" variant="subtitle1">
          User
        </Typography>
        <Typography variant="subtitle1" className="simple-text">
          for delete User
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mb: 4 }}
      >
        <TextField
          className="text-input"
          size="small"
          fullWidth
          onChange={(e) => setValueVoid(e.target.value)}
        />
      </Stack>

      <Stack direction="row" spacing={5}>
        {valueVoid === "User" ? (
          <Button
            onClick={handleDel}
            className="btn-void"
            sx={{ ":hover": { backgroundColor: "red", border: "none" } }}
            variant="outlined"
            fullWidth
          >
            DELETE NOW
          </Button>
        ) : (
          <Button variant="outlined" fullWidth className="btn-delete">
            DELETE
          </Button>
        )}
      </Stack>
    </Box>
  );
}

export default DeleteUser;
