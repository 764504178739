import React, { useState, useEffect } from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Avatar,
  Select,
  styled,
  Button,
  MenuItem,
  TextField,
  IconButton,
  Autocomplete,
  FormControl,
} from "@mui/material";

import "./createproduct.scss";
import ClearIcon from "@mui/icons-material/Clear";
import imageCompression from "browser-image-compression";
import { useFormik, Form, FormikProvider } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import moment from "moment";
import axios from "axios";

//
import { GET_UNIT } from "../../Schema/unit";
import { CREATE_PRODUCT, GET_CATEGORIES_PRODUCT } from "../../Schema/product";
import { useMutation, useQuery } from "@apollo/client";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";


export default function CreateProduct({
  handleClose,
  btnTitle,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
}) {

  const [loading, setLoading] = useState(false);

  const Input = styled("input")({
    display: "none",
  });

  const [categoryPro,setCategoryPro] = useState([]);
  const { refetch: refetchCategory } = useQuery(GET_CATEGORIES_PRODUCT,{
      onCompleted: ({getCategorise}) => {
          let rows= [];
          getCategorise?.forEach( (element,index) => {
              const allRow = { label: element };
              rows.push(allRow);
          })
          setCategoryPro(rows);
      }
  })
  useEffect( () => {
    refetchCategory()
  },[])

  const { data: DataUnit } = useQuery(GET_UNIT);
  const [selectUnit, setSelectUnit] = useState([]);
  useEffect(() => {
    if (DataUnit) {
      let rows = [];
      DataUnit?.getUnit.forEach((element) => {
        const allrow = {
          label: element?.name,
          _id: element?._id,
        };
        rows.push(allrow);
      });
      setSelectUnit(rows);
    }
  }, [DataUnit]);

  const [createProduct] = useMutation(CREATE_PRODUCT, {
    onCompleted: ({ createProduct }) => {
      if (createProduct?.status) {
        setchekMessage("success");
        setMessage(createProduct?.message);
        setAlert(true);
        setRefetch();
        setLoading(false);
        handleClose();
      } else {
        setchekMessage("error");
        setMessage(createProduct?.message);
        setAlert(true);
        setLoading(false);
      }
    },
    onError: ({ error }) => {
      setchekMessage("error");
      setMessage(error.message);
      setAlert(true);
      setLoading(false);
    },
  });

  // upload Image
  const [imageFile, setImageFile] = useState(null);

  const newDate = moment(new Date()).format("MMdYYYY");
  const uploadImage = async (file, values) => {
    //compressfile to small
    if (!file) return;
    const formData = new FormData();
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    //change image name
    let newName = `${uuidv4()}${newDate}${file.name.split(".").pop()}`;
    //convert extention
    var newFile = new File([compressedFile], `${newName}.png`, {
      type: "image/png",
    });
    formData.append("image", newFile);

    //post to store at backend
    await axios
      .post(
        `${process.env.REACT_APP_UPLOAD_URL}/api/inventory-mart/upload/`,
        formData,
        config
      )
      //catch image url
      .then(async function (response) {
        createProduct({
          variables: {
            input: {
              image_src: `${process.env.REACT_APP_UPLOAD_URL}/api${response?.data}`,
              ...values,
            },
          },
        });
      });
  };

  //fromik
  const SetupAdd = Yup.object().shape({
    name: Yup.string().required("require name!"),
    cost: Yup.number().required().min(0.0001, "input invalid"),
    category: Yup.string().required("require category!"),
    feature: Yup.string().required("require feature!"),
    type: Yup.string().required("require type!"),
    unit: Yup.string().required("require unit!"),
    qty_inUnit: Yup.number().required("require qty in Unit!"),
    unit_of_qty_inUnit: Yup.string().required("require Unit!"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      cost: 1,
      category: "",
      remark: "",
      type: "For Sale",
      feature: "",
      unit: "",
      barcode: "",
      qty_inUnit: "",
      unit_of_qty_inUnit: "",
    },

    validationSchema: SetupAdd,
    onSubmit: async (values) => {
      setLoading(true);
      const newproduct = {
        name: values.name,
        cost: parseFloat(values.cost),
        category: values.category,
        remark: values.remark,
        type: values.type,
        feature: values.feature,
        unit: values.unit,
        bar_Code: values.barcode,
        qty_inUnit: values?.qty_inUnit
      };
      if (imageFile) {
        uploadImage(imageFile, newproduct);
      } else {
        createProduct({
          variables: {
            input: {
              image_src: "",
              ...newproduct,
            },
          },
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box spacing={5} className="product-page">
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" className="title">
              CREATE PRODUCT
            </Typography>
            <Box sx={{ flexGrow: 1 }} />

            <IconButton onClick={() => handleClose()}>
              <ClearIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>

          <Stack direction="row" spacing={5}>
            <Typography variant="body2" className="simple-text">
              Please complete option
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={5}
            sx={{ mt: 2 }}
            justifyContent="center"
          >
            <label htmlFor="icon-button-file">
              <Input
                accept="image/*, capture=camera"
                capture="”camera"
                id="icon-button-file"
                type="file"
                onChange={(event) => {
                  setImageFile(event.target.files[0]);
                }}
              />

              {imageFile ? (
                <Avatar
                  variant="rounded"
                  className="upload"
                  alt="Image"
                  src={URL.createObjectURL(imageFile)}
                />
              ) : (
                <Avatar className="upload" alt="Image" variant="rounded" />
              )}
            </label>
          </Stack>
          <Typography className="body-title"> Image </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field"> Name </Typography>

              <TextField
                size="small"
                fullWidth
                placeholder="name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="field"> Cost </Typography>

              <TextField
                size="small"
                type="number"
                fullWidth
                placeholder="cost"
                {...getFieldProps("cost")}
                error={Boolean(touched.cost && errors.cost)}
                helperText={touched.cost && errors.cost}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="field"> Type </Typography>
              <Box className="btn-filter">
                <FormControl
                  className="form-controll-select"
                  size="small"
                  fullWidth
                >
                  <Select
                    IconComponent={(props) => (
                      <KeyboardArrowDownOutlinedIcon {...props} />
                    )}
                    {...getFieldProps("type")}
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="For Sale">
                      <Typography> For Sale </Typography>
                    </MenuItem>
                    <MenuItem value="For Usage">
                      <Typography> For Usage </Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography className="field"> Category </Typography>
              <Box className="btn-filter">
                <Autocomplete
                  className="selection"
                  disablePortal
                  id="combo-box-demo"
                  options={categoryPro}
                  size="small"
                  onChange={(e, value) => {
                    console.log("value:", value);
                    setFieldValue("category", value?.label);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="text-field"
                      {...params}
                      placeholder="Choose"
                      {...getFieldProps("category")}
                      error={Boolean(touched.category && errors.category)}
                      helperText={touched.category && errors.category}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography className="field"> Feature </Typography>
              <Box className="btn-filter">
                <Autocomplete
                  className="selection"
                  disablePortal
                  id="combo-box-demo"
                  options={featData}
                  size="small"
                  onChange={(e, value) => {
                    setFieldValue("feature", value?.label);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="text-field"
                      {...params}
                      {...getFieldProps("feature")}
                      error={Boolean(touched.feature && errors.feature)}
                      helperText={touched.feature && errors.feature}
                      placeholder="Choose"
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography className="field"> Barcode </Typography>
              <TextField
                id="bar"
                required
                size="small"
                fullWidth
                placeholder="barcode"
                {...getFieldProps("barcode")}
                error={Boolean(touched.barcode && errors.barcode)}
                helperText={touched.barcode && errors.barcode}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="field"> Unit </Typography>
              <Box className="btn-filter">
                <Autocomplete
                  className="selection"
                  disablePortal
                  id="combo-box-demo"
                  options={selectUnit}
                  size="small"
                  onChange={(e, value) => {
                    setFieldValue("unit", value?.label);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="text-field"
                      {...params}
                      {...getFieldProps("unit")}
                      error={Boolean(touched.unit && errors.unit)}
                      helperText={touched.unit && errors.unit}
                      placeholder="Choose"
                    />
                  )}
                />
              </Box>
            </Grid>
            

            <Grid item xs={6}>
              <Typography className="field">Qty in Unit</Typography>

              <Stack direction="row" spacing={1} width="100%">
                <Box width="40%">
                  <TextField
                      type="number"
                      size="small"
                      fullWidth
                      placeholder="0"
                      {...getFieldProps("qty_inUnit")}
                      error={Boolean(touched.qty_inUnit && errors.qty_inUnit)}
                      helperText={touched.qty_inUnit && errors.qty_inUnit}
                      inputProps={{ min: 0 }}
                  />
                </Box>

                <Box className="btn-filter" width="60%">
                    <Autocomplete
                      className="selection"
                      disablePortal
                      id="combo-box-demo"
                      options={selectUnit}
                      size="small"
                      onChange={(e, value) => {
                        setFieldValue("unit_of_qty_inUnit", value?.label);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="text-field"
                          {...params}
                          {...getFieldProps("unit_of_qty_inUnit")}
                          error={Boolean(touched.unit_of_qty_inUnit && errors.unit_of_qty_inUnit)}
                          helperText={touched.unit_of_qty_inUnit && errors.unit_of_qty_inUnit}
                          placeholder="Choose"
                        />
                      )}
                    />
                </Box>

              </Stack>              
            </Grid>


            <Grid item xs={12}>
              <Typography className="field">Remark</Typography>
              <TextField
                multiline
                rows={3}
                size="small"
                fullWidth
                placeholder="remark"
                {...getFieldProps("remark")}
                error={Boolean(touched.remark && errors.remark)}
                helperText={touched.remark && errors.remark}
              />
            </Grid>
          </Grid>

          <Grid item xs={6} mt={2}>
            {loading ? (
              <Button variant="text" fullWidth className="btn-create">
                loading...
              </Button>
            ) : (
              <Button
                variant="text"
                fullWidth
                type="submit"
                className="btn-create"
              >
                {btnTitle}
              </Button>
            )}
          </Grid>
        </Box>
      </Form>
    </FormikProvider>
  );
}

// type
const simpleData = [
  { label: "Food" },
  { label: "Meat" },
  { label: "Drink" },
  { label: "Vegetables" },
  { label: "Fruit" },
  { label: "Cosmetic" },
  { label: "Snacks" },
  { label: "Spices" },
  { label: "Soap" },
  { label: "Ice Cream" },
  { label: "Preserved Food" },
  { label: "Other" },
];

//feature

const featData = [
  { label: "Short Term" },
  { label: "Long Term" },
  { label: "New" },
  { label: "Old" },
];
