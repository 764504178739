import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import "./deleteshop.scss";
import { useMutation } from "@apollo/client";
import { DELETE_SHOP } from "../../Schema/shops";

export default function DeleteShop({
  shopID,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
  handleClose,
}) {
  const [valueVoid, setValueVoid] = React.useState("");
  const [loading, setLoading] = useState(false);

  const [deleteShop] = useMutation(DELETE_SHOP, {
    onCompleted: ({ deleteShop }) => {
      if (deleteShop?.status) {
        setchekMessage("success");
        setMessage(deleteShop?.message);
        setAlert(true);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setchekMessage("error");
        setMessage(deleteShop?.message);
        setAlert(true);
        setLoading(false);
      }
    },
    onError: (error) => {
      setchekMessage("error");
      setMessage(error.message);
      setAlert(true);
      setLoading(false);
    },
  });

  const handleDelete = () => {
    setLoading(true);
    deleteShop({
      variables: {
        shopId: shopID,
      },
    });
  };
  return (
    <Box className="delete-shop">
      <Stack direction="row" spacing={5}>
        <Typography className="title" variant="h6">
          DELETE SHOP
        </Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <IconButton onClick={handleClose}>
          <ClearIcon sx={{ color: "red" }} />
        </IconButton>
      </Stack>

      <Stack direction="row" spacing={5} width="100%">
        <Typography variant="subtitle1" className="simple-text">
          Do you want to delete shop?
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mt: 2 }}
      >
        <Typography variant="subtitle1" className="simple-text">
          Please input
        </Typography>
        <Typography className="body-text">SHOP</Typography>
        <Typography variant="subtitle1" className="simple-text">
          for delete
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mb: 4 }}
      >
        <TextField
          className="text-input"
          size="small"
          fullWidth
          onChange={(e) => setValueVoid(e.target.value)}
        />
      </Stack>

      <Grid item xs={12} spacing={5}>
        {valueVoid === "SHOP" ? (
          <Button
            onClick={handleDelete}
            sx={{ ":hover": { backgroundColor: "red", border: "none" } }}
            className="btn-void"
            variant="outlined"
            fullWidth
          >
            DELETE NOW
          </Button>
        ) : (
          <Button variant="outlined" className="btn-delete" fullWidth>
            DELETE
          </Button>
        )}
      </Grid>
    </Box>
  );
}
