import React, { useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  Stack,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import logo from "../../Assets/logoLogin.svg";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import "./menunavbar.scss";

import { useQuery } from "@apollo/client";
import { GET_USER_LOGIN } from "../../Schema/user";

//icon
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"; //storage
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined"; //dash
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined"; //costumer
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined"; //pro
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined"; //unit
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined"; // sup
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined"; //user
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined"; // release card
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined"; //report

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';

import { auth } from "../../firebase";
import { getAuth, signOut } from "firebase/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "0px",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MenuNavbar({ open, handleDrawerClose , toggleDrawer }) {
  let location = useLocation();
  const theme = useTheme();

  const { permissionRoleAccess } = useContext(AuthContext)

  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/login");
      })
      .catch((error) => {
        // An error happened.
        // console.log(error);
      });
  };

  const { data , refetch } = useQuery(GET_USER_LOGIN , {
    onCompleted: ({getUserLogin}) => {
      console.log(getUserLogin?.role)
    }
  })

  React.useEffect( () => {
    refetch()
  },[])


  const [openLogout, setOpenLogout] = React.useState(false);

  const handleOpenLogout = () => {
    setOpenLogout(true);
  };

  const handleCloseLogout = () => {
    setOpenLogout(false);
  };

  return (
    <>
      <Drawer variant="permanent" open={true} className="drawer-menu">
        <DrawerHeader sx={{ mt: 3, mb: 3 }}>
          <IconButton onClick={() => navigate("/dashboard")}>
            <img src={logo} alt="logo" height="60%" width="60%" />
          </IconButton>
        </DrawerHeader>

        <List className="list-menu">
          {/* Item */}
          <ListItem
            onClick={toggleDrawer("left", false)}
            className={
              location.pathname === "/dashboard"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <GridViewOutlinedIcon width="35%" />
                </ListItemIcon>
                <Typography className="list-item-text">Dashboard</Typography>
              </ListItemButton>
            </Link>
          </ListItem>
          {/* Item */}

          {/* Item */}
          <ListItem
            onClick={toggleDrawer("left", false)}
            className={
              location.pathname === "/storage-room" ||
              location.pathname === "/storage-room/purchase"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/storage-room" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <DomainAddOutlinedIcon width="35%" />
                </ListItemIcon>
                <Typography className="list-item-text"> Storage Room </Typography>
              </ListItemButton>
            </Link>
          </ListItem>
          {/* Item */}



          {/* Item */}
          { permissionRoleAccess(['superadmin','admin','stock_manager']) ? 
            <>
              <ListItem
                onClick={toggleDrawer("left", false)}
                className={
                  location.pathname === "/transfer" 
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/transfer" style={{ textDecoration: "none" }}>
                  <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                    <ListItemIcon className="list-item-icon">
                      <MoveDownIcon width="35%" />
                    </ListItemIcon>
                    <Typography className="list-item-text"> Transfer Stock </Typography>
                  </ListItemButton>
                </Link>
              </ListItem>
              </>
            : null
          }
          {/* Item */}




          {/* Item */}
          <ListItem
            onClick={toggleDrawer("left", false)}
            className={
              location.pathname === "/shop"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/shop" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <PeopleOutlinedIcon width="35%" />
                </ListItemIcon>
                <Typography className="list-item-text"> Shop </Typography>
              </ListItemButton>
            </Link>
          </ListItem>
          {/* Item */}

          {/* Item */}
          <ListItem
            onClick={toggleDrawer("left", false)}
            className={
              location.pathname === "/product"
              || location.pathname === "/unit"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/product" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <LocalGroceryStoreOutlinedIcon width="35%" />
                </ListItemIcon>
                <Typography className="list-item-text"> Product</Typography>
              </ListItemButton>
            </Link>
          </ListItem>
          {/* Item */}

          {/* Item */}
          <ListItem
            onClick={toggleDrawer("left", false)}
            className={
              location.pathname === "/supplies"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/supplies" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <ProductionQuantityLimitsOutlinedIcon width="35%" />
                </ListItemIcon>
                <Typography className="list-item-text">Supplies</Typography>
              </ListItemButton>
            </Link>
          </ListItem>
          {/* Item */}


        {
          permissionRoleAccess(['superadmin','admin']) ?
          <>

          {/* Item */}
          <ListItem
            onClick={toggleDrawer("left", false)}
            className={
              location.pathname === "/user" ? "list-item-active" : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/user" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <PermContactCalendarOutlinedIcon width="35%" />
                </ListItemIcon>
                <Typography className="list-item-text">User</Typography>
              </ListItemButton>
            </Link>
          </ListItem>
          {/* Item */}
          </>
          : null
        }

          {/* Item */}
          <ListItem
            onClick={toggleDrawer("left", false)}
            className={
              location.pathname === "/stockout" ? "list-item-active" : "list-item" } disablePadding sx={{ display: "block" }}
          >
            <Link to="/stockout" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <InventoryOutlinedIcon width="35%" />
                </ListItemIcon>
                <Typography className="list-item-text">Stock Out</Typography>
              </ListItemButton>
            </Link>
          </ListItem>
          {/* Item */}

          {/* Item */}
          <ListItem
            onClick={toggleDrawer("left", false)}
            className={
              location.pathname === "/report" ? "list-item-active" : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/report" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <SignalCellularAltOutlinedIcon width="35%" />
                </ListItemIcon>
                <Typography className="list-item-text">Reports</Typography>
              </ListItemButton>
            </Link>
          </ListItem>
        </List>

        <Box sx={{ flexGrow: 1 }}></Box>

        <List className="list-menu">
          <ListItem
            className="list-item"
            disablePadding
            onClick={toggleDrawer("left", true)}
            sx={{ display: "block" }}            
          >
            <Link
              to="#"
              style={{ textDecoration: "none" }}
              onClick={handleOpenLogout}
            >
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <LogoutIcon />
                </ListItemIcon>
                <Typography className="list-item-text">Logout</Typography>
              </ListItemButton>
            </Link>
          </ListItem>

        </List>
      </Drawer>

      <div >        
        <Dialog  open={openLogout} className="log-out-style">
          <DialogTitle>
            <Stack direction="row" justifyContent="right" sx={{marginTop: '-5px'}}>
              <IconButton onClick={handleCloseLogout}>
                <DoDisturbOnOutlinedIcon sx={{color:"red" , fontSize: "25px"}}/>
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              <Stack direction="column" justifyContent="center" height="150px" >
                <Stack direction="row" justifyContent="center" width="400px" marginTop="-10px">
                  <Typography sx={{fontWeight:"bold" , color:"black" , fontSize:"20px"}}> You are attemping to log out.</Typography>
                </Stack>
                <Stack direction="row" justifyContent="center" width="400px">
                  <Typography sx={{color:"black" , fontSize:"15px"}}> Are you sure?</Typography>
                </Stack>

                <Stack direction="row" justifyContent="center" width="400px" marginTop="30px">
                  <Button 
                    onClick={handleSignOut} 
                    autoFocus fullWidth
                    sx={{
                        backgroundColor:"#0B82C4" ,
                        color:"white",
                        ":hover": {
                          backgroundColor: "#0B82C4"
                        }
                      }}
                  >
                    LOG OUT
                  </Button>
                </Stack>
              </Stack>
            </DialogContentText>
          </DialogContent>         
        </Dialog>
      </div>

    </>
  );
}
