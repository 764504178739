import React, { useState, useEffect } from "react";
import {
  Typography,
  Stack,
  Box,
  Paper,
  Modal,
  Button,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Skeleton,
} from "@mui/material";
import "./product.scss";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

//component
import Profile from "../Component/Menu/Profile";
import CreateProduct from "../Component/Product/CreateProduct";
import ProductAction from "../Component/Product/ProductAction";
import ViewProduct from "../Component/Product/ViewProduct";
import AlertMessage from "../Component/AlertMessage/AlertMessage";
import { makeStyles } from "@material-ui/core/styles";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
import {
  GET_ALL_PRODUCT_WITH_PAGINATION,
  GET_CATEGORIES_PRODUCT,
} from "../Schema/product";
import HoverpopoverProduct from "../Component/Product/HoverpopoverProduct";
import NumberFormat from "react-number-format";

// type
const simpleData = [
  { label: "Food" },
  { label: "Meat" },
  { label: "Drink" },
  { label: "Vegetables" },
  { label: "Fruit" },
  { label: "Cosmetic" },
  { label: "Snacks" },
  { label: "Spices" },
  { label: "Soap" },
  { label: "Ice Cream" },
  { label: "Preserved Food" },
  { label: "Other" },
];

export default function RawMaterialAndProduct() {
  let navigate = useNavigate();
  const [Data, setData] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(true);

  const [openView, setOpenView] = useState(false);
  const handleOpenView = (dataRow) => {
    setOpenView(true);
    setData(dataRow);
    localStorage.setItem("productId", JSON.stringify(dataRow?._id));
  };

  const handleCloseView = () => {
    setOpenView(false);
    // setData(null);
  };

  // AlertMessage
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [checkMessage, setchekMessage] = useState("");

  const [categoryPro, setCategoryPro] = useState([]);
  const { refetch: refetchCategory } = useQuery(GET_CATEGORIES_PRODUCT, {
    onCompleted: ({ getCategorise }) => {
      setCategoryPro(getCategorise);
    },
  });

  useEffect(() => {
    refetchCategory();
  }, []);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("");
  const [showPage, setShowPage] = useState(1);
  const [productData, setProductData] = useState([]);

  
  const { data, refetch } = useQuery(GET_ALL_PRODUCT_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      category: category,
    },

    onCompleted: ({ getProductsPagination }) => {
      setProductData(getProductsPagination);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error.message);
      setLoading(true);
    },
  });

  const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
      color: "#aaa",
    },
  }));

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  //get value from localstorage
  const productId = JSON.parse(localStorage.getItem("productId"));

  useEffect(() => {
    refetch();
    setShowPage(page);
  }, [page, limit, keyword, category]);

  // console.log("productId", productId);

  return (
    <Box className="rawmaterialofproduct-pages">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="color"> Product </Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }}></Box>
        <Profile />
      </Stack>

      <Stack
        direction="row"
        width="100%"
        spacing={2}
        className="menu-containner"
      >
        <Stack direction="column" className="btn-search">
          <Box className="btn-text-field">
            <TextField
              onChange={(e) => setKeyword(e.target.value)}
              className="text-field"
              fullWidth
              id="input-with-sx"
              placeholder="Search Name"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>

        <Stack direction="column" className="btn-filter">
          <FormControl size="small" fullWidth className="form-controll-select">
            <Select
              onChange={(e) => {
                setCategory(e?.target?.value);
              }}
              IconComponent={(props) => (
                <KeyboardArrowDownOutlinedIcon {...props} />
              )}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              displayEmpty
              renderValue={
                category !== ""
                  ? undefined
                  : () => <Placeholder> All Category </Placeholder>
              }
            >
              <MenuItem value={""}>All Category</MenuItem>

              {categoryPro?.map((name, index) => (
                <MenuItem key={index} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" spacing={1} className="btn">
          <Button
            onClick={() => {
              navigate(`/unit`);
            }}
            className="btn-add-style"
            startIcon={<AddCircleOutlineOutlinedIcon className="icon-add" />}
          >
            <Typography className="style-add"> Unit </Typography>
          </Button>

          <Button
            onClick={handleOpen}
            className="btn-add-style"
            startIcon={<AddCircleOutlineOutlinedIcon className="icon-add" />}
          >
            <Typography className="style-add">Create</Typography>
          </Button>

          <Modal open={open}>
            <CreateProduct
              setAlert={setAlert}
              setMessage={setMessage}
              setchekMessage={setchekMessage}
              handleClose={handleClose}
              setRefetch={refetch}
              btnTitle={"CREATE"}
            />
          </Modal>
        </Stack>

        {/* <Stack direction="row" className="btn-threeicon">
          <IconButton onClick={handleClick}>
            <MoreVertIcon className="vert-icon" />
          </IconButton>

          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() => {
                navigate(`/unit`);
              }}
            >
              <Typography className="maker-menu">Unit</Typography>
            </MenuItem>
            <MenuItem onClick={handleOpen}>
              <Typography className="maker-menu">Create</Typography>
            </MenuItem>
          </Menu>
        </Stack> */}
      </Stack>

      <Box className="container">
        <TableContainer className="materail">
          <Table className="table-head">
            <TableHead className="header-title">
              <TableRow className="header-row">
                <TableCell className="header-title" width="3%">
                  N°
                </TableCell>
                <TableCell className="header-title" width="30%">
                  Name
                </TableCell>

                <TableCell className="header-title" align="center" width="17%">
                  Unit
                </TableCell>

                <TableCell className="header-title" align="center" width="17%">
                  Category
                </TableCell>

                <TableCell className="header-title" align="center" width="17%">
                  Cost
                </TableCell>

                <TableCell className="header-title" align="left">
                  Remark
                </TableCell>
                <TableCell
                  className="header-title"
                  align="right"
                  width="2%"
                ></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <>
                <TableHead>
                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={8}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={8}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={8}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={8}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
              </>
            ) : (
              <>
                {productData?.data?.map((row, index) => (
                  <TableBody
                    key={index}
                    className={productId === row?._id ? "body" : "body-odd"}
                  >
                    <TableRow className="body-row">
                      <TableCell
                        onClick={() => {
                          handleOpenView(row);
                        }}
                        className="body-title"
                      >
                        <Typography>
                          {showPage === 1 ? index + 1 : null}
                          {showPage !== 1
                            ? index + 1 + (showPage - 1) * 8
                            : null}
                        </Typography>
                      </TableCell>

                      <TableCell
                        onClick={() => {
                          handleOpenView(row);
                        }}
                        className="body-title"
                      >
                        <HoverpopoverProduct row={row} />
                      </TableCell>

                      <TableCell
                        onClick={() => {
                          handleOpenView(row);
                        }}
                        className="body-title"
                        align="center"
                      >
                        {row?.unit}
                      </TableCell>

                      <TableCell
                        onClick={() => {
                          handleOpenView(row);
                        }}
                        className="body-title"
                        align="center"
                      >
                        {row?.category}
                      </TableCell>

                      <TableCell
                        onClick={() => {
                          handleOpenView(row);
                        }}
                        className="body-title"
                        align="right"
                      >
                        <Stack
                          direction="row"
                          justifyContent="center"
                          spacing={2}
                        >
                          $
                          <NumberFormat
                            value={row?.cost?.toFixed(2)}
                            thousandSeparator={true}
                            displayType={"text"}
                            renderText={(value, props) => (
                              <div {...props}>{value}</div>
                            )}
                          />
                        </Stack>
                      </TableCell>

                      <TableCell
                        onClick={() => {
                          handleOpenView(row);
                        }}
                        className="body-title"
                        align="left"
                      >
                        {row?.remark}
                      </TableCell>

                      <TableCell className="body-title" align="right">
                        <ProductAction
                          Data={row}
                          refetch={refetch}
                          setAlert={setAlert}
                          setMessage={setMessage}
                          setchekMessage={setchekMessage}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </>
            )}
          </Table>
        </TableContainer>

        <Stack className="pagination" direction="row" spacing={1}>
          <Pagination
            page={showPage}
            hideNextButton={false}
            hidePrevButton={false}
            variant="outlined"
            count={productData?.paginator?.totalPages}
            onChange={(event, pageNumber) => {
              setPage(parseInt(pageNumber));
            }}
          />

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Select
                className="form-controll-select"
                id="demo-simple-select"
                size="small"
                value={limit}
                defaultValue={limit}
                onChange={(e) => setLimit(e.target.value)}
              >
                <MenuItem value={8}> 8 /Page</MenuItem>
                <MenuItem value={10}>10 /Page</MenuItem>
                <MenuItem value={20}>20 /Page</MenuItem>
                <MenuItem value={30}>30 /Page</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Box>

      <Modal open={openView}>
        <ViewProduct handleCloseView={handleCloseView} Data={Data}/>
      </Modal>

      <AlertMessage
        alert={alert}
        setAlert={setAlert}
        message={message}
        setMessage={setMessage}
        checkMessage={checkMessage}
      />
    </Box>
  );
}
