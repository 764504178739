import React from "react";
import "./pendingstockout.scss";
import {
  Stack,
  Box,
  Divider,
  Button,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
  Skeleton,
  IconButton,
} from "@mui/material";
import AlertMessage from "../../AlertMessage/AlertMessage";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { GET_RELEASE_PRODUCT_WITH_PAGINATION } from "../../../Schema/stockout";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function RequestPur() {

  const navigate = useNavigate();
  //view
  const [openView, setOpenView] = React.useState(false);
  const handleOpenView = () => setOpenView(true);
  const handleCloseView = () => setOpenView(false);
  // AlertMessage
  const [alert, setAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [checkMessage, setchekMessage] = React.useState("");
  //skeleton
  const [loading, setLoading] = React.useState(true);


  const [releaseData,setReleaseData] = React.useState([]);

  const { data, refetch } = useQuery(GET_RELEASE_PRODUCT_WITH_PAGINATION, {
      variables: {
          page: 1,
          limit: 15,
          keyword: "",
          shopId:"",
          deliveryId:"",
          deliveryAt:"",
      },

      onCompleted:()=>{
        setLoading(false)
     
      },

      onError:()=>{
        setLoading(true)
      }
    }
  );


  React.useEffect( () => {
    refetch();
  },[refetch])
  
  React.useEffect( () => {
      if(data?.getReleaseProductPagination) {
        let rows = [];
        data?.getReleaseProductPagination?.data?.forEach( Element => {            
            if(Element?.delivery !== true && Element?.status !== true ){
                rows.push(Element);
            }
        })  
        setReleaseData(rows);
      }    
  },[data?.getReleaseProductPagination])


  if(loading){

    return (
      <Box className="requestpur" sx={{ padding: "20px"}}>
        <Stack direction="column" justifyContent="center" height="45px"> 
            <Skeleton height={20} width="40%" variant="rectangular" /> 
        </Stack>

        <Box  sx={{width:"100%" , marginTop: "10px" , marginBottom: "10px"}}>
          <Divider variant="middle" sx={{margin: 0}}/>
        </Box>
  
        <Box  sx={{width: "100%"}}>
          <TableContainer className="main-head">
              <Table>
                <TableRow>
                    <TableCell className="listitem_main" width="12%">N°</TableCell>
                    <TableCell className="listitem_main" width="30%">Shop</TableCell>
                    <TableCell className="listitem_main" width="30%">Order Date</TableCell>
                    <TableCell className="listitem_main" width="20%">Requestion By</TableCell>
                    <TableCell className="listitem_main"></TableCell>
                </TableRow>

                      
                <TableRow className="listitem">
                    <TableCell className="tbcell" colSpan={5}>
                      <Skeleton sx={{ height: "220px", width:"100%" }} animation="wave" variant="rectangular" />
                    </TableCell>
                </TableRow>
        
            </Table>
          </TableContainer>
        </Box>
  

        <Box sx={{ flexGrow: 1 }}></Box>
  
        <Stack direction="row" spacing={1} justifyContent="center">
          <Skeleton className="btn-export" animation="wave" variant="rectangular" height={15} width="20%" /> 
        </Stack>
  
      </Box>
    );
  }

  return (
    <Box className="requestpur" sx={{ padding: "20px"}}>
      
      <Stack direction="row" spacing={2} >
          <Stack direction="column" justifyContent="center" height="45px"> 
            <Typography className="toptitle">
                Stock Out Pending
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }}></Box>   
      </Stack>

      <Box  sx={{width:"100%" , marginTop: "10px" , marginBottom: "10px"}}>
        <Divider variant="middle" sx={{margin: 0}}/>
      </Box>

      <Box  sx={{width: "100%"}}>
        <TableContainer className="main-head">
            <Table>
              <TableRow>
                  <TableCell className="listitem_main" width="12%">N°</TableCell>
                  <TableCell className="listitem_main" width="30%">Shop</TableCell>
                  <TableCell className="listitem_main" width="30%">Order Date</TableCell>
                  <TableCell className="listitem_main" width="20%">Requestion By</TableCell>
                  <TableCell className="listitem_main"></TableCell>
              </TableRow>

            {
              releaseData?.length !== 0 ?
                <>
                  {
                    releaseData?.slice(0,4)?.map( (row,index) => (
                     
                      <TableRow key={index}>
                          <TableCell className="listitem" width="20%">
                            {row?.numbering}

                          </TableCell>
                          
                          <TableCell className="listitem" width="20%"> 
                            {row?.shop_Id?.name}
                          </TableCell>

                          <TableCell className="listitem">
                            {moment(row?.order_Date).format("DD-MMM-YYYY")} {moment(row?.order_Date).format("HH:mm A")}
                          </TableCell>

                          <TableCell  className="listitem">
                            {row?.delivery_By?.firsName+" "+row?.delivery_By?.lastName}
                          </TableCell>

                          <TableCell  className="listitem">                      
                             <IconButton  onClick={ () => {navigate(`/stockout?id=${row?._id}`); handleOpenView()} }>
                                <CheckBoxOutlineBlankIcon className="check-icon"  />
                            </IconButton> 
                          </TableCell>
                      </TableRow>

                    ))
                  }
                </>
              :
                <>           
                  <TableRow>                
                    <TableCell colSpan={5} className="listitem"  align="center"  sx={{ height: "200px" , color: "gray"}}>      
                        <RemoveShoppingCartIcon />                  
                        <Stack direction="column" justifyContent="center">
                          Product Empty
                        </Stack>
                    </TableCell>               
                  </TableRow>            
                </>
            }
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ flexGrow: 1 }}></Box>

      <Box className="st-btn">
          <Button variant="outlined" className="btn-export" onClick={ () => navigate('/stockout')}>
            View All
          </Button>
      </Box>

      <AlertMessage
          alert={alert}
          setAlert={setAlert}
          message={message}
          setMessage={setMessage}
          checkMessage={checkMessage}
      />
    </Box>
  );
}
export default RequestPur;
