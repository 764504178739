import {
  Avatar,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Box,
  Typography,
  Skeleton,
  Badge,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { GET_USER_LOGIN } from "../../Schema/user";
import { useQuery } from "@apollo/client";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useContext } from "react";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { AuthContext } from "../../context/AuthContext";
import { GET_PURCHASE_PENDDING } from "../../Schema/purchase";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

const MeunStyle = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 20,
      height: 20,
      ml: -0.5,
      mr: 1,
    },

    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
}

export default function Profile() {

  const { setRole , exchangeRate } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/login");
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const [userData, setUserData] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data } = useQuery(GET_USER_LOGIN, {
    onCompleted: ({ getUserLogin }) => {
      setUserData(getUserLogin);
      setLoading(true);
      window.localStorage.setItem("role" , JSON.stringify(getUserLogin?.role))
      setRole(getUserLogin?.role);
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(false);
    },
    pollInterval: 1000,
  });

  const { data: getPendingPurchase } = useQuery(GET_PURCHASE_PENDDING, {
    onCompleted: ({ getPendingPurchase }) => {
        console.log(getPendingPurchase)
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(false);
    },
    pollInterval: 1000,
  });

  return (
    <Stack direction="row" spacing={2}  sx={{ justifyContent:"center" }}>

        <Stack direction="column" justifyContent="center" spacing={2}>
            <Typography>
              Exchange Rate NBC : {exchangeRate} KHR / USD
            </Typography>  
        </Stack>

        <IconButton 
          onClick={handleClick}
          sx={{
            backgroundColor: "white",      
            // color: "red",
            width: "50px",      
            ":hover": {
              backgroundColor: "white",
            }
          }}
        > 
        
          {
            getPendingPurchase?.getPendingPurchase?.length > 0 ?
              <Badge badgeContent={getPendingPurchase?.getPendingPurchase?.length} color="error">
                  <NotificationsActiveOutlinedIcon />
              </Badge>
            :
              <NotificationsActiveOutlinedIcon />             
          }
         
        </IconButton>

        <Box
          sx={{ backgroundColor: "white", padding: "10px", borderRadius: "10px" }}
        >
          {loading ? (
            <Stack direction="row"  sx={{ fontFamily: "Century Gothic", justifyContent:"center" }}>
              <Avatar
                sx={{ marginRight: "5px", width: 30, height: 30 }}
                alt={userData?.userName}
                src={`${userData?.image_src}`}
              />
              <Stack direction="column" justifyContent="center" spacing={1}>
                <Typography sx={{ fontFamily: "Century Gothic"}}>
                  {userData?.firsName + " " + userData?.lastName}
                </Typography>
                {/* <KeyboardArrowDownIcon /> */}
              </Stack>
            </Stack>
          ) : (
            <Skeleton variant="text"  width={100}/>
          )}
          
          
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={MeunStyle}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >

            {
              getPendingPurchase?.getPendingPurchase?.map( (rowPen,index) => (
                <Link 
                  key={index}
                  to={`/storage-room/purchase?id=${rowPen?.storage_Room_Id?._id}&name=${rowPen?.storage_Room_Id?.name}&poID=${rowPen?._id}`} 
                  style={{ textDecoration: "none" }}
                >
                    <MenuItem>
                        <Stack direction="row" spacing={2}>
                            <ShoppingCartOutlinedIcon/>  
                            <Typography>
                             PO ID: {rowPen?.numbering}
                            </Typography>
                        </Stack>
                        
                    </MenuItem>
                </Link>
              ))
            }

            

            
          </Menu>
        </Box>
    </Stack>
  );
}
