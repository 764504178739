import React, { useState, useEffect } from "react";
import "./stockout.scss";
import { useQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import {
  Typography,
  Stack,
  Box,
  Paper,
  Modal,
  Button,
  TextField,
  Grid,
  Pagination,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/lab";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
//components
import Profile from "../Component/Menu/Profile";
import AlertMessage from "../Component/AlertMessage/AlertMessage";
import CreateReleaseCard from "../Component/StockOut/CreateStockOutCard";
import ViewStockOutCard from "../Component/StockOut/ViewStockOutCard";
import { GET_ALL_SHOPS } from "../Schema/shops";
import { GET_ALL_USER } from "../Schema/user";
import { GET_RELEASE_PRODUCT_WITH_PAGINATION } from "../Schema/stockout";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
import moment from "moment";
import ReleaseCardAction from "../Component/StockOut/StockOutAction";

export default function StockOut() {
  //Shops
  const [Shop, setShop] = useState([]);
  const [userId, setUserId] = useState();
  const [userVal, setUserVal] = useState([]);
  const [orderDate, setOrderDate] = useState();

  //add function
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //view
  const [openView, setOpenView] = useState(false);
  const [itemData, setItemData] = useState();
  const handleOpenView = (e) => {
    setOpenView(true);
    setItemData(e);
  };
  const handleCloseView = () => setOpenView(false);

  // AlertMessage
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [checkMessage, setCheckMessage] = useState("");

  const [releaseData, setReleaseData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showPage, setShowPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [shopId, setShopId] = useState("");
  const [deliveryId, setDeliveryId] = useState("");
  const [deliveryAt, setDeliveryAt] = useState("");

  const { data, refetch } = useQuery(GET_RELEASE_PRODUCT_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      shopId: shopId !== undefined && shopId !== "" ? shopId : "",
      deliveryId:
        deliveryId !== undefined && deliveryId !== "" ? deliveryId : "",
      deliveryAt:
        deliveryAt !== "" && deliveryAt !== "Invalid date" ? deliveryAt : "",
    },
    onCompleted: ({ getReleaseProductPagination }) => {
      // console.log("release Card",getReleaseProductPagination)
      setReleaseData(getReleaseProductPagination);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error.message);
      setLoading(true);
    },
  });

  const { data: userData } = useQuery(GET_ALL_USER);

  useEffect(() => {
    if (userData) {
      let rows = [];
      userData?.getAllUser?.forEach((e) => {
        const allrow = {
          id: e?._id,
          label: e?.firsName + " " + e?.lastName,
        };
        rows.push(allrow);
      });
      setUserVal(rows);
    }
  }, [userData]);

  useEffect(() => {
    setShowPage(page);
  }, [page, limit]);

  const { data: ShopData } = useQuery(GET_ALL_SHOPS, {
    variables: {
      keyword: "",
    },
  });

  useEffect(() => {
    if (ShopData) {
      let rows = [];
      ShopData?.getAllShops?.forEach((e) => {
        const allrow = {
          id: e?._id,
          label: e?.name,
        };
        rows.push(allrow);
      });
      setShop(rows);
    }
  }, [ShopData]);
  useEffect(() => {
    refetch();
    setShowPage(page);
  }, [page, limit, shopId, deliveryId, deliveryAt]);

  //click setlocalStorage
  const handleClick = (event) => {
    localStorage.setItem("stockId", JSON.stringify(event?._id));
  };

  //get value from localstorage
  const stockId = JSON.parse(localStorage.getItem("stockId"));

  return (
    <div className="releasecard-pages">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="color"> Stock Out </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Profile />
      </Stack>

      <Grid item container spacing={2}>
        <Grid item xs={6} lg={2.4}>
          <Stack direction="row" spacing={1} className="btn-filter">
            <Autocomplete
              className="text-field"
              id="combo-box-demo"
              disablePortal
              options={Shop}
              onChange={(e, value) => {
                setShopId(value?.id);
              }}
              renderInput={(params) => (
                <TextField
                  className="text-field"
                  fullWidth
                  {...params}
                  placeholder="Choose Shop"
                  size="small"
                />
              )}
            />
          </Stack>
        </Grid>

        <Grid item xs={6} lg={2.4}>
          <Stack direction="row" spacing={1} className="btn-filter">
            <Autocomplete
              className="text-field"
              id="combo-box-demo"
              disablePortal
              options={userVal}
              onChange={(e, value) => {
                setDeliveryId(value?.id);
              }}
              renderInput={(params) => (
                <TextField
                  className="text-field"
                  fullWidth
                  {...params}
                  placeholder="Choose Deliver"
                  size="small"
                />
              )}
            />
          </Stack>
        </Grid>

        <Grid item xs={6} lg={2.4}>
          <Stack direction="row" spacing={1} className="btn-filter">
            <LocalizationProvider
              className="text-field"
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                value={deliveryAt}
                onChange={(e) => setDeliveryAt(moment(e).format("YYYY-MM-DD"))}
                renderInput={(params) => (
                  <TextField
                    type="date"
                    className="text-field"
                    placeholder="DD/MM/YYYY"
                    size="small"
                    {...params}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>

        <Box sx={{ flexGrow: 1 }} />

        <Grid item xs={6} lg={2.4}>
          <Stack
            direction="row"
            justifyContent="right"
            spacing={1}
            className="btn"
          >
            <Button
              startIcon={
                <AddCircleOutlineOutlinedIcon sx={{ color: "white" }} />
              }
              onClick={handleOpen}
              className="btn-add-style"
            >
              <Typography className="style-add">Create</Typography>
            </Button>
          </Stack>

          <Modal open={open}>
            <CreateReleaseCard
              checkButton={"create"}
              btnTitle={"Create"}
              setRefetch={refetch}
              handleClose={handleClose}
              setAlert={setAlert}
              setMessage={setMessage}
              setCheckMessage={setCheckMessage}
            />
          </Modal>
        </Grid>
      </Grid>

      {/* </Stack> */}

      <Box className="container">
        <TableContainer>
          <Table className="table">
            <TableHead className="header-title">
              <TableRow className="header-row">
                <TableCell className="header-title" width="3%">
                  N°
                </TableCell>
                <TableCell className="header-title" align="left" width="20%">
                  Shop
                </TableCell>

                <TableCell className="header-title" align="left" width="15%">
                  Order Date
                </TableCell>

                <TableCell className="header-title" width="15%">
                  Deliver
                </TableCell>

                <TableCell className="header-title" width="15%">
                  Delivery Date
                </TableCell>

                <TableCell className="header-title" align="center">
                  Delivery
                </TableCell>
                <TableCell className="header-title" align="right"></TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <>
                <TableHead className="header-title">
                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={6}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={6}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={6}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={6}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={6}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
              </>
            ) : (
              <>
                {releaseData?.data?.map((row, index) =>
                  row?.status === true ? (
                    <TableBody
                      key={index}
                      component={Paper}
                      className={
                        stockId === row?._id
                          ? "body-active"
                          : index % 2 === 0
                          ? "void-made"
                          : "void-odd"
                      }
                    >
                      <TableRow
                        className="voiced-body-row"
                        // onClick={() => handleClick(row)}
                      >
                        <TableCell
                          className="voiced-body-title"
                          width="3%"
                          onClick={() => handleOpenView(row)}
                        >
                          <Typography>{row?.numbering}</Typography>
                        </TableCell>

                        <TableCell
                          className="voiced-body-title"
                          width="15%"
                          onClick={() => handleOpenView(row)}
                        >
                          <Typography>{row?.shop_Id?.name}</Typography>
                        </TableCell>

                        <TableCell
                          className="voiced-body-title"
                          width="15%"
                          onClick={() => handleOpenView(row)}
                        >
                          <Typography>
                            {moment(row?.order_Date).format("DD-MM-YYYY")}
                          </Typography>
                        </TableCell>

                        <TableCell
                          className="voiced-body-title"
                          width="15%"
                          onClick={() => handleOpenView(row)}
                        >
                          <Typography>
                            {row?.delivery_By?.firsName +
                              " " +
                              row?.delivery_By?.lastName}
                          </Typography>
                        </TableCell>

                        <TableCell
                          className="voiced-body-title"
                          width="15%"
                          onClick={() => handleOpenView(row)}
                        >
                          <Typography>
                            {/* {moment(row?.delivery_At).format("DD-MM-YYYY")} {row?.time} */}

                            {moment(
                              `${moment(row?.delivery_At).format(
                                "YYYY-MM-DD"
                              )} ${moment(row?.time, "HH:mm A")?.format(
                                "HH:mm:ss"
                              )}`
                            ).format("DD-MM-YYYY HH:mm A")}
                          </Typography>
                        </TableCell>

                        <TableCell
                          className="body-title"
                          width="15%"
                          onClick={() => handleOpenView(row)}
                          align="center"
                        >
                          {row?.delivery ? (
                            <Typography className="typography-style">
                              Yes
                            </Typography>
                          ) : (
                            <Typography className="typography-No">
                              No
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell
                          className="voiced-body-title"
                          align="center"
                          width="15%"
                        >
                          <Typography sx={{ color: "red", fontWeight: "bold" }}>
                            Void
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody
                      key={index}
                      component={Paper}
                      className={
                        stockId === row?._id
                          ? "body-active"
                          : index % 2 === 0
                          ? "body"
                          : "body-odd"
                      }
                    >
                      <TableRow
                        className="body-row"
                        onClick={() => handleClick(row)}
                      >
                        <TableCell
                          className="body-title"
                          sx={{ cursor: "pointer" }}
                        >
                          {row?.numbering}
                        </TableCell>

                        <TableCell
                          className="body-title"
                          align="left"
                          onClick={() => handleOpenView(row)}
                        >
                          {row?.shop_Id?.name}
                        </TableCell>

                        <TableCell
                          className="body-title"
                          align="left"
                          onClick={() => handleOpenView(row)}
                        >
                          {moment(row?.order_Date).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell
                          className="body-title"
                          onClick={() => handleOpenView(row)}
                        >
                          {row?.delivery_By?.firsName +
                            " " +
                            row?.delivery_By?.lastName}
                        </TableCell>

                        <TableCell
                          className="body-title"
                          onClick={() => handleOpenView(row)}
                        >
                          {/* {moment(row?.delivery_At).format("YYYY-MM-DD")+" "+row?.time}      */}

                          {moment(
                            `${moment(row?.delivery_At).format(
                              "YYYY-MM-DD"
                            )} ${moment(row?.time, "HH:mm A")?.format(
                              "HH:mm:ss"
                            )}`
                          ).format("DD-MM-YYYY HH:mm A")}
                        </TableCell>

                        <TableCell
                          className="body-title"
                          onClick={() => handleOpenView(row)}
                          align="center"
                        >
                          {row?.delivery ? (
                            <Typography className="typography-style">
                              Yes
                            </Typography>
                          ) : (
                            <Typography className="typography-No">
                              No
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell className="body-title" align="right">
                          {row?.delivery ? (
                            ""
                          ) : (
                            <ReleaseCardAction
                              row={row}
                              setRefetch={refetch}
                              setAlert={setAlert}
                              setMessage={setMessage}
                              setCheckMessage={setCheckMessage}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                )}
              </>
            )}
          </Table>
        </TableContainer>

        <Stack className="pagination" direction="row" spacing={1}>
          <Pagination
            page={showPage}
            hideNextButton={false}
            hidePrevButton={false}
            variant="outlined"
            count={releaseData?.paginator?.totalPages}
            onChange={(event, pageNnumber) => setPage(parseInt(pageNnumber))}
          />

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Select
                id="demo-simple-select"
                size="small"
                value={limit}
                defaultValue={limit}
                onChange={(e) => setLimit(e.target.value)}
              >
                <MenuItem value={8}> 8 /Page</MenuItem>
                <MenuItem value={10}>10 /Page</MenuItem>
                <MenuItem value={20}>20 /Page</MenuItem>
                <MenuItem value={30}>30 /Page</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Box>

      <Modal open={openView}>
        <ViewStockOutCard
          handleClose={handleCloseView}
          releaseData={releaseData}
          itemData={itemData}
        />
      </Modal>

      <AlertMessage
        alert={alert}
        setAlert={setAlert}
        message={message}
        setMessage={setMessage}
        checkMessage={checkMessage}
      />
    </div>
  );
}
