import { gql } from "@apollo/client";

//----Mutation

export const CREATE_UNIT = gql`
  mutation CreateUnit($input: UnitInput!) {
    createUnit(input: $input) {
      status
      message
    }
  }
`;

export const UPDATE_UNIT = gql`
  mutation Mutation($unitId: ID!, $input: UnitInput!) {
    updateUnit(unitId: $unitId, input: $input) {
      status
      message
    }
  }
`;

export const DELETE_UNIT = gql`
  mutation DeleteUnit($unitId: ID) {
    deleteUnit(unitId: $unitId) {
      status
      message
    }
  }
`;
//Query
export const GET_UNIT = gql`
  query Query {
    getUnit {
      _id
      name
      created_At
    }
  }
`;
