import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Stack,
  FormControl,
  MenuItem,
  Select,
  Typography,
  IconButton,
  TextField,
  Button,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Autocomplete,
  Paper,
  TableBody,
  Checkbox,
  Grid,
} from "@mui/material";
import "./createpurchaseout.scss";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
//Date
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
// icon priority
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ListPurchase from "./ListPurchase";
import { GET_ALL_SUPPLIES } from "../../Schema/supplies";
import { GET_ALL_STORAGE_ROOM } from "../../Schema/storageroom";
import { CREATE_ADJUST_QUANTITY, CREATE_PRODUCT_WAREHOUSE, GET_PRODUCT_IN_STOCK } from "../../Schema/transfer";
import { useQuery, useMutation } from "@apollo/client";
import { sendMessage } from "../Telegram/TelegrameClient";
import NumberFormat from "react-number-format";
import moment from "moment";
import { GET_ALL_PRODUCT } from "../../Schema/product";

export default function TransferOut({
  handleClose,
  setRefetch,
  setAlert,
  setMessage,
  setCheckMessage,
  checkButton,
}) {
  const [loading, setLoading] = useState(false);

  const [checkFieldRequired, setCheckFieldRequried] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  //total Amount
  const [totalAmount, setTotalAmount] = useState(0);


  // ====================== Storage Room ===========================
  const [storageRoom, setStorageRoom] = useState([]);
  const { data: dataStorageRoom , refetch: refetchRoom} = useQuery(GET_ALL_STORAGE_ROOM, {
    variables: {
      keyword: "",
    },
  });

  useEffect(() => {
    if (dataStorageRoom) {
      let rows = [];
      dataStorageRoom?.getStorageRoom?.forEach((element) => {
        const allrow = {
          label: element?.name,
          id: element?._id,
        };
        rows.push(allrow);
      });
      setStorageRoom(rows);
    }
  }, [dataStorageRoom]);


  //============================= Supplier
  const [suppliers, setSuppliers] = useState([]);
  const [supplierSelected, setSupplierSelected] = useState({});

  const { data , refetch } = useQuery(GET_ALL_SUPPLIES, {
    variables: {
      keyword: "",
    },
  });

  useEffect(() => {
    if (data) {
      let rows = [];
      data?.getSuppliers?.data.forEach((element) => {
        const allrow = {
          label: element?.name,
          id: element?._id,
        };
        rows.push(allrow);
      });
      setSuppliers(rows);
    }
    refetch()
  }, [data]);
  //================================= end ===========================

  // =================== get product =========================
  const [allProduct, setAllProduct] = useState([]);
  const { data: dataAllProduct , refetch: refetchProduct } = useQuery(GET_ALL_PRODUCT, {
    onCompleted: ({ getAllProduct }) => {
      // console.log(getAllProduct)
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (dataAllProduct?.getAllProduct) {
      let rows = [];
      dataAllProduct?.getAllProduct?.forEach((element) => {
        const allrow = {
          // label: element?.name+" "+element?.remark,
          label: element?.name,
          id: element?._id,
          unitPrice: element?.cost,
        };
        rows.push(allrow);
      });
      setAllProduct(rows);
    }
  }, [dataAllProduct?.getAllProduct]);
  //================================================================

  useEffect( () => {
    refetchRoom();
    refetch();
    refetchProduct();
  },[])

  // ========================= get product in stock ================
  const [storageRoomId,setStorageRoomId] = useState("");
  const [receiveDate,setReceiveDate] = useState("");
  const [supplierId,setSupplierId] = useState("");
  const [productId,setProductId] = useState("");
  const [listProductInStock,setListProductInStock] = useState([]);
  const [arrayAdjust,setArrayAdjust] = useState([]);

  const { data: dataInStock , refetch: refetchInStock } = useQuery(GET_PRODUCT_IN_STOCK,{
      variables: {
        storageRoomId: storageRoomId ? storageRoomId : null,
        receiveDate: receiveDate !== "" && receiveDate !== undefined && receiveDate !== null ? moment(receiveDate).format("YYYY-MM-DD") : null,
        supplierId: supplierId ? supplierId : null,
        productId: productId ? productId : null,
      },
      onCompleted: ({getProductInStockForAdjust}) => {
        console.log(getProductInStockForAdjust)

        if(getProductInStockForAdjust) {
            let rows = [];
            getProductInStockForAdjust?.map( (row) => {
                const allrow = {
                  id: row?._id,
                  state: false,
                  productName: row?.product_Id?.name,
                  qty: row?.qty,
                  price: row?.unit_Price,
                  instock_At: row?.instock_At,
                }
                rows.push(allrow)
            })
            setListProductInStock(rows)
        }
        
      }
  });
  useEffect( () => {
    refetchInStock();
  },[storageRoomId , receiveDate , supplierId , productId])


  const handleUpdateState = (value,i) => {
      let newArray = listProductInStock;     
      newArray?.map( (row,index) => {
          row.state = index === i ? value : false;         
      })
      setListProductInStock([...newArray]); 
      setArrayAdjust([]);     
  }
  // console.log(listProductInStock)


  const handlePushAdjustArray = () => {
    if(listProductInStock?.length !== 0) {
      let newArray = listProductInStock.filter( (e) => e.state === true);
      if(newArray?.length !== 0) {
        setArrayAdjust([...newArray]);
        setFieldValue("qtyAdjust" , newArray[0]?.qty)
      }
    }
  }
 
  
  const [adjustProductInStock] = useMutation(CREATE_ADJUST_QUANTITY, {
    onCompleted: async ({ adjustProductInStock }) => {
        if (adjustProductInStock?.status === true) {
            setCheckMessage("success");
            setMessage(adjustProductInStock?.message);
            setAlert(true);
            setRefetch();
            handleClose();
            setLoading(false);     
        } else {
            setCheckMessage("error");
            setMessage(adjustProductInStock?.message);
            setAlert(true);
            setLoading(false);
        }
    },
    onError: (error) => {
      setCheckMessage("error");
      setMessage(error.message);
      setAlert(true);
      setLoading(false);
    },
  });


  // formik
  const AddStorage = Yup.object().shape({
    id: Yup.string().required(),   
    qtyAdjust: Yup.number().required(),
    unitPrice: Yup.number().required(),
  });
  

  const formik = useFormik({
    initialValues: {
      id: "",
      qtyAdjust: 0,
      unitPrice: 0
    },

    validationSchema: AddStorage,
    onSubmit: (values) => {

      setLoading(true);
      const newValue = {
          productInstockId: values?.id,
          qty: values.qtyAdjust,
          unitPrice: values?.unitPrice,
      }

      // console.log("newValue" , newValue);
      adjustProductInStock({
          variables: {
              ...newValue,
          }
      })
      
    },
  });

  const {  errors, touched,  handleSubmit,  getFieldProps,  setFieldValue,   values, } = formik;

  // console.log(receiveDate);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box className="purchase-out">
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" className="title">
              Transfer Out Product
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <ClearIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>

          <Box sx={{ width: "100%" }}>

          <Stack direction="row" spacing={2}>
              <TableCell className="sub-title" width="33.33%" colSpan={2}>
                  <Typography className="text-typography" width="100px">
                    Storage:
                  </Typography>
                  <Stack direction="row" spacing={1}>   
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo-storage"
                      size="small"
                      fullWidth
                      options={storageRoom}
                      getOptionLabel={(option) => (option.label ? option.label : "")}
                      onChange={(e, value) => {
                        setStorageRoomId(value?.id);                    
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="text-box-demo-storage"
                          placeholder="Choose"
                          size="small"
                          {...params}
                          error={Boolean(
                            touched.storage_Room_Id && errors.storage_Room_Id
                          )}
                          helperText={touched.storage_Room_Id && errors.storage_Room_Id}
                        />
                      )}
                    />
                  </Stack>
              </TableCell>

              <TableCell className="sub-title" width="33.33%" colSpan={2}>
                    <Typography className="text-typography" width="100px">
                      Product:
                    </Typography>
                    <Stack direction="row" spacing={1}>                    
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        fullWidth
                        options={allProduct}
                        getOptionLabel={(option) => (option.label ? option.label : "")}
                        onChange={(e, value) => {
                          setProductId(value?.id);
                          // setSupplierSelected({ label: value?.label, id: value?.id });
                        }}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Choose"
                            size="small"
                            {...params}
                            error={Boolean(touched.supplier_id && errors.supplier_id)}
                            helperText={touched.supplier_id && errors.supplier_id}
                          />
                        )}
                      />
                    </Stack>
              </TableCell>


              <TableCell className="sub-title" width="33.33%" colSpan={2}>
                  <Typography className="text-typography" width="100px">
                    Suppliers:
                  </Typography>
                  <Stack direction="row" spacing={1}>                    
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        fullWidth
                        options={suppliers}
                        getOptionLabel={(option) => (option.label ? option.label : "")}
                        onChange={(e, value) => {
                          setSupplierId(value?.id);
                          setSupplierSelected({ label: value?.label, id: value?.id });
                        }}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Choose"
                            size="small"
                            {...params}
                            error={Boolean(touched.supplier_id && errors.supplier_id)}
                            helperText={touched.supplier_id && errors.supplier_id}
                          />
                        )}
                      />
                  </Stack>                
              </TableCell>

            </Stack>

            <Stack direction="row" spacing={2}>  
              <TableCell className="sub-title" width="33.33%">
                <Typography className="text-typography"> Recieved:</Typography>
                <Stack direction="row" spacing={1}>
                  

                  <LocalizationProvider
                    className="date-controll"
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      value={receiveDate}
                      onChange={(e) => setReceiveDate(e)}
                      renderInput={(params) => (
                        <TextField
                          className="text-field"
                          size="small"
                          {...params}
                          fullWidth
                          type="date"
                          defaultValue={receiveDate}                          
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </TableCell>
            </Stack>
            
          </Box>

          <Grid container mt={2} spacing={2}>
                <Grid item xs={5}>
                  <TableContainer className="container">
                      <Table className="table" aria-label="simple table">
                        <TableHead>
                          <TableRow className="header-row">
                            <TableCell className="header-title" align="center" width="5%">
                              #
                            </TableCell>
                            <TableCell className="header-title" align="left" width="25%">
                              Product Name
                            </TableCell>
                            <TableCell
                              className="header-title"
                              align="center"
                              width="15%"
                            >
                              Qty
                            </TableCell>
                            <TableCell
                              className="header-title"
                              align="center"
                              width="15%"                    
                            >
                              Unit Price
                            </TableCell>      
                            <TableCell
                              className="header-title"
                              align="center"
                              width="15%"                    
                            >
                              InStockOn
                            </TableCell>                                      
                          </TableRow>
                        </TableHead>

                        {
                          listProductInStock?.map( (row,index) => (
                            <TableBody component={Paper} className="body-list-product" key={index}>
                                <TableRow className="body-row" width="100%">
                                    <TableCell
                                      className="body-title"
                                      component="th"
                                      scope="row"            
                                    >
                                      <Checkbox checked={row?.state} onChange={() => handleUpdateState(!row?.state,index)}/>
                                    </TableCell>
                                    <TableCell className="body-title">
                                      {row?.productName}
                                    </TableCell>
                                    <TableCell className="body-title" align="center">
                                      {row?.qty}
                                    </TableCell>
                                    <TableCell className="body-title" align="center">
                                      {row?.price}
                                    </TableCell>      
                                    <TableCell className="body-title" align="center">
                                      {moment(row?.instock_At)?.format("DD-MMM")}
                                    </TableCell>                                
                                </TableRow>
                            </TableBody>
                          ))
                        }

                          </Table>
                    </TableContainer>

                </Grid>
                <Grid item xs={12} md={2}>
                  <Stack direction="column" justifyContent="center" height="100%">
                      <Button
                          className="btn-create"
                          variant="text"
                          fullWidth             
                          sx={{ mt: 2 , mb: 2 }}
                          onClick={handlePushAdjustArray}
                          startIcon={<DoubleArrowIcon />}
                          endIcon={<DoubleArrowIcon />}
                      >
                          transfer
                      </Button>
                  </Stack>                  
                </Grid>
                <Grid item xs={12} md={5}>
                  <TableContainer className="container">
                      <Table className="table" aria-label="simple table">
                        <TableHead>
                          <TableRow className="header-row">
                            <TableCell className="header-title" align="center" width="5%">
                              #
                            </TableCell>
                            <TableCell className="header-title" align="left" width="25%">
                              Product Name
                            </TableCell>
                            <TableCell
                              className="header-title"
                              align="center"
                              width="15%"
                            >
                              Qty
                            </TableCell>
                            <TableCell
                              className="header-title"
                              align="center"
                              width="15%"                    
                            >
                              Unit Price
                            </TableCell>
                            <TableCell
                              className="header-title"
                              align="center"
                              width="15%"                    
                            >
                              InStockOn
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      
                      {
                        arrayAdjust?.map( (row,index) => (
                          <TableBody component={Paper} className="body-list-product" key={index}>
                              <TableRow className="body-row" width="100%">
                                  <TableCell
                                    className="body-title"
                                    component="th"
                                    scope="row"            
                                  >
                                    <Checkbox checked/>
                                  </TableCell>
                                  <TableCell className="body-title">
                                    {row?.productName}
                                  </TableCell>
                                  <TableCell className="body-title" align="center">                                    
                                    <TextField
                                      fullWidth
                                      type="number"
                                      className="text-field"                                    
                                      size="small"
                                      value={values?.qtyAdjust}
                                      onFocus={ (e) => e.target.select()}
                                      onChange={ (e) => {
                                          if(parseFloat(e.target.value) < row?.qty ) {
                                            setFieldValue("id" , row?.id);
                                            setFieldValue("qtyAdjust" , parseFloat(e.target.value));
                                            setFieldValue("unitPrice" , row?.price);
                                          } else {
                                            setFieldValue("id" , "");
                                            setFieldValue("qtyAdjust" , parseFloat(row?.qty));
                                            setFieldValue("unitPrice" , 0);
                                          }                                      
                                      }}                                                                           
                                    />
                                  </TableCell>
                                  <TableCell className="body-title" align="center">
                                    {row?.price}
                                  </TableCell>   
                                  <TableCell className="body-title" align="center">
                                    {moment(row?.instock_At)?.format("DD-MMM")}
                                  </TableCell>      
                              </TableRow>
                          </TableBody>
                        ))
                      }
                        
                      </Table>
                    </TableContainer>

                </Grid>
          </Grid>


        <Stack direction="row" justifyContent="center" width="100%">
            <Box width="50%">
            { loading ? (
                <Button
                  className="btn-create"
                  variant="text"
                  fullWidth
                  sx={{ mt: 3 }}
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  className="btn-create"
                  variant="text"
                  fullWidth
                  type="submit"
                  sx={{ mt: 3 }}
                >
                  Adjust Qauntity
                </Button>
              )}
            </Box>
        </Stack>
          
        </Box>
      </Form>
    </FormikProvider>
  );
}
