import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Modal,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import "./shop.scss";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
//component
import Profile from "../Component/Menu/Profile";
import AlertMessage from "../Component/AlertMessage/AlertMessage";
import CreateStorageRoom from "../Component/StorageRoom/CreateStorageRoom";
import StorageRoomAction from "../Component/StorageRoom/StorageRoomAction";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
import { GET_STORAGE_ROOM } from "../Schema/storageroom";
import { useQuery } from "@apollo/client";

export default function StorageRoom() {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(true);

  // AlertMessage
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [checkMessage, setchekMessage] = useState("");
  const [tableData, setTableData] = useState([]);

  const [keyword, setKeyword] = useState("");
  const { refetch } = useQuery(GET_STORAGE_ROOM, {
    variables: {
      keyword: keyword,
    },
    onCompleted: ({ getStorageRoom }) => {
      setTableData(getStorageRoom);
      setLoading(false);
    },

    onError: () => {
      setLoading(true);
    },
  });

  //click setlocalStorage
  const handleClickLocal = (e) => {
    localStorage.setItem("storageId", JSON.stringify(e?._id));
  };

  //get value from localstorage
  const storageId = JSON.parse(localStorage.getItem("storageId"));

  return (
    <div className="customer-page">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="page-title"> Storage Room</Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }}></Box>
        <Profile />
      </Stack>

      <Stack direction="row" spacing={2}>
        <Stack direction="column" spacing={2} className="btn-search">
          <Box className="btn-text-field">
            <TextField
              className="text-field"
              onChange={(e) => setKeyword(e.target.value)}
              fullWidth
              id="input-with-sx"
              placeholder="Search Room"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="column"
          className="stack-btn"
          justifyContent="right"
          spacing={1}
        >
          <Button
            onClick={handleOpen}
            startIcon={<AddCircleOutlineOutlinedIcon className="icon-add" />}
            className="btn-add"
          >
            <Typography className="btn-text">Create</Typography>
          </Button>

          <Modal open={open}>
            <CreateStorageRoom
              modalTitle={"CREATE STORAGEROOM"}
              setRefetch={refetch}
              setAlert={setAlert}
              setMessage={setMessage}
              handleClose={handleClose}
              setchekMessage={setchekMessage}
            />
          </Modal>
        </Stack>
      </Stack>

      <Box className="container">
        <TableContainer>
          <Table className="table" aria-label="simple table">
            <TableHead>
              <TableRow className="header-row">
                <TableCell className="header-title" width="7%">N°</TableCell>
                <TableCell className="header-title">Name</TableCell>
                <TableCell className="header-title">Location</TableCell>
                <TableCell className="header-title">Remark</TableCell>    
                <TableCell className="header-title" align="center"></TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <>
                <TableBody>
                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={5}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={5}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={5}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </>
            ) : (
              <>
                {tableData?.map((row, index) => (
                  <TableBody
                    key={index}
                    className={
                      storageId === row?._id ? "body-active" : "body-odd"
                    }
                  >
                    <TableRow
                      className="body-row"
                      onClick={() => handleClickLocal(row)}
                    >
                      <TableCell
                        className="body-title"
                        component="th"
                        scope="row"
                        width="3%"
                        sx={{ cursor: "pointer" }}
                      >
                        {index + 1}
                      </TableCell>

                      <TableCell
                        className="body-bold"
                        component="th"
                        scope="row"
                        onClick={() => {
                          navigate(
                            `/storage-room/purchase?id=${row?._id}&name=${row?.name}`
                          );
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <Stack direction="row" spacing={1}>
                          <BusinessOutlinedIcon className="icon-maker" />
                          <Typography className="text">{row?.name}</Typography>
                        </Stack>
                      </TableCell>

                      <TableCell
                        className="body-title"
                        onClick={() => {
                          navigate(
                            `/storage-room/purchase?id=${row?._id}&name=${row?.name}`
                          );
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        {row?.place}
                      </TableCell>

                      <TableCell
                        className="body-title"
                        onClick={() => {
                          navigate(
                            `/storage-room/purchase?id=${row?._id}&name=${row?.name}`
                          );
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        {row?.remark}
                      </TableCell>

                      
                      <TableCell className="body-title" align="right">
                        <StorageRoomAction
                          row={row}
                          setRefetch={refetch}
                          setAlert={setAlert}
                          setMessage={setMessage}
                          setchekMessage={setchekMessage}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </>
            )}
          </Table>
        </TableContainer>
      </Box>

      <AlertMessage
        alert={alert}
        setAlert={setAlert}
        message={message}
        setMessage={setMessage}
        checkMessage={checkMessage}
      />
    </div>
  );
}
