import React, { useEffect } from "react";
import "./productdetails.scss";
import { Box, Typography, Stack, Skeleton, Grid } from "@mui/material";
import Stock from "../../../Assets/new-dashboard/stock-market.png";
import Todo from "../../../Assets/new-dashboard/to-do.png";
import { GET_NOITEMS, GET_LOWSTOCKITEMS, GET_TOTAL_ADJUST, GET_TOTAL_CANCELORDER } from "../../../Schema/dashboard";
import { useQuery } from "@apollo/client";
import trolley from "../../../Assets/new-dashboard/trolley.png";
import NumberFormat from "react-number-format";
import Order from "../../../Assets/new-dashboard/order.png";

export default function ProductDetails() {
  const [loading, setLoading] = React.useState(true);

  const { data } = useQuery(GET_LOWSTOCKITEMS, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: (error) => {
      setLoading(true);
    },
  });

  const { data: Data } = useQuery(GET_NOITEMS, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: (error) => {
      setLoading(true);
    },
  });

  const { data: DataCancelOrder , refetch } = useQuery(GET_TOTAL_CANCELORDER, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: (error) => {
      setLoading(true);
    },
  });


  const { data: totalAdjust , refetch: refetchQty } = useQuery(GET_TOTAL_ADJUST, {
    onCompleted: () => {
      setLoading(false);
    },

    onError: () => {
      setLoading(true);
    },
  });
  

  useEffect( () => {
    refetch()
    refetchQty()
  },[])


  if (loading) {
    return (
      <Box className="product-details" sx={{ height: "50%" }}>
        <Box sx={{ width: "100%" }}>
          <Stack direction="column" spacing={2}>
            <Typography variant="body2" className="main-title">
              <Skeleton height={20} width="40%" variant="rectangular" />
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} mt={2}>
            <Skeleton height="100px" width="100%" variant="rectangular" />
            <Skeleton height="100px" width="100%" variant="rectangular" />
          </Stack>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="product-details">
      <Typography className="main-title">Purchase Overview </Typography>

      <Box className="grid-group">
        <Grid container spacing={3}>

          <Grid item xs={12} sm={12} md={6}>
            <Box className="card-productdetail">
              <Box sx={{display:"flex" ,  flexDirection: "column", justifyContent: "center" ,width:"100%"}}>
                <Stack direction="row" justifyContent="center" width="100%">
                    <Stack direction="row" justifyContent="left" width="40%">
                      <img src={`${Order}`} width="60px" height="60px" />
                    </Stack>
                    <Box sx={{flexGrow:1}} />
                    <Stack direction="row" justifyContent="center" width="60%">
                        <Stack direction="column">
                            <Typography
                            className="sub-title"
                            >
                               Cancel Order
                            </Typography>
                            <Typography className="number" variant="h5" mt={2}>
                              {
                                <NumberFormat
                                  value={DataCancelOrder?.getTotalCancelOrder}
                                  thousandSeparator={true}
                                  displayType={"text"}
                                  renderText={(value, props) => (
                                    <div {...props}>{value}</div>
                                  )}
                                />
                              }
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
              </Box>
            </Box>
          </Grid>


          
          <Grid item xs={12} sm={12} md={6}>
            <Box className="card-productdetail">
                <Box sx={{display:"flex" ,  flexDirection: "column", justifyContent: "center" ,width:"100%"}}>
                    <Stack direction="row" justifyContent="center" width="100%">
                        <Stack direction="row" justifyContent="left" width="40%">
                          <img src={`${trolley}`} width="60px" height="60px" />
                        </Stack>

                        <Stack direction="row" justifyContent="center" width="60%">
                          <Stack direction="column">
                            <Typography className="sub-title">
                              Adjust Quantity
                            </Typography>
                            <Typography className="number" variant="h5" mt={2}>
                              {totalAdjust?.totalAdjust} times
                            </Typography>
                          </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
          </Grid>
         
        </Grid>
      </Box>
    </Box>
  );
}
