import { gql } from "@apollo/client";

export const UPDATE_RELEASE_CARD = gql`
  mutation UpdateReleaseCard($input: updateReleaseCardInput!) {
    updateReleaseCard(input: $input) {
      message
      status
      data {
        _id
        shop_Id {
          _id
          name
          email
          phone
          address
          remark
          created_At
        }
        delivery
        status
        release_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        delivery_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        numbering
        delivery_At
        time
        remark
        order_Date
      }
    }
  }
`;

export const GET_RELEASE_PRODUCT_WITH_PAGINATION = gql`
  query GetReleaseProductPagination(
    $page: Int!
    $limit: Int!
    $keyword: String!
    $shopId: String!
    $deliveryId: String!
    $deliveryAt: String!
  ) {
    getReleaseProductPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      shop_Id: $shopId
      delivery_Id: $deliveryId
      delivery_At: $deliveryAt
    ) {
      data {
        _id
        shop_Id {
          _id
          name
          email
          phone
          address
          remark
          created_At
        }
        delivery
        status
        release_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        delivery_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        numbering
        delivery_At
        time
        remark
        items {
          _id
          product_Id {
            _id
            name
            cost
            category
            image_src
            remark
            type
            feature
            unit
            bar_Code
            created_At
          }
          qty
          unit_Price
          purchase_Id {
            _id
            supplier_id {
              _id
              name
              tel
              email
              adress
              remark
              facebook
              image_Src
              image_Name
              created_At
            }
            numbering
            need_Date
            remark
            storage_Room_Id {
              _id
              name
              place
              remark
              created_At
            }
            approve_status
            priority
            status
            receive_Date
            created_At
          }
          key
          storage_Room_Id {
            _id
            name
            place
            remark
            created_At
          }
          created_At
          stock_Status
          valua
        }
        order_Date
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
      message
    }
  }
`;

export const CREATE_RELEASE_CARD = gql`
  mutation CreateReleaseCard($input: createReleaseCardInput!) {
    createReleaseCard(input: $input) {
      message
      status
      data {
        _id
        shop_Id {
          _id
          name
          email
          phone
          address
          remark
          created_At
        }
        delivery
        status
        release_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        delivery_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        numbering
        delivery_At
        time
        remark
        items {
          _id
          product_Id {
            _id
            name
            cost
            category
            image_src
            remark
            type
            feature
            unit
            bar_Code
            created_At
          }
          qty
          unit_Price
          purchase_Id {
            _id
            supplier_id {
              _id
              name
              tel
              email
              adress
              remark
              facebook
              image_Src
              image_Name
              created_At
            }
            numbering
            need_Date
            remark
            storage_Room_Id {
              _id
              name
              place
              remark
              created_At
            }
            purchase_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            receive_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            approve_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            items {
              _id
              product_Id {
                _id
                name
                cost
                category
                image_src
                remark
                type
                feature
                unit
                bar_Code
                created_At
              }
              qty
              unit_Price
              key
              created_At
              stock_Status
              valua
            }
            approve_status
            priority
            status
            receive_Date
            created_At
          }
          key
          storage_Room_Id {
            _id
            name
            place
            remark
            created_At
          }
          created_At
          stock_Status
          valua
        }
        order_Date
      }
    }
  }
`;

export const VOID_RELEASE_CARD = gql`
  mutation VoidingReleaseCard($releaseCardId: String!) {
    voidingReleaseCard(release_Card_Id: $releaseCardId) {
      message
      status
    }
  }
`;

export const DELIVER_RELEASE_CARD = gql`
  mutation Delivered($releaseCardId: String!, $input: createReleaseCardInput!) {
    delivered(release_Card_Id: $releaseCardId, input: $input) {
      message
      status
    }
  }
`;

export const CREATE_RELEASECARD = gql`
  mutation CreateReleasMaterialCard($input: releaseMaterialInput!) {
    createReleasMaterialCard(input: $input) {
      status
      message
    }
  }
`;


// // export const GET_RELEASECARD_WITH_PAGINATION = gql`
// //   query GetReleaseMaterialPagination(
// //     $page: Int!
// //     $limit: Int!
// //     $customerId: String!
// //     $stockControllerId: String!
// //     $orderDate: String!
// //   ) {
// //     getReleaseMaterialPagination(
// //       page: $page
// //       limit: $limit
// //       customer_Id: $customerId
// //       stock_Controller_Id: $stockControllerId
// //       order_Date: $orderDate
// //     ) {
// //       data {
// //         _id
// //         no
// //         delivery_Date
// //         created_At
// //         order_Date
// //         customer_Id {
// //           _id
// //           name
// //           tel
// //           email
// //           address
// //         }
// //         stock_Controller_Id {
// //           _id
// //           user_name
// //         }
// //         delivery_man {
// //           _id
// //           user_name
// //         }
// //         status
// //         items {
// //           materrail_Id {
// //             _id
// //             name
// //           }
// //           from_Stock_Id {
// //             _id
// //             name
// //           }
// //           qty
// //           other
// //           key
// //         }
// //       }
// //       paginator {
// //         slNo
// //         prev
// //         next
// //         perPage
// //         totalPosts
// //         totalPages
// //         currentPage
// //         hasPrevPage
// //         hasNextPage
// //         totalDocs
// //       }
// //       message
// //     }
// //   }
// // `;

// // export const GET_RELEASE_CARD_BY_DATE = gql`
// //   query GetRelaseCardByDate($deliveryDate: String!) {
// //     getRelaseCardByDate(delivery_Date: $deliveryDate) {
// //       data {
// //         _id
// //         no
// //         delivery_Date
// //         created_At
// //         order_Date
// //         customer_Id {
// //           _id
// //           name
// //           tel
// //           email
// //           address
// //           remark
// //           created_At
// //         }
// //         stock_Controller_Id {
// //           _id
// //           user_name
// //           mail
// //           position
// //           image_name
// //           image_src
// //           created_At
// //           update_At
// //           status
// //         }
// //         delivery_man {
// //           _id
// //           user_name
// //           mail
// //           position
// //           image_name
// //           image_src
// //           created_At
// //           update_At
// //           status
// //         }
// //         status
// //         items {
// //           materrail_Id {
// //             _id
// //             name
// //             price
// //             category
// //             image_src
// //             remark
// //             type
// //             feature
// //             unit
// //             created_At
// //           }
// //           from_Stock_Id {
// //             _id
// //             name
// //             created_At
// //             remark
// //             type
// //           }
// //           qty
// //           other
// //           key
// //         }
// //       }
// //       message
// //       status
// //     }
// //   }
// // `;

// export const VOICE_RELEASECARD = gql`
//   mutation VoidReleasMaterialCard($releasCardId: String!) {
//     voidReleasMaterialCard(releas_Card_Id: $releasCardId) {
//       status
//       message
//     }
//   }
// `;
