import React, { useState } from "react";
import {
  Box,
  Stack,
  Grid,
  styled,
  Typography,
  IconButton,
  TextField,
  Avatar,
  Button,
} from "@mui/material";
import "./suppliesadd.scss";
import * as Yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik, Form, FormikProvider } from "formik";
import { CREATE_SUPPLIES } from "../../Schema/supplies";
import { useMutation } from "@apollo/client";
import imageCompression from "browser-image-compression";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import axios from "axios";

export default function SuppliesAdd({
  handleClose,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
}) {
  const [loading, setLoading] = useState(false);

  const Input = styled("input")({
    display: "none",
  });

  const [createdSupplier] = useMutation(CREATE_SUPPLIES, {
    onCompleted: ({ createdSupplier }) => {
      if (createdSupplier?.status === true) {
        setchekMessage("success");
        setMessage(createdSupplier?.message);
        setAlert(true);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setchekMessage("error");
        setMessage(createdSupplier?.message);
        setAlert(true);
        setLoading(false);
      }
    },

    onError: ({ error }) => {
      setchekMessage("error");
      setMessage(error.message);
      setAlert(true);
      setLoading(false);
    },
  });

  // upload Image
  const [imageFile, setImageFile] = useState(null);

  const newDate = moment(new Date()).format("MMDDYYYY");
  const uploadImage = async (file, values) => {
    // console.log("files::", file);

    //compressfile to small
    if (!file) return;
    const formData = new FormData();
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    //change image name
    let newName = `${uuidv4()}${newDate}${file.name.split(".").pop()}`;
    //convert extention
    var newFile = new File([compressedFile], `${newName}.png`, {
      type: "image/png",
    });

    formData.append("image", newFile);

    //post to store at backend
    await axios
      .post(
        `${process.env.REACT_APP_UPLOAD_URL}/api/inventory-mart/upload/`,
        formData,
        config
      )

      //catch image url
      .then(async function (response) {
        createdSupplier({
          variables: {
            input: {
              image_Name: imageFile.name,
              image_Src: `${process.env.REACT_APP_UPLOAD_URL}/api${response?.data}`,
              ...values,
            },
          },
        });
      });
  };

  const AddSupplies = Yup.object().shape({
    name: Yup.string().required("required Name"),
    email: Yup.string(),
    tel: Yup.string(),
    adress: Yup.string(),
    remark: Yup.string(),
  });

  // empty field
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      adress: "",
      tel: "",
      remark: "",
      facebook: "",
    },

    validationSchema: AddSupplies,

    onSubmit: (values) => {
      setLoading(true);
      if (imageFile) {
        uploadImage(imageFile, values);
      } else {
        createdSupplier({
          variables: {
            input: {
              ...values,
              image_Src: "",
              image_Name: "",
            },
          },
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box spacing={5} className="supplies-add">
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" className="title">
              CREATE SUPPLIES
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <ClearIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>

          <Stack
            direction="row"
            spacing={5}
            sx={{ mt: 2 }}
            justifyContent="center"
          >
            <label htmlFor="icon-button-file">
              <Input
                accept="image/*, capture=camera"
                capture="”camera"
                id="icon-button-file"
                type="file"
                onChange={(event) => {
                  setImageFile(event.target.files[0]);
                }}
              />

              {imageFile ? (
                <Avatar
                  className="upload"
                  alt="Image"
                  src={URL.createObjectURL(imageFile)}
                />
              ) : (
                <Avatar className="upload" alt="Image" />
              )}
            </label>
          </Stack>
          <Typography className="body-title"> Image </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="Sub-title"> Name </Typography>
              <TextField
                className="text-field"
                id="name"
                size="small"
                fullWidth
                placeholder="name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="Sub-title">Email</Typography>
              <TextField
                className="text-field"
                size="small"
                fullWidth
                placeholder="email"
                {...getFieldProps("email")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="Sub-title">Phone Number</Typography>
              <TextField
                className="text-field"
                id="tel"
                size="small"
                fullWidth
                placeholder="phone-number"
                {...getFieldProps("tel")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="Sub-title">Address</Typography>
              <TextField
                className="text-field"
                required
                size="small"
                fullWidth
                placeholder="adress"
                {...getFieldProps("adress")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="Sub-title">Remark</Typography>
              <TextField
                className="text-field"
                required
                size="small"
                rows={3}
                fullWidth
                placeholder="remark"
                {...getFieldProps("remark")}
              />
            </Grid>

            <Grid item xs={12}>
              {loading ? (
                <Button className="btn-create" variant="text" fullWidth>
                  loading...
                </Button>
              ) : (
                <Button
                  className="btn-create"
                  variant="text"
                  fullWidth
                  type="submit"
                >
                  CREATE
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Form>
    </FormikProvider>
  );
}
