import { gql } from "@apollo/client";


export const GET_PURCHASE_PENDDING = gql`
  query GetPendingPurchase {
    getPendingPurchase {
      _id
      items {
        _id
        product_Id {
          name
        }
        qty
        unit_Price
        key
        created_At
        stock_Status
        valua
      }
      storage_Room_Id {
        _id
        name
      }
      priority
      numbering
      need_Date
      docsType
      created_At
      approve_status
      status
      remark
    }
  }
`;

export const CREATE_PURCHASE = gql`
  mutation Mutation($input: createPurchaseInput!) {
    createPurchase(input: $input) {
      message
      status
      data {
        _id
        supplier_id {
          _id
          name
          tel
          email
          adress
          remark
          facebook
          image_Src
          image_Name
          created_At
        }
        numbering
        need_Date
        remark
        storage_Room_Id {
          _id
          name
          place
          remark
          created_At
        }
        purchase_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        receive_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        approve_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        items {
          _id
          product_Id {
            _id
            name
            cost
            category
            image_src
            remark
            type
            feature
            unit
            created_At
          }
          qty
          unit_Price
          purchase_Id {
            _id
            supplier_id {
              _id
              name
              tel
              email
              adress
              remark
              facebook
              image_Src
              image_Name
              created_At
            }
            numbering
            need_Date
            remark
            storage_Room_Id {
              _id
              name
              place
              remark
              created_At
            }
            purchase_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            receive_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            approve_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            items {
              _id
              product_Id {
                _id
                name
                cost
                category
                image_src
                remark
                type
                feature
                unit
                created_At
              }
              qty
              unit_Price
              key
              purchase_Id {
                _id
                numbering
                need_Date
                remark
                storage_Room_Id {
                  _id
                  name
                  place
                  remark
                  created_At
                }
                purchase_By {
                  _id
                  firsName
                  lastName
                  image_name
                  image_src
                  email
                  role
                  created_At
                }
                receive_By {
                  _id
                  firsName
                  lastName
                  image_name
                  image_src
                  email
                  role
                  created_At
                }
                approve_By {
                  _id
                  firsName
                  lastName
                  image_name
                  image_src
                  email
                  role
                  created_At
                }
                items {
                  _id

                  qty
                  unit_Price
                  purchase_Id {
                    _id
                    supplier_id {
                      _id
                      name
                      tel
                      email
                      adress
                      remark
                      facebook
                      image_Src
                      image_Name
                      created_At
                    }
                  }
                  key
                  storage_Room_Id {
                    _id
                    name
                    place
                    remark
                    created_At
                  }
                }
                approve_status
                priority
                status
                created_At
              }
            }
            approve_status
            priority
            status
            created_At
          }
          key
          storage_Room_Id {
            _id
            name
            place
            remark
            created_At
          }
        }
        approve_status
        priority
        status
        created_At
      }
    }
  }
`;

export const VOID_PURCHASE = gql`
  mutation Mutation($purchaseId: String) {
    voidingPurchas(purchase_Id: $purchaseId) {
      message
      status
    }
  }
`;

export const APPROVE_PURCHASING = gql`
  mutation Mutation($status: String!, $purchaseId: String) {
    approvePurchasing(status: $status, purchase_Id: $purchaseId) {
      message
      status
      data {
        _id
        supplier_id {
          _id
          name
          tel
          email
          adress
          remark
          facebook
          image_Src
          image_Name
          created_At
        }
        numbering
        need_Date
        remark
        storage_Room_Id {
          _id
          name
          place
          remark
          created_At
        }
        purchase_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        receive_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        approve_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        items {
          _id
          product_Id {
            _id
            name
            cost
            category
            image_src
            remark
            type
            feature
            unit
            created_At
          }
          qty
          unit_Price
          purchase_Id {
            _id
            supplier_id {
              _id
              name
              tel
              email
              adress
              remark
              facebook
              image_Src
              image_Name
              created_At
            }
            numbering
            need_Date
            remark
            storage_Room_Id {
              _id
              name
              place
              remark
              created_At
            }
            purchase_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            receive_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            approve_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            approve_status
            priority
            status
            created_At
          }
          key
          storage_Room_Id {
            _id
            name
            place
            remark
            created_At
          }
        }
        approve_status
        priority
        status
        created_At
      }
    }
  }
`;

export const RECIEVE_PURCHASE = gql`
  mutation ReceivePuchaseingProduct($input: recievePurchaseInput!) {
    receivePuchaseingProduct(input: $input) {
      message
      status
      data {
        _id
        supplier_id {
          _id
          name
          tel
          email
          adress
          remark
          facebook
          image_Src
          image_Name
          created_At
        }
        numbering
        need_Date
        remark
        storage_Room_Id {
          _id
          name
          place
          remark
          created_At
        }
        purchase_By {
          _id
          firsName
          lastName
        }
        receive_By {
          _id
          firsName
          image_name
        }
        approve_By {
          _id
          firsName
          lastName
        }
        items {
          _id
          valua
          qty
          unit_Price
          storage_Room_Id {
            remark
            place
            name
            created_At
            _id
          }
          created_At
          key
          product_Id {
            _id
            unit
            type
            remark
            name
            image_src
            feature
            created_At
            cost
            category
            bar_Code
          }
        }
        approve_status
        priority
        status
        receive_Date
        created_At
      }
    }
  }
`;

export const UPDATE_PURCHASE = gql`
  mutation Mutation($purchaseId: String!, $input: updatePurchaseInput!) {
    updatePurchase(purchase_Id: $purchaseId, input: $input) {
      message
      status
      data {
        _id
        supplier_id {
          _id
          name
          tel
          email
          adress
          remark
          facebook
          image_Src
          image_Name
          created_At
        }
        numbering
        need_Date
        remark
        storage_Room_Id {
          _id
          name
          place
          remark
          created_At
        }
        purchase_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        receive_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        approve_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        items {
          _id
          product_Id {
            _id
            name
            cost
            category
            image_src
            remark
            type
            feature
            unit
            created_At
          }
          qty
          unit_Price
          purchase_Id {
            _id
            supplier_id {
              _id
              name
              tel
              email
              adress
              remark
              facebook
              image_Src
              image_Name
              created_At
            }
            numbering
            need_Date
            remark
            storage_Room_Id {
              _id
              name
              place
              remark
              created_At
            }
            purchase_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            receive_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            approve_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            approve_status
            priority
            status
            created_At
          }
          key
        }
        approve_status
        priority
        status
        created_At
      }
    }
  }
`;

export const GET_PURCHASE_PAGINATION = gql`
  query Query(
    $page: Int!
    $limit: Int!
    $keyword: String!
    $storageRoomId: String!
    $approveStatus: String!
    $priority: String!
  ) {
    getPurchasingPaginatioin(
      page: $page
      limit: $limit
      keyword: $keyword
      storage_Room_Id: $storageRoomId
      approve_status: $approveStatus
      priority: $priority
    ) {
      data {
        _id
        exchange_rate
        supplier_id {
          _id
          name
          tel
          email
          adress
          remark
          facebook
          image_Src
          image_Name
          created_At
        }
        numbering
        need_Date
        remark
        storage_Room_Id {
          _id
          name
          place
          remark
          created_At
        }
        purchase_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        receive_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        approve_By {
          _id
          firsName
          lastName
          image_name
          image_src
          email
          role
          created_At
        }
        items {
          _id
          product_Id {
            _id
            name
            cost
            category
            image_src
            remark
            type
            feature
            unit
            created_At
          }
          qty
          unit_Price
          purchase_Id {
            _id
            supplier_id {
              _id
              name
              tel
              email
              adress
              remark
              facebook
              image_Src
              image_Name
              created_At
            }
            numbering
            need_Date
            remark
            storage_Room_Id {
              _id
              name
              place
              remark
              created_At
            }
            purchase_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            receive_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            approve_By {
              _id
              firsName
              lastName
              image_name
              image_src
              email
              role
              created_At
            }
            approve_status
            priority
            status
            created_At
          }
          key
        }
        approve_status
        priority
        status
        created_At
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
      message
    }
  }
`;

export const GET_PURCHASE_BYID = gql`
  query GetPurchaseById($purchaseId: ID) {
    getPurchaseById(purchase_id: $purchaseId) {
      _id
      supplier_id {
        _id
        adress
        name
        tel
        email
      }
      numbering
      need_Date
      remark
      storage_Room_Id {
        _id
        name
      }
      purchase_By {
        _id
        firsName
        lastName
      }
      receive_By {
        _id
        firsName
        lastName
        role
      }
      approve_By {
        _id
        firsName
        lastName
      }
      items {
        _id
        product_Id {
          _id
          cost
          name
          qty_inUnit
          unit
          type
          remark
          image_src
          bar_Code
          feature
        }
        qty
        unit_Price      
        key      
        created_At
        stock_Status
        valua
      }
      approve_status
      priority
      status
      receive_Date
      docsType
      created_At
    }
  }
`;