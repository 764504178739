import * as React from "react";
import "./reporttable.scss";
import { useQuery } from "@apollo/client";
import { GET_STOCK_ON_HAND_REPORT } from "../../Schema/report";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import NumberFormat from "react-number-format";

//logo
import Logo from "../../Assets/GoGlobalFull_Name.png";
import WingdingImage from "../../Assets/tacteing.jpg";


function StockOnHandReportPrint({ FromData, ToData, productId, category }) {

  const [viewReport, setViewReport] = React.useState([]);
  const [EditInput, setEditInput] = React.useState([]);

  console.log(FromData,ToData);

  const { data, refetch } = useQuery(GET_STOCK_ON_HAND_REPORT, {
    variables: {
      catagory: category !== undefined && category !== "" ? category : "",
      productId: productId !== undefined && productId !== "" ? productId : "",
      from: FromData !== undefined && FromData !== "" ? FromData : "",
      to: ToData !== undefined && ToData !== "" ? ToData : "",
    },
    onCompleted: ({ getStockOnhandReport }) => {
      setViewReport(getStockOnhandReport);

      console.log(getStockOnhandReport);

      let quantity = [];
      let allQuantity = 0;
      getStockOnhandReport?.data?.forEach((element) =>
        element?.stock_Detail?.map( (e) => {
          allQuantity = e?.qty;
          quantity.push(allQuantity);
        })
      );

      setEditInput(quantity);
    },
    pollInterval: 10000,
  });

  const handleClickEdit = (e, replaceVal) => {
    const newArray = EditInput.map((val, i) =>
      i !== e ? val : parseInt(replaceVal)
    );
    setEditInput(newArray);
  };
  
  React.useEffect(() => {
    refetch();
  }, [FromData,ToData,category,productId]);

  // const handleGetQty = () => {
  //   let totalQty = 0;
  //   viewReport?.data?.forEach( (element,index) => {
  //     totalQty += element?.total_Qty
  //   })

  //   return totalQty
  // }

  // // console.log(viewReport?.total_All_Qty)
  // console.log(handleGetQty())

  return (
    <div className="tableRP">
      <Box className="header-table">
        <Grid container spacing={2} className="grid-containner">
          <Grid item xs={4} className="grid-item">
            <Stack direction="column" justifyContent="right" className="logo">
              <Box sx={{ mt: "58px" }}>
                <img src={Logo} width="130px" height="130px" />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={4} className="grid-item2">
            <Stack className="kingdom" direction="column" spacing={1}>
              <Typography className="title2">ព្រះរាជាណាចក្រកម្ពុជា</Typography>
              <Typography className="title2">
                ជាតិ សាសនា ព្រះមហាក្សត្រ
              </Typography>
              <div className="tackteng-font">
                <img
                  alt="Image"
                  src={WingdingImage}
                  className="image-tackteng"
                />
              </div>
            </Stack>
            <Stack direction="row" justifyContent="center" mt={3.5}>
              <Typography className="title3">
                បញ្ជីឈ្មោះទំនិញសម្រាប់ហ្គោគ្លូប៊លម៉ាត
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>


      <TableContainer className="TableContainerPrint">
        <Table sx={{ minWidth: 650 }}>
          <TableHead className="tbhead">
            <TableRow className="item-row">
              <TableCell className="title" width="2%">
                N°
              </TableCell>
              <TableCell className="title" width="20%" align="center">
                Item
              </TableCell>
              <TableCell className="title" align="center" width="10%">
                Unit
              </TableCell>
              <TableCell className="title" align="center" width="20%">
                Quantity
              </TableCell>
              <TableCell className="title" align="left" width="15%">
                &nbsp;Unit Price
              </TableCell>
              <TableCell
                className="title"
                align="center"
                width="20px"
              ></TableCell>
              <TableCell className="title" align="left" width="20%">
                {" "}
                &nbsp; Amount{" "}
              </TableCell>
            </TableRow>

            <TableRow className="tbrow">
                <TableCell className="title"></TableCell>
                <TableCell className="title"> </TableCell>
                <TableCell
                  className="title"
                  align="center"
                  sx={{ fontFamily: "Siemreap", fontWeight: "bold" , color: "blue"}}
                >
                  សរុប
                </TableCell>
                <TableCell
                  className="title"
                  align="center"
                  sx={{ fontFamily: "Century Gothic", fontWeight: "bold" , color: "blue" }}
                >    
                    {(viewReport?.total_All_Qty)?.toFixed(2)}
                </TableCell>
                <TableCell
                  className="title"
                  align="left"
                  sx={{ fontFamily: "Siemreap", fontWeight: "bold" , color: "blue"}}
                > </TableCell>
                <TableCell
                  className="title"
                  align="left"
                  sx={{ fontFamily: "Century Gothic", fontWeight: "bold" }}
                >                  
                </TableCell>
                <TableCell className="title">
                  <Stack
                    direction="row"
                    sx={{
                      fontFamily: "Century Gothic",
                      justifyContent: "left",
                      fontWeight: "bold",
                      color: "blue"
                    }}
                  >
                    $&nbsp;&nbsp;
                    <NumberFormat
                      value={(viewReport?.total_All_Amount)?.toFixed(2)}
                      thousandSeparator={true}
                      displayType={"text"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </Stack>
                </TableCell>
              </TableRow>
              
            {/* <TableRow className="item-row-amout">
              <TableCell className="title" colSpan={3}></TableCell>

              <TableCell className="title">
                <Stack direction="row" justifyContent="center" spacing={2}>
                  {viewReport?.total_All_Qty}
                </Stack>
              </TableCell>

              <TableCell className="title">
                <Stack direction="row" justifyContent="left" spacing={2}>
                  $&nbsp;&nbsp;
                  <NumberFormat
                    value={viewReport?.total_all_unit_Price?.toFixed(2)}
                    thousandSeparator={true}
                    displayType={"text"}
                    renderText={(value, props) => <div {...props}>{value}</div>}
                  />
                </Stack>
              </TableCell>

              <TableCell className="title"></TableCell>
              <TableCell className="title" align="right">
                <Stack direction="row" justifyContent="left" spacing={2}>
                  $&nbsp;&nbsp;
                  <NumberFormat
                    value={viewReport?.total_All_Amount?.toFixed(2)}
                    thousandSeparator={true}
                    displayType={"text"}
                    renderText={(value, props) => <div {...props}>{value}</div>}
                  />
                </Stack>
              </TableCell>
            </TableRow> */}
          </TableHead>

          {viewReport?.data?.map((row, index1) => (
            <TableBody className="bodytable" key={index1}>

              {row?.stock_Detail?.map((item, index) => (

                <TableRow key={index} className="tbrow-body">
                  {index !== 0 ? null : (
                    <>
                      {/* {console.log("item::", item)}
                      {console.log("row::", row)} */}
                      <TableCell
                        className="tbcell"
                        rowSpan={row?.stock_Detail?.length}
                        sx={{ verticalAlign: "middle" }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Century Gothic",
                            fontSize: "14px",
                          }}
                        >
                          {index === 0 ? index1 + 1 : null}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className="tbcell"
                        width="30%"
                        rowSpan={row?.stock_Detail?.length}
                        sx={{ verticalAlign: "middle" }}
                      >
                        {row?.productName+" "+row?.remark}
                      </TableCell>
                    </>
                  )}
                  <TableCell className="tbcell" align="center">
                    {row?.unit}
                  </TableCell>
                  <TableCell
                    className="tbcell"
                    align="center"
                    onClick={handleClickEdit}
                  >
                    {/* <input
                          style={{border: "none",  backgroundColor:"#fafafa"}}
                          value={EditInput}
                          onChange={(e, index) => {
                            setEditInput(e.target.value, index);
                          }}
                      /> */}
                    <Typography sx={{ fontFamily: "Century Gothic", fontSize: "14px" }} >
                      {(item?.qty_Stock)?.toFixed(2)}
                    </Typography>
                  </TableCell>

                  <TableCell className="tbcell" align="center">
                    <Stack
                      direction="row"
                      sx={{
                        fontFamily: "Century Gothic",
                        justifyContent: "left",
                      }}
                    >
                      $&nbsp;&nbsp;
                      <NumberFormat
                        value={item?.unit_Price?.toFixed(2)}
                        thousandSeparator={true}
                        displayType={"text"}
                        renderText={(value, props) => (
                          <div {...props}>{value}</div>
                        )}
                      />
                    </Stack>
                  </TableCell>

                  <TableCell className="tbcell"></TableCell>
                  <TableCell className="tbcell" align="right">
                    <Stack
                      direction="row"
                      sx={{
                        fontFamily: "Century Gothic",
                        justifyContent: "left",
                      }}
                    >
                      $&nbsp;&nbsp;
                      <NumberFormat
                        value={(item?.qty_Stock * item?.unit_Price)?.toFixed(2)}
                        thousandSeparator={true}
                        displayType={"text"}
                        renderText={(value, props) => (
                          <div {...props}>{value}</div>
                        )}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}

              <TableRow key={index1} className="tbrow">
                <TableCell className="title"> </TableCell>
                <TableCell className="title"> </TableCell>
                <TableCell
                  className="title"
                  align="center"
                  sx={{ fontFamily: "Century Gothic", fontWeight: "bold" }}
                ></TableCell>
                <TableCell
                  className="title"
                  align="center"
                  sx={{ fontFamily: "Century Gothic", fontWeight: "bold" }}
                >
                  {row?.total_Qty?.toFixed(2)}
                  {/* {row?.total_Qty} */}
                </TableCell>
                <TableCell
                  className="title"
                  align="left"
                  sx={{ fontFamily: "Century Gothic", fontWeight: "bold" }}
                >                  
                  <NumberFormat
                    value={row?.stock_Detail[0]?.unit_Price?.toFixed(2)}
                    thousandSeparator={true}
                    displayType={"text"}
                    renderText={(value, props) => (
                      <div {...props}>$ {value}</div>
                    )}
                  />
                </TableCell>
                <TableCell
                  className="title"
                  align="left"
                  sx={{ fontFamily: "Century Gothic", fontWeight: "bold" }}
                >  </TableCell>
                <TableCell className="title">
                  <Stack
                    direction="row"
                    sx={{
                      fontFamily: "Century Gothic",
                      justifyContent: "left",
                      fontWeight: "bold",
                    }}
                  >
                    $&nbsp;&nbsp;
                    <NumberFormat
                      value={row?.total_Amount?.toFixed(2)}
                      thousandSeparator={true}
                      displayType={"text"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>

          ))}

          
        </Table>
      </TableContainer>
    </div>
  );
}

export default StockOnHandReportPrint;
