import * as React from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import "./viewProduct.scss";
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Modal,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";

export default function ViewProduct({ handleCloseView, Data }) {
  return (
    <Box className="view-product">
      <Stack direction="row" spacing={5}>
        <Typography className="header-title" variant="h6">
          PRODUCT DETAILS
        </Typography>

        <Box sx={{ flexGrow: 1 }}></Box>
        <IconButton onClick={() => handleCloseView()}>
          <ClearIcon sx={{ color: "red" }} />
        </IconButton>
      </Stack>

      <Divider variant="fullWidth" />

      <Stack direction="row" className="stack_call" spacing={2} sx={{ mt: 2 }}>
        {Data?.image_src === "" ? (
          <Avatar
            variant="rounded"
            src={`${Data?.image_src}`}
            sx={{ width: 200, height: 200 }}
          >
            {Data?.name}
          </Avatar>
        ) : (
          <Avatar
            alt={Data?.name}
            variant="rounded"
            src={`${Data?.image_src}`}
            sx={{ width: 200, height: 200 }}
          />
        )}

        <TableContainer>
          <Table size="small">
            <TableRow width="100%">
              <TableCell className="sub-title">
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography">Name</Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>:
                </Stack>
              </TableCell>

              <TableCell className="text-detail">
                <Typography>{Data?.name}</Typography>
              </TableCell>
            </TableRow>
            {Data?.bar_Code === "" ? null : (
              <TableRow width="100%">
                <TableCell className="sub-title">
                  <Stack direction="row" spacing={1}>
                    <Typography className="text-typography">
                      Bar Code
                    </Typography>
                    <Box sx={{ flexGrow: 1 }}></Box>:
                  </Stack>
                </TableCell>
                <TableCell className="text-detail">{Data?.bar_Code}</TableCell>
              </TableRow>
            )}

            <TableRow width="100%">
              <TableCell className="sub-title">
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography">Unit</Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>:
                </Stack>
              </TableCell>
              <TableCell className="text-detail">
                <Typography>{Data?.unit}</Typography>
              </TableCell>
            </TableRow>

            <TableRow width="100%">
              <TableCell className="sub-title">
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography">Qty in unit</Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>:
                </Stack>
              </TableCell>
              <TableCell className="text-detail">
                <Typography>{Data?.qty_inUnit}</Typography>
              </TableCell>
            </TableRow>

            <TableRow width="100%">
              <TableCell className="sub-title">
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography">Cost</Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>:
                </Stack>
              </TableCell>
              <TableCell className="text-detail">
                <Typography className="text">{Data?.cost}$</Typography>
              </TableCell>
            </TableRow>

            <TableRow width="100%">
              <TableCell className="sub-title">
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography">Category</Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>:
                </Stack>
              </TableCell>
              <TableCell className="text-detail">
                <Typography className="text">{Data?.category}</Typography>
              </TableCell>
            </TableRow>

            <TableRow width="100%">
              <TableCell className="sub-title">
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography">Type</Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>:
                </Stack>
              </TableCell>
              <TableCell className="text-detail">
                <Typography className="text">{Data?.type}</Typography>
              </TableCell>
            </TableRow>

            <TableRow width="100%">
              <TableCell className="sub-title">
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography"> Feature </Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>:
                </Stack>
              </TableCell>
              <TableCell className="text-detail">
                <Typography className="text">{Data?.feature}</Typography>
              </TableCell>
            </TableRow>

            {Data?.remark === "" ? null : (
              <TableRow width="100%">
                <TableCell className="sub-title">
                  <Stack direction="row" spacing={1}>
                    <Typography className="text-typography">Remark</Typography>
                    <Box sx={{ flexGrow: 1 }}></Box>:
                  </Stack>
                </TableCell>
                <TableCell className="text-detail">
                  <Typography>{Data?.remark}</Typography>
                </TableCell>
              </TableRow>
            )}
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
}
