import React, { useState } from "react";
import {
  Box,
  Stack,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import "./modalunitadd.scss";
import * as Yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik, Form, FormikProvider } from "formik";
import { CREATE_UNIT } from "../../Schema/unit";
import { useMutation } from "@apollo/client";

export default function ModalUnitAdd({
  setRefetch,
  handleClose,
  setAlert,
  setMessage,
  setchekMessage,
}) {
  const [loading, setLoading] = useState(false);

  const [createUnit] = useMutation(CREATE_UNIT, {
    onCompleted: ({ createUnit }) => {
      if (createUnit?.status === true) {
        setchekMessage("success");
        setMessage(createUnit?.message);
        setAlert(true);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setchekMessage("error");
        setMessage(createUnit?.message);
        setAlert(true);
        setLoading(false);
      }
    },

    onError: (error) => {
      setchekMessage("error");
      setMessage(error.message);
      setAlert(true);
      setLoading(false);
    },
  });

  const Addunit = Yup.object().shape({
    name: Yup.string().required("required Name"),
  });

  // empty field
  const formik = useFormik({
    initialValues: {
      name: "",
    },

    validationSchema: Addunit,

    onSubmit: (values) => {
      console.log(values, "create unit");
      setLoading(true);
      createUnit({
        variables: {
          input: values,
        },
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box spacing={5} className="unit">
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" className="title">
              CREATE UNIT
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <ClearIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>

          <Grid container spacing={2}>
            <Grid item xs={12} >
              <Typography className="Sub-title"> Name </Typography>
              <TextField
                className="text-field"
                size="small"
                fullWidth
                placeholder="name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>

            <Grid item xs={12} >
              {loading ? (
                <Button className="btn-create" variant="text" fullWidth>
                  Loading...
                </Button>
              ) : (
                <Button
                  className="btn-create"
                  variant="text"
                  fullWidth
                  type="submit"
                >
                  CREATE
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Form>
    </FormikProvider>
  );
}
