import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { GET_SUMMARY_INVENTORY } from "../../../Schema/dashboard";
import { useEffect, useState } from "react";
import moment from "moment";


export default function ChartInventory() {
      
    // ============ get data report =================
    const [reportData,setReportData] = useState([]);
    const [dataStockIn,setDataStockIn] = useState([]);
    const [dataStockOut,setDataStockOut] = useState([]);
    const [dataOnHand,setDataOnHand] = useState();

    
    const { data , refetch } = useQuery(GET_SUMMARY_INVENTORY,{
        onCompleted: ({productSummaryQtyBarChart}) => {
            // console.log(productSummaryQtyBarChart)
            if(productSummaryQtyBarChart?.length !== 0) {
                let rowIn = [];
                let rowOut = [];
                let rowHand = [];

                productSummaryQtyBarChart.forEach( (row,index) => {
                    rowIn.push(parseFloat((row?.inStock)?.toFixed(2)));
                    rowOut.push(parseFloat((row?.outStock)?.toFixed(2)));
                    rowHand.push(parseFloat((row?.onHand)?.toFixed(2)));
                })

                setDataStockIn(rowIn)
                setDataStockOut(rowOut)
                setDataOnHand(rowHand)
            }
        },
        onError: (error) => {
            console.log(error.message)
        },
        pollInterval: 1000,
    })

    useEffect( () => {
        refetch();
    },[])

    const state = {          
        series: [
            {
                name: 'In Stock',
                type: 'column',
                data: dataStockIn                
            }, 
            {
                name: 'Out Stock',
                type: 'column',
                data: dataStockOut
            }, 
            {
                name: 'On Hand',
                type: 'line',
                data: dataOnHand
            }
        ],
        options: {
            chart: {         
                height: 350,
                type: 'line',
                stacked: false
            },
            markers: {
                size: [6, 9],
                colors: undefined,
                strokeColors: '#fff',
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                showNullDataPoints: true,
                hover: {
                  size: undefined,
                  sizeOffset: 3
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [1, 1, 4]
            },
            title: {
                text: `In Stock, Out Stock and On Hand Analysis (${moment().format("YYYY")})`,
                align: 'left',
                offsetX: 10,  
                offsetY: 10,           
                style: {
                    fontSize:  '16px',
                    fontWeight:  'bold',
                    fontFamily:  undefined,
                    color:  '#0f81c2'
                },
            },
            xaxis: {
                categories: ["JAN", "FEB" , "MAR" , "APR" , "MAY" , "Jun", "Jul", "AUG", "SEP", "OCT", "NOV", "DEC"],
            },
            yaxis: [
                {
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#008FFB'
                    },
                    labels: {
                        style: {
                        colors: '#008FFB',
                        }
                    },
                    title: {
                        text: "In Stock (thousand crores)",
                        style: {
                        color: '#008FFB',
                        }
                    },
                    tooltip: {
                        enabled: true
                    }
                },
                {
                    seriesName: 'In Stock',
                    opposite: true,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#00E396'
                    },
                    labels: {
                        style: {
                        colors: '#00E396',
                        }
                    },
                    title: {
                        text: "Out Stock (thousand crores)",
                        style: {
                            color: '#00E396',
                        }
                    },
                },
                {
                    seriesName: 'Out Stock',
                    opposite: true,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#FEB019'
                    },
                    labels: {
                        style: {
                        colors: '#FEB019',
                        },
                    },
                    title: {
                        text: "On Hand (thousand crores)",
                        style: {
                            color: '#FEB019',
                        }
                    }
                },
            ],
            tooltip: {
                fixed: {
                    enabled: true,
                    position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                    offsetY: 30,
                    offsetX: 60
                },
            },
            legend: {
                horizontalAlign: 'left',
                offsetX: 40
            }
        },     
    };
    
    return(
        <Box width="100%" padding="15px">
            <div id="chart">
                <ReactApexChart options={state.options} series={state.series} type="line" height={400}/>
            </div>
        </Box>
    )
}