import React from "react";
import "./deletesupplies.scss";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useMutation } from "@apollo/client";
import { DELETE_SUPPLIES } from "../../Schema/supplies";

function DeleteSupplies({
  row,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
  handleCloseDel,
}) {
  const [valueVoid, setValueVoid] = React.useState("");
  const [deleteSupplier] = useMutation(DELETE_SUPPLIES, {
    variables: {
      supplierId: row?._id,
    },

    onCompleted: ({ deleteSupplier }) => {
      if (deleteSupplier?.status) {
        setchekMessage("success");
        setMessage(deleteSupplier?.message);
        setAlert(true);
        setRefetch();
        handleCloseDel();
      } else {
        setchekMessage("error");
        setMessage(deleteSupplier?.message);
        setAlert(true);
      }
    },

    onError: (error) => {
      setchekMessage("error");
      setMessage(error.message);
      setAlert(true);
    },
  });

  const handleDelete = () => {
    deleteSupplier({
      variables: {
        supplerId: row?._id,
      },
    });
  };

  return (
    <Box className="delete-supplies">
      <Stack direction="row" spacing={5}>
        <Typography className="title" variant="h6">
          DELETE SUPPLIER
        </Typography>

        <Box sx={{ flexGrow: 1 }}></Box>

        <IconButton onClick={handleCloseDel}>
          <ClearIcon sx={{ color: "red" }} />
        </IconButton>
      </Stack>

      <Stack direction="row" spacing={5} width="100%">
        <Typography variant="subtitle1" className="body-sub">
          Do you want delete the Supplies?
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mt: 2 }}
      >
        <Typography variant="subtitle1" className="body-sub">
          Please input
        </Typography>

        <Typography variant="subtitle1" className="text">
          SUPPLIER
        </Typography>

        <Typography variant="subtitle1" className="body-sub">
          to delete
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mb: 4 }}
      >
        <TextField
          className="text-input"
          size="small"
          fullWidth
          onChange={(e) => setValueVoid(e.target.value)}
        />
      </Stack>

      <Stack direction="row" spacing={5}>
        {valueVoid === "SUPPLIER" ? (
          <Button
            className="btn-void"
            onClick={handleDelete}
            sx={{ ":hover": { backgroundColor: "red", border: "none" }}}
            variant="outlined"
            fullWidth
          >
            Delete Now
          </Button>
        ) : (
          <Button className="delete" variant="outlined" fullWidth>
            Delete
          </Button>
        )}
      </Stack>
    </Box>
  );
}

export default DeleteSupplies;
