import "./unit.scss";
import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Table,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  Modal,
  Paper,
} from "@mui/material";
//icon from material
import Profile from "../Component/Menu/Profile";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
//component
import AlertMessage from "../Component/AlertMessage/AlertMessage";
import ModalUnitAdd from "../Component/Unit/ModalUnitAdd";
import UnitAction from "../Component/Unit/UnitAction";
import { useQuery } from "@apollo/client";
import { GET_UNIT } from "../Schema/unit";

export default function Unit() {
  //handle open and close
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // AlertMessage
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [checkMessage, setchekMessage] = useState("");
  const { data, refetch } = useQuery(GET_UNIT);
  const [rowData, setRowData] = useState([]);

  //click setlocalStorage
  const handleClick = (e) => {
    localStorage.setItem("unitId", JSON.stringify(e?._id));
  };

  //get value from localstorage
  const unitID = JSON.parse(localStorage.getItem("unitId"));

  return (
    <div className="unit_page">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="page-title">UNIT</Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }}></Box>

        <Profile />
      </Stack>

      <Stack direction="row" spacing={2}>
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          className="stack-btn"
          justifyContent="right"
          spacing={1}
        >
          <Button
            onClick={handleOpen}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            className="btn-add"
          >
            <Typography className="btn-text"> Create</Typography>
          </Button>

          <Modal open={open}>
            <ModalUnitAdd
              setRefetch={refetch}
              handleClose={handleClose}
              setAlert={setAlert}
              setMessage={setMessage}
              setchekMessage={setchekMessage}
            />
          </Modal>
        </Stack>
      </Stack>

      <Box className="container">
        <TableContainer>
          <Table className="table" aria-label="simple table">
            <TableHead>
              <TableRow className="header-row">
                <TableCell className="header-title">N°</TableCell>
                <TableCell className="header-title" colSpan={2}>
                  Name
                </TableCell>
              </TableRow>
            </TableHead>

            {data?.getUnit?.map((item, index) => (
              <TableBody
                key={item}
                component={Paper}
                className={unitID === item?._id ? "body" : "body-odd"}
              >
                <TableRow className="body-row" onClick={() => handleClick(item)}>
                  <TableCell className="body-title">
                    <Typography sx={{ fontFamily: "Century Gothic" }}>
                      {index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell className="body-title" onClick={() => setRowData(item)}>
                    {item?.name}
                  </TableCell>
                  <TableCell className="body-title" align="right" onClick={() => setRowData(item)}>
                    <UnitAction
                      rowData={item}
                      setRefetch={refetch}
                      setAlert={setAlert}
                      setMessage={setMessage}
                      setchekMessage={setchekMessage}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Box>

      <AlertMessage
        alert={alert}
        setAlert={setAlert}
        message={message}
        setMessage={setMessage}
        checkMessage={checkMessage}
      />
    </div>
  );
}
