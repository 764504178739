import React, { useEffect } from "react";
import "./reporttable.scss";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
//logo
import Logo from "../../Assets/GoGlobalFull_Name.png";
import WingdingImage from "../../Assets/tacteing.jpg";
import NumberFormat from "react-number-format";
import { GET_SUMMARY_STOCK } from "../../Schema/report";
import { useQuery } from "@apollo/client";
import { GET_EXPIRE_PRODUCTS } from "../../Schema/report";
import { useState } from "react";
import moment from "moment";

export default function ProductExpire({ fieldValue, productId }) {
  const [productExData, setProductExData] = useState({});

  const { data, refetch } = useQuery(GET_EXPIRE_PRODUCTS, {
    variables: {
      page: 1,
      limit: 1000,
      productId: productId !== undefined && productId !== "" ? productId : "",
      status: fieldValue,
    },
    onCompleted: ({ getExpireProducts }) => {
      setProductExData(getExpireProducts);
    },
    onError: (error) => {
      console.log(error?.message);
    },
    pollInterval: 10000
  });

  React.useEffect(() => {
    refetch();
  }, []);
  
  return (
    <>
      <div className="tableRP">
        <Box className="header-table">
          <Grid container spacing={2} className="grid-containner">
            <Grid item xs={4} className="grid-item">
              <Stack direction="column" justifyContent="right" className="logo">
                <Box sx={{ mt: "58px" }}>
                  <img src={Logo} width="130px" height="130px" />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={4} className="grid-item2">
              <Stack className="kingdom" direction="column" spacing={1}>
                <Typography className="title2">
                  ព្រះរាជាណាចក្រកម្ពុជា
                </Typography>
                <Typography className="title2">
                  ជាតិ សាសនា ព្រះមហាក្សត្រ
                </Typography>
                <div className="tackteng-font">
                  <img
                    alt="Image"
                    src={WingdingImage}
                    className="image-tackteng"
                  />
                </div>
              </Stack>
              <Stack direction="row" justifyContent="center" mt={3.5}>
                <Typography className="title3">
                  បញ្ជីឈ្មោះទំនិញផុតកំណត់សម្រាប់ហ្គោគ្លូប៊លម៉ាត
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <TableContainer className="TableContainer">
          <Table sx={{ minWidth: 650 }}>
            <TableHead className="tbhead">
              <TableRow className="item-row">
                <TableCell className="title" width="2%">
                  N°
                </TableCell>
                <TableCell className="title" width="20%" align="left">
                  Items
                </TableCell>

                <TableCell className="title" align="left" width="20%">
                  Unit
                </TableCell>
                {/* <TableCell className="title" align="left" width="15%">
                  UnitPrice
                </TableCell> */}
                <TableCell className="title" align="left" width="15%">
                  Instock
                </TableCell>
                <TableCell className="title" align="left" width="15%">
                  Expire
                </TableCell>
              </TableRow>
            </TableHead>

            {productExData?.data?.map((row, index) => (
              <TableBody
                key={index}
                className={index % 2 ? "bodytable-odd" : "bodytable"}
              >
                <>
                  <TableRow className="tbrow">
                    <TableCell className="tbcell">{index + 1}</TableCell>

                    <TableCell className="tbcell" width="30%">
                      {row?.product_Id?.name}
                    </TableCell>
                    <TableCell className="tbcell">
                      {row?.product_Id?.unit}
                    </TableCell>
                    {/* <TableCell className="tbcell" align="right">
                      <Stack direction="row">
                        $&nbsp;&nbsp;&nbsp;
                        <NumberFormat
                          value={row?.product_Id?.cost?.toFixed(2)}
                          thousandSeparator={true}
                          displayType={"text"}
                          renderText={(value, props) => (
                            <div {...props}>{value}</div>
                          )}
                        />
                      </Stack>
                    </TableCell> */}

                    <TableCell className="tbcell" align="left">
                      <Typography
                        sx={{ fontFamily: "Century Gothic", fontSize: "14px" }}
                      >
                        {moment(`${row?.instock_At}`).format("DD-MMM-YYYY")}
                      </Typography>
                    </TableCell>

                    <TableCell className="tbcell" align="left">
                      <Typography
                        sx={{ fontFamily: "Century Gothic", fontSize: "14px" }}
                      >
                        {moment(`${row?.expire_At}`).format("DD-MMM-YYYY")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
