import {
  Box,
  Button,
  Modal,
  Stack,
  Paper,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  Typography,
} from "@mui/material";
import "./user.scss";

import React, { useState } from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

//component
import Profile from "../Component/Menu/Profile";
import ViewUser from "../Component/User/ViewUser";
import UserAction from "../Component/User/UserAction";
import ModalUserAdd from "../Component/User/ModalUserAdd";
import AlertMessage from "../Component/AlertMessage/AlertMessage";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
import { GET_ALL_USER } from "../Schema/user";
import { useQuery } from "@apollo/client";

export default function User() {
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //view
  const [openView, setOpenView] = useState(false);
  const handleOpenView = () => setOpenView(true);
  const handleCloseView = () => setOpenView(false);

  // AlertMessage
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [checkMessage, setchekMessage] = useState("");
  const [RowData, setRowData] = useState([]);

  // console.log('RowData',RowData)
  const { data, refetch } = useQuery(GET_ALL_USER, {
    variables: {
      keyword: "",
    },
    onCompleted: () => {
      setLoading(false);
    },
    onError: (erre) => {
      setLoading(true);
      console.log(erre?.message);
    },
  });

  //click setlocalStorage
  const handleClick = (e) => {
    localStorage.setItem("userId", JSON.stringify(e?._id));
    return null;
  };

  //get value from localstorage
  const userId = JSON.parse(localStorage.getItem("userId"));

  return (
    <div className="user-page">
      <Box className="contanner-header">
        <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
          <Stack direction="column" justifyContent="center">
            <IconMenuResponsive />
          </Stack>

          <Stack direction="column" justifyContent="center">
            <Box className="slash" />
          </Stack>

          <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
            <Typography className="color"> User </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }}></Box>
          <Profile />
        </Stack>

        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          className="stack-btn"
          justifyContent="right"
          spacing={1}
        >
          <Button
            onClick={handleOpen}
            className="btn-add"
            startIcon={<AddCircleOutlineOutlinedIcon className="icon-add" />}
          >
            <Typography className="btn-text">Create</Typography>
          </Button>

          <Modal open={open}>
            <ModalUserAdd
              refetch={refetch}
              handleClose={handleClose}
              setAlert={setAlert}
              setMessage={setMessage}
              setchekMessage={setchekMessage}
            />
          </Modal>
        </Stack>
      </Box>
      <Box className="container">
        <TableContainer>
          <Table className="table" aria-label="simple table">
            <TableHead>
              <TableRow className="header-row">
                <TableCell className="header-title" width="3%">
                  N°
                </TableCell>
                <TableCell className="header-title" sx={{ mr: 5 }}>
                  Name{" "}
                </TableCell>
                <TableCell className="header-title">Position</TableCell>
                <TableCell className="header-title">Email</TableCell>
                <TableCell className="header-title"></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <>
                <TableHead>
                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={5}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={40}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={5}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={40}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={5}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={40}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
              </>
            ) : (
              <>
                {data?.getAllUser?.map((row, index) => (
                  <TableBody
                    key={index}
                    component={Paper}
                    className={userId === row?._id ? "body" : "body-odd"}
                  >
                    <TableRow
                      className="body-row"
                      onClick={() => handleClick(row)}
                    >
                      <TableCell className="body-title" width="3%">
                        {index + 1}
                      </TableCell>

                      <TableCell
                        onClick={() => {
                          handleOpenView(row);
                          setRowData(row);
                        }}
                        sx={{ cursor: "pointer" }}
                        className="body-title"
                        component="th"
                        scope="row"
                      >
                        {row?.image_src === "" ? (
                          <Stack
                            direction="row"
                            spacing={0}
                            sx={{ justifyContent: "left" }}
                          >
                            <Avatar
                              className="avatar"
                              alt={row?.firsName + " " + row?.lastName}
                              src={`${row?.image_Src}`}
                            />

                            <Typography className="product-name" ml={2}>
                              {row?.firsName} {row?.lastName}
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack
                            direction="row"
                            spacing={0}
                            sx={{ justifyContent: "left" }}
                          >
                            <Avatar
                              className="avatar"
                              alt={row?.user_name}
                              src={`${row?.image_src}`}
                            />
                            <Typography className="product-name" ml={2}>
                              {row?.firsName} {row?.lastName}
                            </Typography>
                          </Stack>
                        )}

                        {/* {row?.image_src === "" ? (
                        <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            
                          >
                          <Avatar
                            alt={row?.firsName +" "+ row?.lastName}
                            src={`${row?.image_Src}`}
                            className="avatar"
                          />
                       
                          <Typography className="product-name" ml={2}>{row?.firsName} {row?.lastName}</Typography>
                        </Box>
                      ) : ( 
                        <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Avatar className="avatar" alt={row?.user_name} src={`${row?.image_src}`} />
                            <Typography className="product-name" ml={2}>{row?.firsName +" "+ row?.lastName}</Typography>
                      
                        </Box>
                      )}  */}
                      </TableCell>

                      <TableCell
                        onClick={() => {
                          handleOpenView(row);
                          setRowData(row);
                        }}
                        sx={{ cursor: "pointer" }}
                        className="body-title"
                      >
                        {row?.role}
                      </TableCell>

                      <TableCell
                        onClick={() => {
                          handleOpenView(row);
                          setRowData(row);
                        }}
                        sx={{ cursor: "pointer" }}
                        className="body-english"
                      >
                        {row.email}
                      </TableCell>

                      <TableCell
                        className="body-title"
                        onClick={() => {
                          setRowData(row);
                        }}
                        align="right"
                      >
                        <UserAction
                          RowData={row}
                          setRefetch={refetch}
                          setAlert={setAlert}
                          setMessage={setMessage}
                          setchekMessage={setchekMessage}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </>
            )}
          </Table>
        </TableContainer>
      </Box>

      <Modal open={openView}>
        <ViewUser handleCloseView={handleCloseView} RowData={RowData} />
      </Modal>

      <AlertMessage
        alert={alert}
        setAlert={setAlert}
        message={message}
        setMessage={setMessage}
        checkMessage={checkMessage}
      />
    </div>
  );
}
