import { gql } from "@apollo/client";

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CustomerInput!) {
    createCustomer(input: $input) {
      status
      message
    }
  }
`;

export const GET_ALL_CUSTOMERS = gql`
  query Query($keyword: String!) {
    getAllCustomer(keyword: $keyword) {
      _id
      name
      email
      phone
      address
      remark
      created_At
    }
  }
`;

export const GET_CUSTOMERS_BY_ID = gql`
  query GetCustomerbyId($customerId: String!) {
    getCustomerbyId(customer_Id: $customerId) {
      status
      message
      data {
        _id
        name
        tel
        email
        address
        remark
        created_At
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($input: CustomerInput, $customerId: ID) {
    updateCustomer(input: $input, customerId: $customerId) {
      status
      message
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation Mutation($customerId: ID) {
    deleteCustomer(customerId: $customerId) {
      status
      message
    }
  }
`;

export const CREATE_SHOP = gql`
  mutation CreateShop($input: ShopInput!) {
    createShop(input: $input) {
      status
      message
    }
  }
`;

export const UPDATE_SHOP = gql`
  mutation UpdateShop($shopId: ID, $input: ShopInput) {
    updateShop(shopId: $shopId, input: $input) {
      status
      message
    }
  }
`;

export const DELETE_SHOP = gql`
  mutation DeleteShop($shopId: ID) {
    deleteShop(shopId: $shopId) {
      status
      message
    }
  }
`;


export const GET_ALL_SHOPS = gql`
  query GetAllShops($keyword: String!) {
    getAllShops(keyword: $keyword) {
      _id
      name
      name_kh
      email
      phone
      address
      vat
      remark
      created_At
    }
  }
`;
