import React, {useState} from "react";
import "./purchasepending.scss";
import {
  Stack,
  Box,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  Typography,
  Modal,
  Divider,
  Button,
  Skeleton,
  IconButton
} from "@mui/material";

// icon priority
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

//for table
import ViewPurchase from "../../StorageRoom/ViewPurchase";
import { GET_PURCHASE_PAGINATION } from "../../../Schema/purchase";
import { useQuery } from "@apollo/client";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import moment from "moment";



function PurchaseProductPending() {
  const [purchase,setPurchase] = React.useState([]);
  //handleview
  const [openViewPurchase, setOpenViewPurchase] = React.useState(false);
  const handleOpenViewPurchase = () => setOpenViewPurchase(true);
  const handleCloseViewPurchase = () => setOpenViewPurchase(false);
  //Alert message
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [checkMessage, setCheckMessage] = useState("");
  const [DataCell, setDataCell] = useState([])
  const [loading, setLoading] = useState(true);
  
  //check box
  const [checked, setChecked] = React.useState(true);
  
    const { data , refetch } = useQuery(GET_PURCHASE_PAGINATION, {
      variables: {
        page: 1,
        limit: 15,
        keyword: "",
        storageRoomId: "",
        approveStatus: "pending",
        priority: "",
      },

      
      onCompleted: ({getPurchasingPaginatioin}) => {
          let rows = [];          
          getPurchasingPaginatioin?.data?.forEach( element => {
              if(element?.status === false ) {
                  rows.push(element);
              }
          })
          setPurchase(rows);
          setLoading(false)
      },
      onError: () => {
        setLoading(true)
      }
    });

    const handleChange = (event) => {
      setChecked(event.target.checked);
    };
  

  

  if(loading) {
    return(
      <Box className="datap-style" sx={{ width: "100%", padding: "20px" }} >           
        <Stack direction="column" justifyContent="center" height="45px" spacing={3}>             
              <Skeleton height={20} width="40%" variant="rectangular" />                 
        </Stack>
        <Box sx={{width:"100%" , marginTop: "10px" , marginBottom: "10px"}}>
          <Divider variant="middle" sx={{margin: 0}}/>
        </Box>

      <TableContainer className="tbcontainner" >
        <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
          <TableHead className="tbheader">
            <TableRow className="tbrow">
              <TableCell className="tbcell" width="8%">
                  N°
              </TableCell>
              <TableCell className="tbcell"  align="center">
                  Purchase by
              </TableCell>
              <TableCell className="tbcell" align="center">
                  Need Date
              </TableCell>
              <TableCell className="tbcell" align="center">
                  Priority
              </TableCell>
              <TableCell className="tbcell" align="center">

              </TableCell>              
            </TableRow>
          </TableHead>

          <TableBody className="tbbody">
              <TableCell className="tbcell" colSpan={5}>
                <Skeleton sx={{ height: "220px", width:"100%" }} animation="wave" variant="rectangular" />
              </TableCell>
          </TableBody>
        
        </Table>
      </TableContainer>
      
      <Box sx={{ flexGrow: 1 }}></Box>
    </Box>
    )
  }

  return (
    <Box className="datap-style" sx={{ width: "100%", padding: "20px" }} >
      <Stack direction="row" justifyContent="center" spacing={3}>   
          <Stack direction="column" justifyContent="center" height="45px">          
              <Typography className="title">Purchase Pending</Typography>           
          </Stack>
          <Box sx={{ flexGrow: 1 }} />          
      </Stack>

      <Box sx={{width:"100%" , marginTop: "10px" , marginBottom: "10px"}}>
        <Divider variant="middle" sx={{margin: 0}}/>
      </Box>

      <TableContainer className="tbcontainner">
        <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
          <TableHead className="tbheader">
            <TableRow className="tbrow">
              <TableCell className="tbcell" width="12%">
                 N°
              </TableCell>
              <TableCell className="tbcell"  align="left" width="30%">
                Purchase by
              </TableCell>
              <TableCell className="tbcell" align="left" width="30%">
                Need Date
              </TableCell>
              <TableCell className="tbcell" align="center" width="20%">
                Priority
              </TableCell>
              <TableCell className="tbcell" align="center">

              </TableCell>              
            </TableRow>
          </TableHead>
              
        {purchase?.length !== 0 ?

      <>  
        { purchase?.slice(0,5)?.map( (item,index) => (

          item?.items?.map((row) =>(

            <TableBody key={index} className="tbbody">
              
            { row?.product_Id === null ? null 
                :
                  <>
                      <TableCell onClick={()=>{setDataCell(item)}} className="tbcell" >
                          {item?.numbering}
                      </TableCell>
                      
                      <TableCell onClick={()=>{setDataCell(item)}} className="tbcell"  >
                          {item?.purchase_By?.firsName+" "+item?.purchase_By?.lastName}
                      </TableCell>

                      
                      <TableCell onClick={()=>{setDataCell(item)}} className="tbcell" >
                        {moment(item?.need_Date).format('DD-MM-YYYY')}
                      </TableCell>

                      <TableCell onClick={()=>{setDataCell(item)}} className="tbcell" >

                          {item?.priority === "Meduim" ?(

                              <Stack direction="row" sx={{justifyContent:"center"}} spacing={1}>
                                <FiberManualRecordIcon sx={{color: '#2AEA00', width: "15px"}} /> 
                                <Typography className="body-text">{item?.priority}</Typography>
                              </Stack>

                              )                  
                                                                                        
                              :item?.priority === "Urgent" ?

                              (
                                                
                              <Stack direction="row" sx={{justifyContent:"center"}} spacing={1} >
                                <PriorityHighIcon sx={{color: 'red', width: "15px"}} />
                                <Typography className="body-text">{item?.priority}</Typography>
                              </Stack>

                              )

                              :
                              (
                              <Stack direction="row" sx={{justifyContent:"center"}} spacing={1} >
                                  <NotificationsActiveIcon sx={{color: 'red', width: "15px"}} />
                                  <Typography className="body-text">{item?.priority}</Typography>
                              </Stack>
                              )
                            
                          }   
                      
                        </TableCell>
                            
                      <TableCell onClick={()=>{ handleOpenViewPurchase(); setDataCell(item)}} className="tbcell" align="right">
                          <IconButton  
                              checked={checked}
                              onChange={handleChange}
                              inputProps={{ 'aria-label': 'controlled' }}>
                              <CheckBoxOutlineBlankIcon className="check-icon"/>
                          </IconButton>
                      </TableCell>  

                  </>
              }
              </TableBody>
            ))

          ))}
          </>

          : <>
              <TableRow>                
                    <TableCell colSpan={5} className="listitem" >      
                        <RemoveShoppingCartIcon />                  
                        <Typography className="sub-title">
                          Product Empty
                        </Typography>
                    </TableCell>               
              </TableRow>           
          </>
        
      }  

        </Table>
      </TableContainer>
      
      <Modal open={openViewPurchase}>
          <ViewPurchase 
              handleClose={handleCloseViewPurchase}
              DataCell={DataCell}
              checkButton={"create"}
              setRefetch={refetch} 
              setAlert={setAlert}
              setMessage={setMessage}
              setCheckMessage={setCheckMessage}
            />
        </Modal>


      <Box sx={{ flexGrow: 1 }}></Box>
    </Box>
  );
}
export default PurchaseProductPending;




