import React, { useState, useEffect } from "react";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Autocomplete,
  Paper,
} from "@mui/material";
import "./listpurchase.scss";
import { useQuery } from "@apollo/client";
import { GET_ALL_PRODUCT } from "../../Schema/product";

import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

export default function ListPurchase(props) {
  
  const [allProduct, setAllProduct] = useState([]);
  const { data: dataAllProduct } = useQuery(GET_ALL_PRODUCT, {
    onCompleted: ({ getAllProduct }) => {
      // console.log(getAllProduct)
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (dataAllProduct?.getAllProduct) {
      let rows = [];
      dataAllProduct?.getAllProduct?.forEach((element) => {
        const allrow = {
          // label: element?.name+" "+element?.remark,
          label: element?.name,
          id: element?._id,
          unitPrice: element?.cost,
        };
        rows.push(allrow);
      });
      setAllProduct(rows);
    }
  }, [dataAllProduct?.getAllProduct]);

  // Handle Message Error TextField
  const [errorMessage, setErrorMessage] = useState([
    "Can't input 0",
    "Invalid Value",
    "Field is required!",
    "Duplicate item!",
  ]);

  const [touchedProduct, setTouchedProduct] = useState(false);
  const handleTouchProduct = () => setTouchedProduct(true);

  const [touchedQty, setTouchedQty] = useState(false);
  const handleTouchQty = () => setTouchedQty(true);

  const [touchedUnitPrice, setTouchedUnitPrice] = useState(false);
  const handleTouchUnitPrice = () => setTouchedUnitPrice(true);
  const items = props.items;

  const listItems = items.map((item) => {
    return (
      <TableBody
        key={item?.key}
        component={Paper}
        className="body-list-product"
      >
        <TableRow className="body-row" width="100%">
          <TableCell
            className="body-title"
            component="th"
            scope="row"            
          >
            <Stack direction="column" spacing={1} className="btn-filter">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                className="input-root"
                options={allProduct}
                sx={{ width: "auto" }}
                onChange={(e, value) => {
                  props.setCreateProduct(value?.id, value?.label , item.key, e);
                  props.setCreateUnit_Price(value?.unitPrice, item?.key);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={handleTouchProduct}
                    error={
                      (touchedProduct && item?.product_Id === undefined) ||
                      (props.checkFieldRequired &&
                        item?.product_Id === "Choose") ||
                      (props.checkFieldRequired && item?.product_Id === "") ||
                      props.duplicate
                    }
                    helperText={
                      (touchedProduct &&
                        item?.product_Id === undefined &&
                        errorMessage[2]) ||
                      (props.checkFieldRequired &&
                        item?.product_Id === "" &&
                        errorMessage[2]) ||
                      (props.checkFieldRequired &&
                        item?.product_Id === "Choose" &&
                        errorMessage[2]) ||
                      (props.duplicate && errorMessage[3])
                    }
                  />
                )}
              />
            </Stack>
          </TableCell>

          <TableCell className="body-title">
            <TextField
              fullWidth
              type="number"
              className="text-field"
              id={item.key}
              size="small"
              value={item.qty}
              onChange={(e) => props.setCreateQty(e.target.value, item.key)}
              onFocus={handleTouchQty}
              error={
                (touchedQty && item?.qty < 0) ||
                (touchedQty && item?.qty === 0) ||
                (props.checkFieldRequired && item?.qty === 0) ||
                (touchedQty && isNaN(item?.qty))
              }
              helperText={
                (touchedQty && item?.qty < 0 && errorMessage[1]) ||
                (touchedQty && item?.qty === 0 && errorMessage[0]) ||
                (props.checkFieldRequired &&
                  item?.qty === 0 &&
                  errorMessage[0]) ||
                (touchedQty && isNaN(item?.qty) && errorMessage[2])
              }
            />
          </TableCell>

          <TableCell className="body-title">
            <TextField
              fullWidth
              type="number"
              className="text-field"
              id={item.key}
              size="small"
              value={item.unit_Price}
              onChange={(e) => {
                props.setCreateUnit_Price(e.target.value, item.key);
              }}
              onFocus={handleTouchUnitPrice}
              error={
                (touchedUnitPrice && item?.unit_Price < 0) ||
                (props.checkFieldRequired && item?.unit_Price < 0) ||
                (touchedUnitPrice && isNaN(item?.unit_Price))
              }
              helperText={
                (touchedUnitPrice && item?.unit_Price < 0 && errorMessage[1]) ||
                (touchedQty && item?.unit_Price < 0 && errorMessage[0]) ||
                (props.checkFieldRequired &&
                  item?.unit_Price < 0 &&
                  errorMessage[0]) ||
                (touchedUnitPrice && isNaN(item?.unit_Price) && errorMessage[2])
              }
            />
          </TableCell>

          <TableCell className="body-title">
            <LocalizationProvider
                className="date-controll"
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  value={item?.expire_At}
                  onChange={(e) => props.setUpdateExpire_At(e, item.key)}
                  renderInput={(params) => (
                    <TextField
                      className="selection"
                      size="small"
                      {...params}
                      type="date"
                      fullWidth
                    />
                  )}
                />
            </LocalizationProvider>
          </TableCell>

          <TableCell className="body-title" align="right">
            <IconButton
              onClick={() => {
                props.deleteItem(item.key);
              }}
            >
              <DeleteRoundedIcon sx={{ color: "red" }} />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  });
  // return <FlipMove duration={300} easing="ease-in-out">
  //     {listItems}
  // </FlipMove>;

  return listItems;
}
