import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Button,
  Stack,
  Typography,
  Modal,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  Pagination,
  Tab,
} from "@mui/material";
import "./shop.scss";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
//component
import AlertMessage from "../Component/AlertMessage/AlertMessage";
import CreateShop from "../Component/Shops/CreateShop";
import ShopAction from "../Component/Shops/ShopAction";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
import Profile from "../Component/Menu/Profile";
import { GET_ALL_SHOPS } from "../Schema/shops";
import { useQuery } from "@apollo/client";
import CreatePurchase from "../Component/Transfer/CreatePurchase";
import TransferOut from "../Component/Transfer/TransferOut";
import { GET_HISTORY_ADJUST_STOCK, GET_PRODUCT_IN_OLD_MONTH } from "../Schema/transfer";
import TransferAction from "../Component/Transfer/TransferAction"
import moment from "moment";
import { TabContext, TabList, TabPanel } from "@mui/lab";


export default function Transfer() {
  
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openOut, setOpenOut] = useState(false);
  const handleOpenOut = () => setOpenOut(true);
  const handleCloseOut = () => setOpenOut(false);

  const [valueTab,setValueTab] = useState("1");
  const handleChangeTab = (event, newValue) => {
    console.log(newValue)
    setValueTab(newValue);
  };
  
  const [oldInstockProduct,setOldInstockProduct] = useState([]);

  //=================== AlertMessage ========================
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [checkMessage, setchekMessage] = useState("");
  const [loading, setLoading] = useState(true);


  const [Data, setRowData] = useState(null);


  //======================= Instock Old Product ==================== 
  const [keyword, setKeyword] = React.useState("");  
  const [page,setPage] = useState(1);
  const [limit,setLimit] = useState(8);

  const { data, refetch } = useQuery(GET_PRODUCT_IN_OLD_MONTH, {
    variables: {      
      page: page,
      limit: limit,
      keyword: keyword,
      storageRoomId: null,
    },
    onCompleted: ({getOldProductInStockPaginatioin}) => {
      setLoading(false);
      // console.log(getOldProductInStockPaginatioin)
      if(getOldProductInStockPaginatioin?.data) {
        setOldInstockProduct(getOldProductInStockPaginatioin?.data);
      } else {
        setOldInstockProduct([])
      }
    },
    onError: () => {
      setLoading(true);
    },
  });

  const [historyAdjustStockAt,setHistoryAdjustStockAt] = useState([]);
  
  const { data: dataHistoryAdjust , refetch: refetchHistoryAdjust } = useQuery(GET_HISTORY_ADJUST_STOCK,{
      variables: {
        page: 1,
        limit: 10,
        keyword: "",
        pagination: true,
        fromDate: null,
        toDate: null,
        productId: null
      },
      onCompleted: ({getAdjustProductInStockHistoryPagination}) => {
        console.log("getAdjustProductInStockHistoryPagination",getAdjustProductInStockHistoryPagination)
        if(getAdjustProductInStockHistoryPagination) {
            setHistoryAdjustStockAt(getAdjustProductInStockHistoryPagination?.data);
        } else {
            setHistoryAdjustStockAt([]);
        }
        
      },
      onError: (error) => {
        console.log(error.message)
      }
  })
  
  
  useEffect( () => {
    refetch();
  },page,keyword)





  return (
    <Box className="customer-page">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="page-title">Transfer</Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Profile />
      </Stack>

      <Stack direction="row" spacing={2}>
        <Stack direction="column" spacing={2} className="btn-search">
          <Box className="btn-text-field">
            <TextField
              className="text-field"
              fullWidth
              placeholder="Search Name"
              size="small"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="column"
          className="stack-btn"
          justifyContent="right"
          spacing={2}
        >
          <Button
            onClick={handleOpen}
            // startIcon={<AddCircleOutlineOutlinedIcon className="icon-add" />}
            className="btn-add"
          >
            <Typography className="btn-text">Transfer In</Typography>
          </Button>
          <Modal open={open}>
            <CreatePurchase
              handleClose={handleClose}
              setRefetch={refetch}              
              checkButton={"create"}
              setAlert={setAlert}
              setMessage={setMessage}
              setCheckMessage={setchekMessage}
            />
          </Modal>
        </Stack>


        <Stack
          direction="column"
          className="stack-btn"
          justifyContent="right"
          spacing={2}
        >
          <Button
            onClick={handleOpenOut}
            // startIcon={<AddCircleOutlineOutlinedIcon className="icon-add" />}
            className="btn-add"
          >
            <Typography className="btn-text">Transfer Out</Typography>
          </Button>

          <Modal open={openOut}>
            <TransferOut
              handleClose={handleCloseOut}
              setRefetch={refetch}              
              checkButton={"create"}
              setAlert={setAlert}
              setMessage={setMessage}
              setCheckMessage={setchekMessage}
            />
          </Modal>
          
        </Stack>

      
      
      </Stack>

      

      <Box className="container">

        <TabContext value={valueTab}>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                  <Tab label="Transfer in" value="1" />
                  <Tab label="Transfter out" value="2" />                  
              </TabList>
          </Box>

          <TabPanel value="1">   

            <TableContainer>
              <Table className="table" aria-label="simple table">
                <TableHead>
                  <TableRow className="header-row">
                    {/* <TableCell className="header-title">N°</TableCell> */}
                    <TableCell className="header-title">TransferIn At</TableCell>
                    <TableCell className="header-title">StorageRoom</TableCell>
                    <TableCell className="header-title">Product</TableCell>
                    <TableCell className="header-title">Supplier</TableCell>
                    <TableCell className="header-title">Instock By</TableCell>
                    <TableCell className="header-title" align="center"></TableCell>
                  </TableRow>
                </TableHead>

                {loading ? (
                  <>
                    <TableHead>
                      <TableRow className="header-row">
                        <TableCell className="header-title" colSpan={7}>
                          <Skeleton
                            sx={{ height: 50, width: "100%" }}
                            variant="rectangular"
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow className="header-row">
                        <TableCell className="header-title" colSpan={7}>
                          <Skeleton
                            sx={{ height: 50, width: "100%" }}
                            variant="rectangular"
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </>
                ) : (
                  <>
                    { oldInstockProduct?.map((row, index) => (
                      <TableBody
                        key={index}
                        component={Paper}
                        className={"body-odd"}
                      >
                        <TableRow  className="body-row" >
                          {/* <TableCell
                            className="body-title"
                            width="3%"
                          >
                            {index + 1}
                          </TableCell> */}
                          <TableCell
                            className="body-title"
                            onClick={() => setRowData(row)}
                          >
                            { moment(row?.receive_Date).format("YYYY-MM-DD") }
                          </TableCell>
                          <TableCell className="body-title"  onClick={() => setRowData(row)}>
                            {row?.storage_Room_Id?.name}
                          </TableCell>
                          <TableCell className="body-title"  onClick={() => setRowData(row)}>
                            {
                              row?.items?.map( (items,indexs) => (
                                <Typography key={indexs}>
                                  Name: {items?.product_Id?.name} - Qty: {items?.qty} {items?.product_Id?.unit}
                                </Typography>
                              ))
                            }
                          </TableCell>
                          <TableCell className="body-title"  onClick={() => setRowData(row)}>
                            <Typography>{row?.supplier_id?.name}</Typography>
                          </TableCell>
                          <TableCell className="body-title"  onClick={() => setRowData(row)}>
                            <Typography>{row?.receive_By?.firsName+" "+row?.receive_By?.lastName}</Typography>
                          </TableCell>
                          <TableCell className="body-title" align="right" onClick={() => setRowData(row)}>
                            {/* <TransferAction  Data={row} /> */}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </>
                )}
              </Table>
            </TableContainer> 
          
            <Stack className="pagination" direction="row" spacing={1}>
              <Pagination
                page={page}
                hideNextButton={false}
                hidePrevButton={false}
                variant="outlined"
                count={data?.getOldProductInStockPaginatioin?.paginator?.totalPages}
                onChange={(event, pageNnumber) => setPage(parseInt(pageNnumber))}
              />
            </Stack>
          </TabPanel>

          <TabPanel value="2">
                                 
            <TableContainer>
                <Table className="table" aria-label="simple table">
                  <TableHead>
                    <TableRow className="header-row">
                      {/* <TableCell className="header-title">N°</TableCell> */}
                      <TableCell className="header-title">TransferOut At</TableCell>
                      <TableCell className="header-title">StorageRoom</TableCell>
                      <TableCell className="header-title">Product</TableCell>
                      <TableCell className="header-title">Adjust To</TableCell>
                      <TableCell className="header-title">Instock At</TableCell>
                      <TableCell className="header-title" align="center"></TableCell>
                    </TableRow>
                  </TableHead>

                  {loading ? (
                    <>
                      <TableHead>
                        <TableRow className="header-row">
                          <TableCell className="header-title" colSpan={7}>
                            <Skeleton
                              sx={{ height: 50, width: "100%" }}
                              variant="rectangular"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow className="header-row">
                          <TableCell className="header-title" colSpan={7}>
                            <Skeleton
                              sx={{ height: 50, width: "100%" }}
                              variant="rectangular"
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </>
                  ) : (
                    <>
                      { historyAdjustStockAt?.map((row, index) => (
                        <TableBody
                          key={index}
                          component={Paper}
                          className={"body-odd"}
                        >
                          <TableRow  className="body-row" >
                            {/* <TableCell
                              className="body-title"
                              width="3%"
                            >
                              {index + 1}
                            </TableCell> */}
                            <TableCell
                              className="body-title"
                              onClick={() => setRowData(row)}
                            >
                              { moment(row?.created_At).format("YYYY-MM-DD") }
                            </TableCell>
                            <TableCell className="body-title"  onClick={() => setRowData(row)}>
                              {row?.storage_room_id?.name}
                            </TableCell>
                            <TableCell className="body-title"  onClick={() => setRowData(row)}>
                              <Stack direction="row" width="100%">
                                  <Typography>
                                    {row?.product_id?.name} 
                                  </Typography>
                                  <Box flexGrow={1} />
                                  <Typography>
                                    {row?.old_qty+" "+row?.product_id?.unit}
                                  </Typography>
                              </Stack>                              
                            </TableCell>
                            <TableCell className="body-title"  onClick={() => setRowData(row)}>
                              <Typography>{row?.new_qty+" "+row?.product_id?.unit}</Typography>
                            </TableCell>
                            <TableCell className="body-title"  onClick={() => setRowData(row)}>
                              <Typography>{ moment(row?.product_stock_in_id?.instock_At)?.format("YYYY-MM-DD") }</Typography>
                            </TableCell>
                            <TableCell className="body-title" align="right" onClick={() => setRowData(row)}>
                              {/* <TransferAction  Data={row} /> */}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ))}
                    </>
                  )}
                </Table>
              </TableContainer> 
          

              <Stack className="pagination" direction="row" spacing={1}>
                <Pagination
                  page={page}
                  hideNextButton={false}
                  hidePrevButton={false}
                  variant="outlined"
                  count={dataHistoryAdjust?.getAdjustProductInStockHistoryPagination?.paginator?.totalPages}
                  onChange={(event, pageNnumber) => setPage(parseInt(pageNnumber))}
                />
              </Stack>

          </TabPanel>

        </TabContext>
        
        

      
      </Box>

      <AlertMessage
        alert={alert}
        setAlert={setAlert}
        message={message}
        setMessage={setMessage}
        checkMessage={checkMessage}
      />
    </Box>
  );
}
