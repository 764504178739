import React, { useState } from "react";
import {
  Stack,
  IconButton,
  Typography,
  Modal,
  Menu,
  MenuItem,
} from "@mui/material";
import "../../Component/StorageRoom/modalaction.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
//component
import UpdateSupplies from "./UpdateSupplies";
import DeleteSupplies from "./DeleteSupplies";

export default function SuppliesAction({
  row,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const openEl = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEl = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [deleteID, setDeleteID] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseDel = () => setOpenDel(false);

  const handleOpenDel = () => {
    setOpenDel(true);
    setDeleteID(row?._id);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon sx={{ color: "#3d3d3d" }} />
      </IconButton>

      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={openEl}
        onClose={handleCloseEl}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpen();
            handleCloseEl();
          }}
        >
          <Stack direction="row" spacing={1}>
            <AppRegistrationOutlinedIcon className="icon-menu-update" />
            <Typography className="text-menu"> Update </Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenDel();
            handleCloseEl();
          }}
        >
          <Stack direction="row" spacing={1}>
            <DeleteOutlineOutlinedIcon className="icon-menu-delete" />
            <Typography className="text-menu"> Delete </Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <Modal open={open}>
        <UpdateSupplies
          row={row}
          setRefetch={setRefetch}
          setAlert={setAlert}
          setMessage={setMessage}
          setchekMessage={setchekMessage}
          handleClose={handleClose}
        />
      </Modal>

      <Modal open={openDel}>
        <DeleteSupplies
          row={row}
          deleteID={deleteID}
          setRefetch={setRefetch}
          setAlert={setAlert}
          setMessage={setMessage}
          setchekMessage={setchekMessage}
          handleCloseDel={handleCloseDel}
        />
      </Modal>
    </>
  );
}
