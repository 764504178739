import React, { useState, useEffect } from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Button,
  TextField,
  IconButton,
  Autocomplete,
  Avatar,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import "./createproduct.scss";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

//
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import moment from "moment";
import axios from "axios";
import { GET_UNIT } from "../../Schema/unit";
import { styled } from "@mui/material/styles";
import { GET_CATEGORIES_PRODUCT, UPDATE_PRODUCT } from "../../Schema/product";
import { useMutation, useQuery } from "@apollo/client";

export default function EditSale({
  handleClose,
  Data,
  btnTitle,
  setAlert,
  setMessage,
  setchekMessage,
  refetch,
}) {
  const [loading, setLoading] = useState(false);
  //autoComplete
  const [autocompleteCategory, setAutocompleteCategory] = useState({});
  const [autocompleteFeature, setAutocompleteFeature] = useState({});
  const [autocompleteUnit, setAutocompleteUnit] = useState({});
  const [autocompleteQtyUnit, setAutocompleteQtyUnit] = useState({});


  const [categoryPro, setCategoryPro] = useState([]);
  const { refetch: refetchCategory } = useQuery(GET_CATEGORIES_PRODUCT, {
    onCompleted: ({ getCategorise }) => {
      let rows = [];
      getCategorise?.forEach((element, index) => {
        const allRow = { label: element };
        rows.push(allRow);
      });
      setCategoryPro(rows);
    },
  });
  useEffect(() => {
    refetchCategory();
  }, []);

  const Input = styled("input")({
    display: "none",
  });

  const { data: DataUnit } = useQuery(GET_UNIT);
  const [selectUnit, setSelectUnit] = useState({});

  useEffect(() => {
    if (DataUnit) {
      let rows = [];
      DataUnit?.getUnit.forEach((element) => {
        const allrow = {
          label: element?.name,
          _id: element?._id,
        };
        rows.push(allrow);
      });
      setSelectUnit(rows);
    }
  }, [DataUnit]);

  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    onCompleted: ({ updateProduct }) => {
      if (updateProduct?.status) {
        setchekMessage("success");
        setMessage(updateProduct?.message);
        setAlert(true);
        handleClose();
        refetch();
        setLoading(false);
      } else {
        setchekMessage("error");
        setMessage(updateProduct?.message);
        setAlert(true);
        setLoading(false);
      }
    },
    onError: (error) => {
      setchekMessage("error");
      setMessage(error.message);
      setAlert(true);
      setLoading(false);
    },
  });

  // upload Image
  const [imageFile, setImageFile] = useState(null);

  const newDate = moment(new Date()).format("MMdYYYY");
  const uploadImage = async (file, values) => {
    //compressfile to small
    if (!file) return;
    const formData = new FormData();
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    //change image name
    let newName = `${uuidv4()}${newDate}${file.name.split(".").pop()}`;
    //convert extention
    var newFile = new File([compressedFile], `${newName}.png`, {
      type: "image/png",
    });
    formData.append("image", newFile);

    //post to store at backend
    await axios
      .post(
        `${process.env.REACT_APP_UPLOAD_URL}/api/inventory-mart/upload/`,
        formData,
        config
      )

      //catch image url
      .then(async function (response) {
        updateProduct({
          variables: {
            input: {
              product_Id: Data?._id,
              image_src: `${process.env.REACT_APP_UPLOAD_URL}/api${response?.data}`,
              ...values,
            },
          },
        });
      });
  };

  const SetupAdd = Yup.object().shape({
    name: Yup.string().required("require name!"),
    cost: Yup.number().required("require cost!"),
    category: Yup.string().required("require category!"),
    remark: Yup.string(),
    type: Yup.string().required("require type!"),
    unit: Yup.string().required("require unit!"),
    qty_inUnit: Yup.number().required("require qty in Unit!"),
    unit_of_qty_inUnit: Yup.string().required("require unit!"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      cost: 0,
      category: "",
      remark: "",
      type: "For Sale",
      feature: "",
      unit: "",
      bar_Code: "",
      qty_inUnit: 0,
      unit_of_qty_inUnit: "",
    },

    validationSchema: SetupAdd,
    onSubmit: async (values) => {
      setLoading(true);

      if (imageFile) {
        uploadImage(imageFile);
      } else {
        updateProduct({
          variables: {
            input: {
              product_Id: Data?._id,
              image_src: Data?.image_src,
              cost: parseFloat(values?.cost),
              ...values,
            },
          },
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =  formik;


  useEffect( () => {
      if(Data) {          
          console.log(Data)

          setFieldValue("name" , Data?.name )
          setFieldValue("cost" , Data?.cost )
          setFieldValue("category" , Data?.category )
          setFieldValue("remark" , Data?.remark )
          setFieldValue("type" , "For Sale" )
          setFieldValue("feature" , Data?.feature )
          setFieldValue("unit" , Data?.unit )
          setFieldValue("bar_Code" , Data?.bar_Code )
          setFieldValue("qty_inUnit" , Data?.qty_inUnit )
          setFieldValue("unit_of_qty_inUnit", Data?.unit_of_qty_inUnit)
      }
  },[Data])

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box spacing={5} className="product-page">
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" className="title">
              UPDATE PRODUCT
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={() => handleClose()}>
              <ClearIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>

          <Stack direction="row" spacing={5}>
            <Typography variant="body2" className="simple-text">
              Please complete option
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={5}
            sx={{ mt: 2 }}
            justifyContent="center"
          >
            <label htmlFor="icon-button-file">
              <Input
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={(event) => {
                  console.log("event", event);
                  setImageFile(event.target.files[0]);
                }}
              />
              <Avatar
                sx={{
                  width: 140,
                  height: 140,
                  cursor: "pointer",
                  transition: "0.6s",
                  boxShadow: 3,
                  "&:hover": { boxShadow: 6, transition: "0.3s" },
                }}
                alt="Remy Sharp"
                variant="rounded"
                src={
                  imageFile
                    ? URL.createObjectURL(imageFile)
                    : Data?.image_src === ""
                    ? null
                    : Data?.image_src
                }
              />
            </label>
          </Stack>
          <Typography className="body-title"> Image</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field"> Name </Typography>
              <TextField
                id="name"
                required
                size="small"
                fullWidth
                placeholder="name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="field"> Cost </Typography>
              <TextField
                id="cost"
                type="number"
                required
                size="small"
                fullWidth
                placeholder="cost"
                {...getFieldProps("cost")}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="field"> Type </Typography>
              <Box className="btn-filter">
                <FormControl
                  className="form-controll-select"
                  size="small"
                  fullWidth
                >
                  <Select
                    IconComponent={(props) => (
                      <KeyboardArrowDownOutlinedIcon {...props} />
                    )}
                    {...getFieldProps("type")}
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}
                  >
                    <MenuItem value="For Sale">
                      <Typography>For Sale</Typography>
                    </MenuItem>
                    <MenuItem value="For Usage">
                      <Typography>For Usage</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography className="field"> Category </Typography>
              <Box className="btn-filter">
                <Autocomplete
                  className="selection"
                  disablePortal
                  id="combo-box-demo"
                  value={autocompleteCategory}
                  options={categoryPro}
                  size="small"
                  getOptionSelected={(option, value) => option.id === value?.id}
                  getOptionLabel={(option) =>
                    option.label === "" || option.label === undefined
                      ? Data?.category
                      : option.label
                  }
                  onChange={(e, value) => {
                    setFieldValue("category", value?.label);
                    setAutocompleteCategory({
                      id: value?.id,
                      label: value?.label,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField className="text-field" {...params} />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography className="field"> Feature </Typography>
              <Box className="btn-filter">
                <Autocomplete
                  className="selection"
                  disablePortal
                  id="combo-box-demo"
                  value={autocompleteFeature}
                  options={featData}
                  size="small"
                  getOptionSelected={(option, value) => option.id === value?.id}
                  getOptionLabel={(option) =>
                    option.label === "" || option.label === undefined
                      ? Data?.feature
                      : option.label
                  }
                  onChange={(e, value) => {
                    setFieldValue("feature", value?.label);
                    setAutocompleteFeature({
                      id: value?.id,
                      label: value?.label,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField className="text-field" {...params} />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography className="field"> Barcode </Typography>
              <TextField
                size="small"
                fullWidth
                placeholder="barcode"
                {...getFieldProps("bar_Code")}
                error={Boolean(touched.bar_Code && errors.bar_Code)}
                helperText={touched.bar_Code && errors.bar_Code}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="field"> Unit </Typography>
              <Box className="btn-filter">
                <Autocomplete
                  className="selection"
                  disablePortal
                  id="combo-box-demo"
                  value={autocompleteUnit}
                  options={selectUnit}
                  size="small"
                  getOptionSelected={(option, value) => option.id === value?.id}
                  getOptionLabel={(option) =>
                    option.label === "" || option.label === undefined
                      ? Data?.unit
                      : option.label
                  }
                  onChange={(e, value) => {
                    setFieldValue("unit", value?.label);
                    setAutocompleteUnit({
                      id: value?.id,
                      label: value?.label,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField className="text-field" {...params} />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography className="field">Qty in Unit</Typography>

              <Stack direction="row" spacing={1} width="100%">
                <Box width="40%">
                  <TextField
                      type="number"
                      size="small"
                      fullWidth
                      placeholder="0"
                      {...getFieldProps("qty_inUnit")}
                      error={Boolean(touched.qty_inUnit && errors.qty_inUnit)}
                      helperText={touched.qty_inUnit && errors.qty_inUnit}
                      inputProps={{ min: 0 }}
                  />
                </Box>
                <Box className="btn-filter" width="60%">
                    <Autocomplete
                      className="selection"
                      disablePortal
                      id="combo-box-demo"
                      value={autocompleteQtyUnit}
                      options={selectUnit}
                      size="small"
                      getOptionSelected={(option, value) => option.id === value?.id}
                      getOptionLabel={(option) =>
                        option.label === "" || option.label === undefined
                          ? Data?.unit_of_qty_inUnit
                          : option.label
                      }
                      onChange={(e, value) => {
                        setFieldValue("unit_of_qty_inUnit", value?.label);
                        setAutocompleteQtyUnit({
                          id: value?.id,
                          label: value?.label,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="text-field"
                          {...params}
                          {...getFieldProps("unit_of_qty_inUnit")}
                          error={Boolean(touched.unit_of_qty_inUnit && errors.unit_of_qty_inUnit)}
                          helperText={touched.unit_of_qty_inUnit && errors.unit_of_qty_inUnit}
                          placeholder="Choose"
                        />
                      )}
                    />
                </Box>
              </Stack>
              
            </Grid>

            <Grid item xs={12}>
              <Typography className="field"> Remark </Typography>

              <TextField
                multiline
                rows={3}
                size="small"
                fullWidth
                placeholder="remark"
                {...getFieldProps("remark")}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              {loading ? (
                <Button variant="text" fullWidth className="btn-create">
                  Loading...
                </Button>
              ) : (
                <Button
                  variant="text"
                  fullWidth
                  type="submit"
                  className="btn-create"
                >
                  {btnTitle}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Form>
    </FormikProvider>
  );
}

// type
const simpleData = [
  { label: "Food" },
  { label: "Meat" },
  { label: "Drink" },
  { label: "Vegetables" },
  { label: "Fruit" },
  { label: "Cosmetic" },
  { label: "Snacks" },
  { label: "Spices" },
  { label: "Soap" },
  { label: "Other" },
];

//feature

const featData = [
  { label: "Short Term" },
  { label: "Long Term" },
  { label: "New" },
  { label: "Old" },
];
