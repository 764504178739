import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Button,
  Stack,
  Typography,
  Modal,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  Pagination,
  FormControl,
  Select,
  Menu,
  MenuItem,
} from "@mui/material";
import "./supplies.scss";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AlertMessage from "../Component/AlertMessage/AlertMessage";

import Profile from "../Component/Menu/Profile";
import SuppliesAdd from "../Component/Suppliers/SuppliesAdd";
import ViewSupplies from "../Component/Suppliers/ViewSupplies";
import SuppliesAction from "../Component/Suppliers/SuppliesAction";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
import { GET_SUPPLIER_WITH_PAGINATION } from "../Schema/supplies";
import { useQuery } from "@apollo/client";
import HoverpopoverSuppliers from "../Component/Suppliers/HoverpopoverSuppliers";

export default function Supplies() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(true);
  // AlertMessage
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [checkMessage, setchekMessage] = useState("");
  //view

  const [viewData, setViewData] = useState([]);
  const [rowData, setRowData] = useState([])
  const [openView, setOpenView] = useState(false);
  //pagination
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState("");
  const [showPage, setShowPage] = useState(1);

  const { data, refetch } = useQuery(GET_SUPPLIER_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: null,
    },
    onCompleted: () => {
      setLoading(false);
    },
    onError: () => {
      setLoading(true);
    },
  });

  // it store values
  const handleCloseView = () => setOpenView(false);
  const handleOpenView = (e) => {
    setOpenView(true);
    setViewData(e);
    return null;
  };

  //click setlocalStorage
  const handleClick = (e) => {
    localStorage.setItem("supplierId", JSON.stringify(e?._id));
  };

  //get value from localstorage
  const supplierID = JSON.parse(localStorage.getItem("supplierId"));

  useEffect(() => {
    refetch();
    setShowPage(page);
  }, [page, keyword, limit]);

  return (
    <Box className="supplies-page">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="color">Supplies</Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }}></Box>
        <Profile />
      </Stack>

      <Stack direction="row" spacing={2}>
        <Stack direction="column" spacing={2} className="btn-search">
          <Box className="btn-text-field">
            <TextField
              onChange={(e) => setKeyword(e.target.value)}
              className="text-field"
              fullWidth
              id="input-with-sx"
              placeholder="Search Name"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="column"
          className="stack-btn"
          justifyContent="right"
          spacing={1}
        >
          <Button
            onClick={handleOpen}
            startIcon={<AddCircleOutlineOutlinedIcon className="icon-add" />}
            className="btn-add"
          >
            <Typography className="btn-text">Create</Typography>
          </Button>

          <Modal open={open}>
            <SuppliesAdd
              setRefetch={refetch}
              setAlert={setAlert}
              setMessage={setMessage}
              handleClose={handleClose}
              setchekMessage={setchekMessage}
            />
          </Modal>
        </Stack>
      </Stack>

      <Box className="container">
        <TableContainer>
          <Table className="table" aria-label="simple table">
            <TableHead>
              <TableRow className="header-row">
                <TableCell className="header-title" width="3%">
                  N°
                </TableCell>
                <TableCell className="header-title" width="20%">
                  Name
                </TableCell>
                <TableCell className="header-title" width="17%">
                  Phone Number
                </TableCell>

                <TableCell className="header-title" width="23%">
                  Address
                </TableCell>
                <TableCell className="header-title" width="10%">
                  Remark
                </TableCell>
                <TableCell className="header-title"></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableHead>
                <TableRow className="header-row">
                  <TableCell className="header-title" colSpan={8}>
                    <Skeleton
                      sx={{ height: 50, width: "100%" }}
                      variant="rectangular"
                    />
                  </TableCell>
                </TableRow>

                <TableRow className="header-row">
                  <TableCell className="header-title" colSpan={8}>
                    <Skeleton
                      sx={{ height: 50, width: "100%" }}
                      variant="rectangular"
                    />
                  </TableCell>
                </TableRow>

                <TableRow className="header-row">
                  <TableCell className="header-title" colSpan={8}>
                    <Skeleton
                      sx={{ height: 50, width: "100%" }}
                      variant="rectangular"
                    />
                  </TableCell>
                </TableRow>

                <TableRow className="header-row">
                  <TableCell className="header-title" colSpan={8}>
                    <Skeleton
                      sx={{ height: 50, width: "100%" }}
                      variant="rectangular"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <>
                {data?.getSuppliersWithPagination.data.map((row, index) => (
                  <TableBody
                    key={index}
                    component={Paper}
                    className={
                      supplierID === row?._id ? "body" : "body-odd"
                    }
                  >
                    <TableRow
                      className="body-row"
                      onClick={() => handleClick(row)}
                    >
                      <TableCell
                        className="body-title"
                        width="3%"
                        sx={{ cursor: "pointer" }}
                      >
                        {index + 1}
                      </TableCell>

                      <TableCell
                        className="body-title"
                        onClick={() => {handleOpenView(row); setRowData(row)}}
                      >
                        <HoverpopoverSuppliers row={row} />
                      </TableCell>

                      <TableCell
                        className="body-title"
                        onClick={() => {handleOpenView(row); setRowData(row)}}
                      >
                        {row?.tel}
                      </TableCell>

                      <TableCell
                        className="body-title"
                        onClick={() => {handleOpenView(row); setRowData(row)}}
                      >
                        <Typography className="product-name">
                          {row?.adress}
                        </Typography>
                      </TableCell>

                      <TableCell
                        className="body-title"
                        onClick={() => {handleOpenView(row); setRowData(row)}}
                        sx={{ cursor: "pointer", width: "20%" }}
                      >
                        <Typography className="product-name">
                          {row?.remark}
                        </Typography>
                      </TableCell>

                      <TableCell className="body-title" onClick={() => setRowData(row)} align="right">
                        <SuppliesAction
                          row={row}
                          setRefetch={refetch}
                          setAlert={setAlert}
                          setMessage={setMessage}
                          setchekMessage={setchekMessage}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </>
            )}
          </Table>
        </TableContainer>

        <Stack className="pagination" direction="row" spacing={1}>
          <Pagination
            page={showPage}
            hideNextButton={false}
            hidePrevButton={false}
            variant="outlined"
            count={data?.getSuppliersWithPagination?.paginator?.totalPages}
            onChange={(event, pageNnumber) => setPage(parseInt(pageNnumber))}
          />

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Select
                id="demo-simple-select"
                size="small"
                value={limit}
                defaultValue={limit}
                onChange={(e) => setLimit(e.target.value)}
              >
                <MenuItem value={8}> 8 /Page</MenuItem>
                <MenuItem value={10}>10 /Page</MenuItem>
                <MenuItem value={20}>20 /Page</MenuItem>
                <MenuItem value={30}>30 /Page</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Box>

      <Modal open={openView}>
        <ViewSupplies handleCloseView={handleCloseView} viewData={viewData} />
      </Modal>

      <AlertMessage
        alert={alert}
        setAlert={setAlert}
        message={message}
        setMessage={setMessage}
        checkMessage={checkMessage}
      />
    </Box>
  );
}
