import React from "react";
import {
  Stack,
  Grid,
  Typography,
  Box,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import "./modalunitUpdate.scss";
import { useMutation } from "@apollo/client";
import { UPDATE_UNIT } from "../../Schema/unit";
import { useState } from "react";

export default function ModalunitUpdate({
  handleClose,
  Row,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
}) {
  const [loading, setLoading] = useState(false);
  const unitupdate = Yup.object().shape({
    name: Yup.string().required("required name!"),
  });

  const [updateUnit] = useMutation(UPDATE_UNIT, {
    onCompleted: ({ updateUnit }) => {
      if (updateUnit?.status === true) {
        setchekMessage("success");
        setMessage(updateUnit?.message);
        setAlert(true);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setchekMessage("error");
        setMessage(updateUnit.message);
        setAlert(true);
        setLoading(false);
      }
    },

    onError: ({ error }) => {
      setchekMessage("error");
      setMessage(error.message);
      setAlert(true);
      setLoading(false);
    },
  });

  const formik = useFormik({
    initialValues: {
      name: Row?.name, // we get row from function above
    },

    validationSchema: unitupdate,
    onSubmit: async (values) => {
      setLoading(true);
      updateUnit({
        variables: {
          unitId: Row?._id,

          input: {
            ...values,
          },
        },
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box className="unit-update ">
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" className="title">
              UPDATE UNIT
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={() => handleClose()}>
              <ClearIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" className="Sub-title">
                Name
              </Typography>

              <TextField
                size="small"
                fullWidth
                placeholder="name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>

            <Grid item xs={12}>
              {loading ? (
                <Button className="btn-create" variant="text" fullWidth>
                  Loading...
                </Button>
              ) : (
                <Button
                  className="btn-create"
                  variant="text"
                  fullWidth
                  type="submit"
                >
                  UPDATE
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Form>
    </FormikProvider>
  );
}
