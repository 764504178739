import React from "react";
import "./modalunitdelete.scss";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useMutation } from "@apollo/client";
import { DELETE_UNIT } from "../../Schema/unit";

function ModalunitDelete({
  Row,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
  handleClose,

}) {
  const [valueVoid, setValueVoid] = React.useState("");
  const [deleteUnit] = useMutation(DELETE_UNIT, {
    variables: {
        unitId: Row?._id,
    },
    
    onCompleted: ({ deleteUnit }) => {

      if (deleteUnit?.status) {

        setchekMessage("success");
        setMessage(deleteUnit?.message);
        setAlert(true);
        setRefetch();
        handleClose();

      } 
      
      else {

        setchekMessage("error");
        setMessage(deleteUnit?.message);
        setAlert(true);

      }
    },

    onError: (error) => {
      setchekMessage("error");
      setMessage(error.message);
      setAlert(true);
      },
    });

    const handleDelete = () => {
          deleteUnit({
            variables: {
              unitId: Row?._id,
            },
          });
      };

      
  return (
      <Box className="delete-supplies">
          <Stack direction="row" spacing={5}>

              <Typography className="title" variant="h6">
                DELETE UNIT
              </Typography>

              <Box sx={{ flexGrow: 1 }}></Box>

              <IconButton onClick={handleClose}>
                <ClearIcon sx={{ color: "red" }} />
              </IconButton>

          </Stack>

        <Stack direction="row" spacing={5} width="100%">

          <Typography variant="subtitle1" className="simple-text">
           Do you want delete the Supplies?
          </Typography>

        </Stack>

        <Stack
            direction="row"
            justifyContent="center"
            spacing={1}
            width="100%"
            sx={{ mt: 2 }}
          >
            <Typography variant="subtitle1" className="simple-text">
              Please input 
            </Typography>

            <Typography variant="subtitle1" className="body-text">
              {Row?.name}
            </Typography>

            <Typography variant="subtitle1" className="simple-text">
              to delete
            </Typography>

        </Stack>

        <Stack
            direction="row"
            justifyContent="center"
            spacing={1}
            width="100%"
            sx={{ mb: 4 }}
          >
          <TextField
              className="text-input"
              size="small"
              fullWidth
              onChange={(e) => setValueVoid(e.target.value)}
            />

        </Stack>

        <Stack direction="row" spacing={5}>
          {valueVoid === Row?.name ? (
            <Button className="btn-void" onClick={handleDelete} variant="outlined" fullWidth>
              Delete Now
            </Button>
          ) : (
            <Button className="btn-delete" variant="outlined" fullWidth>
              Delete
            </Button>
          )}
        </Stack>
      </Box>
  );
}

export default ModalunitDelete;
