import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation Mutation($input: UserInput!) {
    createUser(input: $input) {
      message
      status
    }
  }
`;

export const GET_USER_LOGIN = gql`
  query Query {
    getUserLogin {
      _id
      firsName
      lastName
      image_name
      image_src
      email
      role
      created_At
    }
  }
`;

export const GET_ALL_USER = gql`
query GetAllUser($keyword: String) {
  getAllUser(keyword: $keyword) {
    role
    lastName
    image_src
    image_name
    firsName
    email
    created_At
    _id
  }
}
`;

export const UPDATE_USER = gql`
  mutation Mutation($userId: ID, $input: UserUpdate) {
    updateUser(user_Id: $userId, input: $input) {
      message
      status
    }
  }
`;

export const DELETE_USER = gql`
  mutation Mutation($userId: ID!) {
    deleteUser(user_id: $userId) {
      message
      status
    }
  }
`;
