import React, { useState, useEffect, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./alertmessage.scss";
import { Stack, Typography, Button } from "@mui/material";
import { GET_EXPIRE_PRODUCTS } from "../../Schema/report";
import { useQuery } from "@apollo/client";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
 
  return <MuiAlert elevation={6} ref={ref} sx={{color:"warning" }} variant="filled" {...props} />;
});


export default function AlertMessageInfo() {
  const [open, setOpen] = useState(false);
  const [messageData, setMessageData] = useState(null);

  const navigate = useNavigate();

  const handleCloseAlert = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setTimeout(() => {
      setOpen(false);
      setMessageData(null);
    }, 100000);
  };

  const { data } = useQuery(GET_EXPIRE_PRODUCTS, {
    variables: {
      page: 1,
      limit: 5,
      productId: "",
      status: "NearingExpiration",
    },
    onCompleted: ({ getExpireProducts }) => {
      if (getExpireProducts?.data?.length !== 0) {
        setOpen(true);
        setMessageData(getExpireProducts?.data);
      } else {
        setOpen(false);
        setMessageData(getExpireProducts?.data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });


  return (
    <div className="alert-message">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right", color:"#fff" }}
        className="snackbar-alert"
      >
        <Alert
          onClose={handleCloseAlert}
          className="alert-info"
          severity="warning"
        >
          <Typography variant="body1" className="alert-info">
            Products Nearing Expiration
          </Typography>

          {data?.getExpireProducts?.data?.map((row) => (
            <>
              <Stack direction="row" spacing={2} mt={1}>
                <Typography variant="body2">{row?.product_Id?.name}</Typography>
                <Typography variant="body2">
                  {moment(row?.expire_At).format("DD-MM-YYYY")}
                </Typography>
              </Stack>
            </>
          ))}
          <Stack direction="row" justifyContent="center">
            <Button onClick={() => navigate("/report")} className="btn">
              View Details
            </Button>
          </Stack>
        </Alert>
      </Snackbar>
    </div>
  );
}
