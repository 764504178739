import { gql } from "@apollo/client";

export const CREATE_STORAGE_ROOM = gql`
  mutation Mutation($input: StorageRoomInput!) {
    createStorageRoom(input: $input) {
      status
      message
    }
  }
`;

export const GET_STORAGE_ROOM = gql`
query GetStorageRoom($keyword: String!) {
  getStorageRoom(keyword: $keyword) {
    _id
    name
    place
    remark
    created_At
  }
}
`;

export const DELETE_STORAGE_ROOM = gql`
  mutation Mutation($storageRoomId: ID!) {
    deleteStorageRoom(StorageRoomId: $storageRoomId) {
      status
      message
    }
  }
`;

export const UPDATE_STORAGE_ROOM = gql`
  mutation UpdateStorageRoom($storageRoomId: ID!, $input: StorageRoomInput!) {
    updateStorageRoom(StorageRoomId: $storageRoomId, input: $input) {
      status
      message
    }
  }
`;

export const GET_ALL_STORAGE_ROOM = gql`
  query GetStorageRoom($keyword: String!) {
    getStorageRoom(keyword: $keyword) {
      _id
      name
      place
      remark
      created_At
    }
  }
` 