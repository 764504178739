import React, {useState} from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import "./voidstockoutcard.scss";
import { useMutation } from "@apollo/client";
import { VOID_RELEASE_CARD } from "../../Schema/stockout";

export default function VoidStockOutCard({
  releaseCardId,
  setRefetch,
  setAlert,
  setMessage,
  setCheckMessage,
  handleClose,
}) {

  const [valueVoid, setValueVoid] = useState("");

  const [voidingReleaseCard] = useMutation(VOID_RELEASE_CARD, {
    onCompleted: ({ voidingReleaseCard }) => {
      if (voidingReleaseCard?.status) {
        setCheckMessage("success");
        setMessage(voidingReleaseCard?.message);
        setAlert(true);
        setRefetch();
        handleClose();
     
      } else {
        setCheckMessage("error");
        setMessage(voidingReleaseCard?.message);
        setAlert(true);
      }
    },
    onError: (error) => {
      setCheckMessage("error");
      setMessage(error.message);
      setAlert(true);
    },
  });

  const handleDelete = () => {
    console.log(releaseCardId)

    voidingReleaseCard({
      variables: {
        releaseCardId: releaseCardId,
      },
    });
  };
  return (
    <Box className="delete-supplies">
      <Stack direction="row" spacing={5}>
        <Typography className="title" variant="h6">
          Void Stock Out Card
        </Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <IconButton onClick={handleClose}>
          <ClearIcon sx={{ color: "red" }} />
        </IconButton>
      </Stack>

      <Stack direction="row" spacing={5} width="100%">
        <Typography variant="subtitle1" className="simple-text">
          Do you want to void this card?
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mt: 2 }}
      >
        <Typography variant="subtitle1" className="simple-text">
          Please type 
        </Typography>
        <Typography className="body-text" >
          Card
        </Typography>
        <Typography variant="subtitle1" className="simple-text">
          to void
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mb: 4 }}
      >
        <TextField
          className="text-input"
          size="small"
          fullWidth
          onChange={(e) => setValueVoid(e.target.value)}
        />
      </Stack>

      <Stack direction="row" spacing={5}>

        {
          valueVoid === "Card" ?
              <Button
                onClick={handleDelete}
                sx={{ ":hover": { backgroundColor: "red", border: "none" } }}
                className="btn-void"
                variant="outlined"
                fullWidth
              >
                Void NOW
              </Button>
          :
              <Button  variant="outlined" className="btn-delete" fullWidth> 
                Void
              </Button>
        }
          
      </Stack>
    </Box>
  );
}
