import React, { useState, useEffect } from "react";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Autocomplete,
  Paper,
} from "@mui/material";
import { useQuery } from "@apollo/client";
//components
import { GET_ALL_PRODUCT } from "../../Schema/product";
import { GET_ALL_STORAGE_ROOM } from "../../Schema/storageroom";
import "./liststockoutcard.scss";

export default function ListStockOutCard(props) {
  // const [autoCompletedLocation, setAutoCompletedLocation] = useState({});

  // Get All Product ===============================================================================
  const [allProduct, setAllProduct] = useState([]);
  const { data: dataAllProduct } = useQuery(GET_ALL_PRODUCT, {
    onCompleted: ({ getAllProduct }) => {
      // console.log(getAllProduct)
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (dataAllProduct?.getAllProduct) {
      let rows = [];
      dataAllProduct?.getAllProduct?.forEach((element) => {
        // console.log("er", element);
        const allrow = {
          // label: element?.name+" "+element?.remark,
          label: element?.name,
          id: element?._id,
          // cost: element?.cost,
        };
        rows.push(allrow);
      });
      setAllProduct(rows);
    }
  }, [dataAllProduct?.getAllProduct]);

  // console.log("allProduct", allProduct);

  //autoComplete ==========================================================================
  const [storageRoom, setStorageRoom] = useState([]);
  const { data: dataStorageRoom } = useQuery(GET_ALL_STORAGE_ROOM, {
    variables: {
      keyword: "",
    },
  });

  useEffect(() => {
    if (dataStorageRoom) {
      let rows = [];
      dataStorageRoom?.getStorageRoom?.forEach((element) => {
        const allrow = {
          label: element?.name,
          id: element?._id,
        };
        rows.push(allrow);
      });
      setStorageRoom(rows);
    }
  }, [dataStorageRoom]);
  //end

  // Handle Message Error TextField
  const [errorMessage, setErrorMessage] = React.useState([
    "Can't input 0",
    "Invalid Value",
    "Field is required!",
    "Duplicate item",
  ]);

  const [touchedProduct, setTouchedProduct] = React.useState(false);
  const handleTouchProduct = () => setTouchedProduct(true);

  const [touchedQty, setTouchedQty] = React.useState(false);
  const handleTouchQty = () => setTouchedQty(true);

  const [touchedUnitPrice, setTouchedUnitPrice] = React.useState(false);
  const handleTouchUnitPrice = () => setTouchedUnitPrice(true);

  const [touchedStorageRoom, setTouchedStorageRoom] = React.useState(false);
  const handleTouchStorageRoom = () => setTouchedStorageRoom(true);

  const items = props.items;

  // console.log("props", props)
  const listItems = items.map((item, index) => {
    return (
      <TableBody key={index} component={Paper} className="body-list-materiales">
        <TableRow className="body-row" width="100%">
          <TableCell
            className="body-title"
            component="th"
            scope="row"
            width="50%"
          >
            <Stack direction="column" spacing={1} className="btn-filter">
              <Autocomplete
                disabled={props.deliver ? true : false}
                id={item?.key}
                disablePortal
                fullWidth
                options={allProduct}
                value={{ label: item?.product_name, id: item?.product_Id }}
                getOptionSelected={(value, option) => value.id === option.id}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                onChange={(e, value) => {
                  props.setUpdateProductName(
                    value?.label,
                    value?.id,
                    item?.key
                  );
                  //  props.setUpdateUnitPrice(parseFloat(value?.cost), item.key)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="text-field"
                    placeholder="Choose Materail"
                    disabled={props?.btnTitle === "Update" ? true : false}
                    size="small"
                    onFocus={handleTouchProduct}
                    error={
                      (touchedProduct && item?.product_Id === undefined) ||
                      (props.checkFieldRequired && item?.product_Id === "") ||
                      props.duplicate
                    }
                    helperText={
                      (touchedProduct &&
                        item?.product_Id === undefined &&
                        errorMessage[2]) ||
                      (props.checkFieldRequired &&
                        item?.product_Id === "" &&
                        errorMessage[2]) ||
                      (props.duplicate && errorMessage[3])
                    }
                  />
                )}
              />
            </Stack>
          </TableCell>

          <TableCell className="body-title" width="15%">
            <Stack direction="column" spacing={1} className="btn-filter">
              <TextField
                fullWidth
                type="number"
                className="text-field"
                id={item.key}
                size="small"
                value={item.qty}
                // disabled={props?.btnTitle === "Update" ? true : false}
                onChange={(e) =>
                  props.setUpdateQty(parseFloat(e.target.value), item.key)
                }
                onFocus={handleTouchQty}
                error={
                  (touchedQty && item?.qty < 0) ||
                  (touchedQty && item?.qty === 0) ||
                  (props.checkFieldRequired && item?.qty === 0) ||
                  (touchedQty && isNaN(item?.qty))
                }
                helperText={
                  (touchedQty && item?.qty < 0 && errorMessage[1]) ||
                  (touchedQty && item?.qty === 0 && errorMessage[0]) ||
                  (props.checkFieldRequired &&
                    item?.qty === 0 &&
                    errorMessage[0]) ||
                  (touchedQty && isNaN(item?.qty) && errorMessage[2])
                }
              />
            </Stack>
          </TableCell>

          {/* unit Price*/}
          
          {/* <TableCell className="body-title" width="15%">
            <TextField
              fullWidth
              type="number"
              className="text-field"
              id={item.key}
              size="small"
              disabled={ props?.btnTitle === "Update" ? true : false}
              defaultValue={allProduct?.map((e) => e?.cost)}
              value={item.unit_Price}
              onChange={(e) =>
                props.setUpdateUnitPrice(parseFloat(e.target.value), item.key)
              }
              onFocus={handleTouchUnitPrice}
              error={
                (touchedUnitPrice && item?.unit_Price < 0) ||
                (touchedUnitPrice && item?.unit_Price === 0) ||
                (props.checkFieldRequired && item?.unit_Price === 0) ||
                (touchedUnitPrice && isNaN(item?.unit_Price))
              }
              helperText={
                (touchedUnitPrice && item?.unit_Price < 0 && errorMessage[1]) ||
                (touchedUnitPrice &&
                  item?.unit_Price === 0 &&
                  errorMessage[0]) ||
                (props.checkFieldRequired &&
                  item?.unit_Price === 0 &&
                  errorMessage[0]) ||
                (touchedUnitPrice && isNaN(item?.unit_Price) && errorMessage[2])
              }
            />
          </TableCell> */}

          {/*end*/}
          <TableCell className="body-title" width="35%">
            <Autocomplete
              disabled={props.deliver ? true : false}
              className="btn-filter"
              disablePortal
              options={storageRoom}
              value={{
                label: item?.storage_Room_name,
                id: item?.storage_Room_Id,
              }}
              getOptionSelected={(value, option) => value.id === option.id}
              getOptionLabel={(option) => (option.label ? option.label : "")}
              onChange={(e, value) => {
                props.setUpdateStorageRoom(value?.label, value.id, item?.key);
              }}
              renderInput={(params) => (
                <TextField
                  className="text-field"
                  fullWidth
                  {...params}
                  placeholder="Choose Storagroom"
                  size="small"
                  // disabled={props?.btnTitle === "Update" ? true : false}
                  onFocus={handleTouchStorageRoom}
                  error={
                    (props.checkFieldRequired &&
                      item?.storage_Room_name === "") ||
                    (touchedStorageRoom &&
                      item?.storage_Room_name === undefined)
                  }
                  helperText={
                    (props.checkFieldRequired &&
                      item?.storage_Room_name === "" &&
                      errorMessage[2]) ||
                    (touchedStorageRoom &&
                      item?.storage_Room_name === undefined &&
                      errorMessage[2])
                  }
                />
              )}
            />
          </TableCell>

          <TableCell className="body-title" align="right">
            <IconButton
              onClick={() => {
                props.deleteItem(item.key);
              }}
            >
              <DeleteRoundedIcon sx={{ color: "red" }} />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  });
  // return <FlipMove duration={300} easing="ease-in-out">
  //     {listItems}
  // </FlipMove>;

  return listItems;
}
