import { gql } from "@apollo/client";

export const GET_CATEGORIES_PRODUCT = gql`
  query Query {
    getCategorise
  }
`

export const CREATE_PRODUCT = gql`
  mutation Mutation($input: createProductInput!) {
    createProduct(input: $input) {
      message
      status
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($input: updateProductInput!) {
    updateProduct(input: $input) {
      message
      status
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($productId: String!) {
    deleteProduct(product_Id: $productId) {
      message
      status
    }
  }
`;
export const GET_ALL_PRODUCT_WITH_PAGINATION = gql`
  query GetProductsPagination(
    $page: Int!
    $limit: Int!
    $keyword: String!
    $category: String!
  ) {
    getProductsPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      category: $category
    ) {
      message
      data {
        _id
        name
        cost
        category
        image_src
        remark
        qty_inUnit
        type
        feature
        unit
        unit_of_qty_inUnit
        bar_Code
        created_At
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_ALL_PRODUCT = gql`
  query Query {
    getAllProduct {
      _id
      name
      cost
      category
      image_src
      bar_Code
      remark
      type
      feature
      unit
      created_At
    }
  }
`;
