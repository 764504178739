import React, { useState } from "react";
import {
  Box,
  Stack,
  Avatar,
  Button,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import "./login.scss";
import logiImage from "../Assets/logoLogin.svg";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import AlertMessage from "../Component/AlertMessage/AlertMessage";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { auth } from "../firebase";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

export default function Login() {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  // AlertMessage
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [checkMessage, setCheckMessage] = useState("");

  const navigate = useNavigate();
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Input wrong fromat!").required("require email!"),
    password: Yup.string()
      .required("Input password")
      .min(6, "Completed more than 6 character"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    // userCredential
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredential) => {          
          setAlert(true);
          setCheckMessage("success");
          setMessage("Login successful!");
          setTimeout(() => {
            navigate("/dashboard");
          }, 1200);
        })
        .catch((error) => {
          setAlert(true);
          setCheckMessage("error");
          setMessage("Invalid Email/Password!");
        });
      
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box className="login-page">
            <Box className="background-image" />
            <Box className="container">
              <Box className="login-container">
                <Box className="login">
                  <Box className="box-logo">
                    <Avatar
                      sx={{ width: 100, height: 100 }}
                      variant="square"
                      alt="logo"
                      src={logiImage}
                    />
                  </Box>
                  <Box className="box-text" sx={{ mt: 1 }}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Typography className="title" variant="h6" align="center">
                        Inventory Management
                      </Typography>
                      <Typography className="title" variant="h6" align="center">
                        System
                      </Typography>
                    </Stack>
                  </Box>

                  <Box className="box-login" sx={{ mt: 3 }}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      spacing={2}
                    >
                      <Box className="field-email">
                        <TextField
                          placeholder="email"
                          className="text-field"
                          size="small"
                          fullWidth
                          {...getFieldProps("email")}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon className="icon" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box className="field-email">
                        <TextField
                          placeholder="password"
                          className="text-field"
                          type={show ? "text" : "password"}
                          size="small"
                          {...getFieldProps("password")}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                onClick={handleClick}
                              >
                                {show ? (
                                  <VisibilityIcon className="icon" />
                                ) : (
                                  <VisibilityOffIcon className="icon" />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Button
                        className="btb-sign-in"
                        type="submit"
                        sx={{ ":hover": { backgroundColor: "#fff" } }}
                      >
                        Login
                      </Button>
                      <Link
                        to="/forgotpassword"
                        style={{ textDecorationColor: "#0B82C4" }}
                      >
                        <Typography
                          className="forgot-password"
                          variant="subtitle2"
                          align="center"
                        >
                          Forgot Password?
                        </Typography>
                      </Link>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ fontFamily: "Siemreap" }}>
              <Typography
                className="footer"
                align="center"
                color="#0B82C4"
                sx={{ mb: 3 }}
              >
                &copy;រក្សាសិទ្ធិដោយហ្គោគ្លូប៊លម៉ាត ខេត្តសៀមរាប
              </Typography>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
      <AlertMessage
        alert={alert}
        setAlert={setAlert}
        message={message}
        setMessage={setMessage}
        checkMessage={checkMessage}
      />
    </>
  );
}
