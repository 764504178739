import { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Layout from "./Layout/Layout";
//Page
import Dashboard from "./Pages/Dashboard";
import User from "./Pages/User";
import StockOut from "./Pages/StockOut";
import Report from "./Pages/Report";
import Supplies from "./Pages/Supplies";
import Transfer from "./Pages/Transfer";
import Shop from "./Pages/Shop";
import Login from "./Pages/Login";
import { AuthContext } from "./context/AuthContext";
import { useContext } from "react";
import Page404 from "./Pages/Page404";
import StorageRoom from "./Pages/StorageRoom";
import Product from "./Pages/Product";
import Unit from "./Pages/Unit";
import PurchaseRoom from "./Component/StorageRoom/PurchaseRoom";
import ForgotPassword from "./Pages/ForgotPassword";

export default function Router({ prefersDarkMode, setPrefersDarkMode }) {


  //================================
  const { state } = useContext(AuthContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (state.user) {
      setTimeout(() => {
        setUser(state.user);
      }, 1200);
    } else {
      setUser(null);
    }
  }, [state]);

  // console.log(state, "user");

  const Logins = useRoutes([
    { path: "/", element: <Login /> },
    { path: "login", element: <Login /> },
    { path: "forgotpassword", element: <ForgotPassword /> },
    // { path: "*", element: <Page404 /> },
  ]);

  const Pages = useRoutes([
    {
      path: "/",
      element: (
        <Layout
          to="/dashboard"
          prefersDarkMode={prefersDarkMode}
          setPrefersDarkMode={setPrefersDarkMode}
        />
      ),
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "dashboard", element: <Dashboard /> },

        { path: "storage-room", element: <StorageRoom /> },
        { path: "storage-room/purchase", element: <PurchaseRoom /> },

        { path: "transfer", element: <Transfer /> },

        { path: "product", element: <Product /> },
        { path: "unit", element: <Unit /> },

        { path: "/shop", element: <Shop /> },

        { path: "supplies", element: <Supplies /> },
        { path: "user", element: <User /> },

        { path: "stockout", element: <StockOut /> },
        { path: "report", element: <Report /> },
        // { path: "login", element: <Login /> },
        { path: "*", element: <Page404 /> },
      ],
    },
  ]);

  if (user) {
    return Pages;
  } else {
    return Logins;
  }
}
