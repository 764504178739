import { gql } from "@apollo/client";

export const CREATE_SUPPLIES = gql`
  mutation Mutation($input: createSupplierInput!) {
    createdSupplier(input: $input) {
      message
      status
    }
  }
`;

export const GET_ALL_SUPPLIES = gql`
  query GetSuppliers($keyword: String!) {
    getSuppliers(keyword: $keyword) {
      message
      status
      data {
        _id
        name
        tel
        email
        adress
        remark
        facebook
        image_Src
        image_Name
        created_At
      }
    }
  }
`;

export const GET_SUPPLIER_WITH_PAGINATION = gql`
  query GetSuppliersWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
  ) {
    getSuppliersWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        name
        tel
        email
        adress
        remark
        facebook
        image_Src
        image_Name
        created_At
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_SUPPLIES = gql`
  mutation UpdateSupplier($input: createSupplierInput!, $supplerId: ID) {
    updateSupplier(input: $input, supplerId: $supplerId) {
      message
      status
    }
  }
`;

export const DELETE_SUPPLIES = gql`
  mutation DeleteSupplier($supplerId: ID) {
    deleteSupplier(supplerId: $supplerId) {
      message
      status
    }
  }
`;
