import React, { useState } from "react";
import {
  Stack,
  Grid,
  Box,
  styled,
  Avatar,
  IconButton,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import "./suppliesadd.scss";
import { v4 as uuidv4 } from "uuid";
import { Image } from "cloudinary-react";
import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import moment from "moment";
import axios from "axios";
import { useMutation } from "@apollo/client";
import { UPDATE_SUPPLIES } from "../../Schema/supplies";

export default function UpdateSupplies({
  handleClose,
  row,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
}) {
  const [loading, setLoading] = useState(false);

  const Input = styled("input")({
    display: "none",
  });

  // upload Image
  const [imageFile, setImageFile] = useState(null);

  const newDate = moment(new Date()).format("MMdYYYY");
  const uploadImage = async (file, values) => {
    //compressfile to small
    if (!file) return;
    const formData = new FormData();
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    //change image name
    let newName = `${uuidv4()}${newDate}${file.name.split(".").pop()}`;
    //convert extention
    var newFile = new File([compressedFile], `${newName}.png`, {
      type: "image/png",
    });
    formData.append("image", newFile);

    //post to store at backend
    await axios
      .post(
        `${process.env.REACT_APP_UPLOAD_URL}/api/inventory-mart/upload/`,
        formData,
        config
      )
      //catch image url
      .then(async function (response) {
        console.log(
          "image::",
          `${process.env.REACT_APP_UPLOAD_URL}/api${response?.data}`,
          values
        );

        updateSupplier({
          variables: {
            supplerId: row?._id,
            input: {
              image_Name: imageFile.name,
              image_Src: `${process.env.REACT_APP_UPLOAD_URL}/api${response?.data}`,
              ...values,
            },
          },
        });
      });
  };

  const SuppliesUpdate = Yup.object().shape({
    name: Yup.string().required("required Name"),
    email: Yup.string(),
    tel: Yup.string(),
    adress: Yup.string(),
  });

  const [updateSupplier] = useMutation(UPDATE_SUPPLIES, {
    onCompleted: ({ updateSupplier }) => {
      if (updateSupplier?.status) {
        setchekMessage("success");
        setMessage(updateSupplier?.message);
        setAlert(true);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setchekMessage("error");
        setMessage(updateSupplier?.message);
        setAlert(true);
        setLoading(false);
      }
    },

    onError: ({ error }) => {
      setchekMessage("error");
      setMessage(error.message);
      setAlert(true);
      setLoading(false);
    },
  });

  const formik = useFormik({
    initialValues: {
      name: row?.name, // we get row from function above
      tel: row?.tel,
      email: row?.email,
      adress: row?.adress,
      remark: row?.remark,
    },

    validationSchema: SuppliesUpdate,
    onSubmit: async (values) => {
      setLoading(true);
      if (imageFile) {
        uploadImage(imageFile, values);
      } else {
        updateSupplier({
          variables: {
            supplerId: row?._id,
            input: {
              image_Name: row?.image_Name,
              image_Src: row?.image_Src,
              ...values,
            },
          },
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box className="supplies-add">
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" className="title">
              UPDATE SUPPLIES
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            <IconButton onClick={() => handleClose()}>
              <ClearIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>

          <Stack
            direction="row"
            spacing={5}
            sx={{ mt: 2 }}
            justifyContent="center"
          >
            <label htmlFor="icon-button-file">
              <Input
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={(event) => {
                  setImageFile(event.target.files[0]);
                }}
              />
              <Avatar
                sx={{
                  width: 140,
                  height: 140,
                  cursor: "pointer",
                  transition: "0.6s",
                  boxShadow: 3,
                  "&:hover": { boxShadow: 6, transition: "0.3s" },
                }}
                alt="Remy Sharp"
                // src={"/update.image"}
                src={
                  imageFile
                    ? URL.createObjectURL(imageFile)
                    : row?.image_Src === ""
                    ? null
                    : row?.image_Src
                }
              />
            </label>
          </Stack>
          <Typography className="body-title"> Image </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" className="Sub-title">
                Name
              </Typography>
              <TextField
                className="text-field"
                size="small"
                fullWidth
                placeholder="name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" className="Sub-title">
                Email
              </Typography>
              <TextField
                className="text-field"
                size="small"
                fullWidth
                placeholder="email"
                {...getFieldProps("email")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" className="Sub-title">
                Phone Number
              </Typography>
              <TextField
                className="text-field"
                size="small"
                fullWidth
                placeholder="phone-number"
                {...getFieldProps("tel")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" className="Sub-title">
                Address
              </Typography>
              <TextField
                className="text-field"
                size="small"
                fullWidth
                placeholder="adress"
                {...getFieldProps("adress")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" className="Sub-title">
                Remark
              </Typography>
              <TextField
                className="text-field"
                id="remark"
                size="small"
                rows={3}
                fullWidth
                placeholder="remark"
                {...getFieldProps("remark")}
              />
            </Grid>

            <Grid item xs={12} spacing={2}>
              {loading ? (
                <>
                  <Button className="btn-create" variant="text" fullWidth>
                    Loading...
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="btn-create"
                    variant="text"
                    fullWidth
                    type="submit"
                  >
                    UPDATE
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Form>
    </FormikProvider>
  );
}
