import React, { useState } from "react";
import {
  Box,
  Stack,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import "./createstorageroom.scss";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
import { useMutation } from "@apollo/client";


//components
import {
  CREATE_STORAGE_ROOM,
  UPDATE_STORAGE_ROOM,
} from "../../Schema/storageroom";


export default function CreateStorageRoom({
  modalTitle,
  handleClose,
  editData,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
  
}) {

  const [loading,setLoading] = useState(false);

  const [createStorageRoom] = useMutation(CREATE_STORAGE_ROOM, {
    onCompleted: ({ createStorageRoom }) => {
      if (createStorageRoom?.status === true) {
        setchekMessage("success");
        setMessage(createStorageRoom?.message);
        setAlert(true);
        setRefetch();
        handleClose();
        setLoading(false)
      } else {
        setchekMessage("error");
        setMessage(createStorageRoom?.message);
        setAlert(true);
        setLoading(false)
      }
    },
    onError: ({ addError }) => {
      setchekMessage("error");
      setMessage(addError.message);
      setAlert(true);
      setLoading(false)
    },
  });

  const [updateStorageRoom] = useMutation(UPDATE_STORAGE_ROOM, {

    onCompleted: ({ updateStorageRoom }) => {

      if (updateStorageRoom?.status === true) {
        setchekMessage("success");
        setMessage(updateStorageRoom?.message);
        setAlert(true);
        setRefetch();
        handleClose();
        setLoading(false)
      } else {
        setLoading(false)
        setchekMessage("error");
        setMessage(updateStorageRoom?.message);
        setAlert(true);
      }
    },
  });

  const AddStorage = Yup.object().shape({
    name: Yup.string().required("require name!"),
    place: Yup.string().required("require place!"),
    
  });

  const formik = useFormik({
    initialValues: {
      name: editData?.name,
      place: editData?.place,
      remark: editData?.remark,
    },

    validationSchema: AddStorage,
    onSubmit: (values) => {
      setLoading(true)
      if (!editData) {
        createStorageRoom({
          variables: {
            input: {
              ...values,
            },
          },
        });
      } else {
        updateStorageRoom({
          variables: {
            storageRoomId: editData?._id,
            input: {
              ...values,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    // setFieldValue,
    // values,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box spacing={5} className="storageroom">
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" className="title">
              {modalTitle === "CREATE STORAGEROOM"
                ? "CREATE STORAGEROOM"
                : "UPDATE STORAGEROOM"}
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            <IconButton onClick={handleClose}>
              <ClearIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>

          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Typography className="Sub-title"> Name </Typography>
              <TextField
                className="text-field"
                size="small"
                fullWidth
                placeholder="name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="Sub-title"> Place </Typography>
              <TextField
                className="text-field"
                size="small"
                fullWidth
                placeholder="place"
                {...getFieldProps("place")}
                error={Boolean(touched.place && errors.place)}
                helperText={touched.place && errors.place}
              />
            </Grid>

    

            <Grid item xs={12} >
              <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
                <Typography className="Sub-title"> Remark </Typography>
                <TextField
                  className="text-field"
                  multiline
                  rows={3}
                  size="small"
                  fullWidth
                  placeholder="remark"
                  {...getFieldProps("remark")}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              {
                loading ?

                  <Button
                    className="btn-create"
                    variant="text"
                    fullWidth                   
                  >
                    loading...
                  </Button>
              :
                  <Button
                    className="btn-create"
                    variant="text"
                    fullWidth
                    type="submit"
                  >
                    {editData ? "UPDATE" : "CREATE"}
                  </Button>
              }
              
            </Grid>
          </Grid>
        </Box>
      </Form>
    </FormikProvider>
  );
}
