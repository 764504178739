import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Stack,
  Grid,
  FormControl,
  MenuItem,
  Select,
  Typography,
  IconButton,
  TextField,
  Button,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Autocomplete,
} from "@mui/material";
import "./createpurchase.scss";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
//Date
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
// icon priority
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
//-----------------
import UpdateListPurchase from "./UpdateListPurchase";
import { GET_ALL_SUPPLIES } from "../../Schema/supplies";
import { UPDATE_PURCHASE } from "../../Schema/purchase";
import { useQuery, useMutation } from "@apollo/client";
import { sendMessage } from "../Telegram/TelegrameClient";
import NumberFormat from "react-number-format";
import moment from "moment";

export default function UpdatePurchase({
  roomId,
  editData,
  checkButton,
  setRefetch,
  setAlert,
  setMessage,
  handleClose,
  setCheckMessage,
}) {
  //---------------required-------------
  const [checkFieldRequired, setCheckFieldRequried] = useState(false);
  const [loading, setLoading] = useState(false);

  const [duplicate, setDuplicate] = useState(false);
  //---------------Supplier-------------
  const [suppliers, setSuppliers] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const { data } = useQuery(GET_ALL_SUPPLIES, {
    variables: {
      keyword: "",
    },
  });

  useEffect(() => {
    if (data) {
      let rows = [];
      data?.getSuppliers?.data.forEach((element) => {
        const allrow = {
          label: element?.name,
          id: element?._id,
        };
        rows.push(allrow);
      });
      setSuppliers(rows);
    }
  }, [data]);
  //end

  // Buy product
  const [currentItem, setCurrentItem] = useState({
    product_Id: "",
    product_name: "",
    qty: "",
    unit_Price: 0,
    key: "",
  });

  const [item, setItem] = useState([]);

  useEffect(() => {
    if (editData?.items) {
      let rows = [];
      editData?.items?.map((e) => {
        const allRow = {
          product_Id: e?.product_Id?._id,
          product_name: e?.product_Id?.name,
          qty: e?.qty,
          unit_Price: e?.unit_Price,
          key: e?.key,
        };
        rows.push(allRow);
      });
      setItem(rows);
    }
  }, [editData]);

  const addItem = () => {
    const newItem = currentItem;
    if (newItem.product_Id !== "") {
      const items = [...item, newItem];
      setItem([...items]);
      setCurrentItem({
        product_Id: "",
        product_name: "",
        qty: 0,
        unit_Price: 0,
        key: "",
      });
    }
  };

  const hanleAddProduct = () => {
    setCurrentItem({
      product_Id: "ID",
      product_name: "NAME",
      qty: 1,
      unit_Price: 0,
      key: Date.now(),
    });
  };

  useEffect(() => {
    if (currentItem?.product_Id !== "") {
      addItem();
    }
  }, [currentItem]);

  const deleteItem = (key) => {
    const filteredItems = item?.filter((t) => t.key !== key);
    setItem(filteredItems);
  };

  const setUpdateProduct = (product_Id, product_name, key) => {
    const items = item;

    // items.map((i) => {
    //   if (i.key === key) {
    //     i.product_Id = product_Id;
    //     i.product_name = product_name;
    //   }
    // });
    // setItem([...items]);

    if (items?.filter((e) => e?.product_Id === product_Id)?.length > 0) {
      setDuplicate(true);
    } else {
      items.map((i) => {
        if (i.key === key) {
          i.product_Id = product_Id;
          i.product_name = product_name;
        }
      });
      setDuplicate(false);
      setItem([...items]);
    }
  };

  const setUpdateQty = (qty, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.qty = parseFloat(qty);
      }
    });
    setItem([...items]);
    setUpdateTotalAmount();
  };

  const setUpdateUnit_Price = (unit_Price, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.unit_Price = parseFloat(unit_Price);
      }
    });
    setItem([...items]);
    setUpdateTotalAmount();
  };

  const setUpdateTotalAmount = () => {
    const items = item;
    let totalAmounts = 0;
    items.map((i) => {
      totalAmounts += i?.qty * i?.unit_Price;
    });

    setTotalAmount(totalAmounts);
  };

  useEffect(() => {
    if (editData?.items?.lenght !== 0) {
      let amount = 0;

      editData?.items?.map((element) => {
        amount += element?.qty * element?.unit_Price;
      });
      setTotalAmount(amount);
    }
  }, [editData?.items]);

  const [updatePurchase] = useMutation(UPDATE_PURCHASE, {
    onCompleted: async ({ updatePurchase }) => {
      if (updatePurchase?.status === true) {
        setCheckMessage("success");
        setMessage(updatePurchase?.message);
        setAlert(true);
        setRefetch();
        handleClose();
        setLoading(false);
        let ArrPurchase = "";
        let totalPurchase = 0;

        updatePurchase?.data?.items?.map((e, index) => {
          ArrPurchase +=
            index +
            1 +
            "-\t" +
            e?.product_Id?.name +
            "\t" +
            e?.qty +
            "\t x \t" +
            e?.unit_Price +
            "$" +
            "\n";
          totalPurchase += e?.qty * e?.unit_Price;
        });

        // await sendMessage({
        //   content: `<b>[Request Update Purchase]</b>\n\n👩‍🚀 N°: ${
        //     updatePurchase?.data?.numbering
        //   }\n\n${ArrPurchase}\n✅\tTotalAmount = ${totalPurchase}$\n\nTotalAmount = ${totalPurchase}$\n\n🗓 Date:${moment(
        //     updatePurchase?.data?.created_At
        //   ).format("DD/MM/YYYY")}\n\n 📌 Siem Reap \n\n 📲 Tel: 085 883 884 `,
        // });
      } else {
        setCheckMessage("error");
        setMessage(updatePurchase?.message);
        setAlert(true);
        setLoading(false);
      }
    },

    onError: ({ addError }) => {
      setCheckMessage("error");
      setMessage(addError.message);
      console.log("message", addError.message);
      setAlert(true);
      setLoading(false);
    },
  });

  // formik
  const AddStorage = Yup.object().shape({
    remark: Yup.string(),
    need_Date: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      remark: editData?.remark,
      need_Date: editData?.need_Date,
      priority: editData?.priority,
      storage_Room_Id: roomId,
      supplier_id: suppliers?.id,
      items: item,
    },

    validationSchema: AddStorage,
    onSubmit: (values) => {
      let update = false;

      if (item?.length !== 0) {
        const items = item;
        items.map((i) => {
          if (
            i.product_Id !== "" &&
            i.product_Id !== undefined &&
            i.product_name !== "" &&
            i.product_name !== undefined &&
            i.qty !== 0 &&
            i.qty > 0 &&
            i.unit_Price >= 0 &&
            i.unit_Price !== undefined &&
            duplicate !== true
          ) {
            update = true;
          } else {
            update = false;
            setCheckFieldRequried(true);
            return;
          }
        });
      } else {
        update = false;
        setCheckFieldRequried(true);
        return;
      }

      if (update) {
        setLoading(true);

        const newValue = {
          need_Date: values?.need_Date,
          remark: values?.remark,
          priority: values?.priority,
          storage_Room_Id: values?.storage_Room_Id,
          supplier_id: values?.supplier_id,
          items: item,
        };

        if (checkButton === "update") {
          updatePurchase({
            variables: {
              purchaseId: editData?._id,
              input: {
                ...newValue,
              },
            },
          });
        } //if
      } //if
    },
  });

  // console.log("editData?._id", editData?._id, suppliers, roomId)
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box className="purchase">
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" className="title">
              UPDATE PURCHASE
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            <IconButton onClick={handleClose}>
              <ClearIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>

          {/*------------------------------------------*/}

          <Box sx={{ width: "100%" }}>
            <Stack direction="row" spacing={2}>
              <TableContainer className="sub-title" width="50%">
                <TableCell className="sub-title" width="5%">
                  Priority:
                </TableCell>

                <TableCell className="sub-title" width="45%">
                  <FormControl
                    className="form-controll-select"
                    size="small"
                    fullWidth
                  >
                    <Select
                      IconComponent={(props) => (
                        <KeyboardArrowDownOutlinedIcon {...props} />
                      )}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      {...getFieldProps("priority")}
                      placeholder="choose"
                    >
                      <MenuItem value="Meduim">
                        <Stack direction="row" spacing={1}>
                          <FiberManualRecordIcon
                            sx={{ color: "#2AEA00", width: "17px" }}
                          />
                          <Typography sx={{ fontFamily: "Century Gothic" }}>
                            Meduim
                          </Typography>
                        </Stack>
                      </MenuItem>

                      <MenuItem value="Urgent">
                        <Stack direction="row" spacing={1}>
                          <PriorityHighIcon
                            sx={{ color: "red", width: "17px" }}
                          />
                          <Typography sx={{ fontFamily: "Century Gothic" }}>
                            Urgent
                          </Typography>
                        </Stack>
                      </MenuItem>

                      <MenuItem value="Low">
                        <Stack direction="row" spacing={1}>
                          <NotificationsActiveIcon
                            sx={{ color: "red", width: "17px" }}
                          />
                          <Typography sx={{ fontFamily: "Century Gothic" }}>
                            Low
                          </Typography>
                        </Stack>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableContainer>

              <Box sx={{ flexGrow: 1 }} />

              <TableContainer className="sub-title" width="50%">
                <TableCell className="sub-title" width="5%">
                  Date:
                </TableCell>

                <TableCell className="sub-title" width="45%">
                  <LocalizationProvider
                    className="date-controll"
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      value={values?.need_Date}
                      onChange={(e) => setFieldValue("need_Date", e)}
                      renderInput={(params) => (
                        <TextField
                          className="text-field"
                          size="small"
                          {...params}
                          type="date"
                          fullWidth
                          error={Boolean(touched.need_Date && errors.need_Date)}
                          helperText={touched.need_Date && errors.need_Date}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </TableCell>
              </TableContainer>
            </Stack>
          </Box>

          <Box sx={{ display: "flex", width: "50%" }}>
            <TableContainer className="sub-title">
              <TableCell className="sub-title" width="5%">
                Suppliers:
              </TableCell>
              <TableCell className="sub-title" width="45%">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  options={suppliers}
                  value={editData?.supplier_id?.name}
                  fullWidth
                  onChange={(e, value) => {
                    console.log("value", value);
                    setFieldValue("supplier_id", value?.id);
                    // setSupplierId({ label: value?.label, id: value?.id });
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      placeholder="Choose"
                      size="small"
                      {...params}
                    />
                  )}
                />
              </TableCell>
            </TableContainer>
          </Box>

          <TableContainer className="container">
            <Table className="table" aria-label="simple table">
              <TableHead>
                <TableRow className="header-row">
                  <TableCell className="header-title" align="left">
                    Product Name
                  </TableCell>
                  <TableCell className="header-title" align="center">
                    Qty
                  </TableCell>
                  <TableCell
                    className="header-title"
                    align="center"
                    colSpan={2}
                  >
                    Unit Price
                  </TableCell>
                  <TableCell className="header-title" align="right">
                    <IconButton onClick={hanleAddProduct}>
                      <AddCircleOutlineRoundedIcon sx={{ color: "green" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>

              <UpdateListPurchase
                items={item}
                deleteItem={deleteItem}
                setUpdateQty={setUpdateQty}
                setUpdateProduct={setUpdateProduct}
                setUpdateUnit_Price={setUpdateUnit_Price}
                checkFieldRequired={checkFieldRequired}
                duplicate={duplicate}
              />
            </Table>
          </TableContainer>

          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Typography sx={{ fontFamily: "Century Gothic" }}>
              Exchange Rate: {editData?.exchange_rate ? editData?.exchange_rate : 0} KHR/USD
            </Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Typography className="textfield">Total Amount:</Typography>

            {setUpdateTotalAmount ? (
              <NumberFormat
                value={totalAmount !== NaN ? totalAmount?.toFixed(2) : 0.0}
                thousandSeparator={true}
                displayType={"text"}
                renderText={(value, props) => (
                  <div className="body" {...props} style={{width: "110px" , textAlign: "right"}}>
                    $ {value}
                  </div>
                )}
              />
            ) : null}
          </Stack>

          {
            editData?.exchange_rate ?
              <Stack direction="row" spacing={2}> 
                <Box sx={{ flexGrow: 1 }}></Box>
                <Typography className="textfield">Total Amount (KHR):</Typography>

                { setUpdateTotalAmount ? (
                  <NumberFormat
                    value={totalAmount !== NaN ? (totalAmount*editData?.exchange_rate)?.toFixed(0) : 0.0}
                    thousandSeparator={true}
                    displayType={"text"}
                    renderText={(value, props) => (
                      <div className="body" {...props} style={{width: "110px" , textAlign: "right"}}>
                        ៛ {value}
                      </div>
                    )}
                  />
                ) : null}
              </Stack>
            : null
          }
          


          <Stack direction="column" spacing={1} sx={{ mt: 2 }} width="100%">
            <Typography className="field"> Remark </Typography>
            <TextField
              className="text-field"
              multiline
              rows={3}
              size="small"
              fullWidth
              placeholder="remark"
              {...getFieldProps("remark")}
              error={Boolean(touched.remark && errors.remark)}
              helperText={touched.remark && errors.remark}
            />
          </Stack>
          {loading ? (
            <Button
              className="btn-create"
              variant="text"
              fullWidth
              sx={{ mt: 2 }}
            >
              Loading...
            </Button>
          ) : (
            <Button
              className="btn-create"
              variant="text"
              fullWidth
              type="submit"
              sx={{ mt: 2 }}
            >
              {checkButton}
            </Button>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
}
