import React, { useEffect, useState } from "react";
import "./modalreceive.scss";
import {
  Box,
  Stack,
  Button,
  Typography,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TableContainer,
} from "@mui/material";
import moment from "moment";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

// icon priority
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

//component
import { RECIEVE_PURCHASE } from "../../Schema/purchase";
import { useMutation } from "@apollo/client";
import ListCheckProduct from "./ListCheckProduct";
import { sendMessage } from "../Telegram/TelegrameClient";
import NumberFormat from "react-number-format";

export default function ModalReceive({
  roomId,
  handleClose,
  editData,
  setRefetch,
  setAlert,
  setMessage,
  checkButton,
  setCheckMessage,
}) {
  const [checkFieldRequired, setCheckFieldRequried] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [currentItem, setCurrentItem] = useState({
    product_Id: "",
    product_name: "",
    qty: 0,
    unit_Price: 0,
    expire_At: "",
    key: "",
  });

  // console.log(editData);

  const [item, setItem] = useState([]);
  //---------------render----------------
  useEffect(() => {
    if (editData?.items) {
      let rows = [];
      editData?.items?.map((e) => {
        console.log("e?.expire_At", moment(e?.expire_At));

        const allRow = {
          product_Id: e?.product_Id?._id,
          product_name: e?.product_Id?.name,
          qty: e?.qty,
          unit_Price: e?.unit_Price,
          expire_At: moment(e?.expire_At),
          key: e?.key,
        };
        rows.push(allRow);
      });
      setItem(rows);
    }
  }, [editData]);

  const addItem = () => {
    const newItem = currentItem;
    if (newItem.product_Id !== "") {
      const items = [...item, newItem];
      setItem([...items]);
      setCurrentItem({
        product_Id: "",
        product_name: "",
        qty: 0,
        unit_Price: 0,
        expire_At: "",
        key: "",
      });
    }
  };

  const hanleAddProduct = () => {
    setCurrentItem({
      product_Id: "id",
      product_name: "choose",
      qty: 1,
      unit_Price: 0,
      expire_At: "",
      key: Date.now(),
    });
  };

  useEffect(() => {
    if (currentItem?.product_Id !== "") {
      addItem();
    }
  }, [currentItem]);

  const deleteItem = (key) => {
    const filteredItems = item?.filter((t) => t.key !== key);
    setItem(filteredItems);
  };

  const setUpdateProduct = (product_Id, product_name, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.product_Id = product_Id;
        i.product_name = product_name;
      }
    });
    setItem([...items]);
  };

  const setUpdateQty = (qty, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.qty = parseFloat(qty);
      }
    });
    setItem([...items]);
    setUpdateTotalAmount();
  };

  const setUpdateUnit_Price = (unit_Price, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.unit_Price = parseFloat(unit_Price);
      }
    });
    setItem([...items]);
    setUpdateTotalAmount();
  };

  const setUpdateExpire_At = (expire_At, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.expire_At = moment(expire_At);
      }
    });
    setItem([...items]);
  };

  const setUpdateTotalAmount = () => {
    const items = item;
    let totalAmounts = 0;
    items.map((i) => {
      totalAmounts += i?.qty * i?.unit_Price;
    });

    setTotalAmount(totalAmounts);
  };

  useEffect(() => {
    if (editData?.items?.lenght !== 0) {
      let amount = 0;

      editData?.items?.map((element) => {
        amount += element?.qty * element?.unit_Price;
      });
      setTotalAmount(amount);
    }
  }, [editData?.items]);

  const [receivePuchaseingProduct] = useMutation(RECIEVE_PURCHASE, {
    onCompleted: async ({ receivePuchaseingProduct }) => {
      if (receivePuchaseingProduct?.status === true) {
        setCheckMessage("success");
        setMessage(receivePuchaseingProduct?.message);
        setAlert(true);
        setRefetch();
        handleClose();
        setLoading(false);

        let ArrPurchase = "";
        let totalPurchase = 0;

        receivePuchaseingProduct?.data?.items?.map((e, index) => {
          ArrPurchase +=
            index +
            1 +
            "-\t" +
            e?.product_Id?.name +
            "\t" +
            e?.qty +
            "\t x \t" +
            e?.unit_Price +
            "$" +
            "\n";
          totalPurchase += e?.qty * e?.unit_Price;
        });

        await sendMessage({
          content: `<b>[Receive Purchasing Product]</b>\n\n👩‍🚀 N°: ${
            receivePuchaseingProduct?.data?.numbering
          }\n\n ${ArrPurchase}\n✅\tTotalAmount = ${totalPurchase}$\n\n🗓 Date:${moment(
            receivePuchaseingProduct?.data?.created_At
          ).format("DD/MM/YYYY")}\n`,
        });
      } else {
        setCheckMessage("error");
        setMessage(receivePuchaseingProduct?.message);
        setAlert(true);
        setLoading(false);
      }
    },
    onError: (addError) => {
      setCheckMessage("error");
      setMessage(addError.message);
      setAlert(true);
      setLoading(false);
    },
  });

  // formik
  const AddStorage = Yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      _id: editData?._id,
      receive_Date: editData?.need_Date,
      remark: editData?.remark,
      storage_Room_Id: roomId,
      items: item,
    },

    validationSchema: AddStorage,

    onSubmit: (values) => {

      setLoading(true);
      
      let receive = false;       
      const BreakException = {};
      try {          
          if (item?.length !== 0) {
            const items = item;
            items?.forEach(function(elem) {
              if ( elem.qty !== 0 && elem.qty > 0 && elem.unit_Price >= 0 && elem.unit_Price !== undefined ) {
                receive = true;
              } else {
                receive = false;
                setLoading(false);
                setCheckFieldRequried(true);
                throw BreakException;
              }
            });
          } else {
            receive = false;
            setCheckFieldRequried(true);
            throw BreakException;
          }
          
      } catch (e) {
          if (e !== BreakException) throw e;
      }


      if (receive) {
          const newValue = {
            _id: values?._id,
            remark: values?.remark,
            storage_Room_Id: roomId,
            receive_Date: values?.receive_Date,
            items: item,
          };

          console.log("newValue", newValue);
          if (checkButton === "received") {
            receivePuchaseingProduct({
              variables: {
                input: {
                  ...newValue,
                },
              },
            });
          } 
      } 
    },
  });

  console.log("", editData);

  const { handleSubmit, setFieldValue, values, getFieldProps } = formik;

  useEffect(() => {
    if (editData) {
      setFieldValue("receive_Date", editData?.need_Date);
    }
  }, [editData]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box className="recieve">
          <Stack direction="row" spacing={5}>
            <Stack direction="column" justifyContent="center">
              <Typography className="title" variant="h6">
                RECIEVE PURCHASE
              </Typography>
            </Stack>

            <Box sx={{ flexGrow: 1 }}></Box>

            <IconButton onClick={() => handleClose()}>
              <ClearIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>

          <Stack direction="row" spacing={2} width="100%">
            <Stack direction="column" width="50%">
              <Typography className="text-typography">Room</Typography>
              <Box
                sx={{
                  backgroundColor: "#d2ecfb",
                  height: "40px",
                  padding: "10px",
                }}
              >
                <Typography className="text-list">
                  {editData?.storage_Room_Id?.name}
                </Typography>
              </Box>
            </Stack>

            <Stack direction="column" width="50%">
              <Typography className="text-typography">Priority</Typography>
              <Box
                sx={{
                  backgroundColor: "#d2ecfb",
                  height: "40px",
                  padding: "10px",
                }}
              >
                <Stack direction="row" spacing={2}>
                  {editData?.priority === "Meduim" ? (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "center" }}
                      spacing={1}
                    >
                      <FiberManualRecordIcon
                        sx={{ color: "green", width: "15px" }}
                      />
                      <Typography className="sub-title">
                        {editData?.priority}
                      </Typography>
                    </Stack>
                  ) : editData?.priority === "Urgent" ? (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "center" }}
                      spacing={1}
                    >
                      <PriorityHighIcon sx={{ color: "red", width: "15px" }} />
                      <Typography className="sub-title">
                        {editData?.priority}
                      </Typography>
                    </Stack>
                  ) : editData?.priority === "Low" ? (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "center" }}
                      spacing={1}
                    >
                      <NotificationsActiveIcon
                        sx={{ color: "red", width: "15px" }}
                      />
                      <Typography className="sub-title">
                        {editData?.priority}
                      </Typography>
                    </Stack>
                  ) : null}
                  <Box sx={{ flexGrow: 1 }}></Box>
                  <KeyboardArrowDownOutlinedIcon />
                </Stack>
              </Box>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            className="list-style"
            mt={2}
            width="100%"
          >
            <Stack direction="column" className="list-item" width="50%">
              <Typography className="text-typography">Supplier</Typography>
              <Box
                sx={{
                  backgroundColor: "#d2ecfb",
                  height: "40px",
                  padding: "10px",
                }}
              >
                <Typography variant="body">
                  {editData?.supplier_id?.name}
                </Typography>
              </Box>
            </Stack>

            <Stack direction="column" className="list-item" width="50%">
              <Stack direction="row">
                <Typography className="text-typography">
                  Receive Date
                </Typography>
              </Stack>

              <Box
                sx={{
                  backgroundColor: "#d2ecfb",
                  // height: "40px",
                  // padding: "10px",
                }}
              >
                {/* <Stack direction="row" spacing={2}>
                  <Typography className="sub-title">
                    {moment(editData?.need_Date).format("DD-MM-YYYY")}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>
                  <EventIcon className="box-icon" />
                </Stack> */}

                <LocalizationProvider
                  className="date-controll"
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    value={values.receive_Date}
                    onChange={(e) => {
                      // console.log("e", e);
                      setFieldValue(
                        "receive_Date",
                        moment(e).format("YYYY-MM-DD")
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="selection"
                        {...getFieldProps("receive_Date")}
                        size="small"
                        {...params}
                        type="date"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Stack>
          </Stack>

          <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
            <Typography className="header-title"> Remark</Typography>
            <TextField
              // className="text-field"
              multiline
              rows={2}
              size="small"
              fullWidth
              placeholder="Other"
              value={editData?.remark}
            />
          </Stack>

          <Box className="container" sx={{ mt: 2, mb: 2 }}>
            <TableContainer>
              <Table className="table" aria-label="simple table">
                <TableHead>
                  <TableRow className="header-row">
                    <TableCell className="header-title">Product Name</TableCell>
                    <TableCell className="header-title" align="center">
                      Qty
                    </TableCell>
                    <TableCell className="header-title" align="center">
                      Unit Price
                    </TableCell>
                    <TableCell className="header-title" align="center">
                      Expire At
                    </TableCell>
                    <TableCell
                      className="header-title"
                      align="right"
                      width="10px"
                    >
                      <IconButton 
                          // onClick={hanleAddProduct}
                      >
                        <AddCircleOutlineRoundedIcon sx={{ color: "green" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <ListCheckProduct
                  editData={editData}
                  items={item}
                  deleteItem={deleteItem}
                  setUpdateQty={setUpdateQty}
                  setUpdateProduct={setUpdateProduct}
                  setUpdateUnit_Price={setUpdateUnit_Price}
                  setUpdateExpire_At={setUpdateExpire_At}
                  checkFieldRequired={checkFieldRequired}
                />
              </Table>
            </TableContainer>

            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Typography className="body-title">Total Amount:</Typography>
              <NumberFormat
                value={totalAmount?.toFixed(2)}
                thousandSeparator={true}
                displayType={"text"}
                renderText={(value, props) => (
                  <div className="body" {...props}>
                    $ {value}
                  </div>
                )}
              />
            </Stack>
            {loading ? (
              <Button
                className="btn-create"
                variant="text"
                fullWidth
                sx={{ mt: 2 }}
              >
                Loading...
              </Button>
            ) : (
              <Button
                className="btn-create"
                variant="text"
                fullWidth
                type="submit"
                sx={{ mt: 2 }}
              >
                {checkButton}
              </Button>
            )}
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}
