import React, { useEffect, useState } from "react";
import "./inventorySammary.scss";
import { Box, Typography, Stack, Grid, Skeleton } from "@mui/material";
import Boxes from "../../../Assets/new-dashboard/boxes.png";
import trolley from "../../../Assets/new-dashboard/trolley.png";
import { GET_QTYINHAND, GET_QTY_RECIEVED, GET_TOTAL_COST } from "../../../Schema/dashboard";
import { useQuery } from "@apollo/client";
import NumberFormat from "react-number-format";
import Money from "../../../Assets/new-dashboard/money-bag.png";

export default function InventorySammary() {

  const [loading, setLoading] = useState(true);

  const { data , refetch: refetchQty } = useQuery(GET_QTYINHAND, {
    onCompleted: () => {
      setLoading(false);
    },

    onError: () => {
      setLoading(true);
    },
  });


  const { data: DataCost , refetch } = useQuery(GET_TOTAL_COST, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: (error) => {
      setLoading(true);
    },
  });


  useEffect( () => {
    refetchQty()
    refetch()
  },[])

  // const { data: DataQtyRecieved } = useQuery(GET_QTY_RECIEVED, {
  //   onCompleted: () => {
  //     setLoading(false);
  //   },

  //   onError: () => {
  //     setLoading(true);
  //   },
  // });

  if (loading) {
    return (
      <Box className="invetory-dashboard">
        <Box sx={{ width: "100%" }}>
          <Stack direction="column" spacing={1}>
            <Typography variant="body2" className="main-title">
              <Skeleton height={20} width="40%" variant="rectangular" />
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} mt={2}>
            <Skeleton height={100} width="100%" variant="rectangular" />
            <Skeleton height={100} width="100%" variant="rectangular" />
          </Stack>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="invetory-dashboard">
      <Typography className="main-title">Inventory Summary</Typography>
      <Box className="grid-group">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className="card-sammary">
              <Stack direction="row" justifyContent="left" width="40%">
                <img src={`${Boxes}`} width="60px" height="60px" />
              </Stack>
              <Stack direction="row" justifyContent="center" width="60%">
                <Stack direction="column" justifyContent="center">
                  <Typography className="sub-title">
                    Quantity In Hand
                  </Typography>
                  <Typography className="number" variant="h5">
                    {(data?.getQtyInHand)?.toFixed(2)}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          
        
          <Grid item xs={12} sm={12} md={6}>
            <Box className="card-sammary">
              <Stack direction="row" justifyContent="left" width="40%">
                <img src={`${Money}`} width="60px" height="60px" />
              </Stack>
              <Stack direction="row" justifyContent="center" width="60%">
                <Stack direction="column" justifyContent="center">
                  <Typography className="sub-title">
                    Cost In hand
                  </Typography>
                  <Typography className="number" variant="h5">
                   
                    {
                      <NumberFormat
                        value={DataCost?.getTotalCostOnHand ? DataCost?.getTotalCostOnHand?.toFixed(2) : 0.00}
                        thousandSeparator={true}
                        displayType={"text"}
                        renderText={(value, props) => (
                          <div {...props}>$ {value}</div>
                        )}
                      />
                    }
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>


        </Grid>
      </Box>
    </Box>
  );
}
