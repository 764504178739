import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import AlertMessage from "../Component/AlertMessage/AlertMessage";

import React, { useRef, useState, useEffect } from "react";
import "./report.scss";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import DateRangeIcon from "@mui/icons-material/DateRange";
import moment from "moment";
import SummaryReport from "../Component/Report/SummaryReport";
import SummaryReportPrint from "../Component/Report/SummaryReportPrint";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
import StockInReport from "../Component/Report/StockInReport";
import StockInReportPrint from "../Component/Report/StockInReportPrint"
import StockOutReport from "../Component/Report/StockOutReport";
import StockOutReportPrint from "../Component/Report/StockOutReportPrint";
import ProductExpire from "../Component/Report/ProductExpire";
import { useReactToPrint } from "react-to-print";
import Profile from "../Component/Menu/Profile";
import StockOnHandReport from "../Component/Report/StockOnHandReport";
import StockOnHandReportPrint from "../Component/Report/StockOnHandReportPrint";
import { GET_ALL_PRODUCT } from "../Schema/product";
import { useQuery } from "@apollo/client";
import { GET_CATEGORIES_PRODUCT } from "../Schema/product";



export default function Report() {
  // AlertMessage
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [checkMessage, setchekMessage] = useState("");

  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");

  const [typeReport, setTypeReport] = React.useState("summary_report");
  const [dateReport, setDateReport] = React.useState("this_month");

  const [allProduct, setAllProduct] = useState([]);
  const [productId, setProductId] = useState("");
  const [category, setCategory] = useState("");

  const [categoryPro, setCategoryPro] = useState([]);
  const { refetch: refetchCategory } = useQuery(GET_CATEGORIES_PRODUCT, {
    onCompleted: ({ getCategorise }) => {
      setCategoryPro(getCategorise);
    },
  });
  useEffect(() => {
    refetchCategory();
  }, []);

  //expire product
  const [fieldValue, setFieldValue] = useState("NearingExpiration");

  const { data: dataAllProduct } = useQuery(GET_ALL_PRODUCT, {
    onCompleted: ({ getAllProduct }) => {
      // console.log(getAllProduct)
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (dataAllProduct?.getAllProduct) {
      let rows = [];
      dataAllProduct?.getAllProduct?.forEach((element) => {
        const allrow = {
          label: element?.name,
          id: element?._id,
        };
        rows.push(allrow);
      });
      setAllProduct(rows);
    }
  }, [dataAllProduct?.getAllProduct]);

  // Date
  const now = new Date();

  function getFirstDayOfWeek(d) {
    // 👇️ clone date object, so we don't mutate it
    const date = new Date(d);
    const day = date.getDay(); // 👉️ get day of week
    // console.log("day", day)
    // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
    const diff = date.getDate() - day;

    return new Date(date.setDate(diff));
  }

  function getLastDayOfWeek(d) {
    // 👇️ clone date object, so we don't mutate it
    const date = new Date(d);
    const day = date.getDay(); // 👉️ get day of week

    const diff = date.getDate() - day + 6;

    return new Date(date.setDate(diff));
  }

  // ===============================================================================================

  //for print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  React.useEffect(() => {
    if (dateReport === "all") {
      setFromDate("");
      setToDate("");
    } else if (dateReport === "today") {
      setFromDate(moment(now).format("YYYY-MM-DD"));
      setToDate(moment(now).format("YYYY-MM-DD"));
    } else if (dateReport === "this_week") {
      // setFromDate(getFirstDayOfWeek(now))
      // setToDate(getLastDayOfWeek(now))
      setFromDate(moment(getFirstDayOfWeek(now)).format("YYYY-MM-DD"));
      setToDate(moment(getLastDayOfWeek(now)).format("YYYY-MM-DD"));
    } else if (dateReport === "this_month") {
      // setFromDate(new Date(now.getFullYear(), now.getMonth(), 1))
      // setToDate(new Date(now.getFullYear(), now.getMonth() + 1, 0))
      setFromDate(
        moment(new Date(now.getFullYear(), now.getMonth(), 1)).format(
          "YYYY-MM-DD"
        )
      );
      setToDate(
        moment(new Date(now.getFullYear(), now.getMonth() + 1, 0)).format(
          "YYYY-MM-DD"
        )
      );
    } else if (dateReport === "this_year") {
      // setFromDate(new Date(new Date().getFullYear(), 0, 1))
      // setToDate(new Date(new Date().getFullYear(), 11, 31))
      setFromDate(
        moment(new Date(new Date().getFullYear(), 0, 1)).format("YYYY-MM-DD")
      );
      setToDate(
        moment(new Date(new Date().getFullYear(), 11, 31)).format("YYYY-MM-DD")
      );
    } else if (dateReport === "last_week") {
      const currentDate = moment().subtract(1, "weeks");
      let weekStart = currentDate.clone().startOf("week");
      let weekEnd = currentDate.clone().endOf("week");

      // setFromDate(weekStart)
      // setToDate(weekEnd)
      setFromDate(moment(weekStart).format("YYYY-MM-DD"));
      setToDate(moment(weekEnd).format("YYYY-MM-DD"));
    } else if (dateReport === "last_month") {
      // setFromDate(new Date(now.getFullYear(), now.getMonth() - 1, 1))
      // setToDate(new Date(now.getFullYear(), now.getMonth() , 0))
      setFromDate(
        moment(new Date(now.getFullYear(), now.getMonth() - 1, 1)).format(
          "YYYY-MM-DD"
        )
      );
      setToDate(
        moment(new Date(now.getFullYear(), now.getMonth(), 0)).format(
          "YYYY-MM-DD"
        )
      );
    } else if (dateReport === "last_year") {
      // setFromDate(new Date(new Date().getFullYear() -1 , 0, 1))
      // setToDate(new Date(new Date().getFullYear() -1 , 11, 31))
      setFromDate(
        moment(new Date(new Date().getFullYear() - 1, 0, 1)).format(
          "YYYY-MM-DD"
        )
      );
      setToDate(
        moment(new Date(new Date().getFullYear() - 1, 11, 31)).format(
          "YYYY-MM-DD"
        )
      );
    }
  }, [dateReport]);

  // //click setlocalStorage
  const handleClickMenuLocal = (e) => {
    localStorage.setItem("menuVal", JSON.stringify(e));
  };
  // //get value from localstorage
  // const menuVal = JSON.parse(localStorage.getItem("menuVal"));

  // console.log("menuVal", menuVal);

  useEffect( () => {
    if(typeReport === "summary_report") {
      setDateReport("this_month");
    } else {
      setDateReport("all");
    }
  },[typeReport])

  return (
    <div className="report-page">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="color"> Report </Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }}></Box>
        <Profile />
      </Stack>

      <Box className="container">
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} sm={12} md={3} lg={2.4}>
            <FormControl className="selected-from" size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>

              <Select
                className="field"
                value={typeReport}
                onChange={(e) => {
                  // console.log("values", e.target.value);
                  setTypeReport(e.target.value);
                }}
                onClick={() => handleClickMenuLocal(typeReport)}
                label="Type"
                defaultValue={"expire-product"}
              >
                <MenuItem
                  sx={{ fontFamily: "Century Gothic" }}
                  value="summary_report"
                >
                  Summary Report
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: "Century Gothic" }}
                  value="stock_onhand_report"
                >
                  Stock On Hand Report
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: "Century Gothic" }}
                  value="stock_in_report"
                >
                  Stock In Report
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: "Century Gothic" }}
                  value="stock_out_report"
                >
                  Stock Out Report
                </MenuItem>

                <MenuItem
                  sx={{ fontFamily: "Century Gothic" }}
                  value="expire-product"
                >
                  Expire Product
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Box flexGrow={1}/>

          {typeReport === "expire-product" ? (
            <>             
              <Grid item xs={12} sm={12} md={3} lg={2.4}>
                <Stack direction="row" spacing={1}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    className="selected-from"
                    options={allProduct}
                    fullWidth
                    onChange={(e, value) => {
                      setProductId(value?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="field"
                        sx={{ width: "100%" }}
                        {...params}
                        label="Product"
                        placeholder="Choose product"
                        size="small"
                      />
                    )}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={2.4}>
                <FormControl className="selected-from" size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    className="field"
                    defaultValue={"NearingExpiration"}
                    onChange={(e) => setFieldValue(e.target.value)}
                    label="Type"
                  >
                    <MenuItem
                      sx={{ fontFamily: "Century Gothic" }}
                      value="NearingExpiration"
                    >
                      NearingExpiration
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "Century Gothic" }}
                      value="Expired"
                    >
                      Expired
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </>
          ) : (
            <>
              {typeReport === "stock_onhand_report" ||
              typeReport === "stock_out_report" ||
              typeReport === "stock_in_report" ? (
                <>
                  <Grid item xs={12} sm={12} md={3} lg={2.4}>
                    <Stack direction="row" spacing={1}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        className="selected-from"
                        options={allProduct}
                        fullWidth
                        onChange={(e, value) => {
                          setProductId(value?.id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="field"
                            sx={{ width: "100%" }}
                            {...params}
                            label="Product"
                            placeholder="Choose product"
                            size="small"
                          />
                        )}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2.4}>
                    <FormControl
                      className="selected-from"
                      size="small"
                      fullWidth
                    >
                      <InputLabel id="demo-simple-select-label">
                        Category
                      </InputLabel>
                      <Select
                        className="field"
                        label="Category"
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <MenuItem
                          sx={{ fontFamily: "Century Gothic" }}
                          value=""
                          defaultValue=""
                        >
                          all
                        </MenuItem>

                        {categoryPro?.map((row, index) => (
                          <MenuItem
                            key={index}
                            sx={{ fontFamily: "Century Gothic" }}
                            value={`${row}`}
                          >
                            {row}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <>                  
                  <Grid item xs={12} sm={12} md={2} lg={2.4}>
                    <FormControl
                      className="selected-from"
                      // disabled={typeReport === "summary_report" ? true : false}
                      size="small"
                      fullWidth
                    >
                      <InputLabel id="demo-simple-select-label">
                        Category
                      </InputLabel>
                      <Select
                        className="field"
                        label="Category"
                        onChange={(e) => {
                          // console.log("e", e);
                          setCategory(e.target.value);
                        }}
                      >
                        <MenuItem
                          sx={{ fontFamily: "Century Gothic" }}
                          value=""
                          defaultValue="all"
                        >
                          all
                        </MenuItem>

                        {categoryPro?.map((row, index) => (
                          <MenuItem
                            key={index}
                            sx={{ fontFamily: "Century Gothic" }}
                            value={`${row}`}
                          >
                            {row}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}


            {
              typeReport === "summary_report" ?
                <>
                  <Grid item xs={12} sm={12} md={2} lg={2.4}>
                    <FormControl
                      className="selected-from"                 
                      size="small"
                      fullWidth
                    >
                      <InputLabel id="demo-simple-select-label">Veiw By</InputLabel>
                      <Select
                        className="field"
                        label="View By"
                        value={dateReport}
                        onChange={(e) => setDateReport(e.target.value)}
                      >                                   
                        <MenuItem
                          sx={{ fontFamily: "Century Gothic" }}
                          value="this_month"
                        >
                          This Month
                        </MenuItem>                 
                        <MenuItem
                          sx={{ fontFamily: "Century Gothic" }}
                          value="last_month"
                        >
                          Last Month
                        </MenuItem>                  
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              : null
            }             
              

              
              <Grid item xs={12} sm={12} md={3} lg={3.8} display="flex">
                <Stack
                  direction="row"
                  spacing={1}
                  className="btn-filter"
                  width="50%"
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                      className="text-field"
                      value={fromDate}
                      onChange={(e) =>
                        setFromDate(moment(e).format("YYYY-MM-DD"))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="text-field"
                          size="small"
                          fullWidth
                          placeholder="DD/MM/YYYY"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DateRangeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>

                <Stack direction="column" justifyContent="center">
                  <Typography variant="body">{" "}To{" "}</Typography>
                </Stack>

                <Stack direction="row" spacing={1} className="btn-filter">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                      className="text-field"
                      value={toDate}
                      onChange={(e) =>
                        setToDate(moment(e).format("YYYY-MM-DD"))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="text-field"
                          size="small"
                          placeholder="DD/MM/YYYY"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DateRangeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
            </>
          )}

        </Grid>
      </Box>

      <Stack direction="row" spacing={1} mt={3}>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          className="btn-report"
          variant="contained"
          onClick={handalePrint}
        >
          Print
        </Button>
      </Stack>
      
      {/* Component to Print */}
      <Box sx={{ height: "30px" }}></Box>


      <Box sx={{ flexGrow: 1 }}>
        {typeReport === "summary_report" ? (
          <SummaryReport
            category={category}
            FromData={fromDate}
            ToData={toDate}
            productId={productId}
          />
        ) : null}

        {typeReport === "stock_in_report" ? (
          <StockInReport
            FromData={fromDate}
            ToData={toDate}
            category={category}
            productId={productId}
            setAlert={setAlert}
            setMessage={setMessage}
            setchekMessage={setchekMessage}
          />
        ) : null}

        {typeReport === "stock_out_report" ? (
          <StockOutReport
            FromData={fromDate}
            ToData={toDate}
            category={category}
            productId={productId}
            setAlert={setAlert}
            setMessage={setMessage}
            setchekMessage={setchekMessage}
          />
        ) : null}

        {typeReport === "stock_onhand_report" ? (
          <StockOnHandReport
            FromData={fromDate}
            ToData={toDate}
            category={category}
            productId={productId}
          />
        ) : null}

        {typeReport === "expire-product" ? (
          <ProductExpire fieldValue={fieldValue} productId={productId} />
        ) : null}
      </Box>
      
      <div style={{display: 'none'}}>
        <Box ref={componentRef} sx={{ flexGrow: 1 }}>
          {typeReport === "summary_report" ? (
            <SummaryReportPrint
              category={category}
              FromData={fromDate}
              ToData={toDate}
              productId={productId}
            />
          ) : null}

          {typeReport === "stock_in_report" ? (
            <StockInReportPrint
              FromData={fromDate}
              ToData={toDate}
              category={category}
              productId={productId}
              setAlert={setAlert}
              setMessage={setMessage}
              setchekMessage={setchekMessage}
            />
          ) : null}

          {typeReport === "stock_out_report" ? (
            <StockOutReportPrint
              FromData={fromDate}
              ToData={toDate}
              category={category}
              productId={productId}
              setAlert={setAlert}
              setMessage={setMessage}
              setchekMessage={setchekMessage}
            />
          ) : null}

          {typeReport === "stock_onhand_report" ? (
            <StockOnHandReportPrint
              FromData={fromDate}
              ToData={toDate}
              category={category}
              productId={productId}
            />
          ) : null}

          {typeReport === "expire-product" ? (
            <ProductExpire fieldValue={fieldValue} productId={productId} />
          ) : null}
        </Box>
      </div>
      {/* Component to Print */}


      <AlertMessage
        alert={alert}
        setAlert={setAlert}
        message={message}
        setMessage={setMessage}
        checkMessage={checkMessage}
      />
    </div>
  );
}

// type
const simpleData = [
  { label: "Food" },
  { label: "Meat" },
  { label: "Drink" },
  { label: "Vegetables" },
  { label: "Fruit" },
  { label: "Cosmetic" },
  { label: "Snacks" },
  { label: "Spices" },
  { label: "Soap" },
  { label: "Ice Cream" },
  { label: "Preserved Food" },
  { label: "Other" },
];
