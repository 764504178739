import React, { useEffect, useState } from "react";
import "./reporttable.scss";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
//logo
import Logo from "../../Assets/GoGlobalFull_Name.png";
import WingdingImage from "../../Assets/tacteing.jpg";
import NumberFormat from "react-number-format";
import { GET_SUMMARY_STOCK } from "../../Schema/report";
import { useQuery } from "@apollo/client";

const viewReport = [
  {
    id: 1,
    productName: "ទឹកពិសារបរិសុទ្ធ​ 500 ml",
    beginining: 0,
    purchasing: 35,
    issues: 2500,
    end_time: 2.35,
  },
  {
    id: 2,
    productName: "ទឹកក្រូច 500 ml",
    beginining: 0,
    purchasing: 5,
    issues: 2500,
    end_time: 2,
  },
  {
    id: 3,
    productName: "ទឹកក្រូច 250 ml",
    beginining: 0,
    purchasing: 10,
    issues: 1508,
    end_time: 7,
  },
  {
    id: 4,
    productName: "មីសសៃរធំ",
    beginining: 0,
    purchasing: 10,
    issues: 2500,
    end_time: 2.35,
  },
  {
    id: 5,
    productName: "ដំឡូង",
    beginining: 0,
    issues: 2500,
    purchasing: 20,
    end_time: 0.5,
  },
];

export default function SummaryReportPrint({ FromData, ToData, category }) {
   
  const [dataReport, setDataReport] = React.useState([]);

  const [openBalance,setOpenBalance] = useState(0);
  const [amountPurchasing, setAmountPurchasing] = React.useState(0);
  const [amountIssues, setAmountIssues] = React.useState(0);
  const [amountEnding, setAmountEnding] = React.useState(0);


  const { data , refetch } = useQuery(GET_SUMMARY_STOCK, {
    variables: {
      catagory: category !== undefined && category !== "" ? category : "",
      from: FromData !== undefined && FromData !== "" ? FromData : "",
      to: ToData !== undefined && ToData !== "" ? ToData : "",
    },
    onCompleted: ({ getSummaryStock }) => {
      setDataReport(getSummaryStock);
    },
    onError: (error) => {
      console.log(error.message);
    },
    pollInterval: 10000,
  });

  useEffect( () => {
    refetch()
  },[FromData, ToData, category])

  //

  useEffect(() => {
    if (dataReport?.lenght !== 0) {
      let openBalance = 0;
      let amountPur = 0;
      let amountIssues = 0;
      let amountEnd = 0;

      dataReport?.map((element) => { 
          openBalance += element?.open_balance;                
          amountPur += element?.purchasing;
          amountIssues += element?.issues;
          amountEnd += element?.ending;
      });

      setOpenBalance(openBalance);
      setAmountPurchasing(amountPur);
      setAmountIssues(amountIssues);
      setAmountEnding(amountEnd);
    }
  }, [dataReport]);


  console.log(category, "category")

  return (
    <div className="tableRP">
      <Box className="header-table">
        <Grid container spacing={2} className="grid-containner">
          <Grid item xs={4} className="grid-item">
            <Stack direction="column" justifyContent="right" className="logo">
              <Box sx={{ mt: "58px" }}>
                <img src={Logo} width="130px" height="130px" />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={4} className="grid-item2">
            <Stack className="kingdom" direction="column" spacing={1}>
              <Typography className="title2">ព្រះរាជាណាចក្រកម្ពុជា</Typography>
              <Typography className="title2">
                ជាតិ សាសនា ព្រះមហាក្សត្រ
              </Typography>
              <div className="tackteng-font">
                <img
                  alt="Image"
                  src={WingdingImage}
                  className="image-tackteng"
                />
              </div>
            </Stack>
            <Stack direction="row" justifyContent="center" mt={3.5}>
              <Typography className="title3">
                បញ្ជីឈ្មោះទំនិញសម្រាប់ហ្គោគ្លូប៊លម៉ាត
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <TableContainer className="TableContainerPrint">
        <Table sx={{ minWidth: 650 }}>
          <TableHead className="tbhead">
            <TableRow className="item-row">
              <TableCell className="title" width="8%">
                N°
              </TableCell>
              <TableCell className="title" width="20%" align="center">
                Description
              </TableCell>
              <TableCell className="title" align="left" width="20%">
                Open Balance
              </TableCell>
              <TableCell className="title" align="left" width="20%">
                Purchasing
              </TableCell>
              <TableCell className="title" align="left" width="15%">
                Issues
              </TableCell>
              <TableCell className="title" align="left" width="15%">
                Ending
              </TableCell>
            </TableRow>
          </TableHead>

          {dataReport?.map((row, index) => (
            <TableBody
              key={index}
              className={index % 2 ? "bodytable-odd" : "bodytable"}
            >
              <>
                <TableRow className="tbrow">
                  <TableCell className="tbcell">{index + 1}</TableCell>

                  <TableCell
                    className="tbcell"                  
                  // sx={{ fontWeight: "bold" }}
                  >
                    {row?.category}
                  </TableCell>

                  <TableCell className="tbcell" align="right">
                    <Stack direction="row">
                      $&nbsp;&nbsp;&nbsp;
                      <NumberFormat
                        value={row?.open_balance > 0 ? row?.open_balance?.toFixed(2) : (0)?.toFixed(2) }
                        thousandSeparator={true}
                        displayType={"text"}
                        renderText={(value, props) => (
                          <div {...props}>{value}</div>
                        )}
                      />
                    </Stack>
                  </TableCell>

                  <TableCell className="tbcell" align="right">
                    <Stack direction="row">
                      $&nbsp;&nbsp;&nbsp;
                      <NumberFormat
                        value={row?.purchasing?.toFixed(2)}
                        thousandSeparator={true}
                        displayType={"text"}
                        renderText={(value, props) => (
                          <div {...props}>{value}</div>
                        )}
                      />
                    </Stack>
                  </TableCell>

                  <TableCell className="tbcell" align="right">
                    <Stack direction="row">
                      $&nbsp;&nbsp;&nbsp;
                      <NumberFormat
                        value={row?.issues?.toFixed(2)}
                        thousandSeparator={true}
                        displayType={"text"}
                        renderText={(value, props) => (
                          <div {...props}>{value}</div>
                        )}
                      />
                    </Stack>
                  </TableCell>

                  <TableCell className="tbcell" align="right">
                    <Stack direction="row">
                      $&nbsp;&nbsp;&nbsp;
                      <NumberFormat
                        value={row?.ending > 0 ? row?.ending?.toFixed(2) : (0)?.toFixed(2) }
                        thousandSeparator={true}
                        displayType={"text"}
                        renderText={(value, props) => (
                          <div {...props}>{value}</div>
                        )}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </>
            </TableBody>
          ))}
          <TableBody className="bodyfooter">
            <TableRow className="tbrow">
              <TableCell
                className="total"
                align="right"
                colSpan={2}
                sx={{ fontWeight: "bold", color: "#0000ff" }}
              >
                Total Amount
              </TableCell>

              <TableCell
                className="total"
                align="right"
                sx={{ background: "#FDF2E7", color: "#0000ff" }}
              >
                <Stack direction="row">
                  $&nbsp;&nbsp;&nbsp;
                  <NumberFormat
                    value={openBalance?.toFixed(2)}
                    thousandSeparator={true}
                    displayType={"text"}
                    renderText={(value, props) => <div {...props}>{value}</div>}
                  />
                </Stack>
              </TableCell>

              <TableCell
                className="total"
                align="right"
                sx={{ background: "#FDF2E7", color: "#0000ff" }}
              >
                <Stack direction="row">
                  $&nbsp;&nbsp;&nbsp;
                  <NumberFormat
                    value={amountPurchasing?.toFixed(2)}
                    thousandSeparator={true}
                    displayType={"text"}
                    renderText={(value, props) => <div {...props}>{value}</div>}
                  />
                </Stack>
              </TableCell>
              <TableCell
                className="total"
                align="right"
                sx={{ background: "#FDF2E7", color: "#0000ff" }}
              >
                <Stack direction="row">
                  $&nbsp;&nbsp;&nbsp;
                  <NumberFormat
                    value={amountIssues?.toFixed(2)}
                    thousandSeparator={true}
                    displayType={"text"}
                    renderText={(value, props) => <div {...props}>{value}</div>}
                  />
                </Stack>
              </TableCell>

              <TableCell
                className="total"
                align="right"
                sx={{ background: "#FDF2E7", color: "#0000ff" }}
              >
                <Stack direction="row">
                  $&nbsp;&nbsp;&nbsp;
                  <NumberFormat
                    value={ amountEnding > 0 ? amountEnding?.toFixed(2) : 0.00 }
                    thousandSeparator={true}
                    displayType={"text"}
                    renderText={(value, props) => <div {...props}>{value}</div>}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
