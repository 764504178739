import React, { useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Button,
  Stack,
  Typography,
  Modal,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import "./shop.scss";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
//component
import AlertMessage from "../Component/AlertMessage/AlertMessage";
import CreateShop from "../Component/Shops/CreateShop";
import ShopAction from "../Component/Shops/ShopAction";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
import Profile from "../Component/Menu/Profile";
import { GET_ALL_SHOPS } from "../Schema/shops";
import { useQuery } from "@apollo/client";

export default function Shop() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // AlertMessage
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [checkMessage, setchekMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState([]);

  const [keyword, setKeyword] = React.useState("");

  const { data, refetch } = useQuery(GET_ALL_SHOPS, {
    variables: {
      keyword: keyword,
    },

    onCompleted: () => {
      setLoading(false);
    },

    onError: () => {
      setLoading(true);
    },
  });

  //click setlocalStorage
  const handleClickLocal = (e) => {
    localStorage.setItem("shopId", JSON.stringify(e?._id));
    return null;
  };

  //get value from localstorage
  const shopID = JSON.parse(localStorage.getItem("shopId"));
  

  return (
    <Box className="customer-page">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="page-title">Shop</Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Profile />
      </Stack>

      <Stack direction="row" spacing={2}>
        <Stack direction="column" spacing={2} className="btn-search">
          <Box className="btn-text-field">
            <TextField
              className="text-field"
              fullWidth
              placeholder="Search Name"
              size="small"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="column"
          className="stack-btn"
          justifyContent="right"
          spacing={2}
        >
          <Button
            onClick={handleOpen}
            startIcon={<AddCircleOutlineOutlinedIcon className="icon-add" />}
            className="btn-add"
          >
            <Typography className="btn-text">Create</Typography>
          </Button>

          <Modal open={open}>
            <CreateShop
              open={open}
              modalTitle={"CREATE SHOP"}
              setRefetch={refetch}
              setAlert={setAlert}
              setMessage={setMessage}
              handleClose={handleClose}
              setchekMessage={setchekMessage}
            />
          </Modal>
        </Stack>
      </Stack>

      <Box className="container">
        <TableContainer>
          <Table className="table" aria-label="simple table">
            <TableHead>
              <TableRow className="header-row">
                <TableCell className="header-title">N°</TableCell>
                <TableCell className="header-title">Name</TableCell>
                <TableCell className="header-title">Phone Number</TableCell>
                <TableCell className="header-title">Email</TableCell>
                <TableCell className="header-title">Location</TableCell>
                <TableCell className="header-title">Remark</TableCell>
                <TableCell className="header-title" align="center"></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <>
                <TableHead>
                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={7}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className="header-row">
                    <TableCell className="header-title" colSpan={7}>
                      <Skeleton
                        sx={{ height: 50, width: "100%" }}
                        variant="rectangular"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
              </>
            ) : (
              <>
                {data?.getAllShops?.map((row, index) => (
                  <TableBody
                    key={index}
                    component={Paper}
                    className={shopID === row?._id ? "body-active" : "body-odd"}
                  >
                    <TableRow
                      className="body-row"
                      onClick={() => handleClickLocal(row)}
                    >
                      <TableCell
                        className="body-title"
                        width="3%"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        className="body-title"
                        onClick={() => setData(row)}
                      >
                        {row?.name}
                      </TableCell>
                      <TableCell className="body-title"  onClick={() => setData(row)}>{row?.phone}</TableCell>
                      <TableCell className="body-title"  onClick={() => setData(row)}>{row?.email}</TableCell>
                      <TableCell className="body-title"  onClick={() => setData(row)}>
                        {row?.address}
                      </TableCell>
                      <TableCell className="body-title"  onClick={() => setData(row)}>
                        {row?.remark}
                      </TableCell>
                      <TableCell className="body-title" align="right" onClick={() => setData(row)}>
                        <ShopAction
                          Data={row}
                          setRefetch={refetch}
                          setAlert={setAlert}
                          setMessage={setMessage}
                          setchekMessage={setchekMessage}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </>
            )}
          </Table>
        </TableContainer>
      </Box>

      <AlertMessage
        alert={alert}
        setAlert={setAlert}
        message={message}
        setMessage={setMessage}
        checkMessage={checkMessage}
      />
    </Box>
  );
}
