import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Button,
  Typography,
  InputAdornment,
} from "@mui/material";
// --------------icon priority------------
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
//--------------------------
import "./viewpurchase.scss";
import { useMutation } from "@apollo/client";
import { APPROVE_PURCHASING } from "../../Schema/purchase";
import { sendMessage } from "../Telegram/TelegrameClient";
import moment from "moment";

import { useQuery } from "@apollo/client";
import { GET_USER_LOGIN } from "../../Schema/user";
import NumberFormat from "react-number-format";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext"
import { useLocation } from "react-router-dom";

export default function ViewPurchase({
  handleClose,
  setRefetch,
  setAlert,
  setCheckMessage,
  setMessage,
  DataCell,
}) {

  console.log("DataCell",DataCell)

  const { permissionRoleAccess } = useContext(AuthContext);
  //status check conditoion in telegram
  const [checkStatus, setCheckStatus] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  
  // get id from  url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [roomId, setRoomId] = React.useState(params.get("id"));
  const [roomName, setRoomName] = React.useState(params.get("name"));


  React.useEffect(() => {
    setRoomId(params.get("id"));
    setRoomName(params.get("name"));
  }, [location.search]);
  

  const [approvePurchasing] = useMutation(APPROVE_PURCHASING, {
    onCompleted: async ({ approvePurchasing }) => {
      if (approvePurchasing?.status === true) {
        setCheckMessage("success");
        setMessage(approvePurchasing?.message);
        setAlert(true);
        setRefetch();
        handleClose();
        
        let ArrPurchase = "";
        let totalPurchase = 0;

        approvePurchasing?.data?.items?.map((e, index) => {
          ArrPurchase +=
            index +
            1 +
            "-\t" +
            e?.product_Id?.name +
            "\t" +
            e?.qty +
            "\t x \t" +
            e?.unit_Price +
            "$" +
            "\n";
          totalPurchase += e?.qty * e?.unit_Price;
        });

        {
          checkStatus === "approve"
            ? await sendMessage({
                content: `<b>[Approve Purchasing Product]</b>\n\n👩‍🚀 N°: ${
                  approvePurchasing?.data?.numbering
                }\n\n${ArrPurchase}\n✅\tTotalAmount = ${totalPurchase}$\n\n🗓 Date:${moment(
                  approvePurchasing?.data?.created_At
                ).format("DD/MM/YYYY")}\n`,
              })
            : await sendMessage({
                content: `<b>[Reject Purchasing Product]</b>\n\n👩‍🚀 N°: ${
                  approvePurchasing?.data?.numbering
                }\n\n${ArrPurchase}\n✅\tTotalAmount = ${totalPurchase}$\n\n🗓 Date:${moment(
                  approvePurchasing?.data?.created_At
                ).format("DD/MM/YYYY")}\n`,
              });
        }

        window.history.replaceState(null, "", `/storage-room/purchase?id=${roomId}&name=${roomName}`)

      } else {
        setCheckMessage("error");
        setMessage(approvePurchasing?.message);
        setAlert(true);
      }
    },

    onError: (error) => {
      setCheckMessage("error");
      setMessage(error.message);
      setAlert(true);
    },
  });

  const handleApprovePurchase = () => {
    // setLoading(true);
    approvePurchasing({
      variables: {
        status: "approve",
        purchaseId: DataCell?._id,
      },
    });
  };

  const handleRejectPurchase = () => {
    approvePurchasing({
      variables: {
        status: "rejected",
        purchaseId: DataCell?._id,
      },
    });
  };

  //for print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //total
  useEffect(() => {
    if (DataCell?.items?.lenght !== 0) {
      let amount = 0;

      DataCell?.items?.map((element) => {
        return (amount += element?.qty * element?.unit_Price);
      });

      setTotalAmount(amount);
    }
  }, [DataCell?.items]);

  const { data } = useQuery(GET_USER_LOGIN, {
    onCompleted: ({ getUserLogin }) => {
      console.log(getUserLogin);
    },
  });

  return (
    <>
      <Box className="view-purchase">
        <div ref={componentRef}>
          <div
            style={{
              marginTop: "20px",
              padding: "10px 40px",
            }}
          >
            <Stack direction="row">
              <Typography
                sx={{
                  color: "#0B82C4",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                variant="h6"
              >
                Purchase ID: {DataCell?.numbering}
              </Typography>

              <Box sx={{ flexGrow: 1 }}></Box>

              <IconButton onClick={handleClose}>
                <ClearIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>

            {/* already*/}
            <Stack direction="row" spacing={2} width="100%">
              <Stack direction="column" width="50%">
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Room
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#d2ecfb",
                    padding: "10px",
                  }}
                >
                  <Typography className="text-list">
                    {DataCell?.storage_Room_Id?.name}
                  </Typography>
                </Box>
              </Stack>

              <Stack direction="column" width="50%">
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Priority
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#d2ecfb",
                    padding: "10px",
                  }}
                >
                  <Stack direction="row" spacing={2}>
                    {DataCell?.priority === "Meduim" ? (
                      <Stack
                        direction="row"
                        sx={{ justifyContent: "center" }}
                        spacing={1}
                      >
                        <FiberManualRecordIcon
                          sx={{ color: "#2AEA00", width: "15px" }}
                        />
                        <Typography className="sub-title">
                          {DataCell?.priority}
                        </Typography>
                      </Stack>
                    ) : DataCell?.priority === "Urgent" ? (
                      <Stack
                        direction="row"
                        sx={{ justifyContent: "center" }}
                        spacing={1}
                      >
                        <PriorityHighIcon
                          sx={{ color: "red", width: "15px" }}
                        />
                        <Typography className="sub-title">
                          {DataCell?.priority}
                        </Typography>
                      </Stack>
                    ) : DataCell?.priority === "Low" ? (
                      <Stack
                        direction="row"
                        sx={{ justifyContent: "center" }}
                        spacing={1}
                      >
                        <NotificationsActiveIcon
                          sx={{ color: "red", width: "15px" }}
                        />
                        <Typography className="sub-title">
                          {DataCell?.priority}
                        </Typography>
                      </Stack>
                    ) : null}
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <KeyboardArrowDownOutlinedIcon />
                  </Stack>
                </Box>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              className="list-style"
              mt={2}
              width="100%"
            >
              <Stack direction="column" className="list-item" width="50%">
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Supplier
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#d2ecfb",
                    padding: "10px",
                  }}
                >
                  <Typography className="text-list">
                    {DataCell?.supplier_id?.name}
                  </Typography>
                </Box>
              </Stack>

              <Stack direction="column" className="list-item" width="50%">
                <Stack direction="row">
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Need Date
                  </Typography>
                </Stack>

                <Box
                  sx={{
                    backgroundColor: "#d2ecfb",
                    padding: "10px",
                  }}
                >
                  <Stack direction="row" spacing={2}>
                    <Typography className="text-list">
                      {moment(DataCell?.need_Date).format("DD-MM-YYYY")}
                    </Typography>

                    <Box sx={{ flexGrow: 1 }}></Box>
                    <EventIcon className="box-icon" />
                  </Stack>
                </Box>
              </Stack>
            </Stack>

            {DataCell?.remark === "" ? null : (
              <Stack direction="column" mt={2}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Remark
                </Typography>

                <TextField
                  rows={3}
                  fullWidth
                  variant="outlined"
                  value={DataCell?.remark}
                />
              </Stack>
            )}

            {DataCell?.approve_status === "pending" ? (
              <TableContainer sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          border: "none",
                          fontSize: "15px",
                          color: "#3d3d3d",
                          fontWeight: "bold",
                          padding: "0px",
                          textAlign: "left",
                          fontFamily: "Century Gothic",
                        }}
                      >
                        Product Name
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          fontSize: "15px",
                          color: "#3d3d3d",
                          fontWeight: "bold",
                          padding: "0px",
                          textAlign: "center",
                          fontFamily: "Century Gothic",
                        }}
                      >
                        Qty
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          fontSize: "15px",
                          color: "#3d3d3d",
                          fontWeight: "bold",
                          padding: "0px",
                          textAlign: "center",
                        }}
                      >
                        Unit Price
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          fontSize: "15px",
                          color: "#3d3d3d",
                          fontWeight: "bold",
                          padding: "0px",
                          textAlign: "center",
                        }}
                      >
                        Total
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody sx={{ boxShadow: "none" }}>
                    {DataCell?.items?.map((row, index) => (
                      <TableRow sx={{ backgroundColor: "#d2ecfb" }} key={index}>
                        <TableCell
                          sx={{
                            padding: "10px",
                            border: "none",
                            fontFamily: "Century Gothic",
                            fontSize: "15px",
                            color: "#3d3d3d",
                            fontWeight: "bold",
                            width: "50%",
                          }}
                          component="th"
                          scope="row"
                        >
                          <TextField
                            size="small"
                            fullWidth
                            // value={row?.product_Id?.name+" "+row?.product_Id?.remark}
                            value={row?.product_Id?.name}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "10px",
                            border: "none",
                            fontSize: "17px",
                            fontFamily: "5entury Gothic",
                            color: "#3d3d3d",
                            fontWeight: "bold",
                            width: "15%",
                          }}
                          component="th"
                          align="center"
                        >
                          <TextField 
                            size="small" 
                            fullWidth 
                            value={row?.qty} 
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {row?.product_Id?.unit}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>

                        <TableCell
                          sx={{
                            padding: "10px",
                            border: "none",
                            fontSize: "17px",
                            fontFamily: "5entury Gothic",
                            color: "#3d3d3d",
                            fontWeight: "bold",
                            width: "15%",
                          }}
                          align="center"
                        >
                          
                            <TextField
                              value={row?.unit_Price}
                              size="small"
                              fullWidth
                            />
                          
                        </TableCell>

                        <TableCell
                          sx={{
                            padding: "10px",
                            border: "none",
                            fontSize: "17px",
                            fontFamily: "5entury Gothic",
                            color: "#3d3d3d",
                            fontWeight: "bold",
                            width: "20%",
                          }}
                          align="center"
                        >
                          
                            <TextField
                              value={(row?.unit_Price*row?.qty)?.toFixed(2)}
                              size="small"
                              fullWidth
                            />
                          
                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : DataCell?.approve_status === "approve" || DataCell?.approve_status === "instock" ? (
              <TableContainer sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          border: "none",
                          fontSize: "15px",
                          color: "#3d3d3d",
                          fontWeight: "bold",
                          padding: "0px",
                          textAlign: "left",
                          width: "50%",
                          fontFamily: "Century Gothic",
                        }}
                      >
                        Product Name
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          fontSize: "15px",
                          color: "#3d3d3d",
                          fontWeight: "bold",
                          padding: "0px",
                          textAlign: "center",
                          width: "15%",
                          fontFamily: "Century Gothic",
                        }}
                      >
                        Qty
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          fontSize: "15px",
                          color: "#3d3d3d",
                          fontWeight: "bold",
                          padding: "0px",
                          textAlign: "center",
                          width: "15%",
                          fontFamily: "Century Gothic",
                        }}
                      >
                        Unit Price
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          fontSize: "15px",
                          color: "#3d3d3d",
                          fontWeight: "bold",
                          padding: "0px",
                          textAlign: "center",
                          width: "20%",
                          fontFamily: "Century Gothic",
                        }}
                      >
                        Total
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody sx={{ boxShadow: "none" }}>
                    { DataCell?.items?.map((row, index) => (
                      <TableRow sx={{ backgroundColor: "#d2ecfb" }} key={index}>
                        <TableCell
                          sx={{
                            padding: "10px",
                            border: "none",
                            fontSize: "15px",
                            color: "#3d3d3d",
                            fontWeight: "bold",
                            width: "50%",
                            fontFamily: "Century Gothic",
                          }}
                          component="th"
                          scope="row"
                        >
                          <TextField
                            size="small"
                            fullWidth
                            // value={row?.product_Id?.name+" "+row?.product_Id?.remark}
                            value={row?.product_Id?.name}
                          />
                        </TableCell>

                        <TableCell
                          sx={{
                            padding: "10px",
                            border: "none",
                            fontSize: "15px",
                            color: "#3d3d3d",
                            fontWeight: "bold",
                            width: "15%",
                            fontFamily: "Century Gothic",
                          }}
                          component="th"
                          align="center"
                        >
                          <TextField 
                            size="small" 
                            fullWidth 
                            value={row?.qty} 
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {row?.product_Id?.unit}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>

                        <TableCell
                          sx={{
                            padding: "10px",
                            border: "none",
                            fontSize: "15px",
                            color: "#3d3d3d",
                            fontWeight: "bold",
                            width: "15%",
                            fontFamily: "Century Gothic",
                          }}
                          align="center"
                        >
                        
                            <TextField
                              sx={{ fontFamily: "Century Gothic" }}
                              value={row?.unit_Price}
                              size="small"
                              fullWidth
                            />
                          
                        </TableCell>

                        <TableCell
                          sx={{
                            padding: "10px",
                            border: "none",
                            fontSize: "15px",
                            color: "#3d3d3d",
                            fontWeight: "bold",
                            width: "20%",
                            fontFamily: "Century Gothic",
                          }}
                          align="center"
                        >
                          
                            <TextField
                              sx={{ fontFamily: "Century Gothic" }}
                              value={(row?.unit_Price*row?.qty)?.toFixed(2)}
                              size="small"
                              fullWidth
                            />
                         
                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}

            <Stack direction="row" spacing={1} mt={2}>
              <Typography
                sx={{ fontFamily: "Century Gothic" }}
              >
                Exchange Rate: {DataCell?.exchange_rate ? DataCell?.exchange_rate : 0} KHR / USD
              </Typography> 

              <Box flexGrow={1} />
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Century Gothic" }}
              >
                Total Amount (USD):
              </Typography> 
              <NumberFormat
                value={totalAmount?.toFixed(2)}
                thousandSeparator={true}
                displayType={"text"}
                renderText={(value, props) => (
                  <div className="amount" {...props} style={{width: "110px" , textAlign: "right"}}>
                    $ {value}
                  </div>
                )}
              /> 
            </Stack>

          {
            DataCell?.exchange_rate ?
              <Stack direction="row" spacing={1} justifyContent="right">
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Century Gothic" }}
                >
                  Total Amount (KHR):
                </Typography> 
                <NumberFormat
                  value={(totalAmount*DataCell?.exchange_rate)?.toFixed(0)}
                  thousandSeparator={true}
                  displayType={"text"}
                  renderText={(value, props) => (
                    <div className="amount" {...props} style={{width: "110px" , textAlign: "right"}}>
                      ៛ {value}
                    </div>
                  )}
                /> 
              </Stack>
            : null
          }
            
          </div>
        </div>
        {/*end Print*/}

        <Stack
          direction="row"
          style={{
            marginTop: "20px",
            padding: "10px 40px",
          }}
        >
          {/*button Print*/}
          {/* {DataCell?.approve_status === "approve" ? ( */}
            <Button
              sx={{
                backgroundColor: "#0B82C4 !important",
                color: "white",
                width: "200px",
              }}
              onClick={handalePrint}
            >
              Print
            </Button>
          {/* ) : null} */}

          <Box sx={{ flexGrow: 1 }}></Box>

          { data?.getUserLogin?.role !== "stock_controller" ? (
            <>
              {DataCell?.status === true ? null : DataCell?.approve_status === "approve" ? (
                <Stack direction="row" spacing={1}>
                  <Typography className="style-text">Approved By :</Typography>

                  <Typography className="tage-name">
                    {DataCell?.approve_By?.lastName}
                    {DataCell?.approve_By?.firsName}
                  </Typography>
                </Stack>
              ) : DataCell?.approve_status === "rejected" ? (
                <Stack direction="row" spacing={1}>
                  <Typography className="style-text">Rejected By :</Typography>
                  <Typography className="tage-name">
                    {DataCell?.approve_By?.lastName}
                    {DataCell?.approve_By?.firsName}
                  </Typography>
                </Stack>
              ) : DataCell?.approve_status === "instock" ? (
                <Stack direction="row" spacing={1}>
                  <Typography className="style-text">Revieved By :</Typography>
                  <Typography className="tage-name">
                    {DataCell?.receive_By?.lastName}
                    {DataCell?.receive_By?.firsName}
                  </Typography>
                </Stack>
              ) : DataCell?.approve_status === "pending" && permissionRoleAccess(['admin','superadmin']) ? (
                <Stack
                  className="btn-maker"
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                >
                  <Button
                    className="btn-reject"
                    onClick={() => {
                      handleRejectPurchase();
                      setCheckStatus("rejected");
                    }}
                  >
                    Reject
                  </Button>

                  <Button
                    className="btn-app"
                    onClick={() => {
                      handleApprovePurchase();
                      setCheckStatus("approve");
                    }}
                  >
                    Approve
                  </Button>
                </Stack>
              ) : (
                <Stack direction="row">
                  {DataCell?.approve_status === "approve" ? (
                    <>
                      <Typography className="style-text">
                        Approved By :
                      </Typography>

                      <Typography className="tage-name">
                        {DataCell?.approve_By?.lastName}
                        {DataCell?.approve_By?.firsName}
                      </Typography>
                    </>
                  ) : DataCell?.approve_status === "rejected" ? (
                    <>
                      <Typography className="style-text">
                        Approved By :
                      </Typography>

                      <Typography className="tage-name">
                        {DataCell?.approve_By?.lastName}
                        {DataCell?.approve_By?.firsName}
                      </Typography>
                    </>
                  ) : null}
                </Stack>
              )}
            </>
          ) : null}
        </Stack>
      </Box>
    </>
  );
}
