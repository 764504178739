import * as React from "react";
import Router from "./routes";
import { BrowserRouter as Routers } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { GlobalDebug } from "./Fn/RemoveConsole";
// import { GlobalDebug } from "Fn";
//
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, } from "@apollo/client";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { setContext } from "@apollo/client/link/context";
import AlertMessageInfo from "./Component/AlertMessage/AlertMessageInfo";

function App() {

  //Apollo
  const { state } = useContext(AuthContext);
  const { user } = state;


  // React.useEffect(() => {
  //   (process.env.NODE_ENV === "development") && GlobalDebug(false);
  // }, []);
  

  const httpLink = createHttpLink({
    uri: process.env.React_APP_END_POINT,
    // uri: "http://192.168.2.18:4000/api"
  });
  


  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: user ? user?.token : "",
      },
    };
  });

  // console.log("state", state);

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });


  
  const [prefersDarkMode, setPrefersDarkMode] = React.useState(false);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          background: {
            default: prefersDarkMode ? "#121212" : "#f8f8f8",
          },
        },
        typography: {
          fontFamily: ["Century Gothic", "Siemreap"].join(","),
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Routers>
          <Router
            prefersDarkMode={prefersDarkMode}
            setPrefersDarkMode={setPrefersDarkMode}
          />
          <AlertMessageInfo />
        </Routers>
        <CssBaseline />
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
