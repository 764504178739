import React from "react";
import { Box, Typography, Stack, IconButton } from "@mui/material";
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Avatar,
} from "@mui/material";

import "./viewsupplies.scss";
import ClearIcon from "@mui/icons-material/Clear";
import Divider from "@mui/material/Divider";

function ViewSupplies({ handleCloseView, viewData }) {
  return (
    <Box className="view-supplies">
      <Stack direction="row" spacing={5}>
        <Typography className="text-title" variant="h6">
          SUPPLIER DETAILS
        </Typography>

        <Box sx={{ flexGrow: 1 }}></Box>
        <IconButton onClick={handleCloseView}>
          <ClearIcon sx={{ color: "red" }} />
        </IconButton>
      </Stack>

      <Divider variant="fullWidth" />

      <Stack direction="row" spacing={2} sx={{ mt: 2 }} justifyContent="center">
        {viewData?.image_Src === "" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src={`${viewData?.image_Src}`}
              sx={{
                width: 120,
                height: 120,
                fontSize: "40px",
                justifyItems: "center",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              alt={viewData?.name}
              src={`${viewData?.image_Src}`}
              sx={{
                width: 120,
                height: 120,
                fontSize: "40px",
                justifyItems: "center",
              }}
            />
          </Box>
        )}

        <TableContainer>
          <Table size="small">
            <TableRow>
              <TableCell className="sub-title">
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography">Name</Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>:
                </Stack>
              </TableCell>
              <TableCell className="text-detail">
                <Typography>{viewData?.name}</Typography>
              </TableCell>
            </TableRow>

            {viewData?.tel === "" ? null : (
              <TableRow>
                <TableCell className="sub-title">
                  <Stack direction="row" spacing={1}>
                    <Typography className="text-typography">
                      Phone Number
                    </Typography>
                    <Box sx={{ flexGrow: 1 }}></Box>:
                  </Stack>
                </TableCell>

                <TableCell className="text-detail">
                  <Typography>{viewData?.tel}</Typography>
                </TableCell>
              </TableRow>
            )}

            {viewData?.email === "" ? null : (
              <TableRow>
                <TableCell className="sub-title">
                  <Stack direction="row" spacing={1}>
                    <Typography className="text-typography">Email</Typography>
                    <Box sx={{ flexGrow: 1 }}></Box>:
                  </Stack>
                </TableCell>

                <TableCell className="text-email">
                  <i> {viewData?.email}</i>
                </TableCell>
              </TableRow>
            )}

            {viewData?.facebook ? (
              <TableRow>
                <TableCell className="sub-title">
                  <Stack direction="row" spacing={1}>
                    <Typography className="text-typography">
                      Facebook
                    </Typography>
                    <Box sx={{ flexGrow: 1 }}></Box>:
                  </Stack>
                </TableCell>

                <TableCell className="text-email">
                  <i> {viewData?.facebook}</i>
                </TableCell>
              </TableRow>
            ) : null}

            {viewData?.adress === "" ? null : (
              <TableRow>
                <TableCell className="sub-title">
                  <Stack direction="row" spacing={1}>
                    <Typography className="text-typography">Address</Typography>
                    <Box sx={{ flexGrow: 1 }}></Box>:
                  </Stack>
                </TableCell>
                <TableCell className="text-detail">
                  <Typography>{viewData?.adress}</Typography>
                </TableCell>
              </TableRow>
            )}
            {viewData?.remark === "" ? null : (
              <TableRow>
                <TableCell className="sub-title">
                  <Stack direction="row" spacing={1}>
                    <Typography className="text-typography">Remark</Typography>
                    <Box sx={{ flexGrow: 1 }}></Box>:
                  </Stack>
                </TableCell>
                <TableCell className="text-detail">
                  <Typography>{viewData?.remark}</Typography>
                </TableCell>
              </TableRow>
            )}
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
}

export default ViewSupplies;
