import React, { useState } from "react";
import "./modalaction.scss";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { IconButton, Modal, Stack, Typography } from "@mui/material";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VoidPurchase from "./VoidPurchase";
import UpdatePurchase from "./UpdatePurchase";
import ModalReceive from "./ModalReceive";

export default function PurchaseRoomAction({
  row,
  roomId,
  setRefetch,
  setAlert,
  setMessage,
  setCheckMessage,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openVoid, setOpenVoid] = useState(false);
  const handleOpenVoid = () => setOpenVoid(true);
  const handleCloseVoid = () => setOpenVoid(false);

  const [openReceive, setOpenReceive] = useState(false);
  const handleOpenReceive = () => setOpenReceive(true);
  const handleCloseReceive = () => setOpenReceive(false);

  console.log(row)

  return (
    <div>
      {row?.status === true ? (
        <IconButton>
          <MoreVertIcon sx={{ color: "#3d3d3d" }} />
        </IconButton>
      ) : (
        <IconButton onClick={handleClick}>
          <MoreVertIcon sx={{ color: "#3d3d3d" }} />
        </IconButton>
      )}

      {row?.approve_status === "pending" ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              handleOpenEdit();
            }}
            disabled={ row?.approve_status === "instock" ? true : false}
          >
            <Stack direction="row" spacing={1}>
              <AppRegistrationOutlinedIcon className="icon-menu-update" />
              <Typography className="text-menu"> Update </Typography>
            </Stack>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose();
              handleOpenVoid();
            }}
          >
            <Stack direction="row" spacing={1}>
              <DeleteOutlineOutlinedIcon className="icon-menu-delete" />
              <Typography className="text-menu"> Void </Typography>
            </Stack>
          </MenuItem>
        </Menu>
      ) : row?.approve_status === "rejected" ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              handleOpenEdit();
            }}
            disabled={ row?.approve_status === "instock" ? true : false}
          >
            <Stack direction="row" spacing={1}>
              <AppRegistrationOutlinedIcon className="icon-menu-update" />
              <Typography className="text-menu"> Update </Typography>
            </Stack>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose();
              handleOpenVoid();
            }}
          >
            <Stack direction="row" spacing={1}>
              <DeleteOutlineOutlinedIcon className="icon-menu-delete" />
              <Typography className="text-menu"> Void </Typography>
            </Stack>
          </MenuItem>
        </Menu>
      ) : row?.approve_status === "approve" ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              handleOpenReceive();
            }}
          >
            <Stack direction="row" spacing={1}>
              <AddTaskIcon className="icon-menu-update" />
              <Typography className="text-menu"> Receive </Typography>
            </Stack>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose();
              handleOpenVoid();
            }}
          >
            <Stack direction="row" spacing={1}>
              <DeleteOutlineOutlinedIcon className="icon-menu-delete" />
              <Typography className="text-menu"> Void </Typography>
            </Stack>
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              handleOpenVoid();
            }}
          >
            <Stack direction="row" spacing={1}>
              <DeleteOutlineOutlinedIcon className="icon-menu-delete" />
              <Typography className="text-menu"> Void </Typography>
            </Stack>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose();
              handleOpenEdit();
            }}
            disabled={ row?.approve_status === "instock" ? true : false}
          >
            <Stack direction="row" spacing={1}>
              <AppRegistrationOutlinedIcon className="icon-menu-update" />
              <Typography className="text-menu"> Update </Typography>
            </Stack>
          </MenuItem>
        </Menu>
      )}

      <Modal open={openEdit}>
        <UpdatePurchase
          handleClose={handleCloseEdit}
          setRefetch={setRefetch}
          checkButton={"update"}
          editData={row}
          roomId={roomId}
          setAlert={setAlert}
          setMessage={setMessage}
          setCheckMessage={setCheckMessage}
        />
      </Modal>

      <Modal open={openReceive}>
        <ModalReceive
          handleClose={handleCloseReceive}
          setRefetch={setRefetch}
          checkButton={"received"}
          roomId={roomId}
          editData={row}
          setAlert={setAlert}
          setMessage={setMessage}
          setCheckMessage={setCheckMessage}
        />
      </Modal>

      <Modal open={openVoid}>
        <VoidPurchase
          row={row}
          handleClose={handleCloseVoid}
          setRefetch={setRefetch}
          setAlert={setAlert}
          setMessage={setMessage}
          setCheckMessage={setCheckMessage}
        />
      </Modal>
    </div>
  );
}
