import { useQuery } from "@apollo/client";
import { Box, Stack, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { GET_CATEGORIES_PRODUCT } from "../../../Schema/product";
import {GET_PURCHASE_CHART} from "../../../Schema/dashboard";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment/moment";


export default function ChartPurchase() {
    
    const [points,setPoints] = useState([]);
    const [dataCategory,setDataGetegory] = useState([]);
    const [dataAmount,setDataAmount]= useState([]);
    
    const {refetch} = useQuery(GET_PURCHASE_CHART,{
        onCompleted: ({purchaseProductBarChart}) => {
            console.log("purchaseProductBarChart",purchaseProductBarChart);
            
            if(purchaseProductBarChart.length !== 0) {      
                let rowCate = [];
                let rowAmount = [];
                let rowPoint = [];

                purchaseProductBarChart.forEach( (row,index) => {                    
                    rowCate.push(row?.category_name)
                    rowAmount.push(parseFloat((row?.total_amount)?.toFixed(2)))
                    // ====== point =========
                    const allRow = {                  
                        x: `${row?.category_name}`,
                        seriesIndex: 0,
                        label: {
                            borderColor: '#775DD0',
                            offsetY: 0,
                            style: {
                                color: '#fff',
                                background: '#775DD0',
                            },
                            text: `${row?.purchase_time}T`,
                        }                
                    }
                    rowPoint.push(allRow)

                })

                setDataGetegory(rowCate);
                setDataAmount(rowAmount);
                setPoints(rowPoint);
            }          

        },
        onError: (error) => {
            console.log(error.message);
        }
    })

    useEffect( () => {
        refetch();
    },[])


    console.log(dataCategory)
    

    const state = {
          
        series: [{
          name: 'Total Amount $',
          data: dataAmount,
        }],
        options: {
          annotations: {
            points: points,
          },
          chart: {
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 0,
              columnWidth: '50%',
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 2
          },
          
          grid: {
            row: {
              colors: ['#fff', '#f2f2f2']
            }
          },
          xaxis: {
            labels: {
                rotate: -66,
                rotateAlways: true,
                trim: true
            },
            categories: dataCategory,            
            tickPlacement: 'on'
          },
          yaxis: {
            title: {
              text: 'Total Amount',
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              type: "horizontal",
              shadeIntensity: 0.25,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 0.85,
              opacityTo: 0.85,
              stops: [50, 0, 300]
            },
          }
        },     
      
      };
    

    return(
        <Box width="100%" padding="15px">
            <Stack direction="row" spacing={2} >
                <Stack direction="column" justifyContent="center" height="45px"> 
                    <Typography sx={{color:"#0f81c2" ,fontWeight: "bold"}}>
                        Request Purchase Product ({moment().format("MMMM YYYY")})
                    </Typography>
                </Stack>
                <Box sx={{ flexGrow: 1 }}></Box>   
            </Stack>

            <div id="chart">
                <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
            </div>
        </Box>
    )
}