import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Autocomplete,
  TextField,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider, DateTimePicker } from "@mui/lab";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useQuery, useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
//formik
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
// Schema
import "./createstockoutcard.scss";
import { GET_ALL_USER } from "../../Schema/user";
import { GET_ALL_CUSTOMERS } from "../../Schema/shops";
import { DELIVER_RELEASE_CARD } from "../../Schema/stockout";
import ListReleaseCard from "./ListStockOutCard";
import moment from "moment";

export default function ModalDelivery({
  checkButton,
  handleClose,
  setRefetch,
  btnTitle,
  setAlert,
  setMessage,
  setCheckMessage,
  editData,
}) {
  const [loading, setLoading] = useState(false);
  // const [create,setCreate] = React.useState(false);
  const [checkFieldRequired, setCheckFieldRequried] = React.useState(false);

  const [selectshop, setSelectShop] = React.useState({
    label: editData?.shop_Id?.name,
    id: editData?.shop_Id?._id,
  });

  const [selectDelivery, setSelectDelivery] = React.useState({
    label:
      editData?.delivery_By?.firsName + " " + editData?.delivery_By?.lastName,
    id: editData?.delivery_By?._id,
  });

  //getvalue user =============================================================
  const [userdelivery, setUserdelivery] = useState([]);
  const { data: allUsers } = useQuery(GET_ALL_USER);

  useEffect(() => {
    if (allUsers) {
      let row = [];
      allUsers?.getAllUser?.forEach((element) => {
        const allrow = {
          label: element?.firsName + " " + element?.lastName,
          id: element?._id,
        };
        row.push(allrow);
      });
      setUserdelivery(row);
    }
  }, [allUsers?.getAllUser]);

  //placholder ==============================================================
  const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
      color: "#aaa",
    },
  }));

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  // Get All shop ===============================================================
  const [shop, setShop] = useState([]);
  const { data: shopData } = useQuery(GET_ALL_CUSTOMERS, {
    variables: {
      keyword: "",
    },
  });

  useEffect(() => {
    if (shopData?.getAllShops) {
      let rows = [];
      // console.log( "Data" ,shopData?.getAllShops )
      shopData?.getAllShops?.forEach((element) => {
        const allrow = {
          id: element?._id,
          label: element?.name,
        };
        rows.push(allrow);
      });
      setShop(rows);
    }
  }, [shopData?.getAllShops]);

  // Set up Release Card Item  ==========================================================================================================
  const [currentItem, setCurrentItem] = useState({
    product_name: "",
    product_Id: "",
    qty: 0,
    // unit_Price : 0,
    storage_Room_Id: "",
    storage_Room_name: "",
    key: "",
  });

  const [item, setItem] = useState([]);

  const addItem = () => {
    const newItem = currentItem;
    if (newItem.product_name !== "") {
      const items = [...item, newItem];
      setItem([...items]);
      setCurrentItem({
        product_name: "",
        product_Id: "",
        qty: 0,
        // unit_Price : 0,
        storage_Room_Id: "",
        storage_Room_name: "",
        key: Date.now(),
      });
    }
  };

  const handleAddItems = () => {
    setCurrentItem({
      product_name: "Product Name",
      product_Id: "",
      qty: 0,
      // unit_Price : 0,
      storage_Room_Id: "",
      storage_Room_name: "",
      key: Date.now(),
    });
  };

  useEffect(() => {
    if (currentItem?.product_name !== "") {
      addItem();
    }
  }, [currentItem]);

  const deleteItem = (key) => {
    const filteredItems = item?.filter((t) => t.key !== key);
    setItem(filteredItems);
  };

  const setUpdateProductName = (product_name, product_Id, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.product_name = product_name;
        i.product_Id = product_Id;
      }

      return i;
    });
    setItem([...items]);
  };

  const setUpdateQty = (qty, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.qty = qty;
      }
      return i;
    });
    setItem([...items]);
  };

  // const setUpdateUnitPrice = ( unit_Price, key) => {
  //   const items = item;
  //   items.map((i) => {
  //     if (i.key === key) {
  //       i.unit_Price = unit_Price;
  //     }
  //     return i
  //   });
  //   setItem([...items]);
  // };

  const setUpdateStorageRoom = (storage_Room_name, storage_Room_Id, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.storage_Room_name = storage_Room_name;
        i.storage_Room_Id = storage_Room_Id;
      }
      return i;
    });
    setItem([...items]);
  };
  // ========================= End Release Card Item =========================


  //​​ ================================== ​create ===============================
  const [delivered] = useMutation(DELIVER_RELEASE_CARD, {
    onCompleted: ({ delivered }) => {
      if (delivered?.status) {
        setLoading(false);
        setCheckMessage("success");
        setMessage(delivered?.message);
        setAlert(true);
        handleClose();
        setRefetch();
      } else {
        setLoading(false);
        setCheckMessage("error");
        setMessage(delivered?.message);
        setAlert(true);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log(error?.message);
      setCheckMessage("error");
      setMessage(error.message);
    },
  });

  // Formik
  const AddRealeseCard = Yup.object().shape({
    delivery_At: Yup.date().required("ទាមទារជ្រើសរើសថ្ងៃដឹក"),
    order_Date: Yup.date().required("ត្រូវជ្រើសរើសអតិថិជន!"),
    shop_Id: Yup.string().required("ត្រូវជ្រើសរើសអ្នកទិញ!"),
    delivery_By: Yup.string().required("ត្រូវជ្រើសរើសអ្នកដឹកជញ្ជូន!"),
  });

  const formik = useFormik({
    initialValues: {
      shop_Id: "",
      delivery_By: "",
      delivery_At: moment(),
      order_Date: moment(),
      remark: "",
    },

    validationSchema: AddRealeseCard,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setLoading(true);

      let create = false;

      if (item?.length !== 0) {
        const items = item;
        items.map((i) => {
          if (
            i.product_Id !== "" &&
            i.product_Id !== undefined &&
            i.qty !== 0 &&
            i.qty > 0 &&
            // i.unit_Price !== 0 && i.unit_Price > 0 &&
            i.storage_Room_name !== "" &&
            i.storage_Room_name !== undefined
          ) {
            create = true;
          } else {
            create = false;
            setLoading(false);
            setCheckFieldRequried(true);
          }
          return i;
        });
      } else {
        create = false;
        setLoading(false);
        setCheckFieldRequried(true);
        return;
      }

      if (create) {
        //Assign New Value        
        const newValue = {
          shop_Id: values?.shop_Id,
          delivery_By: values?.delivery_By,
          delivery_At: moment(values?.delivery_At)?.format("YYYY-MM-DD")+"T"+moment(values?.delivery_At)?.format("HH:mm:ss"),
          order_Date: moment(values?.order_Date)?.utc(false)?.format("YYYY-MM-DD"),
          remark: values?.remark,
          items: item,
        };

        // console.log("newValue:::",newValue);
        // setLoading(false);

        delivered({
          variables: {
            releaseCardId: editData?._id,
            input: {
              ...newValue,
            },
          },
        });
        
      }
    },
  });

  const {
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    touched,
    errors,
  } = formik;

  React.useEffect(() => {
    if (editData) {
      // setFieldValue(
      //   "delivery_At",
      //   moment(
      //     `${moment(editData?.delivery_At).format("YYYY-MM-DD")} ${moment(
      //       editData?.time,
      //       "HH:mm A"
      //     )?.format("HH:mm:ss")}`
      //   )
      // );

      setFieldValue("order_Date", editData?.order_Date);
      setFieldValue("shop_Id", editData?.shop_Id?._id);
      setFieldValue("delivery_By", editData?.delivery_By?._id);

      let rows = [];
      editData?.items?.forEach((element) => {
        const allrow = {
          product_name: element?.product_Id?.name,
          product_Id: element?.product_Id?._id,
          qty: element?.qty,
          // unit_Price : element?.unit_Price,
          storage_Room_Id: element?.storage_Room_Id?._id,
          storage_Room_name: element?.storage_Room_Id?.name,
          key: element?.key,
        };
        rows.push(allrow);
      });
      setItem(rows);
    }
  }, [editData]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box className="create_release">
          <Stack direction="row" spacing={5}>
            <Typography className="modal-title" variant="h6">
              CREATE STOCK OUT CARD
            </Typography>

            <Box sx={{ flexGrow: 1 }}></Box>
            <IconButton onClick={() => handleClose()}>
              <ClearIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>

          <Stack direction="row" spacing={2} width="100%" sx={{ mt: 2 }}>
            <Box className="btn-filter" sx={{ width: "50%" }}>
              <LocalizationProvider
                className="date-controll"
                dateAdapter={AdapterDateFns}
              >
                <Typography className="header-title">Order Date</Typography>
                <DatePicker
                  value={values?.order_Date}
                  // disabled
                  onChange={(e) => setFieldValue("order_Date", e)}
                  renderInput={(params) => (
                    <TextField
                      className="selection"
                      size="small"
                      {...params}
                      type="date"
                      fullWidth
                      error={Boolean(touched.order_Date && errors.order_Date)}
                      helperText={touched.order_Date && errors.order_Date}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box className="btn-filter" sx={{ width: "50%" }}>
              <Typography className="header-title">Delivery Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  className="datetime"
                  inputFormat="MM-d-yyyy HH:mm a"
                  // disabled
                  value={values?.delivery_At}
                  onChange={(e) => setFieldValue("delivery_At", e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="selection"
                      size="small"
                      type="date"
                      fullWidth
                      error={Boolean(touched.delivery_At && errors.delivery_At)}
                      helperText={touched.delivery_At && errors.delivery_At}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Stack>

          <Stack direction="row" spacing={2} width="100%" sx={{ mt: 2 }}>
            <Box className="btn-filter" width="50%">
              <Autocomplete
                // disabled
                className="text-field"
                id="combo-box-demo"
                disablePortal
                options={shop}
                value={selectshop}
                getOptionSelected={(value, option) => value.id === option.id}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                onChange={(e, value) => {
                  setFieldValue("shop_Id", value?.id);
                  setSelectShop({ id: value?.id, label: value?.label });
                }}
                renderInput={(params) => (
                  <TextField
                    className="text-field"
                    fullWidth
                    {...params}
                    placeholder="Choose shop"
                    size="small"
                    error={Boolean(touched.shop_Id && errors.shop_Id)}
                    helperText={touched.shop_Id && errors.shop_Id}
                  />
                )}
              />
            </Box>

            <Box className="btn-filter" sx={{ width: "50%" }}>
              <Autocomplete
                // disabled
                className="text-field"
                id="combo-box-demo"
                disablePortal
                options={userdelivery}
                value={selectDelivery}
                getOptionSelected={(value, option) => value.id === option.id}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                onChange={(e, value) => {
                  setFieldValue("delivery_By", value?.id);
                  setSelectDelivery({ label: value?.label, id: value?.id });
                }}
                renderInput={(params) => (
                  <TextField
                    className="text-field"
                    fullWidth
                    {...params}
                    placeholder="Choose Delivery"
                    size="small"
                    error={Boolean(touched.delivery_By && errors.delivery_By)}
                    helperText={touched.delivery_By && errors.delivery_By}
                  />
                )}
              />
            </Box>
          </Stack>

          <Box className="container" sx={{ mt: 2, mb: 2 }}>
            <TableContainer>
              <Table className="table" aria-label="simple table">
                <TableHead>
                  <TableRow className="header-row">
                    <TableCell className="header-title">Name Product</TableCell>
                    <TableCell className="header-title">Quantity</TableCell>
                    {/* <TableCell className="header-title">Unit Price</TableCell> */}
                    <TableCell className="header-title">Storage Room</TableCell>
                  </TableRow>
                </TableHead>

                <ListReleaseCard
                  deliver={true}
                  items={item}
                  deleteItem={deleteItem}
                  setUpdateProductName={setUpdateProductName}
                  setUpdateQty={setUpdateQty}
                  // setUpdateUnitPrice={setUpdateUnitPrice}
                  setUpdateStorageRoom={setUpdateStorageRoom}
                  checkFieldRequired={checkFieldRequired}
                />
              </Table>
            </TableContainer>
          </Box>

          <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
            {loading ? (
              <Button
                className="add-item"
                fullWidth
                variant="text"
                sx={{ ":hover": { backgroundColor: "#0969A0" } }}
              >
                <Typography className="text-btn" variant="h6" align="center">
                  Loading...
                </Typography>
              </Button>
            ) : (
              <Button
                type="submit"
                className="add-item"
                fullWidth
                variant="text"
                sx={{ ":hover": { backgroundColor: "#0969A0" } }}
              >
                <Typography className="text-btn" variant="h6" align="center">
                  delivery
                </Typography>
              </Button>
            )}
          </Stack>
        </Box>
      </Form>
    </FormikProvider>
  );
}
