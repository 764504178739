import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Stack,
  FormControl,
  MenuItem,
  Select,
  Typography,
  IconButton,
  TextField,
  Button,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Autocomplete,
} from "@mui/material";
import "./createpurchase.scss";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
//Date
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
// icon priority
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ListPurchase from "./ListPurchase";
import { GET_ALL_SUPPLIES } from "../../Schema/supplies";
import { CREATE_PURCHASE } from "../../Schema/purchase";
import { useQuery, useMutation } from "@apollo/client";
import { sendMessage } from "../Telegram/TelegrameClient";
import NumberFormat from "react-number-format";
import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext"

export default function CreatePurchase({
  handleClose,
  roomId,
  setRefetch,
  setAlert,
  setMessage,
  setCheckMessage,
  checkButton,
}) {
  const [loading, setLoading] = useState(false);
  const { exchangeRate } = useContext(AuthContext)

  const [checkFieldRequired, setCheckFieldRequried] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  //total Amount
  const [totalAmount, setTotalAmount] = useState(0);

  //Supplier
  const [suppliers, setSuppliers] = useState([]);
  const [supplierId, setSupplierId] = useState({});

  const { data,refetch } = useQuery(GET_ALL_SUPPLIES, {
    variables: {
      keyword: "",
    },
  });

  useEffect(() => {
    if (data) {
      let rows = [];
      data?.getSuppliers?.data.forEach((element) => {
        const allrow = {
          label: element?.name,
          id: element?._id,
        };
        rows.push(allrow);
      });
      setSuppliers(rows);
    }
    refetch()
  }, [data]);
  //end

  // Buy product
  const [currentItem, setCurrentItem] = useState({
    product_Id: "",
    unit: "",
    qty: "",
    unit_Price: 0,
    key: "",
  });

  const [item, setItem] = useState([]);

  const addItem = () => {
    const newItem = currentItem;
    if (newItem.product_Id !== "") {
      const items = [...item, newItem];
      setItem([...items]);
      setCurrentItem({
        product_Id: "",
        unit: "",
        qty: 0,
        unit_Price: 0,
        key: "",
      });
    }
  };

  const hanleAddProduct = () => {
    setCurrentItem({
      product_Id: "Choose",
      unit: "",
      qty: 1,
      unit_Price: 0,
      key: Date.now(),
    });
  };

  useEffect(() => {
    if (currentItem?.product_Id !== "") {
      addItem();
    }
  }, [currentItem]);

  useMemo(async () => {
    await hanleAddProduct();
    await addItem();
  }, []);

  const deleteItem = (key) => {
    const filteredItems = item?.filter((t) => t.key !== key);
    setItem(filteredItems);
  };

  const setCreateProduct = (product_Id, unit , key) => {
    const items = item;

    if (items?.filter((e) => e?.product_Id === product_Id)?.length > 0) {
      setDuplicate(true);
    } else {
      items.map((i) => {
        if (i.key === key) {
          i.product_Id = product_Id;
          i.unit = unit;
        }
        return i;
      });
      setDuplicate(false);
      setItem([...items]);
    }
  };

  const setCreateQty = (qty, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.qty = parseFloat(qty);
      }
      return i;
    });
    setItem([...items]);
    setUpdateTotalAmount();
  };

  const setCreateUnit_Price = (unit_Price, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.unit_Price = parseFloat(unit_Price);
      }
      return i;
    });
    setItem([...items]);
    setUpdateTotalAmount();
  };

  const setUpdateTotalAmount = () => {
    const items = item;
    let totalAmounts = 0;
    items.map((i) => {
      totalAmounts += i?.qty * i?.unit_Price;
      return i;
    });

    setTotalAmount(totalAmounts);
  };


  const [createPurchase] = useMutation(CREATE_PURCHASE, {
    onCompleted: async ({ createPurchase }) => {
      if (createPurchase?.status === true) {
        setCheckMessage("success");
        setMessage(createPurchase?.message);
        setAlert(true);
        setRefetch();
        handleClose();
        setLoading(false);
        let ArrPurchase = "";
        let totalPurchase = 0;

        createPurchase?.data?.items?.map((e, index) => {
          ArrPurchase +=
            index +
            1 +
            "-\t" +
            e?.product_Id?.name +
            "\t" +
            e?.qty +
            "\t x \t" +
            e?.unit_Price +
            "$" +
            "\n";
          totalPurchase += e?.qty * e?.unit_Price;
        });

        await sendMessage({
          content: `<b>[Request Create Purchase]</b>\n\n👩‍🚀 N°: ${
            createPurchase?.data?.numbering
          }\n\n${ArrPurchase}\n✅\tTotalAmount = ${totalPurchase}$\n\n🗓 Date:\t${moment(
            createPurchase?.data?.created_At
          ).format("DD/MM/YYYY")}\n\n 📌 Siem Reap \n\n 📲 Tel: 085 883 884 `,
        });
      } else {
        setCheckMessage("error");
        setMessage(createPurchase?.message);
        setAlert(true);
        setLoading(false);
      }
    },

    onError: (error) => {
      setCheckMessage("error");
      setMessage(error.message);
      setAlert(true);
      setLoading(false);
    },
  });

  // formik
  const AddStorage = Yup.object().shape({
    remark: Yup.string(),
    need_Date: Yup.date().required("required"),
    supplier_id: Yup.string().required("Suppier is required!"), 
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
      need_Date: new Date(),
      priority: "Meduim",
      storage_Room_Id: roomId,
      supplier_id: supplierId?.id,
      items: item, 
    },

    validationSchema: AddStorage,
    onSubmit: (values) => {
      setLoading(true);
      //--------------required--------------
      let create = false;        
      const BreakException = {};
      try {
        if (item?.length !== 0) {
          const items = item;
          items?.forEach(function(i) {                                
              if(
                i.product_Id !== "" &&
                i.product_Id !== "Choose" &&
                i.product_Id !== undefined &&
                i.qty !== 0 &&
                i.qty > 0 &&
                i.unit_Price > 0 &&
                i.unit_Price !== undefined &&
                duplicate !== true
              ) {                        
                  create = true;
              } else {                 
                create = false;
                setLoading(false);
                setCheckFieldRequried(true);
                throw BreakException;
              }
          });
        } else {
          create = false;
          setCheckFieldRequried(true);
          setLoading(false);
        }
      } catch (e) {
          if (e !== BreakException) throw e;
      }


      //================= CREATE =============
      if (create) {

        const newValue = {
          need_Date: values?.need_Date,
          remark: values?.remark,
          priority: values?.priority,
          storage_Room_Id: values?.storage_Room_Id,
          supplier_id: values?.supplier_id,
          exchange_rate: exchangeRate,
          items: item?.map((e) => ({
            qty: e?.qty,
            unit_Price: e?.unit_Price,
            product_Id: e?.product_Id,
            key: e?.key,
          })),
        };

        if (checkButton === "create") {
          // console.log("newValue", newValue);
          createPurchase({
            variables: {
              input: {
                ...newValue,
              },
            },
          });
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box className="purchase">
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" className="title">
              CREATE PURCHASE
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            <IconButton onClick={handleClose}>
              <ClearIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>

          <Box sx={{ width: "100%" }}>
            <Stack direction="row" spacing={2}>
              <TableCell className="sub-title" width="50%" colSpan={2}>
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography">Priority:</Typography>
                  <FormControl
                    className="form-controll-select"
                    size="small"
                    fullWidth
                  >
                    <Select
                      IconComponent={(props) => (
                        <KeyboardArrowDownOutlinedIcon {...props} />
                      )}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      {...getFieldProps("priority")}
                      displayEmpty
                    >
                      <MenuItem value="Meduim">
                        <Stack direction="row" spacing={1}>
                          <FiberManualRecordIcon
                            sx={{ color: "#2AEA00", width: "17px" }}
                          />
                          <Typography sx={{ fontFamily: "Century Gothic" }}>
                            Meduim
                          </Typography>
                        </Stack>
                      </MenuItem>

                      <MenuItem value="Urgent">
                        <Stack direction="row" spacing={1}>
                          <PriorityHighIcon
                            sx={{ color: "red", width: "17px" }}
                          />
                          <Typography sx={{ fontFamily: "Century Gothic" }}>
                            Urgent
                          </Typography>
                        </Stack>
                      </MenuItem>

                      <MenuItem value="Low">
                        <Stack direction="row" spacing={1}>
                          <NotificationsActiveIcon
                            sx={{ color: "red", width: "17px" }}
                          />
                          <Typography sx={{ fontFamily: "Century Gothic" }}>
                            Low
                          </Typography>
                        </Stack>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </TableCell>

              <Box sx={{ flexGrow: 1 }} />

              <TableCell className="sub-title" width="50%">
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography"> Date:</Typography>

                  <LocalizationProvider
                    className="date-controll"
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      value={values.need_Date}
                      onChange={(e) => setFieldValue("need_Date", e)}
                      renderInput={(params) => (
                        <TextField
                          className="text-field"
                          size="small"
                          {...params}
                          fullWidth
                          type="date"
                          defaultValue={values.need_Date}
                          error={Boolean(touched.need_Date && errors.need_Date)}
                          helperText={touched.need_Date && errors.need_Date}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </TableCell>
            </Stack>

            <Stack direction="row">
              <TableCell className="sub-title" width="50%" colSpan={2}>
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography">
                    Suppliers:
                  </Typography>

                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    fullWidth
                    options={suppliers}
                    onChange={(e, value) => {
                      setFieldValue("supplier_id", value?.id);
                      setSupplierId({ label: value?.label, id: value?.id });
                    }}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Choose"
                        size="small"
                        {...params}
                        error={Boolean(
                          touched.supplier_id && errors.supplier_id
                        )}
                        helperText={touched.supplier_id && errors.supplier_id}
                      />
                    )}
                  />
                </Stack>
              </TableCell>
            </Stack>
          </Box>

          <TableContainer className="container">
            <Table className="table" aria-label="simple table">
              <TableHead>
                <TableRow className="header-row">
                  <TableCell className="header-title" align="left" width="30%">
                    Product Name
                  </TableCell>
                  <TableCell
                    className="header-title"
                    align="center"
                    width="20%"
                  >
                    Qty
                  </TableCell>
                  <TableCell
                    className="header-title"
                    align="center"
                    width="30%"
                    colSpan={2}
                  >
                    Unit Price
                  </TableCell>
                  <TableCell className="header-title" width="10%" align="right">
                    <IconButton onClick={hanleAddProduct}>
                      <AddCircleOutlineRoundedIcon sx={{ color: "green" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>

              <ListPurchase
                items={item}
                checkButton={checkButton}
                totalAmount={totalAmount}
                deleteItem={deleteItem}
                setCreateQty={setCreateQty}
                setCreateProduct={setCreateProduct}
                setCreateUnit_Price={setCreateUnit_Price}
                checkFieldRequired={checkFieldRequired}
                duplicate={duplicate}
              />
            </Table>
          </TableContainer>

          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Typography sx={{ fontFamily: "Century Gothic" }}>Exchange Rate: {exchangeRate} KHR/USD</Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Typography className="textfield">Total Amount:</Typography>
            <NumberFormat
              value={totalAmount?.toFixed(2)}
              thousandSeparator={true}
              displayType={"text"}
              renderText={(value, props) => (
                <div className="body" {...props} style={{width: "110px" , textAlign: "right"}}>
                  $ {value}
                </div>
              )}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Typography className="textfield">Total Amount (KHR):</Typography>
            <NumberFormat
              value={(totalAmount*exchangeRate)?.toFixed(0)}
              thousandSeparator={true}
              displayType={"text"}
              renderText={(value, props) => (
                <div className="body" {...props} style={{width: "110px" , textAlign: "right"}}>
                  ៛ {value}
                </div>
              )}
            />
          </Stack>

          <Stack direction="column" spacing={1} sx={{ mt: 2 }} width="100%">
            <Typography className="field"> Remark </Typography>

            <TextField
              multiline
              rows={3}
              size="small"
              fullWidth
              placeholder="remark"
              {...getFieldProps("remark")}
            />
          </Stack>

          {loading ? (
            <Button
              className="btn-create"
              variant="text"
              fullWidth
              sx={{ mt: 2 }}
            >
              Loading...
            </Button>
          ) : (
            <Button
              className="btn-create"
              variant="text"
              fullWidth
              type="submit"
              sx={{ mt: 2 }}
            >
              {checkButton}
            </Button>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
}
