import React from "react";
import "./noofuser.scss";
import { Box, Typography, Stack, Skeleton, Grid } from "@mui/material";
import Customer from "../../../Assets/new-dashboard/customer.png";
import Supplier from "../../../Assets/new-dashboard/supplier.png";
import { GET_TOTAL_USER } from "../../../Schema/dashboard";
import { useQuery } from "@apollo/client";

export default function NoOfUser() {
  const [loading, setLoading] = React.useState(true);
  const { data } = useQuery(GET_TOTAL_USER, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: () => {
      setLoading(true);
    },
  });

  if (loading) {
    return (
      <Box className="ofuser" sx={{ width: "100%", padding: "20px" }}>
        <Box sx={{ width: "100%" }}>
          <Stack direction="column" spacing={2}>
            <Typography variant="body2" className="main-title">
              <Skeleton height={20} width="40%" variant="rectangular" />
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} mt={2}>
            <Skeleton height={100} width="100%" variant="rectangular" />
            <Skeleton height={100} width="100%" variant="rectangular" />
          </Stack>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="ofuser">
      <Typography className="main-title">No Of User</Typography>

      <Box className="grid-group">
        <Grid container spacing={3}>
          
          <Grid item xs={12} sm={12} md={6}>
            <Box className="card-user">
              <Stack direction="row" justifyContent="left" width="40%">
                <img src={`${Customer}`} width="60px" height="60px" />
              </Stack>

              <Stack direction="row"  justifyContent="center" width="60%">
                <Stack direction="column">
                  <Typography className="sub-title">
                    Total Shop
                  </Typography>
                  <Typography className="number" variant="h5">
                    {data?.getTotalUser?.shop}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Box className="card-user">
              <Stack direction="row" justifyContent="left" width="40%">
                <img src={`${Supplier}`} width="60px" height="60px" />
              </Stack>

              <Stack direction="row" justifyContent="center" width="60%">
                <Stack direction="column" >
                  <Typography className="sub-title">
                    Total Supplier
                  </Typography>
                  <Typography className="number" variant="h5">
                    {data?.getTotalUser?.supplier}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>


        </Grid>
      </Box>
    </Box>
  );
}
