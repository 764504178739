import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Stack,
  FormControl,
  MenuItem,
  Select,
  Typography,
  IconButton,
  TextField,
  Button,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Autocomplete,
} from "@mui/material";
import "./createpurchase.scss";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
//Date
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
// icon priority
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ListPurchase from "./ListPurchase";
import { GET_ALL_SUPPLIES } from "../../Schema/supplies";
import { GET_ALL_STORAGE_ROOM } from "../../Schema/storageroom";
import { CREATE_PRODUCT_WAREHOUSE } from "../../Schema/transfer";
import { useQuery, useMutation } from "@apollo/client";
import { sendMessage } from "../Telegram/TelegrameClient";
import NumberFormat from "react-number-format";
import moment from "moment";

export default function CreatePurchase({
  handleClose,
  setRefetch,
  setAlert,
  setMessage,
  setCheckMessage,
  checkButton,
}) {
  const [loading, setLoading] = useState(false);

  const [checkFieldRequired, setCheckFieldRequried] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  //total Amount
  const [totalAmount, setTotalAmount] = useState(0);


  // ====================== Storage Room ===========================
  const [storageRoom, setStorageRoom] = useState([]);
  const { data: dataStorageRoom , refetch: refetchRoom} = useQuery(GET_ALL_STORAGE_ROOM, {
    variables: {
      keyword: "",
    },
  });

  useEffect( () => {
    refetchRoom();
  },[])
  
  useEffect(() => {
    if (dataStorageRoom) {
      let rows = [];
      dataStorageRoom?.getStorageRoom?.forEach((element) => {
        const allrow = {
          label: element?.name,
          id: element?._id,
        };
        rows.push(allrow);
      });
      setStorageRoom(rows);
    }
  }, [dataStorageRoom]);


  //============================= Supplier
  const [suppliers, setSuppliers] = useState([]);
  const [supplierSelected, setSupplierSelected] = useState({});

  const { data,refetch } = useQuery(GET_ALL_SUPPLIES, {
    variables: {
      keyword: "",
    },
  });

  useEffect(() => {
    if (data) {
      let rows = [];
      data?.getSuppliers?.data.forEach((element) => {
        const allrow = {
          label: element?.name,
          id: element?._id,
        };
        rows.push(allrow);
      });
      setSuppliers(rows);
    }
    refetch()
  }, [data]);
  //================================= end ===========================

  // Buy product
  const [currentItem, setCurrentItem] = useState({
    product_Id: "",  
    product_name:"",
    qty: "",
    unit_Price: 0,
    expire_At: "",
    key: "",
  });

  const [item, setItem] = useState([]);

  const addItem = () => {
    const newItem = currentItem;
    if (newItem.product_Id !== "") {
      const items = [...item, newItem];
      setItem([...items]);
      setCurrentItem({
        product_Id: "",
        product_name:"",
        qty: 0,
        unit_Price: 0,
        expire_At: "",
        key: "",
      });
    }
  };

  const hanleAddProduct = () => {
    setCurrentItem({
      product_Id: "Choose",
      product_name:"",
      qty: 1,
      unit_Price: 0,
      expire_At: "",
      key: Date.now(),
    });
  };

  useEffect(() => {
    if (currentItem?.product_Id !== "") {
      addItem();
    }
  }, [currentItem]);

  useMemo(async () => {
    await hanleAddProduct();
    await addItem();
  }, []);

  const deleteItem = (key) => {
    const filteredItems = item?.filter((t) => t.key !== key);
    setItem(filteredItems);
  };

  const setCreateProduct = (product_Id, product_name , key) => {
    const items = item;

    if (items?.filter((e) => e?.product_Id === product_Id)?.length > 0) {
      setDuplicate(true);
    } else {
      items.map((i) => {
        if (i.key === key) {
          i.product_Id = product_Id;
          i.product_name = product_name;
        }
        return i;
      });
      setDuplicate(false);
      setItem([...items]);
    }
  };

  const setCreateQty = (qty, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.qty = parseFloat(qty);
      }
      return i;
    });
    setItem([...items]);
    setUpdateTotalAmount();
  };

  const setCreateUnit_Price = (unit_Price, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.unit_Price = parseFloat(unit_Price);
      }
      return i;
    });
    setItem([...items]);
    setUpdateTotalAmount();
  };

  const setUpdateExpire_At = (expire_At,key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.expire_At = moment(expire_At);
      }
      return i;
    });
    setItem([...items]);      
  }

  const setUpdateTotalAmount = () => {
    const items = item;
    let totalAmounts = 0;
    items.map((i) => {
      totalAmounts += i?.qty * i?.unit_Price;
      return i;
    });

    setTotalAmount(totalAmounts);
  };

  const [recordOldProductInStock] = useMutation(CREATE_PRODUCT_WAREHOUSE, {
    onCompleted: async ({ recordOldProductInStock }) => {
        if (recordOldProductInStock?.status === true) {
            setCheckMessage("success");
            setMessage(recordOldProductInStock?.message);
            setAlert(true);
            setRefetch();
            handleClose();
            setLoading(false);     
        } else {
            setCheckMessage("error");
            setMessage(recordOldProductInStock?.message);
            setAlert(true);
            setLoading(false);
        }
    },
    onError: (error) => {
      setCheckMessage("error");
      setMessage(error.message);
      setAlert(true);
      setLoading(false);
    },
  });

  // formik
  const AddStorage = Yup.object().shape({
    remark: Yup.string(),   
    supplier_id: Yup.string().required("Suppier is required!"),
    storage_Room_Id: Yup.string().required(),
    receive_Date: Yup.date().required(),
  });
  

  const formik = useFormik({
    initialValues: {
      remark: "",
      receive_Date: "",
      priority: "Meduim",
      storage_Room_Id: "",
      supplier_id: "",
      items: item,
    },

    validationSchema: AddStorage,
    onSubmit: (values) => {
      // setLoading(true);
      //--------------required--------------
      let create = false;        
      const BreakException = {};
      try {
        if (item?.length !== 0) {
          const items = item;
          items?.forEach(function(i) {                                
              if(
                i.product_Id !== "" &&
                i.product_Id !== "Choose" &&
                i.product_Id !== undefined &&
                i.qty !== 0 &&
                i.qty > 0 &&
                i.unit_Price >= 0 &&
                i.unit_Price !== undefined &&
                duplicate !== true
              ) {                        
                  create = true;
              } else {                 
                create = false;
                setLoading(false);
                setCheckFieldRequried(true);
                throw BreakException;
              }
          });
        } else {
          create = false;
          setCheckFieldRequried(true);
          setLoading(false);
        }
      } catch (e) {
          if (e !== BreakException) throw e;
      }

      if (create) {
        const newValue = {         
          remark: values?.remark,         
          storage_Room_Id: values?.storage_Room_Id,
          supplier_id: values?.supplier_id,
          receive_Date: moment(values?.receive_Date).format("YYYY-MM-DD"),
          items: item?.map((e) => ({
            expire_At: e?.expire_At,
            qty: e?.qty,
            unit_Price: e?.unit_Price,
            product_Id: e?.product_Id,
            product_name: e?.product_name,
            key: e?.key,
          })),
        };
    

        if (checkButton === "create") {
          console.log("newValue", newValue);
          recordOldProductInStock({
            variables: {
              input: {
                ...newValue,
              },
            },
          });
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box className="purchase-in">
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" className="title">
              Transfer In Product
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <ClearIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>

          <Box sx={{ width: "100%" }}>

          <Stack direction="row" spacing={2}>
              <TableCell className="sub-title" width="48%" colSpan={2}>
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography" width="100px">
                    Storage:
                  </Typography>

                  <Autocomplete
                    disablePortal
                    id="combo-box-demo-storage"
                    size="small"
                    fullWidth
                    options={storageRoom}
                    getOptionLabel={(option) => (option.label ? option.label : "")}
                    onChange={(e, value) => {
                      setFieldValue("storage_Room_Id", value?.id);                    
                    }}
                    renderInput={(params) => (
                      <TextField
                        id="text-box-demo-storage"
                        placeholder="Choose"
                        size="small"
                        {...params}
                        error={Boolean(
                          touched.storage_Room_Id && errors.storage_Room_Id
                        )}
                        helperText={touched.storage_Room_Id && errors.storage_Room_Id}
                      />
                    )}
                  />
                </Stack>
              </TableCell>
              <Box sx={{ flexGrow: 1 }} />
            </Stack>

            <Stack direction="row" spacing={2}>
              <TableCell className="sub-title" width="50%" colSpan={2}>
                <Stack direction="row" spacing={1}>
                    <Typography className="text-typography" width="100px">
                      Suppliers:
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      fullWidth
                      options={suppliers}
                      getOptionLabel={(option) => (option.label ? option.label : "")}
                      onChange={(e, value) => {
                        setFieldValue("supplier_id", value?.id);
                        setSupplierSelected({ label: value?.label, id: value?.id });
                      }}
                      renderInput={(params) => (
                        <TextField
                          placeholder="Choose"
                          size="small"
                          {...params}
                          error={Boolean(
                            touched.supplier_id && errors.supplier_id
                          )}
                          helperText={touched.supplier_id && errors.supplier_id}
                        />
                      )}
                    />
                  </Stack>
              </TableCell>

              <Box sx={{ flexGrow: 1 }} />

              <TableCell className="sub-title" width="50%">
                <Stack direction="row" spacing={1}>
                  <Typography className="text-typography" width="150px"> InStock On:</Typography>

                  <LocalizationProvider
                    className="date-controll"
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      mask="__-__-____"
                      value={values.receive_Date}
                      onChange={(e) => setFieldValue("receive_Date", e)}
                      renderInput={(params) => (
                        <TextField
                          className="text-field"
                          size="small"
                          {...params}
                          fullWidth
                          type="date"
                          defaultValue={values.receive_Date}
                          error={Boolean(touched.receive_Date && errors.receive_Date)}
                          helperText={touched.receive_Date && errors.receive_Date}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </TableCell>
            </Stack>
            
          </Box>

          <TableContainer className="container">
            <Table className="table" aria-label="simple table">
              <TableHead>
                <TableRow className="header-row">
                  <TableCell className="header-title" align="left" width="40%">
                    Product Name
                  </TableCell>
                  <TableCell
                    className="header-title"
                    align="center"
                    width="15%"
                  >
                    Qty
                  </TableCell>
                  <TableCell
                    className="header-title"
                    align="center"
                    width="15%"                    
                  >
                    Unit Price
                  </TableCell>
                  <TableCell
                    className="header-title"
                    align="center"
                    width="20%"                    
                  >
                    Expire At
                  </TableCell>
                  <TableCell className="header-title" width="10%" align="right">
                    <IconButton onClick={hanleAddProduct}>
                      <AddCircleOutlineRoundedIcon sx={{ color: "green" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>

              <ListPurchase
                items={item}
                checkButton={checkButton}
                totalAmount={totalAmount}
                deleteItem={deleteItem}
                setCreateQty={setCreateQty}
                setCreateProduct={setCreateProduct}
                setCreateUnit_Price={setCreateUnit_Price}
                setUpdateExpire_At={setUpdateExpire_At}
                checkFieldRequired={checkFieldRequired}
                duplicate={duplicate}
              />
            </Table>
          </TableContainer>

          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Typography className="textfield">Total Amount:</Typography>
            <NumberFormat
              value={totalAmount?.toFixed(2)}
              thousandSeparator={true}
              displayType={"text"}
              renderText={(value, props) => (
                <div className="body" {...props}>
                  $ {value}
                </div>
              )}
            />
          </Stack>

          <Stack direction="column" spacing={1} sx={{ mt: 2 }} width="100%">
            <Typography className="field"> Remark </Typography>

            <TextField
              multiline
              rows={3}
              size="small"
              fullWidth
              placeholder="remark"
              {...getFieldProps("remark")}
            />
          </Stack>

          {loading ? (
            <Button
              className="btn-create"
              variant="text"
              fullWidth
              sx={{ mt: 2 }}
            >
              Loading...
            </Button>
          ) : (
            <Button
              className="btn-create"
              variant="text"
              fullWidth
              type="submit"
              sx={{ mt: 2 }}
            >
              {checkButton}
            </Button>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
}
