import React from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import "../../Component/StorageRoom/modalaction.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {Stack, Typography, IconButton, Modal } from '@mui/material';

//component
import DeleteUser from './DeleteUser';
import UpdateUser from './UpdateUser';

export default function UserAction({RowData, setRefetch, setAlert,  setMessage, setchekMessage}) {
  

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openEl = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleCloseEl = () => {
      setAnchorEl(null);
    };
  
    //Modal Update
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //Modal Delete
    const [openDel, setOpenDel] = React.useState(false);
    const handleOpenDel = () => setOpenDel(true);
    const handleCloseDel = () => setOpenDel(false);


  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon sx={{ color: "#3d3d3d" }} />
      </IconButton>

      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={openEl}
        onClose={handleCloseEl}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={()=>{handleOpen(); handleCloseEl()}}>
         <Stack direction="row" spacing={1}>
            <AppRegistrationOutlinedIcon className="icon-menu-update" />
            <Typography className="text-menu"> Update </Typography>
          </Stack>
        </MenuItem>

        <MenuItem onClick={()=>{handleOpenDel(); handleCloseEl()}}>
          <Stack direction="row" spacing={1}>
            <DeleteOutlineOutlinedIcon className="icon-menu-delete" />
            <Typography className="text-menu"> Delete </Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <Modal open={open} >
        <UpdateUser
          RowData={RowData}
          handleClose={handleClose}
          setRefetch={setRefetch}
          setAlert={setAlert}
          setMessage={setMessage}
          setchekMessage={setchekMessage}
        />
      </Modal>

      <Modal open={openDel}>
        <DeleteUser
          handleCloseDel={handleCloseDel}
          RowData={RowData}
          setRefetch={setRefetch}
          setAlert={setAlert}
          setMessage={setMessage}
          setchekMessage={setchekMessage}
        />
      </Modal>
    </div>
  );
}
