import React from "react";
import "./productdetails.scss";
import { Box, Typography, Stack, Skeleton, Grid } from "@mui/material";
import Shop from "../../../Assets/new-dashboard/shopping-cart.png";
import Money from "../../../Assets/new-dashboard/money-bag.png";
import Stock from "../../../Assets/new-dashboard/stock-market.png";
import Order from "../../../Assets/new-dashboard/order.png";
import {
  GET_NO_PURCHASE,
  GET_TOTAL_COST,
  GET_TOTAL_CANCELORDER,
  GET_QTY_RECIEVED,
  GET_STOCK_IN_OUT,
} from "../../../Schema/dashboard";
import { useQuery } from "@apollo/client";
import NumberFormat from "react-number-format";
import { useEffect } from "react";

export default function ProductOverView() {

  const [loading, setLoading] = React.useState(true);

  const { data } = useQuery(GET_NO_PURCHASE, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: (error) => {
      setLoading(true);
    },
  });

  const { data: getTotalStockInAndOut , refetch } = useQuery(GET_STOCK_IN_OUT, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: (error) => {
      setLoading(true);
    },
  });

  const { data: DataCancelOrder } = useQuery(GET_TOTAL_CANCELORDER, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: (error) => {
      setLoading(true);
    },
  });


  useEffect( () => {
    refetch();
  },[])
  
  console.log(getTotalStockInAndOut);

  if (loading) {
    return (
      <Box
        className="invetory"
        sx={{ width: "100%", height: "50%", padding: "20px" }}
      >
        <Box sx={{ width: "100%" }}>
          <Stack direction="column" spacing={1}>
            <Typography variant="body2" className="main-title">
              <Skeleton height={20} width="40%" variant="rectangular" />
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} mt={2}>
            <Skeleton height={120} width="100%" variant="rectangular" />
            <Skeleton height={120} width="100%" variant="rectangular" />
            <Skeleton height={120} width="100%" variant="rectangular" />
          </Stack>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="product-details">
      <Typography className="main-title"> Stock Overview </Typography>

      <Box className="grid-over">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className="card-overview">
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <img src={`${Shop}`} width="60px" height="60px" />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Stack direction="column" justifyContent="center" height="100%">
                    <Typography className="sub-body" mt={1}>
                      Stock In
                    </Typography>
                    <Typography className="num-over" variant="h5">
                      {
                        <NumberFormat
                          value={getTotalStockInAndOut?.getTotalStockInAndOut?.totalStockIn ? getTotalStockInAndOut?.getTotalStockInAndOut?.totalStockIn : 0}
                          thousandSeparator={true}
                          displayType={"text"}
                          renderText={(value, props) => (
                            <div {...props}>{value}</div>
                          )}
                        />
                      }
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={4}>
            <Box className="card-overview">
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <img src={`${Money}`} width="60px" height="60px" />
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Typography className="sub-body" mt={1}>
                    Cost
                  </Typography>
                  <Typography className="num-over" variant="h5">
                    $
                    {
                      <NumberFormat
                        value={DataCost?.getTotalCost?.toFixed(2)}
                        thousandSeparator={true}
                        displayType={"text"}
                        renderText={(value, props) => (
                          <div {...props}>{value}</div>
                        )}
                      />
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid> */}

          <Grid item xs={12} sm={12} md={6}>
            <Box className="card-overview">
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <img src={`${Stock}`} width="60px" height="60px" />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography className="sub-body" mt={1}>
                    Stock Out
                  </Typography>
                  <Typography className="num-over" variant="h5">
                    {
                      <NumberFormat
                        value={getTotalStockInAndOut?.getTotalStockInAndOut?.totalStockOut ? (getTotalStockInAndOut?.getTotalStockInAndOut?.totalStockOut)?.toFixed(2) : 0}
                        thousandSeparator={true}
                        displayType={"text"}
                        renderText={(value, props) => (
                          <div {...props}>{value}</div>
                        )}
                      />
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
