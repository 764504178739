import React, { useState } from "react";
import { Typography, Stack, Avatar, Popover } from "@mui/material";
import "./viewsupplies.scss";

export default function HoverpopoverSuppliers({ row }) {
  const [over, setOver] = useState(false);

  //hover provider
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  //end

  return (
    <>
      {row?.image_Src === "" ? (
        <Stack direction="row" spacing={4}>
          <Avatar
            alt={row?.name}
            src={`${row?.image_src}`}
            className="avatar"
            variant="rounded"
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onMouseOver={() => {
              setOver(true);
            }}
            onMouseOut={() => setOver(false)}
            sx={{ width: 50, height: 50, fontSize: "15px" }}
          />
          <Typography className="product-name">{row?.name}</Typography>
        </Stack>
      ) : (
        <Stack direction="row" spacing={4}>
          <Avatar
            className="avatar"
            alt="arrow"
            src={`${row?.image_Src}`}
            variant="rounded"
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onMouseOver={() => setOver(true)}
            onMouseOut={() => setOver(false)}
            sx={{ width: 50, height: 50, fontSize: "13px" }}
          />

          <Typography className="product-name">{row?.name}</Typography>
        </Stack>
      )}

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {over &&
          (row?.image_Src === "" ? (
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                alt={row?.name}
                src={`${row?.image_src}`}
                className="avatar"
                variant="rounded"
                sx={{ width: 350, height: 350, fontSize: "50px" }}
              />
            </Stack>
          ) : (
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                className="image"
                src={`${row?.image_Src}`}
                alt="arrow"
                variant="rounded"
                sx={{ width: 350, height: 350, fontSize: "50px" }}
              />
            </Stack>
          ))}
      </Popover>
    </>
  );
}
