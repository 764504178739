import React, { useState } from "react";
//import formik
import {
  Stack,
  Grid,
  Box,
  Avatar,
  Button,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import "./modaluserAdd.scss";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import { useFormik, Form, FormikProvider } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useMutation } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import moment from "moment";
import axios from "axios";
import { UPDATE_USER } from "../../Schema/user";

const Input = styled("input")({
  display: "none",
});

function UpdateUser({
  RowData,
  handleClose,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
}) {
  const [loading, setLoading] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: ({ updateUser }) => {
      if (updateUser?.status) {
        setchekMessage("success");
        setMessage(updateUser?.message);
        setAlert(true);
        handleClose();
        setTimeout(() => {
          setRefetch();
        }, 1800);
        setLoading(false);
      } else {
        setchekMessage("error");
        setMessage(updateUser?.message);
        setAlert(true);
        setLoading(false);
      }
    },
    onError: (error) => {
      setchekMessage("error");
      setMessage(error.message);
      setAlert(true);
      setLoading(false);
    },
  });

  // upload Image
  const [imageFile, setImageFile] = useState(null);

  const newDate = moment(new Date()).format("MMdYYYY");
  const uploadImage = async (file, values) => {
    // console.log("files::", file);

    //compressfile to small
    if (!file) return;
    const formData = new FormData();
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    //change image name
    let newName = `${uuidv4()}${newDate}${file.name.split(".").pop()}`;
    //convert extention
    var newFile = new File([compressedFile], `${newName}.png`, {
      type: "image/png",
    });
    formData.append("image", newFile);

    //post to store at backend
    await axios
      .post(
        `${process.env.REACT_APP_UPLOAD_URL}/api/inventory-mart/upload/`,
        formData,
        config
      )
      //catch image url
      .then(async function (response) {
        updateUser({
          variables: {
            userId: RowData?._id,
            input: {
              image_name: imageFile.name,
              image_src: `${process.env.REACT_APP_UPLOAD_URL}/api${response?.data}`,
              ...values,
            },
          },
        });
      });
  };

  //formik
  const UserUpdate = Yup.object().shape({
    firsName: Yup.string().required("required firstName!"),
    lastName: Yup.string().required("required lastName!"),
    role: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      firsName: RowData?.firsName,
      lastName: RowData?.lastName,
      role: RowData?.role,
    },

    validationSchema: UserUpdate,
    onSubmit: async (values) => {
      if (imageFile) {
        uploadImage(imageFile, values);
      } else {
        updateUser({
          variables: {
            userId: RowData?._id,
            input: {
              image_name: RowData?.image_name,
              image_src: RowData?.image_Src,
              ...values,
            },
          },
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Stack className="modal-user" direction="row" spacing={2}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box className="modal">
            <Stack direction="row" spacing={2}>
              <Typography variant="h6" className="title">
                UPDATE USER
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={() => handleClose()}>
                <ClearIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>

            <Grid item container className="title">
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={(event) => {
                    setImageFile(event.target.files[0]);
                  }}
                />
                <Avatar
                  className="upload-image"
                  sx={{
                    cursor: "pointer",
                    transition: "0.6s",
                    boxShadow: 3,
                    "&:hover": { boxShadow: 6, transition: "0.3s" },
                  }}
                  alt="Remy Sharp"
                  src={
                    imageFile
                      ? URL.createObjectURL(imageFile)
                      : RowData?.image_src === ""
                      ? null
                      : RowData?.image_src
                  }
                />
              </label>
            </Grid>

            <Grid item container className="body-title">
              Profile
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className="sub-title">Firstname</Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  placeholder="firstname"
                  {...getFieldProps("firsName")}
                  error={Boolean(touched.firsName && errors.firsName)}
                  helperText={touched.firsName && errors.firsName}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="sub-title">Lastname</Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  placeholder="lastname"
                  {...getFieldProps("lastName")}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="sub-title"> Position </Typography>
                <FormControl
                  className="form-controll-select"
                  size="small"
                  fullWidth
                >
                  <Select
                    IconComponent={(props) => (
                      <KeyboardArrowDownOutlinedIcon {...props} />
                    )}
                    {...getFieldProps("role")}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="admin">
                      <Typography>Admin</Typography>
                    </MenuItem>

                    <MenuItem value="superadmin">
                      <Typography>Supper Admin</Typography>
                    </MenuItem>

                    <MenuItem value="stock_manager">
                      <Typography>Stock Manager</Typography>
                    </MenuItem>

                    <MenuItem value="stock_controller">
                      <Typography>Stock Controller</Typography>
                    </MenuItem>

                    <MenuItem value="cashier">
                      <Typography>Cashier</Typography>
                    </MenuItem>
                    
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} mt={2}>
                {loading ? (
                  <Button className="btn-create" variant="text" fullWidth>
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    size="large"
                    type="submit"
                    variant="text"
                  >
                    UPDATE
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </Stack>
  );
}

export default UpdateUser;
