import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import "./createshop.scss";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
import { useMutation } from "@apollo/client";
//components

import { CREATE_SHOP, UPDATE_SHOP } from "../../Schema/shops";

export default function CreateShop({
  open,
  modalTitle,
  handleClose,
  editData,
  setRefetch,
  setAlert,
  setMessage,
  setchekMessage,
}) {
  const [loading, setLoading] = useState(false);

  const [createShop] = useMutation(CREATE_SHOP, {
    onCompleted: ({ createShop }) => {
      if (createShop?.status === true) {
        setchekMessage("success");
        setMessage(createShop?.message);
        setAlert(true);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setchekMessage("error");
        setMessage(createShop?.message);
        setAlert(true);
        setLoading(false);
      }
    },
    onError: (addError) => {
      setchekMessage("error");
      setMessage(addError.message);
      setAlert(true);
      setLoading(false);
    },
  });

  const [updateShop] = useMutation(UPDATE_SHOP, {
    onCompleted: ({ updateShop }) => {
      // console.log("update:", updateShop);
      if (updateShop?.status === true) {
        setchekMessage("success");
        setMessage(updateShop?.message);
        setAlert(true);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setchekMessage("error");
        setMessage(updateShop?.message);
        setAlert(true);
        setLoading(false);
      }
    },
    onError: (error) => {
      setLoading(false);
    },
  });


  const AddShops = Yup.object().shape({
    name: Yup.string().required("require shop name!"),
    name_kh: Yup.string(),
    email: Yup.string(),
    phone: Yup.string(),
    address: Yup.string(),
    vat: Yup.string(),
    remark: Yup.string(),
  });


  const formik = useFormik({
    initialValues: {
      name: "",
      name_kh: "",
      email: "",
      phone: "",
      address: "",
      remark: "",
      vat: "",
    },

    validationSchema: AddShops,
    onSubmit: (values) => {
      setLoading(true);

      if (!editData) {
        createShop({
          variables: {
            input: {
              ...values,
            },
          },
        });
      } else {
        updateShop({
          variables: {
            shopId: editData?._id,
            input: {
              ...values,
            },
          },
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps , setFieldValue} = formik;

  useEffect( () => {
    if(editData) {
      setFieldValue("name", editData?.name)
      setFieldValue("name_kh", editData?.name_kh ? editData?.name_kh : editData?.name)
      setFieldValue("email", editData?.email)
      setFieldValue("phone", editData?.phone)
      setFieldValue("address", editData?.address)
      setFieldValue("remark", editData?.remark)
      setFieldValue("vat", editData?.vat ? editData?.vat : "");
    }
  },[open])

  return (

      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box spacing={5} className="shop">
            <Stack direction="row" spacing={2}>
              <Typography variant="h6" className="title">
                {modalTitle === "CREATE SHOP"
                  ? "CREATE SHOP"
                  : "UPDATE SHOP"}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={handleClose}>
                <ClearIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography className="Sub-title"> Name (KH)</Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  placeholder="name"
                  {...getFieldProps("name_kh")}
                  error={Boolean(touched.name_kh && errors.name_kh)}
                  helperText={touched.name_kh && errors.name_kh}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography className="Sub-title"> Name (EN)</Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  placeholder="name"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography className="Sub-title">Email</Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  placeholder="email"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography className="Sub-title">Phone Number</Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  placeholder="phone-number"
                  {...getFieldProps("phone")}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography className="Sub-title">Address</Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  placeholder="address"
                  {...getFieldProps("address")}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography className="Sub-title">Vattin</Typography>
                <TextField
                  className="text-field" 
                  size="small"
                  fullWidth
                  placeholder="vat"
                  {...getFieldProps("vat")}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography className="Sub-title">Remark</Typography>
                <TextField
                  className="text-field"
                  rows={3}
                  size="small"
                  fullWidth
                  placeholder="remark"
                  {...getFieldProps("remark")}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                {loading ? (
                  <Button className="btn-create" variant="text" fullWidth>
                    loading...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    variant="text"
                    fullWidth
                    type="submit"
                  >
                    {editData ? "UPDATE" : "CREATE"}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
  );
}
