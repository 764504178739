import React, { useReducer, createContext, useEffect, useState} from 'react';
import { auth } from '../firebase';


// reducer
const firebaseReducer = (state, action) => {
    switch (action.type) {
        case 'LOGGED_IN_USER':
            return { ...state, user: action.payload };
        default:
            return state;
    }
};


// state
const initialState = {
    user: null
};

// create context
const AuthContext = createContext();


// context provider
const AuthProvider = ({ children }) => {
    
    const [exchangeRate,setExchangeRate] = useState(4000);

    const [state, dispatch] = useReducer(firebaseReducer, initialState);

    const [role, setRole] = useState();    

    useEffect( () => {
        async function checkRole() {
          let userRole = await JSON.parse(window.localStorage.getItem("role"));
          if (userRole) {
            setRole(userRole);
            return;
          } 
        }       
        checkRole();        
    }, [role]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                console.log("user" , user?.auth?.currentUser?.displayName);
                const idTokenResult = await user?.accessToken;
                // console.log(idTokenResult , "token")
                dispatch({
                    type: 'LOGGED_IN_USER',
                    payload: {
                        email: user?.email,
                        token: idTokenResult,
                    }
                });
            } else {
                dispatch({
                    type: 'LOGGED_IN_USER',
                    payload: null
                });
            }
        });
        // cleanup
        return () => unsubscribe();
    }, []);


    // Alert Message Action
    const [openInfo, setOpenInfo] = useState(false); 
    const [messageData, setMessageData] = useState(null);
    
    const setAlertInfo = (open , message) => { 
        setOpenInfo(open);    
        setMessageData(message);       
    }
    const alertInfo = () => {
        return { openInfo: openInfo, messageData: messageData};
    }


    const permissionRoleAccess = (roles) => {
        // role = eployee
        const getRole = roles.filter((rol) => rol === role);
        if (getRole.length > 0) {
          return true;
        } else {
          return false;
        }
    };

    const value = { exchangeRate , setExchangeRate , state, dispatch , alertInfo, setAlertInfo , permissionRoleAccess , setRole };

    
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// export
export { AuthContext, AuthProvider };