import * as React from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import "./dashboard.scss";
//Component
import Profile from "../Component/Menu/Profile";
import PendingStockOut from "../Component/Dashboard/PurchasePending/PendingStockOut";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
import InventorySammary from "../Component/Dashboard/Inentorysammary/InventorySammary";
import NoOfUser from "../Component/Dashboard/User/NoOfUser";
import ProductOverView from "../Component/Dashboard/Product/ProductOverView";
import ProductDetails from "../Component/Dashboard/Product/ProductDetails";
import PurchaseProductPending from "../Component/Dashboard/PurchasePending/PurchaseProductPending";
import ChartInventory from "../Component/Dashboard/ChartInventory/ChartInventory";
import ChartPurchase from "../Component/Dashboard/ChartPurchase/ChartPurchase";

export default function Dashboard() {

    
  return (
    <div className="dashboard-page">
        <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
            <Stack direction="column" justifyContent="center">
              <IconMenuResponsive />
            </Stack>
            <Stack direction="column" justifyContent="center">
              <Box className="slash" />
            </Stack>
            <Stack sx={{ml: "16px"}} direction="column" justifyContent="center">
              <Typography className="color">Dashboard</Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Profile />
        </Stack>

        <Box className="container">
            <Grid container spacing={4}>
                
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Stack direction="row" justifyContent="center" className="grid-item">
                        <InventorySammary/>
                    </Stack>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                    <Stack direction="row" justifyContent="center" className="grid-item">
                        <NoOfUser />
                    </Stack>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                    <Stack direction="row" justifyContent="center" className="grid-item">
                        <ProductOverView />
                    </Stack>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                    <Stack direction="row" justifyContent="center" className="grid-item">
                        <ProductDetails />
                    </Stack>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                    <Stack direction="row" justifyContent="center" className="grid-item">
                        <ChartPurchase />
                    </Stack>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                    <Stack direction="row" justifyContent="center" className="grid-item">
                        <ChartInventory />
                    </Stack>
                </Grid>

                {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                    <Stack direction="row" justifyContent="center" className="grid-item">
                        <PurchaseProductPending />
                    </Stack>
                </Grid> */}
                
                {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                    <Stack direction="row" justifyContent="center" className="grid-item">
                        <PendingStockOut />
                    </Stack>
                </Grid> */}

            </Grid>
        </Box>

    </div>
  );
}
