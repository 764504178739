import React, { useState } from "react";
import {
  Stack,
  Grid,
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Avatar,
} from "@mui/material";
import "./modaluserAdd.scss";
import * as Yup from "yup";
import { string, ref } from "yup";
import { styled } from "@mui/material/styles";
import { useFormik, Form, FormikProvider } from "formik";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from "@mui/material/FormControl";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import moment from "moment";
import axios from "axios";
import { useMutation } from "@apollo/client";
import { CREATE_USER } from "../../Schema/user";

const Input = styled("input")({
  display: "none",
});

function ModalUserAdd({
  refetch,
  handleClose,
  setAlert,
  setMessage,
  setchekMessage,
}) {
  //hiden and show vibility
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [loading, setLoading] = useState(false);

  // Create
  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: ({ createUser }) => {
      if (createUser?.status === true) {
        setchekMessage("success");
        setMessage(createUser?.message);
        setAlert(true);
        setTimeout(() => {
          refetch();
        }, 1800);
        setLoading(false)
        handleClose();
      } else {
        setchekMessage("error");
        setMessage(createUser?.message);
        setAlert(true);
        setLoading(false)
      }
    },
    onError: (error) => {
      setchekMessage("error");
      setMessage(error.message);
      setAlert(true);
      setLoading(false)
    },
  });

  // upload Image
  const [imageFile, setImageFile] = useState(null);

  const newDate = moment(new Date()).format("MMdYYYY");
  const uploadImage = async (file, values) => {
    // console.log("files::", file);

    //compressfile to small
    if (!file) return;
    const formData = new FormData();
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    //change image name
    let newName = `${uuidv4()}${newDate}${file.name.split(".").pop()}`;
    //convert extention
    var newFile = new File([compressedFile], `${newName}.png`, {
      type: "image/png",
    });
    formData.append("image", newFile);

    //post to store at backend
    await axios
      .post(
        `${process.env.REACT_APP_UPLOAD_URL}/api/inventory-mart/upload/`,
        formData,
        config
      )
      //catch image url
      .then(async function (response) {
        createUser({
          variables: {
            input: {
              image_name: imageFile.name,
              image_src: `${process.env.REACT_APP_UPLOAD_URL}/api${response?.data}`,
              ...values,
            },
          },
        });
      });
  };

  // Formik
  const UserAdd = Yup.object().shape({
    firsName: Yup.string().required("required firsName!"),
    lastName: Yup.string().required("required lastName!"),
    email: Yup.string().email("Invalid email").required("Required"),
    role: Yup.string().required("choose"),
    password: Yup.string()
      .required("required password!")
      .min(6, "input more than 6 character!"),
    confirmPassword: string()
      .required("confirm password!")
      .oneOf([ref("password")], "password isn't enough!"),
  });

  const formik = useFormik({
    initialValues: {
      firsName: "",
      lastName: "",
      email: "",
      password: "",
      role: "stock_controller",
      confirmPassword: "",
    },

    validationSchema: UserAdd,
    onSubmit: (values) => {
      const newUser = {
        firsName: values?.firsName,
        lastName: values?.lastName,
        email: values?.email,
        password: values?.password,
        role: values?.role,
      };

      if (imageFile) {
        uploadImage(imageFile, newUser);
      } else {
        createUser({
          variables: {
            input: {
              image_name: "",
              image_src: "",
              ...newUser,
            },
          },
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  //End Formik

  return (
    <Stack className="modal-user" direction="row" spacing={2}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit} validationSchema>
          <Box className="modal">
            <Stack direction="row" spacing={2}>
              <Typography variant="h6" className="title">
                CREATE USER
              </Typography>

              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={() => handleClose()}>
                <ClearIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>

            <Grid item container className="title">
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*, capture=camera"
                  capture="”camera"
                  id="icon-button-file"
                  type="file"
                  onChange={(event) => {
                    setImageFile(event.target.files[0]);
                  }}
                />
                {imageFile ? (
                  <Avatar
                    className="upload-image"
                    alt="Image"
                    src={URL.createObjectURL(imageFile)}
                  />
                ) : (
                  <Avatar className="upload-image" alt="Image" />
                )}
              </label>
            </Grid>

            <Grid item container className="body-title">
              Profile
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className="sub-title"> FirstName </Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  placeholder="firsName"
                  {...getFieldProps("firsName")}
                  error={Boolean(touched.firsName && errors.firsName)}
                  helperText={touched.firsName && errors.firsName}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="sub-title"> LastName </Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  placeholder="lastName"
                  {...getFieldProps("lastName")}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="sub-title">Position</Typography>
                <FormControl
                  className="form-controll-select"
                  size="small"
                  fullWidth
                >
                  <Select
                    IconComponent={(props) => (
                      <KeyboardArrowDownOutlinedIcon {...props} />
                    )}
                    {...getFieldProps("role")}
                    error={Boolean(touched.role && errors.role)}
                    helperText={touched.role && errors.role}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="admin">
                      <Typography>Admin</Typography>
                    </MenuItem>

                    <MenuItem value="superadmin">
                      <Typography>Supper Admin</Typography>
                    </MenuItem>

                    <MenuItem value="stock_manager">
                      <Typography>Stock Manager</Typography>
                    </MenuItem>

                    <MenuItem value="stock_controller">
                      <Typography>Stock Controller</Typography>
                    </MenuItem>

                    <MenuItem value="cashier">
                      <Typography>Cashier</Typography>
                    </MenuItem>
                    
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography className="sub-title">Email</Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  placeholder="email"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="sub-title">Password</Typography>
                <TextField
                  className="text-field"
                  type={show ? "text" : "password"}
                  size="small"
                  fullWidth
                  placeholder="password"
                  autoComplete="current-password"
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" onClick={handleClick}>
                        {show ? (
                          <VisibilityIcon className="icon" />
                        ) : (
                          <VisibilityOffIcon className="icon" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="sub-title">Confirm Password</Typography>
                <TextField
                  className="text-field"
                  type={show ? "text" : "password"}
                  name="confirmPassword"
                  size="small"
                  fullWidth
                  placeholder="password"
                  autoComplete="current-password"
                  {...getFieldProps("confirmPassword")}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </Grid>

              <Grid item xs={12} mt={2}>
              {loading ? (
                <Button className="btn-create" variant="text" fullWidth>
                  Loading...
                </Button>
              ) : (
                <Button
                  className="btn-create"
                  size="large"
                  type="submit"
                  variant="text"
                >
                  CREATE
                </Button>
              )}
              </Grid>


            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </Stack>
  );
}

export default ModalUserAdd;
