import React, {useState} from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import "./deletestorageroom.scss";
import ClearIcon from "@mui/icons-material/Clear";
import { useMutation } from "@apollo/client";
import { VOID_PURCHASE } from "../../Schema/purchase";
import { sendMessage } from "../Telegram/TelegrameClient";


export default function VoidPurchase({
  row,
  setRefetch,
  setAlert,
  setMessage,
  setCheckMessage,
  handleClose,
}) {

  const [valueVoid, setValueVoid] = useState("");

  
  const [voidingPurchas] = useMutation(VOID_PURCHASE, {
    onCompleted: async ({ voidingPurchas }) => {

      if (voidingPurchas?.status) {
        setCheckMessage("success");
        setMessage(voidingPurchas?.message);
        setAlert(true);
        setRefetch();
        handleClose();

        await sendMessage({content: "User Voiding Purchase"})

      } else {
        setCheckMessage("error");
        setMessage(voidingPurchas?.message);
        setAlert(true);
      }
    },
    onError: (error) => {
        setCheckMessage("error");
        setMessage(error.message);
        setAlert(true);
    },
  });

  const handleVoid = () => {
    voidingPurchas({
      variables: {
        purchaseId: row?._id,
      },
    });
  };

  return (
    <Box className="delete-supplies">
      <Stack direction="row" spacing={5}>
        <Typography className="title" variant="h6">
          VOID PURCHASE
        </Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <IconButton onClick={handleClose}>
          <ClearIcon sx={{ color: "red" }} />
        </IconButton>
      </Stack>

      <Stack direction="row" spacing={5} width="100%">
        <Typography variant="subtitle1" className="simple-text">
          Do you want to void this purchase?
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mt: 2 }}
      >
        <Typography variant="subtitle1" className="simple-text">
          Please input 
        </Typography>
        <Typography className="body-text" >
            Purchase
        </Typography>
        <Typography variant="subtitle1" className="simple-text">
          to delete
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mb: 4 }}
      >
        <TextField
          className="text-input"
          size="small"
          fullWidth
          onChange={(e) => setValueVoid(e.target.value)}
        />
      </Stack>

      <Stack direction="row" spacing={5}>
        {valueVoid === "Purchase" ? (
          <Button
            onClick={handleVoid}
            sx={{ ":hover": { backgroundColor: "red", border: "none" } }}
            className="btn-void"
            variant="outlined"
            fullWidth
          >
           Void Now
          </Button>
        ) : (
          <Button variant="outlined" className="btn-delete" fullWidth>
            Void
          </Button>
        )}
      </Stack>
    </Box>
  );
}
